import { valibotResolver } from '@hookform/resolvers/valibot';
import { array, custom, minLength, nullable, number, object, optional, pipe, string } from 'valibot';
import { SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../../../../constants';
export var actionItemFormDescriptionSchema = function (selectedSystemType, isEdit) {
    return valibotResolver(object({
        title: pipe(string('Title should be string'), minLength(1, 'Title cannot be empty'), custom(function (title) { return typeof title === 'string' && /^[^\u0400-\u04FF]+$/.test(title); }, 'You cannot use Cyrillic characters in title')),
        urgency: number(),
        tags: pipe(array(object({ id: number(), name: string() }), 'Tags should be an array'), minLength(selectedSystemType === SYSTEM_TYPE_REMASTERED ? 1 : 0, 'Tags cannot be empty')),
        faultRecommendations: pipe(array(optional(nullable(object({
            componentType: optional(nullable(number())),
            fault: optional(nullable(number())),
            severity: optional(nullable(number())),
            recommendations: array(number()),
        }))), 'Recommendations should be an array'), minLength(selectedSystemType === SYSTEM_TYPE_REMASTERED && !isEdit ? 1 : 0, 'Recommendations cannot be empty')),
        description: pipe(string('Description should be string'), minLength(selectedSystemType === SYSTEM_TYPE_REMASTERED ? 0 : 1, 'Description cannot be empty'), custom(function (description) {
            return selectedSystemType === SYSTEM_TYPE_REMASTERED ||
                (description !== '<p><br></p>' && description !== '<p></p>');
        }, 'Description cannot be empty'), custom(function (description) {
            return typeof description === 'string' &&
                (description.length === 0 || /^[^\u0400-\u04FF]+$/.test(description));
        }, 'You cannot use Cyrillic characters in description')),
    }), [
        custom(function (data) {
            if (selectedSystemType === SYSTEM_TYPE_AMAZON || isEdit) {
                return true;
            }
            // @ts-ignore
            var faultRecommendations = data.faultRecommendations;
            return faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .every(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.componentType; });
        }, 'Component Type cannot be empty'),
        custom(function (data) {
            if (selectedSystemType === SYSTEM_TYPE_AMAZON) {
                return true;
            }
            // @ts-ignore
            var faultRecommendations = data.faultRecommendations;
            var everyFaultRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .every(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.fault; });
            if (!isEdit) {
                return everyFaultRecommendationFilled;
            }
            var someRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { var _a; return ((_a = faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            var someSeverityFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.severity; });
            return someRecommendationFilled || someSeverityFilled ? everyFaultRecommendationFilled : true;
        }, 'Fault Name cannot be empty'),
        custom(function (data) {
            if (selectedSystemType === SYSTEM_TYPE_AMAZON) {
                return true;
            }
            // @ts-ignore
            var faultRecommendations = data.faultRecommendations;
            var everyRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .every(function (faultRecommendation) { var _a; return ((_a = faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            if (!isEdit) {
                return everyRecommendationFilled;
            }
            var someFaultRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.fault; });
            var someSeverityFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.severity; });
            return someFaultRecommendationFilled || someSeverityFilled ? everyRecommendationFilled : true;
        }, 'Recommendations cannot be empty'),
        custom(function (data) {
            if (selectedSystemType === SYSTEM_TYPE_AMAZON) {
                return true;
            }
            // @ts-ignore
            var faultRecommendations = data.faultRecommendations;
            var everySeverityFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .every(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.severity; });
            if (!isEdit) {
                return everySeverityFilled;
            }
            var someRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { var _a; return ((_a = faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            var someFaultRecommendationFilled = faultRecommendations
                .filter(function (faultRecommendation) { return faultRecommendation; })
                .some(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.fault; });
            return someRecommendationFilled || someFaultRecommendationFilled ? everySeverityFilled : true;
        }, 'Severity cannot be empty'),
    ]);
};
