import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useContext, useMemo } from 'react';
import { IoAlert } from 'react-icons/io5';
import { Badge } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { SYSTEM_TYPE_REMASTERED } from '../../../../../../constants';
import { SystemTypeContext } from '../../../../../context/SystemTypeContext';
import { FftChartsStore } from '../../../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../../../store/charts/chartsContent/InstallationPointStore';
import { InListStore } from '../../../../../store/charts/inList/InListStore';
import '../../../../../styles/charts/topBar/Labels.scss';
import AbnormalListLabel from './Labels/AbnormalList';
import ActionItemLabel from './Labels/ActionItem';
import AlertListLabel from './Labels/AlertList';
import BadDataLabel from './Labels/BadData';
import DetachedLabel from './Labels/DetachedList';
import HeatsinkLabel from './Labels/Heatsink';
import NoDataLabel from './Labels/NoData';
import NodeGatewayInfo from './Labels/NodeGatewayInfo';
import PreActionItemLabel from './Labels/PreActionItem';
import SeverityIncreasedLabel from './Labels/SeverityIncreased';
import WWAiLabel from './Labels/WWAi';
import WatchListLabel from './Labels/WatchList';
import DeactivationInfo from './components/DeactivationInfo';
import FacilityNotes from './components/FacilityNotes';
import FftAlertsInfo from './components/FftAlertsInfo';
import StartupMode from './components/StartupMode';
import TooltipBlockData from './components/TooltipBlockData/TooltipBlockData';
import { getExistingDataInList, isVisibleStartupMode } from './features';
var TopbarLabels = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var inList = InListStore(function (state) { return state.inList; });
    var fftAlerts = FftChartsStore(function (state) { return state.fftAlerts; });
    var fftAlertsExists = useMemo(function () { return fftAlerts && Object.values(fftAlerts).some(function (alerts) { return alerts.length > 0; }); }, [fftAlerts]);
    var _a = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        startupMode: state.startupMode,
        facilityNotes: state.facilityNotes,
    }); }, shallow), installationPoint = _a.installationPoint, startupMode = _a.startupMode, facilityNotes = _a.facilityNotes;
    var equipment = _get(installationPoint, 'equipment', {});
    var existInList = getExistingDataInList(installationPoint, inList);
    var isStartupModeVisible = isVisibleStartupMode(startupMode);
    return (_jsxs("div", { className: "tags-list", children: [isStartupModeVisible ? (_jsx("div", { className: "tag-item", children: _jsx(TooltipBlockData, { icon: _jsx(Badge, { className: "tag-startupMode", color: "danger", children: _jsx(IoAlert, {}) }), children: _jsx(StartupMode, { startupMode: startupMode }) }) })) : (_jsx(_Fragment, {})), facilityNotes.length > 0 ? (_jsx("div", { className: "tag-item", children: _jsx(TooltipBlockData, { icon: _jsx(Badge, { className: "tag-startupMode", color: "danger", children: _jsx(IoAlert, {}) }), children: _jsx(FacilityNotes, { facilityNotes: facilityNotes }) }) })) : (_jsx(_Fragment, {})), equipment.is_deactivated ? (_jsx("div", { className: "tag-item", children: _jsx(TooltipBlockData, { icon: _jsx(Badge, { className: "tag-startupMode", color: "danger", children: _jsx(IoAlert, {}) }), children: _jsx(DeactivationInfo, { deactivationInfo: equipment.deactivated }) }) })) : (_jsx(_Fragment, {})), selectedSystemType === SYSTEM_TYPE_REMASTERED ? _jsx(HeatsinkLabel, {}) : _jsx(_Fragment, {}), fftAlertsExists ? (_jsx("div", { className: "tag-item", children: _jsx(TooltipBlockData, { icon: _jsx(Badge, { className: "tag-startupMode", color: "primary", children: _jsx(IoAlert, {}) }), children: _jsx(FftAlertsInfo, {}) }) })) : (_jsx(_Fragment, {})), _jsx(ActionItemLabel, { existInList: existInList }), _jsx(PreActionItemLabel, { existInList: existInList }), _jsx(WatchListLabel, { existInList: existInList }), _jsx(BadDataLabel, { existInList: existInList }), _jsx(WWAiLabel, { existInList: existInList }), _jsx(AbnormalListLabel, { existInList: existInList }), _jsx(DetachedLabel, { existInList: existInList }), _jsx(NoDataLabel, { existInList: existInList }), _jsx(AlertListLabel, { existInList: existInList }), _jsx(SeverityIncreasedLabel, { existInList: existInList }), _jsx(NodeGatewayInfo, { installationPoint: installationPoint, inList: inList })] }));
};
export default memo(TopbarLabels);
