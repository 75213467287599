import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import Api from '../../../../../api/api';
var useQuery = useUntypedQuery;
export var UnassignedPointsQueryKey = function (_a) {
    var equipmentId = _a.equipmentId;
    return [
        'unassigned-points',
        { equipmentId: equipmentId },
    ];
};
export var UnassignedPointsQueryFn = function (_a) {
    var equipmentId = _a.equipmentId;
    return Api.get("all-analytics/equipment/".concat(equipmentId, "/asset/points/unassigned"));
};
export var useUnassignedPointsQuery = function (_a) {
    var equipmentId = _a.equipmentId;
    return useQuery({
        queryKey: UnassignedPointsQueryKey({ equipmentId: equipmentId }),
        queryFn: function () { return UnassignedPointsQueryFn({ equipmentId: equipmentId }); },
        select: function (_a) {
            var data = _a.data;
            return { data: data };
        },
        placeholderData: { data: [] },
        enabled: !!equipmentId,
    });
};
