import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'clsx';
import { useController, useFormContext } from 'react-hook-form';
import { ASSET_FOUNDATION_TYPES } from '../../../../../../constants';
import assetBuilderStyles from '../../../../styles.module.scss';
export var FoundationContent = function (_a) {
    var _b;
    var isDisabled = _a.isDisabled;
    var _c = useFormContext(), clearErrors = _c.clearErrors, control = _c.control;
    var _d = useController({
        name: 'data.foundationType',
        control: control,
    }), _e = _d.field, foundationType = _e.value, onChangeFoundationType = _e.onChange, error = _d.fieldState.error;
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Foundation Type" }) }), _jsx("div", { className: cx(assetBuilderStyles.sectionButtons, (_b = {},
                    _b[assetBuilderStyles.errorBorder] = !!(error === null || error === void 0 ? void 0 : error.message),
                    _b)), children: _jsx("div", { className: assetBuilderStyles.sectionButtons, children: ASSET_FOUNDATION_TYPES.map(function (type) {
                        var _a;
                        return (_jsx("button", { disabled: isDisabled, onClick: function () {
                                clearErrors('data.foundationType');
                                onChangeFoundationType(type);
                            }, className: cx(assetBuilderStyles.button, (_a = {},
                                _a[assetBuilderStyles.active] = foundationType === type,
                                _a)), children: type }, type));
                    }) }) }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: error === null || error === void 0 ? void 0 : error.message })] }));
};
FoundationContent.displayName = 'FoundationContent';
