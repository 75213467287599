var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { head as _head } from 'lodash';
import { NUMBER_OF_KEY, STAGES_NAMES_TO_KEY, STAGES_NAMES_TO_TYPE } from '../../../../../constants';
export var getAssetComponentId = function (key, subtype, assetModuleData) {
    var data = assetModuleData.find(function (_a) {
        var name = _a.name;
        return name === key;
    });
    var assetComponent = data === null || data === void 0 ? void 0 : data.assetComponents.find(function (_a) {
        var name = _a.name;
        return name.trim() === subtype;
    });
    return assetComponent === null || assetComponent === void 0 ? void 0 : assetComponent.id;
};
export var getAssetComponentTypeId = function (key, subtype, type, assetModuleData) {
    var data = assetModuleData.find(function (_a) {
        var name = _a.name;
        return name === key;
    });
    var assetComponent = data === null || data === void 0 ? void 0 : data.assetComponents.find(function (_a) {
        var name = _a.name;
        return name.trim() === subtype;
    });
    var assetComponentType = assetComponent === null || assetComponent === void 0 ? void 0 : assetComponent.assetComponentTypes.find(function (_a) {
        var name = _a.name;
        return name.trim() === type;
    });
    return assetComponentType === null || assetComponentType === void 0 ? void 0 : assetComponentType.id;
};
export var getTransmissionAssetComponentId = function (type, assetModuleData) {
    var _a;
    var data = assetModuleData.find(function (_a) {
        var name = _a.name;
        return name === 'Transmission';
    });
    var assetComponent = data === null || data === void 0 ? void 0 : data.assetComponents.find(function (_a) {
        var name = _a.name;
        return name.trim() === type;
    });
    return (_a = assetComponent === null || assetComponent === void 0 ? void 0 : assetComponent.id) !== null && _a !== void 0 ? _a : null;
};
export var getTransmissionAssetComponentTypeId = function (type, secondType, assetModuleData) {
    var _a;
    var data = assetModuleData.find(function (_a) {
        var name = _a.name;
        return name === 'Transmission';
    });
    var assetComponent = data === null || data === void 0 ? void 0 : data.assetComponents.find(function (_a) {
        var name = _a.name;
        return name.trim() === type;
    });
    var assetComponentType = assetComponent === null || assetComponent === void 0 ? void 0 : assetComponent.assetComponentTypes.find(function (_a) {
        var name = _a.name;
        return name.trim() === secondType;
    });
    return (_a = assetComponentType === null || assetComponentType === void 0 ? void 0 : assetComponentType.id) !== null && _a !== void 0 ? _a : null;
};
export var getGearboxBody = function (data, assetTreeData, assetModuleData) {
    var _a;
    var _b, _c;
    var transmission = data.transmission, bearingMonitoredPositionsVariant = data.bearingMonitoredPositionsVariant, bearingMonitoredPositions = data.bearingMonitoredPositions, numberOfStages = data.numberOfStages, componentOrientation = data.componentOrientation, bearingType = data.bearingType, numberOfStagesType = data.numberOfStagesType, oilPump = data.oilPump, gearTeeth = data.gearTeeth, numberOfOilVanes = data.numberOfOilVanes, manufacturer = data.manufacturer, model = data.model, notes = data.notes;
    return {
        assetId: (_c = (_b = _head(assetTreeData)) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : 0,
        components: [
            {
                assetModuleId: 2,
                assetComponentId: getTransmissionAssetComponentId(transmission.type, assetModuleData !== null && assetModuleData !== void 0 ? assetModuleData : []),
                assetComponentTypeId: getTransmissionAssetComponentTypeId(transmission.type, transmission.transmissionType.type, assetModuleData !== null && assetModuleData !== void 0 ? assetModuleData : []),
                options: {
                    numberOfComponents: transmission.transmissionType.numberOfComponents,
                    numberOfTeeth: transmission.transmissionType.numberOfTeeth,
                    sheaveDiameter1: transmission.calculationMode.sdi1,
                    sheaveDiameter2: transmission.calculationMode.sdi2,
                    teeth1: transmission.calculationMode.teeth1,
                    teeth2: transmission.calculationMode.teeth2,
                    speedRatio: transmission.calculationMode.speedRatio,
                    beltLength: transmission.calculationMode.beltLength,
                    beltRpm: transmission.calculationMode.beltRpm,
                },
            },
            {
                assetModuleId: 3,
                assetComponentId: 8,
                options: (_a = {
                        fullData: data,
                        componentOrientation: componentOrientation,
                        bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariant,
                        bearingType: bearingType,
                        type: STAGES_NAMES_TO_TYPE[numberOfStagesType],
                        numberOfStages: numberOfStages.stages
                    },
                    _a[STAGES_NAMES_TO_KEY[numberOfStagesType] + '1'] = numberOfStages.stage1,
                    _a[STAGES_NAMES_TO_KEY[numberOfStagesType] + '2'] = numberOfStages.stage2,
                    _a[STAGES_NAMES_TO_KEY[numberOfStagesType] + '3'] = numberOfStages.stage3,
                    _a[STAGES_NAMES_TO_KEY[numberOfStagesType] + '4'] = numberOfStages.stage4,
                    _a[STAGES_NAMES_TO_KEY[numberOfStagesType] + '5'] = numberOfStages.stage5,
                    _a[STAGES_NAMES_TO_KEY[numberOfStagesType] + '6'] = numberOfStages.stage6,
                    _a[STAGES_NAMES_TO_KEY[numberOfStagesType] + '7'] = numberOfStages.stage7,
                    _a[STAGES_NAMES_TO_KEY[numberOfStagesType] + '8'] = numberOfStages.stage8,
                    _a.oilPump = oilPump,
                    _a.gearTeeth = gearTeeth,
                    _a.numberOfOilVanes = numberOfOilVanes,
                    _a.manufacturer = manufacturer,
                    _a.model = model,
                    _a.notes = notes,
                    _a),
                installationPoints: bearingMonitoredPositions.map(function (installationPoint) {
                    return {
                        installationPointId: installationPoint.installationPointId,
                        bearingPosition: installationPoint.id,
                        installationPointBearings: installationPoint.bearings.map(function (bearing) {
                            var _a;
                            return _a = {
                                    bearingId: bearing.bearingId
                                },
                                _a[bearing.type === 'onShaft' ? 'onShaft' : 'ratio'] = bearing.value,
                                _a.plotLinesCount = bearing.plotlines,
                                _a;
                        }),
                    };
                }),
            },
        ],
    };
};
export var getDrivenBody = function (data, assetTreeData, assetModuleData) {
    var _a;
    var _b, _c, _d, _e, _f, _g;
    var transmission = data.transmission, drivenTypeVariant = data.drivenTypeVariant;
    var variation = drivenTypeVariant.variation;
    var options;
    if (drivenTypeVariant.variation.type) {
        options = (_a = {
                bearingMonitoredPositionsVariant: variation.bearingMonitoredPositionsVariant,
                componentOrientation: variation.componentOrientation,
                bearingType: variation.bearingType,
                numberOfElements: variation.numberOfElements,
                rotor: variation.rotor,
                numberOfThreads: variation.numberOfThreads,
                numberOfLobes: variation.numberOfLobes,
                numberOfVanes: variation.numberOfVanes,
                numberGearTeeth: variation.numberGearTeeth,
                numberOfRowsOfHummer: variation.numberOfRowsOfHummer,
                balanceable: variation.balanceable,
                idlerShaftSpeed: variation.idlerShaftSpeed,
                timingGear: Number(variation.timingGear.value),
                teethIn: variation.timingGear.teethIn,
                teethOut: variation.timingGear.teethOut,
                pistonRate: variation.pistonRate || undefined
            },
            _a[NUMBER_OF_KEY[variation.numberOfStagesType]] = variation.numberOfStages.stages,
            _a[(_b = STAGES_NAMES_TO_KEY[variation.numberOfStagesType]) !== null && _b !== void 0 ? _b : 'stage' + '1'] = variation.numberOfStages.stage1,
            _a[(_c = STAGES_NAMES_TO_KEY[variation.numberOfStagesType]) !== null && _c !== void 0 ? _c : 'stage' + '2'] = variation.numberOfStages.stage2,
            _a[(_d = STAGES_NAMES_TO_KEY[variation.numberOfStagesType]) !== null && _d !== void 0 ? _d : 'stage' + '3'] = variation.numberOfStages.stage3,
            _a[(_e = STAGES_NAMES_TO_KEY[variation.numberOfStagesType]) !== null && _e !== void 0 ? _e : 'stage' + '4'] = variation.numberOfStages.stage4,
            _a.installationPoints = variation.bearingMonitoredPositions.map(function (installationPoint) {
                return {
                    installationPointId: installationPoint.installationPointId,
                    bearingPosition: installationPoint.id,
                    installationPointBearings: installationPoint.bearings.map(function (bearing) {
                        var _a;
                        return _a = {
                                bearingId: bearing.bearingId
                            },
                            _a[bearing.type === 'onShaft' ? 'onShaft' : 'ratio'] = bearing.value,
                            _a.plotLinesCount = bearing.plotlines,
                            _a;
                    }),
                };
            }),
            _a);
    }
    else {
        options = {
            bearingMonitoredPositionsVariant: drivenTypeVariant.bearingMonitoredPositionsVariant,
            componentOrientation: drivenTypeVariant.componentOrientation,
            bearingType: drivenTypeVariant.bearingType,
            numberOfRowsOfHammer: drivenTypeVariant.numberOfRowsOfHammer,
            numberOfElements: drivenTypeVariant.numberOfElements,
            rotor: drivenTypeVariant.rotor,
            headSprocketToothCount: drivenTypeVariant.headSprocketToothCount,
            pivoting: drivenTypeVariant.pivoting,
            fixedHammer: drivenTypeVariant.fixedHammer,
            solidPlateAssembly: drivenTypeVariant.solidPlateAssembly,
            keyedPlateAssembly: drivenTypeVariant.keyedPlateAssembly,
            numberOfPoles: drivenTypeVariant.numberOfPoles,
            installationPoints: drivenTypeVariant.bearingMonitoredPositions.map(function (installationPoint) {
                return {
                    installationPointId: installationPoint.installationPointId,
                    bearingPosition: installationPoint.id,
                    installationPointBearings: installationPoint.bearings.map(function (bearing) {
                        var _a;
                        return _a = {
                                bearingId: bearing.bearingId
                            },
                            _a[bearing.type === 'onShaft' ? 'onShaft' : 'ratio'] = bearing.value,
                            _a.plotLinesCount = bearing.plotlines,
                            _a;
                    }),
                };
            }),
        };
    }
    return {
        assetId: (_g = (_f = _head(assetTreeData)) === null || _f === void 0 ? void 0 : _f.id) !== null && _g !== void 0 ? _g : 0,
        components: [
            {
                assetModuleId: 2,
                assetComponentId: getTransmissionAssetComponentId(transmission.type, assetModuleData !== null && assetModuleData !== void 0 ? assetModuleData : []),
                assetComponentTypeId: getTransmissionAssetComponentTypeId(transmission.type, transmission.transmissionType.type, assetModuleData !== null && assetModuleData !== void 0 ? assetModuleData : []),
                options: {
                    numberOfComponents: transmission.transmissionType.numberOfComponents,
                    numberOfTeeth: transmission.transmissionType.numberOfTeeth,
                    sheaveDiameter1: transmission.calculationMode.sdi1,
                    sheaveDiameter2: transmission.calculationMode.sdi2,
                    teeth1: transmission.calculationMode.teeth1,
                    teeth2: transmission.calculationMode.teeth2,
                    speedRatio: transmission.calculationMode.speedRatio,
                    beltLength: transmission.calculationMode.beltLength,
                    beltRpm: transmission.calculationMode.beltRpm,
                },
            },
            {
                assetModuleId: 4,
                assetComponentId: getAssetComponentId('Driven', drivenTypeVariant.subtype, assetModuleData),
                assetComponentTypeId: getAssetComponentTypeId('Driven', drivenTypeVariant.subtype, drivenTypeVariant.subtype === 'Centrifuge'
                    ? drivenTypeVariant.type
                    : drivenTypeVariant.variation.type, assetModuleData),
                options: __assign(__assign({ fullData: data }, options), { installationPoints: undefined }),
                installationPoints: options === null || options === void 0 ? void 0 : options.installationPoints,
            },
        ],
    };
};
export var getFoundationBody = function (data, assetTreeData, assetModuleData) {
    var _a, _b, _c;
    var foundationType = data.foundationType;
    var el = assetModuleData.find(function (_a) {
        var name = _a.name;
        return name === 'Foundation';
    });
    var assetComponent = el === null || el === void 0 ? void 0 : el.assetComponents.find(function (_a) {
        var name = _a.name;
        return name.trim() === foundationType;
    });
    return {
        assetId: (_b = (_a = _head(assetTreeData)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0,
        components: [
            {
                assetModuleId: 5,
                assetComponentId: (_c = assetComponent === null || assetComponent === void 0 ? void 0 : assetComponent.id) !== null && _c !== void 0 ? _c : null,
                options: {
                    fullData: data,
                },
            },
        ],
    };
};
