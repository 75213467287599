var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from '#typed/reactstrap';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useDeleteSeverityIncrease } from '@sharedHooks/AIPreAi/useDeleteSeverityIncrease';
import { selectProcessingIncreaseSeverityModalState, useProcessingIncreaseSeverityModalActions, } from '@sharedStore/useProcessingIncreaseSeverityModalStore';
import RichTextEditor from '../../../widgets/TextEditor/TextEditor';
export var ProcessingIncreaseSeverityModal = forwardRef(function (_a, ref) {
    var onSuccess = _a.onSuccess;
    var _b = selectProcessingIncreaseSeverityModalState(), isVisibleModal = _b.isVisibleModal, itemId = _b.itemId, storage = _b.storage, isPreAI = _b.isPreAI, onSuccessStateCallback = _b.onSuccess;
    var setModalState = useProcessingIncreaseSeverityModalActions().setModalState;
    var _c = useState('Are you sure you want to delete this item?'), description = _c[0], setDescription = _c[1];
    var _d = useState(''), comment = _d[0], setComment = _d[1];
    var resolveCallback = useRef();
    useImperativeHandle(ref, function () { return ({
        deleteSeverityIncrease: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var beforeAction = payload.beforeAction, modalStorePayload = __rest(payload, ["beforeAction"]);
                        if (beforeAction) {
                            setDescription("The current AI is set to Increase Severity, please process the current data before ".concat(beforeAction));
                        }
                        setModalState(modalStorePayload);
                        resolveCallback.current = resolve;
                    })];
            });
        }); },
    }); });
    var _e = useDeleteSeverityIncrease({
        actionItemId: Number(itemId),
        storage: storage,
        isPreAI: isPreAI,
    }), deleteSeverityIncrease = _e.deleteSeverityIncrease, isPending = _e.isPending;
    var resetModalStore = function () {
        setModalState({
            isVisibleModal: false,
            itemId: null,
            isPreAI: false,
            storage: null,
            onSuccess: null,
        });
    };
    var cancel = function () {
        var _a;
        if (isPending)
            return;
        (_a = resolveCallback.current) === null || _a === void 0 ? void 0 : _a.call(resolveCallback, false);
        resetModalStore();
    };
    var submit = function (action) {
        deleteSeverityIncrease({
            action: action,
            comment: comment,
            onSuccess: function () {
                var _a;
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                onSuccessStateCallback === null || onSuccessStateCallback === void 0 ? void 0 : onSuccessStateCallback();
                (_a = resolveCallback.current) === null || _a === void 0 ? void 0 : _a.call(resolveCallback, true);
                resetModalStore();
            },
        });
    };
    return (_jsxs(Modal, { size: "lg", isOpen: isVisibleModal, children: [_jsxs(ModalHeader, { toggle: cancel, children: [_jsx("span", { children: "Processing Increase Severity" }), isPending && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsxs(ModalBody, { style: { pointerEvents: isPending ? 'none' : 'initial' }, children: [_jsx("p", { children: description }), _jsx(RichTextEditor, { content: comment, onChange: setComment })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: cancel, disabled: isPending, children: "Cancel" }), _jsx(Button, { size: "sm", color: "danger", onClick: function () { return submit('decline'); }, disabled: isPending, children: "Reject" }), _jsx(Button, { size: "sm", color: "primary", onClick: function () { return submit('approve'); }, disabled: isPending, children: "Accept" })] }) })] }));
});
ProcessingIncreaseSeverityModal.displayName = 'ProcessingIncreaseSeverityModal';
