import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '@sharedStore/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import AssetSpeedInfo from './AssetSpeedInfo';
import './scss/assetSpeed.scss';
var AssetSpeedModal = function () {
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow).setStatesChartFeaturesStore;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint, equipment = _get(installationPoint, 'equipment') || {}, asset = _get(equipment, 'assets.0') || {};
    var onCancel = function () {
        setStatesChartFeaturesStore({
            equipmentSpeedVisible: false,
        });
    };
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsx(ModalHeader, { toggle: onCancel, children: "SPEED SETTINGS" }), _jsxs(ModalBody, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Asset:" }) }), _jsx("p", { children: asset.name })] }) }) }), _jsx("hr", {}), _jsx(AssetSpeedInfo, {})] })] }));
};
export default memo(AssetSpeedModal);
