// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typesPosition__CQCPD {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 3fr 1fr;
}

.couplingPosition__TYjM7 {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}

.beltPosition__sF5fx {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 4fr 1fr 1fr 1fr;
}

.chainBeltPosition__xzVN5 {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 4fr 1fr 1fr 1fr;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/Transmission/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,cAAA;EACA,8BAAA;AACD;;AACA;EACC,aAAA;EACA,cAAA;EACA,kCAAA;AAED;;AAAA;EACC,aAAA;EACA,cAAA;EACA,sCAAA;AAGD;;AADA;EACC,aAAA;EACA,cAAA;EACA,sCAAA;AAID","sourcesContent":[".typesPosition {\n\tdisplay: grid;\n\tgrid-gap: 16px;\n\tgrid-template-columns: 3fr 1fr;\n}\n.couplingPosition {\n\tdisplay: grid;\n\tgrid-gap: 16px;\n\tgrid-template-columns: 1fr 1fr 1fr;\n}\n.beltPosition {\n\tdisplay: grid;\n\tgrid-gap: 16px;\n\tgrid-template-columns: 4fr 1fr 1fr 1fr;\n}\n.chainBeltPosition {\n\tdisplay: grid;\n\tgrid-gap: 16px;\n\tgrid-template-columns: 4fr 1fr 1fr 1fr;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typesPosition": `typesPosition__CQCPD`,
	"couplingPosition": `couplingPosition__TYjM7`,
	"beltPosition": `beltPosition__sF5fx`,
	"chainBeltPosition": `chainBeltPosition__xzVN5`
};
module.exports = ___CSS_LOADER_EXPORT___;
