var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { useAssetTachometersQuery } from '../../../../services/useAssetTachometersQuery';
import { selectComponentId } from '../../../../stores/useAssetBuilderStore';
import assetBuilderStyles from '../../styles.module.scss';
import styles from './styles.module.scss';
export var RunningSpeed = memo(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var isDisabled = _a.isDisabled, equipmentId = _a.equipmentId;
    var _l = useFormContext(), clearErrors = _l.clearErrors, control = _l.control;
    var _m = useState(false), vfd = _m[0], setVfd = _m[1];
    var _o = useAssetTachometersQuery({ equipmentId: equipmentId }), dataTachometers = _o.data, isLoading = _o.isLoading, isFetching = _o.isFetching;
    var componentId = selectComponentId();
    var _p = useController({
        name: 'data.baseRunningSpeed',
        control: control,
    }), _q = _p.field, baseRunningSpeedValue = _q.value, onChangeBaseRunningSpeed = _q.onChange, baseRunningSpeedError = _p.fieldState.error;
    var _r = useController({
        name: 'data.speedVariation',
        control: control,
    }), _s = _r.field, speedVariationValue = _s.value, onChangeSpeedVariation = _s.onChange, speedVariationError = _r.fieldState.error;
    var _t = useController({
        name: 'data.speed',
        control: control,
    }), _u = _t.field, speedValue = _u.value, onChangeSpeedValue = _u.onChange, speedError = _t.fieldState.error;
    var onChangeScrInfo = useController({
        name: 'data.scrInfo',
        control: control,
    }).field.onChange;
    useEffect(function () {
        if (componentId && speedValue.variantSpeedType === 'vfd') {
            setVfd(true);
        }
    }, [componentId]);
    var options = (_c = (_b = dataTachometers === null || dataTachometers === void 0 ? void 0 : dataTachometers.list) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
        var serial = _a.serial, location_info = _a.location_info;
        return ({ label: location_info, value: serial });
    })) !== null && _c !== void 0 ? _c : [];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.position, children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Base running speed" }) }), _jsx("input", { disabled: isDisabled, placeholder: "CPM", type: "number", className: assetBuilderStyles.input, value: baseRunningSpeedValue !== null && baseRunningSpeedValue !== void 0 ? baseRunningSpeedValue : '', onChange: function (e) {
                                    clearErrors('data.baseRunningSpeed');
                                    onChangeBaseRunningSpeed(e.target.value ? Number(e.target.value) : undefined);
                                }, min: 1 }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: baseRunningSpeedError === null || baseRunningSpeedError === void 0 ? void 0 : baseRunningSpeedError.message })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Speed Variation (%)" }) }), _jsx("span", { children: _jsx("input", { disabled: isDisabled, placeholder: "%", type: "number", className: assetBuilderStyles.input, value: speedVariationValue !== null && speedVariationValue !== void 0 ? speedVariationValue : '', onChange: function (e) {
                                        clearErrors('data.speedVariation');
                                        onChangeSpeedVariation(e.target.value ? Number(e.target.value) : undefined);
                                    }, min: 1 }) }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: speedVariationError === null || speedVariationError === void 0 ? void 0 : speedVariationError.message })] }), _jsx("div", { className: cx(assetBuilderStyles.section, styles.center), children: _jsxs("label", { htmlFor: "vfd", className: assetBuilderStyles.switchWrapper, children: [_jsxs("div", { className: assetBuilderStyles.switch, children: [_jsx("input", { disabled: isDisabled, type: "checkbox", id: "vfd", checked: vfd, onChange: function (e) {
                                                onChangeScrInfo(undefined);
                                                setVfd(e.target.checked);
                                                if (speedValue.variantSpeedType !== 'tach') {
                                                    onChangeSpeedValue(__assign(__assign({}, speedValue), { variantSpeedType: e.target.checked ? 'vfd' : 'base' }));
                                                }
                                            } }), _jsx("span", { className: assetBuilderStyles.slider })] }), "VFD"] }) }), vfd && (_jsxs(_Fragment, { children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "RPM" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_d = speedValue.speedMin) !== null && _d !== void 0 ? _d : '', placeholder: "Min - CPM", onChange: function (e) {
                                            clearErrors('data.speed.speedMin');
                                            onChangeSpeedValue(__assign(__assign({}, speedValue), { speedMin: e.target.value ? Number(e.target.value) : undefined }));
                                        } }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_e = speedError === null || speedError === void 0 ? void 0 : speedError.speedMin) === null || _e === void 0 ? void 0 : _e.message })] }), _jsxs("div", { className: cx(assetBuilderStyles.section, styles.bottom), children: [_jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_f = speedValue.speedMax) !== null && _f !== void 0 ? _f : '', placeholder: "Max - CPM", onChange: function (e) {
                                            clearErrors('data.speed.speedMax');
                                            onChangeSpeedValue(__assign(__assign({}, speedValue), { speedMax: e.target.value ? Number(e.target.value) : undefined }));
                                        } }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_g = speedError === null || speedError === void 0 ? void 0 : speedError.speedMax) === null || _g === void 0 ? void 0 : _g.message })] })] }))] }), vfd && (_jsxs("div", { className: styles.positionTach, children: [_jsx("div", { className: cx(assetBuilderStyles.section, styles.center), children: _jsxs("label", { htmlFor: "tach", className: assetBuilderStyles.switchWrapper, children: [_jsxs("div", { className: assetBuilderStyles.switch, children: [_jsx("input", { disabled: isDisabled, type: "checkbox", id: "tach", checked: speedValue.variantSpeedType === 'tach', onChange: function (e) {
                                                onChangeSpeedValue(__assign(__assign({}, speedValue), { variantSpeedType: e.target.checked ? 'tach' : vfd ? 'vfd' : 'base' }));
                                            } }), _jsx("span", { className: assetBuilderStyles.slider })] }), "Tachometer"] }) }), speedValue.variantSpeedType === 'tach' && vfd && (_jsxs(_Fragment, { children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Tach Multiplier" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_h = speedValue.tachMultiplier) !== null && _h !== void 0 ? _h : '', onChange: function (e) {
                                            clearErrors('data.speed.tachMultiplier');
                                            onChangeSpeedValue(__assign(__assign({}, speedValue), { tachMultiplier: e.target.value ? Number(e.target.value) : undefined }));
                                        } }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_j = speedError === null || speedError === void 0 ? void 0 : speedError.tachMultiplier) === null || _j === void 0 ? void 0 : _j.message })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Select Tachometer" }) }), _jsx(Select, { placeholder: "Select Tachometer", classNamePrefix: "customSelectStyleDefault", isLoading: isLoading || isFetching, isDisabled: isDisabled, value: options.find(function (el) { return el.value === speedValue.tach; }), onChange: function (data) {
                                            clearErrors('data.speed.tach');
                                            onChangeSpeedValue(__assign(__assign({}, speedValue), { tach: data.value }));
                                        }, options: options }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_k = speedError === null || speedError === void 0 ? void 0 : speedError.tach) === null || _k === void 0 ? void 0 : _k.message })] })] }))] }))] }));
});
RunningSpeed.displayName = 'RunningSpeed';
