import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var useQuery = useUntypedQuery;
var faultFrequencyLabelQueryFn = function (_a) {
    var _b = _a.queryKey, _ = _b[0], data = _b[1];
    var coreLink = data.coreLink, componentId = data.componentId, isOnlyActive = data.isOnlyActive, equipmentById = data.equipmentById, equipmentByPointId = data.equipmentByPointId;
    return Api.get("".concat(coreLink, "/fault-frequency-label"), {
        query: {
            filters: {
                componentId: componentId,
                onlyActive: isOnlyActive,
            },
            options: {
                equipmentById: equipmentById,
                equipmentByPointId: equipmentByPointId,
            },
        },
    });
};
export var useFaultFrequencyLabelQuery = function (_a) {
    var componentId = _a.componentId, equipmentById = _a.equipmentById, equipmentByPointId = _a.equipmentByPointId, isOnlyActive = _a.isOnlyActive;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: [
            'faultFrequencyLabel',
            {
                coreLink: getCoreUrl(selectedSystemType),
                componentId: componentId,
                isOnlyActive: isOnlyActive,
                equipmentById: equipmentById,
                equipmentByPointId: equipmentByPointId,
            },
        ],
        queryFn: faultFrequencyLabelQueryFn,
        placeholderData: { data: [] },
        select: function (_a) {
            var data = _a.data;
            return data;
        },
    });
};
