// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-asset-builder-mode__IynPA {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 80px;
  right: 15px;
  width: 50px;
  height: 50px;
  font-size: 20px;
  background: #fff;
  border: 1px solid #e2e2e2;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s;
}
.dark-layout .toggle-asset-builder-mode__IynPA {
  background: #1e222d;
  border: 1px solid #3e3f4f;
}
.toggle-asset-builder-mode__IynPA:hover {
  background: rgba(41, 98, 255, 0.1882352941);
}
.toggle-asset-builder-mode__IynPA.active__NwG81, .toggle-asset-builder-mode__IynPA.active__NwG81:hover {
  border: 1px solid #2962ff;
  color: #2962ff;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilderModeToggle/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACD;AAAC;EACC,mBAAA;EACA,yBAAA;AAEF;AAAC;EACC,2CAAA;AAEF;AAAC;EAEC,yBAAA;EACA,cAAA;AACF","sourcesContent":[".toggle-asset-builder-mode {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tposition: fixed;\n\tbottom: 80px;\n\tright: 15px;\n\twidth: 50px;\n\theight: 50px;\n\tfont-size: 20px;\n\tbackground: #fff;\n\tborder: 1px solid #e2e2e2;\n\tcursor: pointer;\n\tborder-radius: 50%;\n\ttransition: 0.3s;\n\t:global(.dark-layout) & {\n\t\tbackground: #1e222d;\n\t\tborder: 1px solid #3e3f4f;\n\t}\n\t&:hover {\n\t\tbackground: #2962ff30;\n\t}\n\t&.active,\n\t&.active:hover {\n\t\tborder: 1px solid #2962ff;\n\t\tcolor: #2962ff;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleAssetBuilderMode": `toggle-asset-builder-mode__IynPA`,
	"active": `active__NwG81`
};
module.exports = ___CSS_LOADER_EXPORT___;
