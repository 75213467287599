var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { literal, object, variant } from 'valibot';
import { DIESEL_ENGINE } from '../constants';
import { assetModuleIdRules } from './shared/assetModuleIdRules';
import { baseRunningSpeedRules } from './shared/baseRunningSpeedRules';
import { bearingMonitoredPositionsRules } from './shared/bearingMonitoredPositionsRules';
import { bearingTypeRules } from './shared/bearingTypeRules';
import { componentOrientationRules } from './shared/componentOrientationRules';
import { componentTypeRules } from './shared/componentTypeRules';
import { pistonRateRules } from './shared/pistonRateRules';
import { speedRules } from './shared/speedRules';
import { speedVariationRules } from './shared/speedVariationRules';
var dieselEngineSecondStepSchema = {
    assetModuleId: assetModuleIdRules,
    componentType: componentTypeRules,
    componentOrientation: componentOrientationRules,
    bearingType: bearingTypeRules,
};
var dieselEngineThirdStepSchema = {
    bearingMonitoredPositions: bearingMonitoredPositionsRules([1, 2]),
};
var dieselEngineFourthStepSchema = {
    baseRunningSpeed: baseRunningSpeedRules,
    speedVariation: speedVariationRules,
    speed: speedRules,
    pistonRate: pistonRateRules,
};
export var dieselEngineSchema = object({
    equipmentType: literal(DIESEL_ENGINE),
    data: variant('step', [
        object({
            step: literal(1),
        }),
        object(__assign({ step: literal(2) }, dieselEngineSecondStepSchema)),
        object(__assign(__assign({ step: literal(3) }, dieselEngineSecondStepSchema), dieselEngineThirdStepSchema)),
        object(__assign(__assign(__assign({ step: literal(4) }, dieselEngineSecondStepSchema), dieselEngineThirdStepSchema), dieselEngineFourthStepSchema)),
    ], 'Step is required'),
});
