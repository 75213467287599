import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'clsx';
import { memo } from 'react';
import { useController } from 'react-hook-form';
import { ROTOR_TYPES } from '../../../../constants';
import assetBuilderStyles from '../../styles.module.scss';
export var Rotor = memo(function (_a) {
    var control = _a.control, fieldName = _a.fieldName, isDisabled = _a.isDisabled;
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, value = _b.value, onChangeRotor = _b.onChange;
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Rotor" }), _jsx("div", { className: assetBuilderStyles.sectionButtons, children: ROTOR_TYPES.map(function (type) {
                    var _a;
                    return (_jsx("button", { disabled: isDisabled, onClick: function () { return onChangeRotor(type); }, className: cx(assetBuilderStyles.button, (_a = {},
                            _a[assetBuilderStyles.active] = value === type,
                            _a)), children: type }, type));
                }) })] }));
});
Rotor.displayName = 'Rotor';
