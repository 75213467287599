import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'clsx';
import { get as _get, head as _head } from 'lodash';
import { Fragment, memo } from 'react';
import { FaCheckCircle, FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useConfirmModalActions } from '@sharedStore/global/useConfirmModalStore';
import Api from '../../../../../../api/api';
import { ReactComponent as AssetComponentIcon } from '../../../../../../assets/svg/AssetComponentIcon.svg';
import DefaultTooltip from '../../../../../../shared/components/DefaultTooltip';
import Loader from '../../../../../../shared/components/Loader';
import { TRANSMISSION_ICONS } from '../../constants';
import { getIsViewMode } from '../../features';
import { useAssetTreeQuery } from '../../services/useAssetTreeQuery';
import { selectComponentId, useAssetBuilderActions } from '../../stores/useAssetBuilderStore';
import assetBuilderStyles from '../../styles.module.scss';
import { InstallationPointItem } from '../InstallationPointItem';
import styles from './styles.module.scss';
export var EquipmentTree = memo(function (_a) {
    var _b;
    var equipmentId = _a.equipmentId;
    var _c = useAssetTreeQuery({ equipmentId: equipmentId }), data = _c.data, isLoading = _c.isLoading, isFetching = _c.isFetching;
    var isViewMode = getIsViewMode(data === null || data === void 0 ? void 0 : data.data);
    var setComponentId = useAssetBuilderActions().setComponentId;
    var componentId = selectComponentId();
    var setConfirmData = useConfirmModalActions().setConfirmData;
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("h2", { className: cx(assetBuilderStyles.title, styles.header), children: ["Equipment Tree", _get(data, 'data.0.id') ? (_jsxs(_Fragment, { children: [_jsx("span", { "data-tooltip-id": 'delete_asset', onClick: function () {
                                    setConfirmData({
                                        isVisible: true,
                                        textConfirmationModal: 'Are you sure you want to delete current asset?',
                                        onConfirm: function () {
                                            setConfirmData({
                                                isVisible: false,
                                            });
                                            Api.del("all-analytics/asset/".concat(_get(data, 'data.0.id')), {}).then(function () {
                                                toast.success('Successfully delete', {
                                                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                                });
                                                window.location.reload();
                                            });
                                        },
                                    });
                                }, children: _jsx(FaRegTrashAlt, {}) }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "right", id: 'delete_asset', children: "Delete Asset" })] })) : (_jsx(_Fragment, {}))] }), isLoading || isFetching ? (_jsx("div", { className: styles.loader, children: _jsx(Loader, { variant: "loader-sm" }) })) : (_jsx("div", { className: styles.list, children: !(data === null || data === void 0 ? void 0 : data.data) ? (_jsx("div", { className: styles.settingsBlock, children: "Component Settings" })) : (_jsxs(_Fragment, { children: [data === null || data === void 0 ? void 0 : data.data.map(function (asset) { return (_jsx("div", { children: asset === null || asset === void 0 ? void 0 : asset.assetConfigs.map(function (config) {
                                var _a;
                                var _b;
                                return (_jsx(Fragment, { children: config.assetModuleName === 'Transmission' ? (_jsxs("div", { className: styles.transmission, children: [TRANSMISSION_ICONS[config === null || config === void 0 ? void 0 : config.assetComponentName], config.assetComponentName] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: cx(styles.item, (_a = {},
                                                    _a[styles.viewMode] = isViewMode,
                                                    _a[styles.active] = componentId === config.id,
                                                    _a)), onClick: function () {
                                                    if (!isViewMode) {
                                                        return;
                                                    }
                                                    setComponentId(config.id);
                                                }, children: [_jsx(AssetComponentIcon, { style: { width: '18px', height: '18px' } }), _jsxs("div", { children: [_jsx("div", { className: styles.itemName, children: config.assetComponentName }), config.assetModuleName !== 'Gearbox' && (_jsx("div", { className: styles.itemType, children: config.assetModuleName }))] })] }), (_b = config.installationPoints) === null || _b === void 0 ? void 0 : _b.map(function (installationPoint) { return (_jsx("div", { className: cx(styles.borderBottom, styles.extraPaddingLeft), children: _jsx(InstallationPointItem, { installationPoint: installationPoint }) }, "installationPoint_".concat(installationPoint.id))); })] })) }, "config_".concat(config.id)));
                            }) }, "asset_".concat(asset.id))); }), ((_b = _head(data === null || data === void 0 ? void 0 : data.data)) === null || _b === void 0 ? void 0 : _b.status) !== 1 && (_jsx("div", { className: styles.settingsBlock, children: "Component Settings" }))] })) }))] }));
});
EquipmentTree.displayName = 'EquipmentTree';
