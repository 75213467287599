export var DEFAULT_ALERT_CONDITION = {
    id: null,
    conditionId: null,
    condition: '>=',
    level: '',
    settings: null,
    isRequestFFT: 0,
    fftSettings: {
        fmax: 600,
        limit: 5,
        lor: 1024,
    },
    order: null,
};
