var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, has as _has, head as _head, trim as _trim } from 'lodash';
import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, } from 'reactstrap';
import { useExistingAiPreAiActions } from '@sharedModals/ExistingAiPreAiOnBed/store/useExistingAiPreAiOnBedStore';
import { useActionItemsListQuery, } from '@sharedServices/actionItemsList/useActionItemsListQuery';
import { selectAdvancedSearch, selectDateFrom, selectDateTo, selectHideHardwareAi, selectIncreaseSeverity, selectOnlyHardwareAi, selectPageDataTable, selectPageSizeDataTable, selectReadingType, selectSearchDataTable, selectSelectedAiType, selectSelectedStatuses, selectSortDataTable, selectUrgency, selectWatchingFilter, useActionItemsListDataTableActions, } from '@sharedStore/actionItemsList/useActionItemsListStore';
import Endpoints from '../../../../api/endpoints/endpoints';
import { ACTION_LIST, CUSTOMER_USER_ID } from '../../../../constants';
import ActivityIndicator from '../../../../shared/components/AIPreAi/ActivityIndicator';
import DefaultTooltip from '../../../../shared/components/DefaultTooltip';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { config as baseConfig } from '../../../../shared/config/actionItems/dataTableConfig';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { exportToCsv, onViewActionItem } from '../../../../shared/features/shared';
import { onPageChange, onPageSizeChange, onSearch, onSort, } from '../../../../shared/features/table/tableQuery';
import ExistingAiPreAiOnBedModal from '../../../../shared/modals/ExistingAiPreAiOnBed';
import '../../../../shared/styles/AIPreAi/actionItems.scss';
import * as tableColumnStorage from '../../../../storage/tableColumn';
import { Money } from '../../../../widgets/common';
import { SensorId } from '../../../../widgets/sensor';
import StatusLabelRemastered from '../../AIPreAi/StatusLabel';
import { UrgencyLabel } from '../../AIPreAi/UrgencyLabel';
var initialState = function (isActive, installationPointId, onViewActionItem, setIsVisible, setInstallationPointId, setEquipmentId, isHardwarePage, tableName) {
    var visibleState = tableColumnStorage.get(tableName || '');
    var closedByColumn = {
        name: 'submited_by',
        visible: _has(visibleState, 'submited_by') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.submited_by : !isActive,
        permissions: !isActive,
        title: 'Closed by',
        sortable: true,
    };
    var urgencyColumn = {
        name: 'urgency_column',
        titleComponent: function () {
            var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
            var labelsMapping = {
                '1': 'Standard',
                '2': 'High',
                '3': 'Critical',
            };
            var field = selectUrgency();
            var setUrgency = useActionItemsListDataTableActions().setUrgency;
            return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: labelsMapping[field] || 'Urgency' }), _jsx(DropdownMenu, { className: "dropdown-menu", style: { marginLeft: '-30px' }, children: _jsx("div", { children: Object.keys(labelsMapping).map(function (urgency) { return (_jsx(DropdownItem, { onClick: function () { return setUrgency(field === +urgency ? undefined : +urgency); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: field === +urgency }), labelsMapping[urgency] || 'Urgency'] }) }, "urgency_title_filter_".concat(urgency))); }) }) })] }));
        },
        sortable: false,
        visible: _has(visibleState, 'urgency') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.urgency : true,
        component: function (row) {
            return _jsx(UrgencyLabel, { urgency: +row.urgency });
        },
    };
    var statusColumn = {
        name: 'status',
        visible: _has(visibleState, 'status') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.status : true,
        title: 'Status',
        sortable: true,
        component: function (row) {
            return (_jsx(StatusLabelRemastered, { isTroubleshootingHold: +row.is_troubleshooting_hold, isTroubleshootingWaiting: +row.is_troubleshooting_waiting, status: +row.state, isWaitesNew: row.is_waites_new, userId: +row.user_id, decline: _trim(row.decline_reason), commentsCount: +row.comments_count }));
        },
    };
    var config = baseConfig(onViewActionItem, isActive, statusColumn, urgencyColumn, closedByColumn, installationPointId, ACTION_LIST.STATUS_NAMES, isHardwarePage, tableName);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray([
                {
                    name: 'is_customer_created',
                    component: function (_a) {
                        var id = _a.id, user_id = _a.user_id;
                        var isCustomerCreated = +user_id === CUSTOMER_USER_ID;
                        if (isCustomerCreated) {
                            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flag-customer-created", "data-tooltip-id": "customerCreated".concat(id) }), _jsx(DefaultTooltip, { place: "right", openEvents: { mouseenter: true }, id: "customerCreated".concat(id), children: "Customer's Created" })] }));
                        }
                        return null;
                    },
                },
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                },
                {
                    name: 'watching_filter',
                    titleComponent: function () {
                        var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
                        var labelsMapping = {
                            not_watched: 'Not viewed',
                            watching: 'Watching',
                            watched: 'Processed',
                        };
                        var field = selectWatchingFilter();
                        var setWatchingFilter = useActionItemsListDataTableActions().setWatchingFilter;
                        return (_jsxs(Dropdown, { direction: "down", isOpen: isOpen, toggle: function () { return setIsOpen(!isOpen); }, children: [_jsx(DropdownToggle, { size: "sm", caret: true, tag: "span", children: labelsMapping[field] || 'All' }), _jsxs(DropdownMenu, { className: "dropdown-menu", children: [_jsx(DropdownItem, { onClick: function () { return setWatchingFilter(field === 'not_watched' ? undefined : 'not_watched'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: field === 'not_watched' }), "Not viewed"] }) }), _jsx(DropdownItem, { onClick: function () { return setWatchingFilter(field === 'watching' ? undefined : 'watching'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: field === 'watching' }), "Watching"] }) }), _jsx(DropdownItem, { onClick: function () { return setWatchingFilter(field === 'watched' ? undefined : 'watched'); }, children: _jsxs(Label, { check: true, className: "ms-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: field === 'watched', onChange: function () { return setWatchingFilter(field === 'watched' ? undefined : 'watched'); } }), "Processed"] }) })] })] }));
                    },
                    component: function (row) {
                        var _a;
                        return _jsx(ActivityIndicator, { className: "mt-2", analystLog: (_a = row === null || row === void 0 ? void 0 : row.actionListAnalystLog) !== null && _a !== void 0 ? _a : [] });
                    },
                    sortable: false,
                },
                {
                    name: 'customer_name',
                    visible: _has(visibleState, 'customer_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.customer_name : true,
                    title: 'Customer',
                    sortable: true,
                    component: function (_a) {
                        var customer_name = _a.customer_name, is_customer_request = _a.is_customer_request, id = _a.id;
                        return (_jsxs("span", { className: "customer-name", children: [customer_name, !!is_customer_request && (_jsxs(_Fragment, { children: [_jsx("span", { className: "sub-badge", "data-tooltip-id": "customerRequestTag".concat(id), children: "CR" }), _jsx(DefaultTooltip, { place: "right", openEvents: { mouseenter: true }, id: "customerRequestTag".concat(id), children: "Customer's Request tag" })] }))] }));
                    },
                },
                {
                    name: 'facility_name',
                    visible: _has(visibleState, 'facility_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.facility_name : true,
                    title: 'Facility',
                    sortable: true,
                    component: function (row) {
                        if (row.facility_name) {
                            return row.facility_name;
                        }
                        return (_get(row, [row.equipment_type, 'locationTable', 'facility', 'name']) ||
                            _get(row, [row.equipment_type, 'location', 'facility', 'name']) ||
                            '---');
                    },
                },
                {
                    name: 'location_name',
                    visible: _has(visibleState, 'location_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.location_name : true,
                    title: 'Location',
                    sortable: true,
                    component: function (row) {
                        var location = _get(row, 'location_info') || _get(row, 'location_name');
                        return (location ||
                            _get(row, [row.equipment_type, 'location_info']) ||
                            _get(row, [row.equipment_type, 'locationTable', 'name']) ||
                            '---');
                    },
                },
                {
                    name: 'equipment_name',
                    visible: _has(visibleState, 'equipment_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.equipment_name : true,
                    title: 'Equipment',
                    sortable: true,
                    component: function (row) {
                        return (_jsx(_Fragment, { children: row.activeAisOnEquipmentCount ? (_jsx("div", { className: "fake-link", onClick: function () {
                                    var _a;
                                    setIsVisible(true);
                                    setInstallationPointId(Number(row === null || row === void 0 ? void 0 : row.installation_point_id) || null);
                                    setEquipmentId((_a = row === null || row === void 0 ? void 0 : row.equipment_id) !== null && _a !== void 0 ? _a : null);
                                }, children: row.equipment_info || row.equipment_name || '---' })) : (row.equipment_info || row.equipment_name || '---') }));
                    },
                },
                {
                    name: 'point_name',
                    visible: _has(visibleState, 'point_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.point_name : true,
                    title: 'Point name',
                    sortable: true,
                    component: function (row) {
                        if (row.equipment_type === ACTION_LIST.EQUIPMENT_TYPE_SENSOR ||
                            row.equipment_type === ACTION_LIST.EQUIPMENT_TYPE_MOTE) {
                            return (_get(row, 'point_name') ||
                                _get(ACTION_LIST.EQUIPMENT_TYPES_NAME_MAPPING, row.equipment_type) ||
                                '---');
                        }
                        return _get(ACTION_LIST.EQUIPMENT_TYPES_NAME_MAPPING, row.equipment_type, '---');
                    },
                },
                {
                    name: 'sensor_id',
                    visible: _has(visibleState, 'sensor_id') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.sensor_id : true,
                    title: tableName === 'actionListActiveTable' ? 'Sensor ID' : 'Serial/Sensor ID',
                    sortable: true,
                    component: function (row) {
                        return (_jsxs("div", { className: "no-wrap", children: [row.serial ? "".concat(row.serial, "/") : '', _jsx(SensorId, { notShowEmptyHex: true, hexBreak: true, sensor_id: +row.sensor_id })] }));
                    },
                },
                {
                    name: 'cost_avoided',
                    visible: _has(visibleState, 'cost_avoided')
                        ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.cost_avoided
                        : !isActive && tableName !== 'actionListByInstallationPointTable',
                    title: 'Cost avoided',
                    permissions: !isActive,
                    sortable: true,
                    component: function (row) {
                        return row.cost_avoided || row.cost_avoided === 0 ? _jsx(Money, { amount: row.cost_avoided }) : '---';
                    },
                }
            ], config.columns, true) }),
    };
};
var BaseActionItemsPage = function (_a) {
    var selectedCustomers = _a.selectedCustomers, _b = _a.selectedLocations, selectedLocations = _b === void 0 ? [] : _b, _c = _a.title, title = _c === void 0 ? 'Action list' : _c, _d = _a.isActive, isActive = _d === void 0 ? true : _d, _e = _a.isHardwarePage, isHardwarePage = _e === void 0 ? false : _e, tableName = _a.tableName, installationPointId = _a.installationPointId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _f = useExistingAiPreAiActions(), setIsVisible = _f.setIsVisible, setInstallationPointId = _f.setInstallationPointId, setEquipmentId = _f.setEquipmentId;
    var initialStateConfig = initialState(isActive, installationPointId, function (actionItemId, advancedSearch) { return onViewActionItem(actionItemId, selectedSystemType, advancedSearch); }, setIsVisible, setInstallationPointId, setEquipmentId, isHardwarePage, tableName);
    var _g = useActionItemsListQuery({ options: initialStateConfig }), actionItemsListData = _g.data, isLoading = _g.isLoading, isFetching = _g.isFetching;
    var config = (actionItemsListData === null || actionItemsListData === void 0 ? void 0 : actionItemsListData.config) || initialStateConfig.config;
    var _h = useActionItemsListDataTableActions(), setSearch = _h.setSearch, setPage = _h.setPage, setPageSize = _h.setPageSize, setSort = _h.setSort, setSelectedCustomers = _h.setSelectedCustomers, setSelectedLocation = _h.setSelectedLocation, setOnlyHardwareAi = _h.setOnlyHardwareAi, setHideHardwareAi = _h.setHideHardwareAi, setIsActive = _h.setIsActive, setSelectedStatuses = _h.setSelectedStatuses, setIncreaseSeverity = _h.setIncreaseSeverity, setAdvancedSearch = _h.setAdvancedSearch, setSelectedAiType = _h.setSelectedAiType, setDateFrom = _h.setDateFrom, setDateTo = _h.setDateTo, setInstallationPointIdStore = _h.setInstallationPointId;
    var advancedSearch = selectAdvancedSearch();
    var sort = selectSortDataTable();
    var selectedStatuses = selectSelectedStatuses();
    var selectedAiType = selectSelectedAiType();
    var dateFrom = selectDateFrom();
    var dateTo = selectDateTo();
    var urgency = selectUrgency();
    var readingType = selectReadingType();
    var hideHardwareAi = selectHideHardwareAi();
    var onlyHardwareAi = selectOnlyHardwareAi();
    var increaseSeverity = selectIncreaseSeverity();
    var page = selectPageDataTable();
    var pageSize = selectPageSizeDataTable();
    var watchingFilter = selectWatchingFilter();
    var search = selectSearchDataTable();
    var searchPlaceholder = advancedSearch ? 'Advanced Search' : 'Search';
    var resetFilters = function () {
        setSelectedStatuses({});
        setIncreaseSeverity(false);
        setAdvancedSearch(false);
        setSelectedAiType();
        setDateFrom();
        setDateTo();
        setSort([
            {
                field: 'updated_at',
                direction: 'desc',
            },
        ]);
    };
    useEffect(function () {
        setSelectedCustomers(selectedCustomers);
    }, [selectedCustomers]);
    useEffect(function () {
        setSelectedLocation(_head(selectedLocations || []));
    }, [selectedLocations]);
    useEffect(function () {
        setOnlyHardwareAi(isHardwarePage);
        setHideHardwareAi(!isHardwarePage && !installationPointId);
        resetFilters();
    }, [isHardwarePage]);
    useEffect(function () {
        setInstallationPointIdStore(installationPointId || undefined);
        resetFilters();
    }, [installationPointId]);
    useEffect(function () {
        setIsActive(isActive);
        resetFilters();
    }, [isActive]);
    useEffect(function () {
        setPage(1);
    }, [
        selectedCustomers,
        selectedLocations,
        selectedStatuses,
        selectedAiType,
        dateFrom,
        dateTo,
        urgency,
        readingType,
    ]);
    return (_jsxs(React.Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs("div", { className: "response-table align-pagination-section change-message-badge", children: [_jsxs("h3", { className: "page-title", children: [title, config.data.length > 0 && (_jsx(Button, { color: "success", className: "float-end", size: "sm", onClick: function () {
                                            return exportToCsv(Endpoints.remastered.getActionListCsv, 'action-items', {
                                                query: {
                                                    only_active: +isActive,
                                                    hide_hardware_ai: +hideHardwareAi,
                                                    only_hardware_ai: +onlyHardwareAi,
                                                    selected_statuses: selectedStatuses,
                                                    selected_ai_type: selectedAiType,
                                                    selected_customer_ids: selectedCustomers,
                                                    selected_location_id: _head(selectedLocations),
                                                    installation_point_id: installationPointId,
                                                    increase_severity: +increaseSeverity,
                                                    pagination: {
                                                        page: page,
                                                        pageSize: pageSize,
                                                    },
                                                    sort: sort,
                                                    watching_filter: watchingFilter,
                                                    search: search,
                                                    advanced_search: +advancedSearch,
                                                    urgency: urgency,
                                                    reading_type: readingType,
                                                    date_from: dateFrom ? moment(dateFrom).format('YYYY-MM-DD 00:00:00') : null,
                                                    date_to: dateTo ? moment(dateTo).format('YYYY-MM-DD 00:00:00') : null,
                                                    is_customer_request: Number(!!(selectedStatuses === null || selectedStatuses === void 0 ? void 0 : selectedStatuses.customer_requested) || 0),
                                                },
                                                isBlob: true,
                                            });
                                        }, children: "Export to CSV" }))] }), _jsx(DataTable, { searchPlaceholder: searchPlaceholder, isLoading: isLoading || isFetching, config: config, tableName: tableName, onPageChange: function (page) { return onPageChange(page, setPage); }, onPageSizeChange: function (pageSize) { return onPageSizeChange(pageSize, setPageSize, setPage); }, onSort: function (column_name, ev) { return onSort(column_name, sort, setSort, ev); }, onSearch: function (search) {
                                    if ((search.includes(')') || search.includes('(')) && advancedSearch) {
                                        return toast.error('There is an invalid character in the search field!', {
                                            icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
                                        });
                                    }
                                    onSearch(search, setSearch, setPage);
                                } })] }) }) }), _jsx(ExistingAiPreAiOnBedModal, {})] }));
};
export default BaseActionItemsPage;
