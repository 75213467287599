// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position__oeIa3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
}

.driven__gspjH {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  grid-gap: 16px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/NotFirstAssetContent/components/DrivenContent/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,kCAAA;EACA,cAAA;AACD;;AAEA;EACC,aAAA;EACA,kCAAA;EACA,cAAA;EACA,mBAAA;AACD","sourcesContent":[".position {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 1fr 1fr;\n\tgrid-gap: 16px;\n}\n\n.driven {\n\tdisplay: grid;\n\tgrid-template-columns: 2fr 3fr 3fr;\n\tgrid-gap: 16px;\n\talign-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"position": `position__oeIa3`,
	"driven": `driven__gspjH`
};
module.exports = ___CSS_LOADER_EXPORT___;
