var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createStore } from './createStore';
var useProcessingIncreaseSeverityModalStore = createStore(function (set) { return ({
    isVisibleModal: false,
    itemId: null,
    storage: null,
    isPreAI: false,
    actions: {
        setModalState: function (state) { return set(state); },
    },
}); });
export var useProcessingIncreaseSeverityModalActions = function () {
    return useProcessingIncreaseSeverityModalStore(function (store) { return store.actions; });
};
export var selectProcessingIncreaseSeverityModalState = function () {
    return useProcessingIncreaseSeverityModalStore(function (_a) {
        var _ = _a.actions, state = __rest(_a, ["actions"]);
        return state;
    });
};
