import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import React, { useCallback, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { VscDebugBreakpointLog } from 'react-icons/vsc';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '@sharedStore/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '@sharedStore/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import { isTwfChart } from '../../../../../../helper/chart';
import { getPlotlineColor } from '../../../features/fftTwf';
import { FrequencyConverter } from '../../../features/fftTwf/frequencyConverter';
export var getFaultFrequencyConfig = function (chartIdentifier, currentSpeed, faultFrequencies, chartRef, selectedPoint, dataMax, isVisibleFaultFrequencyIds) {
    if (selectedPoint === void 0) { selectedPoint = 0; }
    if (dataMax === void 0) { dataMax = undefined; }
    if (!faultFrequencies || faultFrequencies.length === 0) {
        return [];
    }
    var faultFrequencyPlotLines = [];
    faultFrequencies.forEach(function (faultFrequency, index) {
        if (isVisibleFaultFrequencyIds && !isVisibleFaultFrequencyIds.includes(+faultFrequency.id)) {
            return;
        }
        if (currentSpeed <= 0 && faultFrequency.frequency_type === 'Orders') {
            return;
        }
        var value = FrequencyConverter.fromType(faultFrequency.frequency_type, faultFrequency.frequency, currentSpeed).toHz().value;
        if (isTwfChart(chartIdentifier)) {
            value = 1 / value;
            dataMax = +(chartRef.current.chart.xAxis[0].dataMax || dataMax);
            selectedPoint = selectedPoint || value;
            for (var i = selectedPoint; i <= dataMax; i += value) {
                var color = i === selectedPoint
                    ? '#4f4f4f'
                    : faultFrequency.source === 'analytic' || faultFrequency.createdType === 'auto'
                        ? getPlotlineColor(index)
                        : '#4f4f4f';
                pushFaultFrequenciesConfig(i, i, faultFrequencyPlotLines, faultFrequency, chartIdentifier, color);
            }
            if (selectedPoint) {
                for (var i = selectedPoint - value; i >= chartRef.current.chart.xAxis[0].dataMin; i -= value) {
                    pushFaultFrequenciesConfig(i, i, faultFrequencyPlotLines, faultFrequency, chartIdentifier, faultFrequency.source === 'analytic' || faultFrequency.createdType === 'auto'
                        ? getPlotlineColor(index)
                        : '#4f4f4f');
                }
            }
            return;
        }
        for (var i = 1; i <= _get(faultFrequency, 'plot_lines_count'); i++) {
            pushFaultFrequenciesConfig(i, value * i, faultFrequencyPlotLines, faultFrequency, chartIdentifier, faultFrequency.source === 'analytic' || faultFrequency.createdType === 'auto'
                ? getPlotlineColor(index)
                : '#4f4f4f');
        }
    });
    return faultFrequencyPlotLines;
};
var pushFaultFrequenciesConfig = function (i, value, faultFrequencyPlotLines, faultFrequency, chartIdentifier, plotLineColor) {
    if (plotLineColor === void 0) { plotLineColor = '#46b67f'; }
    faultFrequencyPlotLines.push({
        id: faultFrequency.id + '-' + i,
        type: 'frequencies',
        width: 2,
        faultFrequencyId: faultFrequency.id,
        frequency_type: faultFrequency.frequency_type,
        frequency: faultFrequency.frequency,
        className: 'fault-frequency-plotline-' + chartIdentifier + '-' + faultFrequency.id,
        value: value,
        i: i,
        dashStyle: 'Dot',
        color: plotLineColor,
        zIndex: 0,
        label: {
            // @ts-ignore
            useHTML: true,
            style: {
                color: '#FFFFFF',
                backgroundColor: plotLineColor,
                marginLeft: '5px',
                padding: '0 2px',
                'font-size': '11px',
                'z-index': 12,
                'min-width': '35px',
                'text-align': 'center',
                'border-radius': '5px',
                border: 'solid 1px rgba(79, 79, 79, 0.5)',
            },
            rotation: 90,
            x: -5,
            y: 0,
            verticalAlign: 'top',
            text: "<span class=\"fault-frequency-plotline-badge ".concat(chartIdentifier, "_").concat(faultFrequency.id, "\">").concat(faultFrequency.name.toUpperCase(), "</span>"),
        },
    });
};
export var FaultFrequencyDropdown = function (_a) {
    var chartIdentifier = _a.chartIdentifier, isVisibleFaultFrequenciesLabels = _a.isVisibleFaultFrequenciesLabels, width = _a.width, isDisabled = _a.isDisabled, dispatch = _a.dispatch, isVisibleFaultFrequencyIds = _a.isVisibleFaultFrequencyIds;
    var onToggleLabelFaultFrequencies = function () {
        dispatch({
            type: 'setState',
            state: {
                isVisibleFaultFrequenciesLabels: !isVisibleFaultFrequenciesLabels,
            },
        });
    };
    var _b = useState(false), isVisibleDropdown = _b[0], setIsVisibleDropdown = _b[1];
    var faultFrequencies = FftChartsStore(function (state) { return ({
        faultFrequencies: state.faultFrequencies,
    }); }, shallow).faultFrequencies;
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; }, shallow);
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var onFaultFrequency = useCallback(function (id) {
        var updatedVisibleFaultFrequencyIds = _cloneDeep(isVisibleFaultFrequencyIds);
        var index = updatedVisibleFaultFrequencyIds.indexOf(id);
        if (index !== -1) {
            updatedVisibleFaultFrequencyIds.splice(index, 1);
        }
        else {
            updatedVisibleFaultFrequencyIds.push(id);
        }
        dispatch({
            type: 'setState',
            state: {
                isVisibleFaultFrequencyIds: updatedVisibleFaultFrequencyIds,
            },
        });
    }, [isVisibleFaultFrequencyIds]);
    var onManageFaultFrequencies = useCallback(function () {
        setStatesChartFeaturesStore({
            isVisibleManageFaultFrequencies: true,
            setFaultFrequency: {
                openedByChartIdentifier: chartIdentifier,
            },
        });
    }, [chartIdentifier]);
    return (_jsx("div", { className: "chart-btn", children: _jsx("div", { className: "fault-frequency-dropdown chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), style: { width: width }, children: _jsxs(Dropdown, { disabled: isDisabled, direction: "down", isOpen: isVisibleDropdown, toggle: function () { return setIsVisibleDropdown(!isVisibleDropdown); }, children: [_jsxs(DropdownToggle, { color: "secondary", size: "sm", caret: true, children: [_jsx("span", { className: "fault-frequency-dropdown-toggle-link", children: "Fault/Forcing frequencies" }), faultFrequencies.length > 0 ? _jsx("span", { className: "indicator indicator-red" }) : _jsx(_Fragment, {})] }), _jsxs(DropdownMenu, { children: [faultFrequencies.length > 0 ? (_jsx("div", { className: "fault-frequencies-list", children: faultFrequencies.map(function (faultFrequency, index) {
                                    var _a;
                                    return (_jsxs("div", { id: "fft-fault-frequency-".concat(chartIdentifier, "-").concat(faultFrequency.id), children: [_jsxs(DropdownItem, { toggle: false, className: "fault-frequency-item", onClick: function () { return onFaultFrequency(faultFrequency.id); }, children: [!((_a = installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.isAsset) &&
                                                        faultFrequency.installation_point_id !== installationPoint.id && (_jsx(VscDebugBreakpointLog, { style: {
                                                            fontSize: '18px',
                                                            marginRight: '4px',
                                                            fill: '#d90',
                                                        } })), isVisibleFaultFrequencyIds.includes(+faultFrequency.id) ? _jsx(FaEye, {}) : _jsx(FaEyeSlash, {}), _jsx("span", { className: "ms-2 label fault-frequency-label", style: {
                                                            backgroundColor: faultFrequency.source === 'analytic' || faultFrequency.createdType === 'auto'
                                                                ? getPlotlineColor(index)
                                                                : '#4f4f4f',
                                                        }, children: faultFrequency.name }), _jsxs("span", { className: "ms-2", children: ["-", ' ', _jsxs("b", { children: [faultFrequency.frequency, faultFrequency.frequency_type === 'Orders' ? 'x' : ''] }), ' ', faultFrequency.frequency_type] })] }), isTwfChart(chartIdentifier) &&
                                                isVisibleFaultFrequencyIds.includes(+faultFrequency.id) ? (_jsx(UncontrolledTooltip, { target: "fft-fault-frequency-".concat(chartIdentifier, "-").concat(faultFrequency.id), children: "Click on the chart while holding down the CTRL (Command) key to change the base point" })) : (_jsx(_Fragment, {}))] }, "fft-fault-frequency-".concat(chartIdentifier, "-").concat(faultFrequency.id)));
                                }) })) : (_jsx(DropdownItem, { className: "btn-sm", onClick: function () { }, children: "There are no fault/forcing frequencies" })), faultFrequencies.length > 0 && _jsx(DropdownItem, { divider: true }), faultFrequencies.length > 0 && (_jsx(DropdownItem, { className: "btn-sm", onClick: onManageFaultFrequencies, children: "Manage fault/forcing frequency..." })), faultFrequencies.length > 0 && (_jsx(DropdownItem, { className: "btn-sm", onClick: onToggleLabelFaultFrequencies, children: isVisibleFaultFrequenciesLabels
                                    ? 'Show labels fault/forcing frequencies'
                                    : 'Hide labels fault frequencies' }))] })] }) }) }));
};
