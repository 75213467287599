var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { first as _first, size as _size, sortBy as _sortBy } from 'lodash';
import moment from 'moment';
import Api from '../../../api/api';
import { CUSTOMER_USER_ID } from '../../../constants';
import Languages from '../../../shared/helper/languages';
import { selectMeasure } from '../../../shared/store/global/useGlobalStore';
import { ACTION_LIST, AVATAR_AMD } from '../../constants';
export var actionItemKey = function (actionItemId) { return ['actionItem', +actionItemId]; };
var params = new URLSearchParams(window.location.search);
var actionItemFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get("amazon-analytic/action-item/".concat(queryKey[1]), {
        query: {
            state_id: params.get('state_id'),
            search: params.get('search'),
        },
    });
};
export var INACTIVE_STATES = [
    ACTION_LIST.STATE_CLOSED,
    ACTION_LIST.STATE_ARCHIVE,
    ACTION_LIST.STATE_REPORT,
    ACTION_LIST.STATE_REPORT_QUESTION,
];
export var useActionItemQuery = function (_a) {
    var actionItemId = _a.actionItemId;
    var measure = selectMeasure();
    return useQuery({
        queryKey: actionItemKey(actionItemId),
        queryFn: actionItemFn,
        select: function (_a) {
            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
            var data = _a.data, languages = _a.languages, action_list_no_actions_types = _a.action_list_no_actions_types, action_list_no_actions_groups = _a.action_list_no_actions_groups, action_list_reports_severity = _a.action_list_reports_severity, tags = _a.tags, photos = _a.photos;
            Languages.set(languages);
            var type = (_b = data === null || data === void 0 ? void 0 : data.type) !== null && _b !== void 0 ? _b : null, stateWithoutTroubleshooting = (_c = data === null || data === void 0 ? void 0 : data.state) !== null && _c !== void 0 ? _c : null, state = (data === null || data === void 0 ? void 0 : data.is_troubleshooting_waiting)
                ? ACTION_LIST.STATE_TROUBLESHOOTING_HOLD_WAITING
                : stateWithoutTroubleshooting, isClosedAi = INACTIVE_STATES.includes(state), isWwAi = type === ACTION_LIST.AI_TYPE_WW, sensorId = +data.sensor_id || null, equipmentType = data.equipment_type || null, isDeactivatedEquipment = !!data.isDeactivatedEquipment, existingActionItems = ((_e = (_d = data === null || data === void 0 ? void 0 : data.installationPoint) === null || _d === void 0 ? void 0 : _d.equipment) === null || _e === void 0 ? void 0 : _e.existingActionItems) || [], isHardwareAiExist = _size(existingActionItems.filter(function (existingActionItem) {
                return (!INACTIVE_STATES.includes(+existingActionItem.state) &&
                    existingActionItem.type === ACTION_LIST.AI_TYPE_WW &&
                    +existingActionItem.installation_point_id === +data.installation_point_id);
            })) > 0, inactiveReports = (data === null || data === void 0 ? void 0 : data.inactiveReports) || [], inactiveWwReports = ((data === null || data === void 0 ? void 0 : data.inactiveWwReports) || []).map(function (report) {
                return __assign(__assign({}, report), { isWwReport: true });
            }), report = data ? _first(data.reports) : null, wwReport = data
                ? _first(data.wwReports)
                    ? __assign(__assign({}, _first(data.wwReports)), { isWwReport: true }) : null
                : null, activeReport = type === ACTION_LIST.AI_TYPE_WW ? wwReport : report, declineReason = (data === null || data === void 0 ? void 0 : data.decline_reason) || null, isDontCreateWo = !!(data === null || data === void 0 ? void 0 : data.dont_create_wo), isMonitoring = !!(data === null || data === void 0 ? void 0 : data.is_monitoring);
            return {
                title: data.title,
                id: +data.id || null,
                activeReport: activeReport,
                declineReason: declineReason,
                equipmentType: equipmentType,
                existingActionItems: existingActionItems,
                isClosedAi: isClosedAi,
                isDeactivatedEquipment: isDeactivatedEquipment,
                isDontCreateWo: isDontCreateWo,
                isWwAi: isWwAi,
                sensorId: sensorId,
                sensorPartNumber: data === null || data === void 0 ? void 0 : data.sensorPartNumber,
                oldSensorId: (data === null || data === void 0 ? void 0 : data.old_sensor_id) || null,
                oldSensorPartNumber: (data === null || data === void 0 ? void 0 : data.oldSensorPartNumber) || null,
                state: state,
                stateWithoutTroubleshooting: stateWithoutTroubleshooting,
                type: type,
                serial: +data.serial || null,
                urgency: +data.urgency,
                timeCreated: data.time_created || null,
                updatedAt: data.updated_at || null,
                updatedBy: (data === null || data === void 0 ? void 0 : data.updated_by) || null,
                createdBy: (data === null || data === void 0 ? void 0 : data.created_by) || null,
                detectedBy: (data === null || data === void 0 ? void 0 : data.detected_by) || null,
                remindDate: (data === null || data === void 0 ? void 0 : data.remind_date) || null,
                installationPoint: data.installationPoint || null,
                installationPointIdsOnEquipment: (data === null || data === void 0 ? void 0 : data.installationPointIdsOnEquipment) || [],
                installationPointName: ((_f = data.installationPoint) === null || _f === void 0 ? void 0 : _f.name) || null,
                installationPointId: ((_g = data.installationPoint) === null || _g === void 0 ? void 0 : _g.id) || null,
                installationPointSensorId: +((_h = data.installationPoint) === null || _h === void 0 ? void 0 : _h.sensor_id) || null,
                isSensor: equipmentType === ACTION_LIST.EQUIPMENT_TYPE_SENSOR ||
                    equipmentType === ACTION_LIST.EQUIPMENT_TYPE_MOTE,
                isSensorExist: !!sensorId,
                isInactive: +((_j = data.installationPoint) === null || _j === void 0 ? void 0 : _j.is_deleted) === 1 || isDeactivatedEquipment,
                isShowMissAi: type === ACTION_LIST.AI_TYPE_REGULAR,
                isShowCommentForMlTeam: type !== ACTION_LIST.AI_TYPE_WW,
                translates: _size(data.translates) === 0 ? {} : data.translates,
                status: {
                    name: (_k = ACTION_LIST.STATUS_NAMES[state]) !== null && _k !== void 0 ? _k : '',
                    color: (_l = ACTION_LIST.STATUS_COLORS[state]) !== null && _l !== void 0 ? _l : '',
                    buttons: (_m = ACTION_LIST.STATUS_BUTTONS[state]) !== null && _m !== void 0 ? _m : [],
                },
                comments: (data === null || data === void 0 ? void 0 : data.activeActionListComments) || [],
                isTroubleshoot: +state === ACTION_LIST.STATE_TROUBLESHOOTING_HOLD,
                isTroubleshootingWaiting: !!(data === null || data === void 0 ? void 0 : data.is_troubleshooting_waiting),
                isViewMode: isWwAi || isClosedAi,
                isBooleanTypeAi: false,
                userImage: AVATAR_AMD,
                isReplaceEquipment: !!data.is_replace_equipment,
                replacementHistory: (_o = data === null || data === void 0 ? void 0 : data.replacementHistory) !== null && _o !== void 0 ? _o : null,
                isShowAwaitingUpdate: state === ACTION_LIST.STATE_QUESTION || state === ACTION_LIST.STATE_IN_PROGRESS,
                isShowInProgress: state !== ACTION_LIST.STATE_TROUBLESHOOTING_HOLD &&
                    [ACTION_LIST.STATE_AWAITING_UPDATE, ACTION_LIST.STATE_QUESTION, ACTION_LIST.STATE_NEW].includes(state),
                isShowChangeToHardwareIssue: state === ACTION_LIST.STATE_WW_NEW && !isHardwareAiExist,
                inactiveReportsList: _sortBy(inactiveWwReports.concat(inactiveReports), function (report) {
                    return moment(report.created_at);
                }).reverse(),
                isReportExist: !!_size(activeReport),
                isNoActionReport: !!data.is_no_actions,
                isWwMiss: +data.is_ww_miss === 1,
                wwMiss: (data === null || data === void 0 ? void 0 : data.wwMiss) || null,
                woNumber: (data === null || data === void 0 ? void 0 : data.wo_number) || null,
                node: (data === null || data === void 0 ? void 0 : data.node) || null,
                isOlderThen3Months: !!(data === null || data === void 0 ? void 0 : data.is_older_then_3_months),
                actionListCharts: (data === null || data === void 0 ? void 0 : data.actionListCharts) || [],
                isDeclinedReport: !!declineReason &&
                    [
                        ACTION_LIST.STATE_AWAITING_UPDATE,
                        ACTION_LIST.STATE_QUESTION,
                        ACTION_LIST.STATE_NEW,
                        ACTION_LIST.STATE_IN_PROGRESS,
                        ACTION_LIST.STATE_ON_HOLD,
                    ].includes(state),
                isShowNotificationBlock: !isWwAi &&
                    state === ACTION_LIST.STATE_WW_NEW &&
                    +data.user_id === CUSTOMER_USER_ID &&
                    isDontCreateWo,
                actionListNoActionsTypes: action_list_no_actions_types,
                actionListNoActionsGroups: action_list_no_actions_groups,
                actionListReportsSeverity: action_list_reports_severity,
                isSensorWasDetached: !!(data === null || data === void 0 ? void 0 : data.sensor_was_detached),
                isShowDetachBlockInReport: ((_p = _first(data === null || data === void 0 ? void 0 : data.replacementHistory)) === null || _p === void 0 ? void 0 : _p.type) === 'Detached sensor',
                isSensorMountedInTheSameOrientation: !!(data === null || data === void 0 ? void 0 : data.sensor_mounted_in_the_same_orientation),
                noActionsDescription: (data === null || data === void 0 ? void 0 : data.no_actions_description) || null,
                description: (data === null || data === void 0 ? void 0 : data.description) || null,
                noActionsType: (data === null || data === void 0 ? void 0 : data.no_actions_type) || null,
                noActionReports: (data === null || data === void 0 ? void 0 : data.noActionReports) || [],
                noActionsGroupId: (data === null || data === void 0 ? void 0 : data.no_actions_group_id) || null,
                noActionsGroupOtherText: (data === null || data === void 0 ? void 0 : data.no_actions_group_other_text) || null,
                wwNoActionReports: (data === null || data === void 0 ? void 0 : data.wwNoActionReports) || [],
                declinedReports: (data === null || data === void 0 ? void 0 : data.declinedReports) || [],
                verifiedTime: (data === null || data === void 0 ? void 0 : data.verified_time) || null,
                verifiedBy: (data === null || data === void 0 ? void 0 : data.verified_by) || null,
                timeSubmited: (data === null || data === void 0 ? void 0 : data.time_submited) || null,
                submitedBy: (data === null || data === void 0 ? void 0 : data.submited_by) || null,
                actionListTags: (data === null || data === void 0 ? void 0 : data.actionListTags) || null,
                facility: (data === null || data === void 0 ? void 0 : data.facility) || null,
                facilityMeasure: ((_q = data === null || data === void 0 ? void 0 : data.facility) === null || _q === void 0 ? void 0 : _q.measurement_units) || measure,
                sensor: (data === null || data === void 0 ? void 0 : data.sensor) || null,
                amazonMessages: (data === null || data === void 0 ? void 0 : data.amazonMessages) || [],
                approximateDate: (data === null || data === void 0 ? void 0 : data.approximate_date) || null,
                sla: (data === null || data === void 0 ? void 0 : data.sla) || null,
                wwActionListReasonGroups: {},
                analystLog: (_r = data === null || data === void 0 ? void 0 : data.analystLog) !== null && _r !== void 0 ? _r : [],
                tags: tags,
                photos: photos,
                isMonitoring: isMonitoring,
                isShowMonitoring: isMonitoring && !isClosedAi,
                severityIncrease: (data === null || data === void 0 ? void 0 : data.severityIncrease) || [],
                recommendations: [],
                isCustomerCreated: +data.user_id === CUSTOMER_USER_ID,
                isShowQuestion: type === ACTION_LIST.AI_TYPE_REGULAR,
            };
        },
        enabled: !isNaN(+actionItemId) && +actionItemId !== 0,
    });
};
