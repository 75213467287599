import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import Api from '../../../../../api/api';
var useQuery = useUntypedQuery;
export var AssetTreeQueryKey = function (_a) {
    var equipmentId = _a.equipmentId;
    return [
        'asset-tree',
        { equipmentId: equipmentId },
    ];
};
export var AssetTreeQueryFn = function (_a) {
    var equipmentId = _a.equipmentId;
    return Api.get("all-analytics/equipment/".concat(equipmentId, "/asset"));
};
export var useAssetTreeQuery = function (_a) {
    var equipmentId = _a.equipmentId;
    return useQuery({
        queryKey: AssetTreeQueryKey({ equipmentId: equipmentId }),
        queryFn: function () { return AssetTreeQueryFn({ equipmentId: equipmentId }); },
        select: function (_a) {
            var data = _a.data;
            return { data: data };
        },
        placeholderData: { data: null },
        enabled: !!equipmentId,
    });
};
