import { literal, number, object, union, variant } from 'valibot';
import { NUMBER_OF_STAGES_TYPE_GEAR_TEETH } from '../../constants';
export var numberOfStagesRules = function (singleType) {
    return variant('stages', [
        variant('type', [
            object({
                stages: literal(1),
                type: union(singleType.map(function (type) { return literal(type); })),
                stage1: number(),
            }),
            object({
                stages: literal(2),
                type: union(singleType.map(function (type) { return literal(type); })),
                stage1: number(),
                stage2: number(),
            }),
            object({
                stages: literal(3),
                type: union(singleType.map(function (type) { return literal(type); })),
                stage1: number(),
                stage2: number(),
                stage3: number(),
            }),
            object({
                stages: literal(4),
                type: union(singleType.map(function (type) { return literal(type); })),
                stage1: number(),
                stage2: number(),
                stage3: number(),
                stage4: number(),
            }),
        ]),
        variant('type', [
            object({
                stages: literal(1),
                type: union([literal(NUMBER_OF_STAGES_TYPE_GEAR_TEETH)]),
                stage1: number(),
                stage2: number(),
            }),
            object({
                stages: literal(2),
                type: union([literal(NUMBER_OF_STAGES_TYPE_GEAR_TEETH)]),
                stage1: number(),
                stage2: number(),
                stage3: number(),
                stage4: number(),
            }),
            object({
                stages: literal(3),
                type: union([literal(NUMBER_OF_STAGES_TYPE_GEAR_TEETH)]),
                stage1: number(),
                stage2: number(),
                stage3: number(),
                stage4: number(),
                stage5: number(),
                stage6: number(),
            }),
            object({
                stages: literal(4),
                type: union([literal(NUMBER_OF_STAGES_TYPE_GEAR_TEETH)]),
                stage1: number(),
                stage2: number(),
                stage3: number(),
                stage4: number(),
                stage5: number(),
                stage6: number(),
                stage7: number(),
                stage8: number(),
            }),
        ]),
    ]);
};
