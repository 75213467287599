import { ASSET_BEARING_TYPE, ASSET_ORIENTATION_HORIZONTAL } from '../../../../../../../constants';
export var drivenTypeVariantVariationDefaultValues = {
    type: undefined,
    bearingType: ASSET_BEARING_TYPE.ROLLING_ELEMENT,
    numberOfStagesType: undefined,
    numberOfStages: {
        stages: undefined,
        stage1: undefined,
        stage2: undefined,
        stage3: undefined,
        stage4: undefined,
        stage5: undefined,
        stage6: undefined,
        stage7: undefined,
        stage8: undefined,
    },
    bearingMonitoredPositionsVariant: 1,
    bearingMonitoredPositions: [],
    componentOrientation: ASSET_ORIENTATION_HORIZONTAL,
    numberOfThreads: undefined,
    numberOfLobes: undefined,
    numberOfVanes: undefined,
    numberGearTeeth: undefined,
    numberOfRowsOfHummer: undefined,
    rotor: undefined,
    balanceable: undefined,
    idlerShaftSpeed: undefined,
    timingGear: {
        value: false,
        teethIn: undefined,
        teethOut: undefined,
    },
    pistonRate: undefined,
};
export var drivenTypeVariantDefaultValues = {
    subtype: undefined,
    componentOrientation: ASSET_ORIENTATION_HORIZONTAL,
    numberOfStagesType: undefined,
    numberOfStages: undefined,
    bearingMonitoredPositionsVariant: 1,
    bearingMonitoredPositions: [],
    bearingType: ASSET_BEARING_TYPE.ROLLING_ELEMENT,
    numberOfPoles: undefined,
    numberOfElements: undefined,
    numberOfRowsOfHammers: undefined,
    rotor: undefined,
    fixedHammer: undefined,
    solidPlateAssembly: undefined,
    keyedPlateAssembly: undefined,
    headSprocketToothCount: undefined,
    pivoting: undefined,
    variation: drivenTypeVariantVariationDefaultValues,
};
