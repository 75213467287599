var _a, _b, _c, _d, _e, _f, _g;
import { jsx as _jsx } from "react/jsx-runtime";
import { ReactComponent as Belt } from '../../../../../assets/svg/Belt.svg';
import { ReactComponent as ChainOrToothedBelt } from '../../../../../assets/svg/ChainOrToothedBelt.svg';
import { ReactComponent as Coupling } from '../../../../../assets/svg/Coupling.svg';
import { ReactComponent as Solid } from '../../../../../assets/svg/Solid.svg';
export var COMPACT_MACHINE_TYPE = 'Compact Machine';
export var DRIVER_A_MOTOR = 'Driver A Motor';
export var DRIVER_A_TURBINE = 'Driver A Turbine';
export var DIESEL_ENGINE = 'Diesel Engine';
export var DRIVER_NOT_MONITORED = 'Driver Not Monitored';
export var NUMBER_OF_STAGES_TYPE_GEAR_TEETH = 'Gear Teeth';
export var NUMBER_OF_STAGES_TYPE_SPEED_RATIO = 'Speed Ratio';
export var NUMBER_OF_STAGES_TYPE_SHAFT_RPM = 'Shaft RPM';
export var NUMBER_OF_STAGES_TYPE_VANES = 'Vanes';
export var NUMBER_OF_STAGES_TYPE_BLADES = 'Blades';
export var TRANSMISSION_SUBTYPE_LOVEJOY = 'Lovejoy';
export var TRANSMISSION_SUBTYPE_UNIVERSAL_JOINT = 'Universal Joint';
export var TRANSMISSION_SUBTYPE_GEAR = 'Gear';
export var TRANSMISSION_TYPE_COUPLING = 'Coupling';
export var TRANSMISSION_TYPE_BELT = 'Belt';
export var TRANSMISSION_TYPE_CHAIN_OR_TOOTHED_BELT = 'Chain or Toothed Belt';
export var TRANSMISSION_TYPE_SOLID_NO_COUPLING = 'Solid - No Coupling';
export var TRANSMISSION_ICONS = (_a = {},
    _a[TRANSMISSION_TYPE_COUPLING] = _jsx(Coupling, { style: { width: '22px', height: '22px' } }),
    _a[TRANSMISSION_TYPE_BELT] = _jsx(Belt, { style: { width: '22px', height: '22px' } }),
    _a[TRANSMISSION_TYPE_CHAIN_OR_TOOTHED_BELT] = _jsx(ChainOrToothedBelt, { style: { width: '22px', height: '22px' } }),
    _a[TRANSMISSION_TYPE_SOLID_NO_COUPLING] = _jsx(Solid, { style: { width: '22px', height: '22px' } }),
    _a);
export var CALCULATION_MODE_SHEAVE_BELT_LENGTH = 'Sheave & Belt Length';
export var CALCULATION_MODE_TEETH_BELT_RPM = 'Teeth & Belt RPM';
export var CALCULATION_MODE_RATIO_BELT_RATE = 'Ratio & Belt Rate';
export var DRIVEN_SUBTYPE_SIMPLE_SHAFT = 'Simple Shaft';
export var DRIVEN_SUBTYPE_PUMP = 'Pump';
export var DRIVEN_SUBTYPE_FAN_AND_BLOWER = 'Fan/Blower';
export var DRIVEN_SUBTYPE_COMPRESSOR = 'Compressor';
export var DRIVEN_SUBTYPE_GENERATOR = 'Generator';
export var DRIVEN_SUBTYPE_CENTRIFUGE = 'Centrifuge';
export var DRIVEN_SUBTYPE_HAMMER_MILL = 'Hammer Mill';
export var DRIVEN_SUBTYPE_CONVEYOR_BELT_DRAG = 'Conveyor Belt / Drag';
export var DRIVEN_SUBTYPE_CONVEYOR_SCREW = 'Conveyor Screw';
export var DRIVEN_SUBTYPE_AGITATOR_MIXER = 'Agitator/Mixer';
export var ROTOR_TYPE_SUPPORTED = 'Supported';
export var ROTOR_TYPE_OVERHUNG = 'Overhung';
export var ROTOR_TYPES = [ROTOR_TYPE_SUPPORTED, ROTOR_TYPE_OVERHUNG];
export var CENTRIFUGE_TYPE_DECANTER = 'Decanter';
export var CENTRIFUGE_TYPE_BOWL = 'Bowl';
export var CENTRIFUGE_TYPE_DISC_STACK = 'Disc Stack';
export var CENTRIFUGE_TYPE_PEALER = 'Pealer';
export var CENTRIFUGE_TYPE_PUSHER = 'Pusher';
export var CENTRIFUGE_TYPES = [
    CENTRIFUGE_TYPE_DECANTER,
    CENTRIFUGE_TYPE_BOWL,
    CENTRIFUGE_TYPE_DISC_STACK,
    CENTRIFUGE_TYPE_PEALER,
    CENTRIFUGE_TYPE_PUSHER,
];
export var VARIATION_TYPE_PUMPS = {
    CENTRIFUGAL_PUMP: 'Centrifugal Pump',
    SCREW_PUMP: 'Screw Pump',
    AXIAL_FLOW_PROPELLER_PUMP: 'Axial Flow / Propeller Pump',
    RECIPROCATING_PUMP: 'Reciprocating Pump',
    SWASH_PLATE_AXIAL_PISTON_PUMP: 'Swash Plate / Axial Piston Pump',
    LOBED_PUMP: 'Lobed Pump',
    SLIDING_VANE_PUMP: 'Sliding Vane Pump',
    GEAR_PUMP: 'Gear Pump',
    SCROLL_PUMP: 'Scroll Pump',
};
export var VARIATION_TYPE_FAN_BLOWER = {
    AXIAL_FLOW_PROPELLER_FAN: 'Axial Flow / Propeller Fan',
    SQUIRREL_CAGE: 'Squirrel Cage',
    CENTRIFUGAL_BLOWER: 'Centrifugal Blower',
    LOBED_BLOWER: 'Lobed Blower',
    SCREW_BLOWER: 'Screw Blower',
};
export var VARIATION_TYPE_COMPRESSOR = {
    CENTRIFUGAL_COMPRESSOR: 'Centrifugal Compressor',
    SCREW_COMPRESSOR: 'Screw Compressor',
    LOBED_COMPRESSOR: 'Lobed Compressor',
    AXIAL_FLOW_COMPRESSOR: 'Axial Flow Compressor',
    SLIDING_VANE_ROTARY_VANE_COMPRESSOR: 'Sliding Vane / Rotary Vane Compressor',
    SWASH_PLATE_AXIAL_PISTON_COMPRESSOR: 'Swash Plate / Axial Piston Compressor',
    SCROLL_COMPRESSOR: 'Scroll Compressor',
    RECIPROCATING_COMPRESSOR: 'Reciprocating Compressor',
};
export var DRIVEN_SUBTYPES = [
    DRIVEN_SUBTYPE_SIMPLE_SHAFT,
    DRIVEN_SUBTYPE_PUMP,
    DRIVEN_SUBTYPE_FAN_AND_BLOWER,
    DRIVEN_SUBTYPE_COMPRESSOR,
    DRIVEN_SUBTYPE_GENERATOR,
    DRIVEN_SUBTYPE_CENTRIFUGE,
    DRIVEN_SUBTYPE_HAMMER_MILL,
    DRIVEN_SUBTYPE_CONVEYOR_BELT_DRAG,
    DRIVEN_SUBTYPE_CONVEYOR_SCREW,
    DRIVEN_SUBTYPE_AGITATOR_MIXER,
];
export var TRANSMISSION_TYPES = [
    TRANSMISSION_TYPE_COUPLING,
    TRANSMISSION_TYPE_BELT,
    TRANSMISSION_TYPE_CHAIN_OR_TOOTHED_BELT,
    TRANSMISSION_TYPE_SOLID_NO_COUPLING,
];
export var ASSET_EQUIPMENT_TYPES = [
    COMPACT_MACHINE_TYPE,
    DRIVER_A_MOTOR,
    DRIVER_A_TURBINE,
    DIESEL_ENGINE,
    DRIVER_NOT_MONITORED,
];
export var ASSET_ORIENTATION_HORIZONTAL = 'Horizontal';
export var ASSET_ORIENTATION_VERTICAL = 'Vertical';
export var ASSET_ORIENTATIONS = [
    ASSET_ORIENTATION_HORIZONTAL,
    ASSET_ORIENTATION_VERTICAL,
];
export var ASSET_BEARING_TYPE = {
    ROLLING_ELEMENT: 'Rolling Element',
    SLEEVE: 'Sleeve',
    BOTH: 'Both',
};
var ASSET_FOUNDATION_TYPE = {
    SPRING_ISOLATORS: 'Spring Isolators',
    GROUT_FILLED_BASE: 'Grout Filled Base',
    SUSPENDED_MOTOR: 'Suspended Motor',
    HOLLOW_FRAMEWORK_NON_GROUT_BASE: 'Hollow, Framework,  Non Grout Base',
    ELEVATED_UNSUPPORTED: 'Elevated Unsupported',
};
export var ASSET_FOUNDATION_TYPES = [
    ASSET_FOUNDATION_TYPE.SPRING_ISOLATORS,
    ASSET_FOUNDATION_TYPE.GROUT_FILLED_BASE,
    ASSET_FOUNDATION_TYPE.SUSPENDED_MOTOR,
    ASSET_FOUNDATION_TYPE.HOLLOW_FRAMEWORK_NON_GROUT_BASE,
    ASSET_FOUNDATION_TYPE.ELEVATED_UNSUPPORTED,
];
export var ASSET_BEARING_TYPES = [
    ASSET_BEARING_TYPE.ROLLING_ELEMENT,
    ASSET_BEARING_TYPE.SLEEVE,
    ASSET_BEARING_TYPE.BOTH,
];
export var ASSET_EQUIPMENT_TYPES_IDS = (_b = {},
    _b[COMPACT_MACHINE_TYPE] = 6,
    _b[DRIVER_A_MOTOR] = 1,
    _b[DRIVER_A_TURBINE] = 1,
    _b[DIESEL_ENGINE] = 1,
    _b[DRIVER_NOT_MONITORED] = 1,
    _b);
export var ASSET_EQUIPMENT_TYPES_SAVE_IDS = (_c = {},
    _c[COMPACT_MACHINE_TYPE] = 1,
    _c[DRIVER_A_MOTOR] = 2,
    _c[DRIVER_A_TURBINE] = 3,
    _c[DIESEL_ENGINE] = 4,
    _c[DRIVER_NOT_MONITORED] = 5,
    _c);
export var LAST_STEP_BY_EQUIPMENT_TYPE = (_d = {},
    _d[COMPACT_MACHINE_TYPE] = 5,
    _d[DRIVER_A_MOTOR] = 5,
    _d[DRIVER_A_TURBINE] = 4,
    _d[DIESEL_ENGINE] = 4,
    _d[DRIVER_NOT_MONITORED] = 5,
    _d);
export var STAGES_NAMES_TO_TYPE = (_e = {},
    _e[NUMBER_OF_STAGES_TYPE_GEAR_TEETH] = 'gear_teeth',
    _e[NUMBER_OF_STAGES_TYPE_SPEED_RATIO] = 'speed_ratio',
    _e[NUMBER_OF_STAGES_TYPE_SHAFT_RPM] = 'shaft_rpm',
    _e);
export var STAGES_NAMES_TO_KEY = (_f = {},
    _f[NUMBER_OF_STAGES_TYPE_GEAR_TEETH] = 't',
    _f[NUMBER_OF_STAGES_TYPE_SPEED_RATIO] = 'ratio',
    _f[NUMBER_OF_STAGES_TYPE_SHAFT_RPM] = 'rpmStage',
    _f);
export var NUMBER_OF_KEY = (_g = {},
    _g[NUMBER_OF_STAGES_TYPE_VANES] = 'numberOfVanes',
    _g[NUMBER_OF_STAGES_TYPE_BLADES] = 'numberOfBlades',
    _g);
