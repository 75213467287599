import { literal, number, object, optional, variant } from 'valibot';
export var timingGearRules = variant('value', [
    object({
        value: literal(true),
        teethIn: optional(number()),
        teethOut: optional(number()),
    }),
    object({
        value: literal(false),
    }),
]);
