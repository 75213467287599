import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { BearingMonitoredPositions } from '../../../../../../../BearingMonitoredPositions';
import { BearingType } from '../../../../../../../BearingType';
import { ComponentOrientation } from '../../../../../../../ComponentOrientation';
import { IdlerShaftSpeed } from '../../../../../../../IdlerShaftSpeed';
import { NumberOfThreads } from '../../../../../../../NumberOfThreads';
import { TimingGear } from '../../../../../../../TimingGear';
import styles from '../../styles.module.scss';
export var ScrewBlowerContent = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.componentOrientationAndBearing, children: [_jsx(ComponentOrientation, { isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.variation.componentOrientation' }), _jsx(BearingType, { isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.variation.bearingType' })] }), _jsx(BearingMonitoredPositions, { control: control, isDisabled: isDisabled, bearingMonitoredPositionsFieldName: 'data.drivenTypeVariant.variation.bearingMonitoredPositions', bearingMonitoredPositionsVariantFieldName: 'data.drivenTypeVariant.variation.bearingMonitoredPositionsVariant', equipmentId: equipmentId, schemas: [
                    [entryBoxNumber + 1, entryBoxNumber + 2],
                    [entryBoxNumber + 3, entryBoxNumber + 4, entryBoxNumber + 1, entryBoxNumber + 2],
                ], timingGearFieldName: 'data.drivenTypeVariant.variation.timingGear' }), _jsxs("div", { className: styles.positionThreeColumn, children: [_jsx(NumberOfThreads, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.variation.numberOfThreads' }), _jsx(IdlerShaftSpeed, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.variation.idlerShaftSpeed' })] }), _jsx("div", { className: styles.positionThreeColumn, children: _jsx(TimingGear, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.variation.timingGear' }) })] }));
});
ScrewBlowerContent.displayName = 'ScrewBlowerContent';
