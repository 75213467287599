import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { DIESEL_ENGINE, DRIVER_A_MOTOR, DRIVER_A_TURBINE } from '../../../../../../constants';
import { DieselEngine } from './components/DieselEngine';
import { DriverAMotor } from './components/DriverAMotor';
import { DriverATurbine } from './components/DriverATurbine';
var Component = function (equipmentType, control, equipmentId) {
    switch (equipmentType) {
        case DRIVER_A_MOTOR:
            return _jsx(DriverAMotor, { control: control, equipmentId: equipmentId });
        case DRIVER_A_TURBINE:
            return _jsx(DriverATurbine, { control: control, equipmentId: equipmentId });
        case DIESEL_ENGINE:
            return _jsx(DieselEngine, { control: control, equipmentId: equipmentId });
    }
};
export var DriverContent = memo(function (_a) {
    var control = _a.control, equipmentId = _a.equipmentId;
    var equipmentType = useWatch({
        control: control,
        name: 'equipmentType',
    });
    return Component(equipmentType, control, equipmentId);
});
DriverContent.displayName = 'DriverContent';
