var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { DRIVEN_SUBTYPES } from '../../../../../../../../constants';
import assetBuilderStyles from '../../../../../../styles.module.scss';
import { drivenTypeVariantDefaultValues, drivenTypeVariantVariationDefaultValues } from '../../constants';
export var Subtype = memo(function (_a) {
    var _b;
    var isDisabled = _a.isDisabled;
    var _c = useFormContext(), clearErrors = _c.clearErrors, control = _c.control;
    var onChangeVariation = useController({
        name: 'data.drivenTypeVariant.variation',
        control: control,
    }).field.onChange;
    var _d = useController({
        name: 'data.drivenTypeVariant',
        control: control,
    }), _e = _d.field, drivenTypeVariant = _e.value, onChange = _e.onChange, error = _d.fieldState.error;
    var onChangeSubtype = function (subtype) {
        onChange(__assign(__assign({}, drivenTypeVariantDefaultValues), { subtype: subtype }));
        onChangeVariation(__assign({}, drivenTypeVariantVariationDefaultValues));
    };
    var options = DRIVEN_SUBTYPES.map(function (subtype) { return ({
        label: subtype,
        value: subtype,
    }); });
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Component Type" }) }), _jsx(Select, { placeholder: "Select Component Type", classNamePrefix: "customSelectStyleDefault", isDisabled: isDisabled, value: options.find(function (el) { return el.value === drivenTypeVariant.subtype; }), onChange: function (data) {
                    clearErrors('data.drivenTypeVariant');
                    onChangeSubtype(data.value);
                }, options: options }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_b = error === null || error === void 0 ? void 0 : error.subtype) === null || _b === void 0 ? void 0 : _b.message })] }));
});
Subtype.displayName = 'Subtype';
