import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '@sharedStore/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import { SPEED_TYPES, SPEED_TYPE_LABELS } from '../../../../constants';
import './scss/assetSpeed.scss';
var AssetSpeedInfo = function () {
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow).setStatesChartFeaturesStore;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint, equipment = _get(installationPoint, 'equipment') || {}, asset = _get(equipment, 'assets.0') || {};
    var onCancel = function () {
        setStatesChartFeaturesStore({
            equipmentSpeedVisible: false,
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Row, { className: "asset-speed-setting-info", children: _jsxs(Col, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Asset Speed Type" }), _jsx(Input, { disabled: true, name: "equipment_speed_type", id: "equipment_speed_type", value: asset.speedSetting.is_variable === 1
                                                ? SPEED_TYPE_LABELS[SPEED_TYPES.VARIABLE]
                                                : SPEED_TYPE_LABELS[SPEED_TYPES.FIXED] })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Speed Variation, %" }), _jsx(Input, { disabled: true, name: "speed_variation", id: "speed_variation", value: asset.speedSetting.speed_variation })] }) })] }), _jsxs(FormGroup, { children: [_jsx(Label, { children: "Base Running Speed" }), _jsx(Input, { disabled: true, name: "base_running_speed", id: "base_running_speed", type: "number", value: asset.speedSetting.base_running_speed })] }), +asset.speedSetting.is_variable === 1 ? (_jsx(FormGroup, { children: _jsxs(Row, { children: [_jsxs(Col, { children: [_jsx(Label, { children: "Minimum RPM:" }), _jsx(Input, { disabled: true, name: "min_rpm", id: "min_rpm", type: "number", value: asset.speedSetting.min_rpm })] }), _jsxs(Col, { children: [_jsx(Label, { children: "Maximum RPM:" }), _jsx(Input, { disabled: true, name: "max_rpm", id: "max_rpm", type: "number", value: asset.speedSetting.max_rpm })] })] }) })) : (_jsx(_Fragment, {})), +asset.speedSetting.is_tachometer === 1 ? (_jsxs(FormGroup, { children: [_jsx(Label, { children: "Tachometer Multiplier" }), _jsx(Input, { disabled: true, name: "tachometer_multiplier", id: "tachometer_multiplier", type: "number", value: asset.speedSetting.tachometer_multiplier })] })) : (_jsx(_Fragment, {}))] }) }) }));
};
export default memo(AssetSpeedInfo);
