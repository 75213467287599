var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { literal, object, variant } from 'valibot';
import { DRIVER_A_TURBINE } from '../constants';
import { assetModuleIdRules } from './shared/assetModuleIdRules';
import { baseRunningSpeedRules } from './shared/baseRunningSpeedRules';
import { bearingMonitoredPositionsRules } from './shared/bearingMonitoredPositionsRules';
import { bearingTypeRules } from './shared/bearingTypeRules';
import { componentOrientationRules } from './shared/componentOrientationRules';
import { componentTypeRules } from './shared/componentTypeRules';
import { numberOfBladesRules } from './shared/numberOfBladesRules';
import { speedRules } from './shared/speedRules';
import { speedVariationRules } from './shared/speedVariationRules';
import { subtypeRules } from './shared/subtypeRules';
var driverATurbineSecondStepSchema = {
    assetModuleId: assetModuleIdRules,
    componentType: componentTypeRules,
    subtype: subtypeRules,
    componentOrientation: componentOrientationRules,
    bearingType: bearingTypeRules,
};
var driverATurbineThirdStepSchema = {
    bearingMonitoredPositions: bearingMonitoredPositionsRules([1, 2]),
};
var driverATurbineFourthStepSchema = {
    baseRunningSpeed: baseRunningSpeedRules,
    speedVariation: speedVariationRules,
    speed: speedRules,
    numberOfBlades: numberOfBladesRules,
};
export var driverATurbineSchema = object({
    equipmentType: literal(DRIVER_A_TURBINE),
    data: variant('step', [
        object({
            step: literal(1),
        }),
        object(__assign({ step: literal(2) }, driverATurbineSecondStepSchema)),
        object(__assign(__assign({ step: literal(3) }, driverATurbineSecondStepSchema), driverATurbineThirdStepSchema)),
        object(__assign(__assign(__assign({ step: literal(4) }, driverATurbineSecondStepSchema), driverATurbineThirdStepSchema), driverATurbineFourthStepSchema)),
    ], 'Step is required'),
});
