// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__vb4c0 {
  position: relative;
  display: flex;
  gap: 16px;
  width: 100%;
  max-width: 1600px;
  margin: 16px auto;
}
.wrapper__vb4c0 .aside__Q10j_ {
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 30%;
}
.wrapper__vb4c0 .title__bgJW9 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #212529;
  padding: 16px;
  margin-bottom: 0;
  border-bottom: 1px solid #d9d9d9;
}
.dark-layout .wrapper__vb4c0 .title__bgJW9 {
  color: #e2e2e2;
  border-color: #3e3f4f;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,aAAA;EACA,SAAA;EACA,WAAA;EACA,iBAAA;EACA,iBAAA;AACD;AAAC;EACC,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;AAEF;AAAC;EACC,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,qBAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;EACA,gCAAA;AAEF;AADE;EACC,cAAA;EACA,qBAAA;AAGH","sourcesContent":[".wrapper {\n\tposition: relative;\n\tdisplay: flex;\n\tgap: 16px;\n\twidth: 100%;\n\tmax-width: 1600px;\n\tmargin: 16px auto;\n\t.aside {\n\t\tposition: sticky;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tgap: 16px;\n\t\twidth: 30%;\n\t}\n\t.title {\n\t\tfont-size: 16px;\n\t\ttext-transform: uppercase;\n\t\tfont-weight: 500;\n\t\tletter-spacing: 0.5px;\n\t\tcolor: #212529;\n\t\tpadding: 16px;\n\t\tmargin-bottom: 0;\n\t\tborder-bottom: 1px solid #d9d9d9;\n\t\t:global(.dark-layout) & {\n\t\t\tcolor: #e2e2e2;\n\t\t\tborder-color: #3e3f4f;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__vb4c0`,
	"aside": `aside__Q10j_`,
	"title": `title__bgJW9`
};
module.exports = ___CSS_LOADER_EXPORT___;
