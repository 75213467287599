import { createStore } from '../../../../../store/createStore';
var useComponentTypeSelectStore = createStore(function (set) { return ({
    componentType: null,
    actions: {
        setComponentType: function (componentType) { return set({ componentType: componentType }); },
    },
}); });
export var useComponentTypeSelectActions = function () {
    return useComponentTypeSelectStore(function (state) { return state.actions; });
};
export var selectComponentType = function () {
    return useComponentTypeSelectStore(function (state) { return state.componentType; });
};
