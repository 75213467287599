import { literal, number, object, optional, union, variant } from 'valibot';
import { TRANSMISSION_SUBTYPE_GEAR, TRANSMISSION_SUBTYPE_LOVEJOY, TRANSMISSION_SUBTYPE_UNIVERSAL_JOINT, TRANSMISSION_TYPE_BELT, TRANSMISSION_TYPE_CHAIN_OR_TOOTHED_BELT, TRANSMISSION_TYPE_COUPLING, TRANSMISSION_TYPE_SOLID_NO_COUPLING, } from '../../constants';
import { calculationModeForBeltRules } from './calculationModeForBeltRules';
import { calculationModeForChainRules } from './calculationModeForChainRules';
export var transmissionRules = variant('type', [
    object({
        type: literal(TRANSMISSION_TYPE_COUPLING),
        transmissionType: variant('type', [
            object({
                type: union([
                    literal('Flexible'),
                    literal('Jack Shaft'),
                    literal('Fluid Coupling'),
                    literal('Magnetic'),
                    literal('Disc'),
                    literal('Grid'),
                ], 'Transmission type is required'),
            }),
            object({
                type: union([
                    literal(TRANSMISSION_SUBTYPE_LOVEJOY),
                    literal(TRANSMISSION_SUBTYPE_UNIVERSAL_JOINT),
                ]),
                numberOfComponents: optional(number()),
            }),
            object({
                type: literal(TRANSMISSION_SUBTYPE_GEAR),
                numberOfTeeth: optional(number()),
            }),
        ], 'Transmission type is required'),
    }),
    object({
        type: literal(TRANSMISSION_TYPE_BELT),
        calculationMode: calculationModeForBeltRules,
    }),
    object({
        type: literal(TRANSMISSION_TYPE_CHAIN_OR_TOOTHED_BELT),
        calculationMode: calculationModeForChainRules,
    }),
    object({
        type: literal(TRANSMISSION_TYPE_SOLID_NO_COUPLING),
    }),
], 'Transmission type is required');
