var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { get as _get } from 'lodash';
import React, { useContext, useEffect, useReducer } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Button, ButtonGroup, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '@sharedStore/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure, } from '@sharedStore/global/useGlobalStore';
import Endpoints from '../../api/endpoints/endpoints';
import { METRIC_KEY, READING_TYPE_FFT_ACCELERATION_X, READING_TYPE_FFT_VELOCITY_X, READING_TYPE_TWF_ACCELERATION_X, READING_TYPE_TWF_VELOCITY_X, SPEED_TYPES, } from '../../constants';
import Loader from '../../shared/components/Loader';
import { fftTicksPositioner, formatXAxisFFT, } from '../../shared/components/charts/chartsWrapper/components/fftTwf/FftAxisFormatter';
import { FftTooltipFormatter, fftPositioner, } from '../../shared/components/charts/chartsWrapper/components/shared/tooltip';
import { DEFAULT_CHARTS_CONFIG } from '../../shared/config/charts/defaultChartsConfig';
import { SystemTypeContext } from '../../shared/context/SystemTypeContext';
import { getUoms } from '../../shared/helper/chart';
import { reducer } from '../../shared/helper/reducer';
import { isSupportZAxis } from '../../widgets/sensor';
import './ClosestFft.scss';
var initialState = {
    optionsFft: DEFAULT_CHARTS_CONFIG,
    optionsTwf: DEFAULT_CHARTS_CONFIG,
    axis: 1,
    type: 'acc',
    loader: true,
};
var fetch = function (state, dispatch, installationPoint, readingTypes, currentSpeed, closestFft, selectedSystemType, measure, personalSettingMeasure) {
    dispatch({
        type: 'setState',
        state: {
            loader: true,
        },
    });
    var axis = state.axis, type = state.type, chartIdentifierFFT = type === 'acc' ? READING_TYPE_FFT_ACCELERATION_X : READING_TYPE_FFT_VELOCITY_X, chartIdentifierTWF = type === 'acc' ? READING_TYPE_TWF_ACCELERATION_X : READING_TYPE_TWF_VELOCITY_X;
    Endpoints[selectedSystemType]
        .getClosestFft(installationPoint.id, axis, closestFft.point.x / 1000, {
        query: {
            selected_facility_metric: METRIC_KEY[measure],
        },
    })
        .then(function (resp) {
        var _a;
        if (resp) {
            dispatch({
                type: 'setState',
                state: {
                    loader: false,
                    optionsFft: __assign(__assign({}, state.optionsFft), { legend: {
                            enabled: false,
                        }, pkpk: _get(resp, ['readings', 'fft', type, 'pkpk']), overall: _get(resp, ['readings', 'fft', type, 'overall']), series: [
                            {
                                id: "closest_fft_".concat(chartIdentifierFFT, "_").concat(installationPoint.id, "_").concat(type),
                                sensorID: installationPoint.sensor_id,
                                instPointID: installationPoint.id,
                                name: installationPoint.name ||
                                    "".concat(installationPoint.component, " - ").concat(installationPoint.point_name),
                                chartType: 'fft',
                                precision: 4,
                                dataGrouping: {
                                    approximation: 'high',
                                    forced: true,
                                },
                                color: '#7cb5ec',
                                data: _get(resp, ['readings', 'fft', type, 'data'], []),
                                units: getUoms(chartIdentifierFFT, measure, personalSettingMeasure),
                            },
                        ], yAxis: [
                            {
                                title: {
                                    text: "".concat(_get(readingTypes, [chartIdentifierFFT, 'title'], ''), " (").concat(getUoms(chartIdentifierFFT, measure, personalSettingMeasure), ")"),
                                },
                            },
                        ], xAxis: [
                            {
                                events: {},
                                tickInterval: 200,
                                crosshair: true,
                                ordinal: false,
                                plotLines: [],
                                title: {
                                    text: 'Frequency (Hz)',
                                },
                                labels: {
                                    formatter: function () {
                                        return formatXAxisFFT(this.chart, this.value, 'Hz', currentSpeed);
                                    },
                                },
                                tickPositioner: function () {
                                    return fftTicksPositioner(this.chart, this.dataMax, 'Hz', currentSpeed);
                                },
                            },
                        ], exporting: {
                            buttons: {},
                        }, tooltip: __assign(__assign({}, (_a = state.optionsFft) === null || _a === void 0 ? void 0 : _a.tooltip), { positioner: function (labelWidth) {
                                return fftPositioner(labelWidth, this.chart);
                            }, formatter: function () {
                                var _a, _b, _c;
                                return ReactDOMServer.renderToStaticMarkup((_jsx(FftTooltipFormatter, { isSensorSpeedType: !!installationPoint.speed, equipmentSpeedTypeId: (_b = (_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.equipment_speed_type_id) !== null && _b !== void 0 ? _b : SPEED_TYPES.FIXED, isAsset: !!((_c = installationPoint.equipment) === null || _c === void 0 ? void 0 : _c.isAsset), readingTypes: readingTypes, points: this.points, currentSpeed: currentSpeed, xValue: this.x })));
                            }, shape: 'square', shared: true, backgroundColor: 'rgba(255, 255, 255, .85)' }), chartIdentifier: READING_TYPE_FFT_ACCELERATION_X }),
                    optionsTwf: __assign(__assign({}, state.optionsTwf), { legend: {
                            enabled: false,
                        }, pkpk: _get(resp, ['readings', 'twf', type, 'pkpk']), overall: _get(resp, ['readings', 'twf', type, 'overall']), series: [
                            {
                                id: "closest_twf_".concat(chartIdentifierTWF, "_").concat(installationPoint.id, "_").concat(type),
                                sensorID: installationPoint.sensor_id,
                                instPointID: installationPoint.id,
                                name: installationPoint.name ||
                                    "".concat(installationPoint.component, " - ").concat(installationPoint.point_name),
                                chartType: 'twf',
                                precision: 4,
                                dataGrouping: {
                                    approximation: 'high',
                                    forced: true,
                                },
                                color: '#7cb5ec',
                                data: _get(resp, ['readings', 'twf', type, 'data'], []),
                                overall: _get(resp, ['readings', 'twf', type, 'overall'], []),
                                units: getUoms(chartIdentifierTWF, measure, personalSettingMeasure),
                            },
                        ], yAxis: [
                            {
                                title: {
                                    text: "".concat(_get(readingTypes, [chartIdentifierTWF, 'title'], ''), " (").concat(getUoms(chartIdentifierTWF, measure, personalSettingMeasure), ")"),
                                },
                            },
                        ], xAxis: [
                            {
                                events: {},
                                tickInterval: 0.1,
                                crosshair: true,
                                ordinal: false,
                                plotLines: [],
                                title: {
                                    text: 'Time',
                                },
                                labels: {
                                    formatter: function () {
                                        return formatXAxisFFT(this.chart, this.value, 'Hz', currentSpeed);
                                    },
                                },
                                tickPositioner: function () {
                                    return fftTicksPositioner(this.chart, this.value, 'Hz', currentSpeed);
                                },
                            },
                        ], exporting: {
                            buttons: {},
                        }, tooltip: __assign(__assign({}, state.optionsTwf.tooltip), { positioner: function (labelWidth) {
                                return fftPositioner(labelWidth, this.chart);
                            }, formatter: function () {
                                var _a, _b, _c;
                                return ReactDOMServer.renderToStaticMarkup((_jsx(FftTooltipFormatter, { isSensorSpeedType: !!installationPoint.speed, equipmentSpeedTypeId: (_b = (_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.equipment_speed_type_id) !== null && _b !== void 0 ? _b : SPEED_TYPES.FIXED, isAsset: !!((_c = installationPoint.equipment) === null || _c === void 0 ? void 0 : _c.isAsset), currentSpeed: currentSpeed, readingTypes: readingTypes, points: this.points, xValue: this.x })));
                            }, shape: 'square', shared: true, backgroundColor: 'rgba(255, 255, 255, .85)' }), chartIdentifier: READING_TYPE_TWF_ACCELERATION_X }),
                },
            });
        }
    })
        .catch(function () {
        dispatch({
            type: 'setState',
            state: {
                loader: true,
            },
        });
    });
};
var ClosestFft = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _a = ChartFeaturesStore(function (state) { return ({
        closestFft: state.closestFft,
        setStatesChartFeaturesStore: state.setStates,
    }); }, shallow), closestFft = _a.closestFft, setStatesChartFeaturesStore = _a.setStatesChartFeaturesStore;
    var _b = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        readingTypes: state.readingTypes,
        currentSpeed: state.currentSpeed,
    }); }, shallow), installationPoint = _b.installationPoint, readingTypes = _b.readingTypes, currentSpeed = _b.currentSpeed, sensor = _get(installationPoint, 'sensor') || {};
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    var optionsFft = state.optionsFft, optionsTwf = state.optionsTwf, axis = state.axis, type = state.type, loader = state.loader;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    useEffect(function () {
        fetch(state, dispatch, installationPoint, readingTypes, currentSpeed, closestFft, selectedSystemType, measure, personalSettingMeasure);
    }, [axis, type]);
    var setAxis = function (axis) {
        dispatch({
            type: 'setState',
            state: {
                axis: axis,
            },
        });
    };
    var setType = function (type) {
        dispatch({
            type: 'setState',
            state: {
                type: type,
            },
        });
    };
    var onCancel = function () {
        setStatesChartFeaturesStore({
            closestFft: {
                isVisibleModal: false,
                point: { x: 0, y: 0 },
            },
        });
    };
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Closest FFT -", ' ', installationPoint.name || "".concat(installationPoint.component, " - ").concat(installationPoint.point_name), " - Date:", ' ', Highcharts.dateFormat('%m/%d/%y %I:%M %p', closestFft.point.x)] }), _jsxs(ModalBody, { id: "charts-wrapper-modal", children: [_jsxs(Row, { children: [_jsx(Col, { md: 3, children: _jsxs(Input, { id: "axis", name: "axis", value: axis, onChange: function (ev) { return setAxis(ev.target.value); }, type: "select", children: [_jsx("option", { value: "1", children: "X-axis" }), _jsx("option", { value: "2", children: "Y-axis" }), isSupportZAxis(sensor.version_type) && _jsx("option", { value: "3", children: "Z-axis" })] }) }), _jsx(Col, { md: 3, children: _jsxs(Input, { id: "type", name: "type", value: type, onChange: function (ev) { return setType(ev.target.value); }, type: "select", children: [_jsx("option", { value: "acc", children: "Acceleration" }), _jsx("option", { value: "vel", children: "Velocity" })] }) })] }), loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center py-5", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsxs(_Fragment, { children: [_jsx(Row, { className: "chart-item mt-2 position-relative", "data-chart-identifier": READING_TYPE_FFT_ACCELERATION_X, children: _jsx(Col, { md: 12, children: _jsx(HighchartsReact, { highcharts: Highcharts, options: optionsFft }) }) }), _jsx(Row, { className: "chart-item mt-2 position-relative", "data-chart-identifier": READING_TYPE_TWF_ACCELERATION_X, children: _jsx(Col, { md: 12, children: _jsx(HighchartsReact, { highcharts: Highcharts, options: optionsTwf }) }) })] }))] }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { className: "response-ignore", children: _jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }) }) })] }));
};
export default ClosestFft;
