var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { get as _get, values as _values } from 'lodash';
import React, { memo, useState } from 'react';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { FftChartsStore } from '@sharedStore/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import { useAddAssetBearingMutation, } from '../../remastered/services/assetBearing/useAddAssetBearingMutation';
import { useAssetShaftPositionsQuery } from '../../remastered/services/useAssetShaftPositionsQuery';
import { afterCreate, updateStatesAfterAction } from '../../shared/features/bearings';
var AddAssetBearing = function (_a) {
    var id = _a.id, selectedInstallationPointId = _a.selectedInstallationPointId, onCancel = _a.onCancel, visible = _a.visible, list = _a.list;
    var queryClient = useQueryClient();
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var _b = FftChartsStore(function (state) { return ({
        installationPointBearings: state.installationPointBearings,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
    }); }, shallow), installationPointBearings = _b.installationPointBearings, setStatesFftChartsStore = _b.setStatesFftChartsStore;
    var data = useAssetShaftPositionsQuery(selectedInstallationPointId || installationPoint.id).data;
    var _c = useAddAssetBearingMutation(), useAddAssetBearingMutate = _c.mutate, isPending = _c.isPending;
    var _d = useState('onShaft'), selectedType = _d[0], setSelectedType = _d[1];
    var _e = useState(null), onShaft = _e[0], setOnShaft = _e[1];
    var _f = useState(null), ratio = _f[0], setRatio = _f[1];
    var onSave = function () {
        var additionalData = selectedType === 'onShaft'
            ? { onShaft: onShaft !== null ? onShaft : _get(data, '0.shaft') }
            : { ratio: ratio !== null ? ratio : 1 };
        useAddAssetBearingMutate(__assign({ bearingId: id, plotLinesCount: 100, installationPointId: installationPoint.id }, additionalData), {
            onSuccess: function (resp) {
                if (resp.data.id) {
                    queryClient.invalidateQueries({
                        queryKey: ['bearings', installationPoint.id],
                    });
                    updateStatesAfterAction(setStatesFftChartsStore, afterCreate(installationPointBearings, list, id, installationPoint));
                    return onCancel();
                }
                if (_values(resp.errors).length > 0) {
                    return toast.error(_values(resp.errors).join(' '), {
                        icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                    });
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            },
            onError: function () {
                toast.error('Something went wrong!', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
            },
        });
    };
    return (_jsxs(Modal, { size: "lg", className: "modal-response", toggle: onCancel, isOpen: visible, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Add Bearing", isPending && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsxs(ModalBody, { children: [_jsxs(Row, { className: "m-2", children: [_jsx(Col, { children: _jsx(FormGroup, { check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "use_metric", checked: selectedType === 'onShaft', onChange: function () {
                                                    setSelectedType('onShaft');
                                                } }), "On shaft"] }) }) }), _jsx(Col, { children: _jsx(FormGroup, { check: true, children: _jsx(Input, { type: "select", name: "select", value: onShaft, onChange: function (ev) { return setOnShaft(+ev.target.value); }, disabled: selectedType !== 'onShaft', children: data &&
                                            (data === null || data === void 0 ? void 0 : data.map(function (item) { return (_jsx("option", { value: item.shaft, children: item.shaft }, "shaf-position-".concat(item.shaft))); })) }) }) })] }), _jsxs(Row, { className: "m-2", children: [_jsx(Col, { children: _jsx(FormGroup, { check: true, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "radio", name: "use_metric", checked: selectedType === 'ratio', onChange: function () {
                                                    setSelectedType('ratio');
                                                } }), "Ratio"] }) }) }), _jsx(Col, { children: _jsx(FormGroup, { check: true, children: _jsx(Input, { value: ratio, disabled: selectedType !== 'ratio', onChange: function (ev) { return setRatio(+ev.target.value); }, name: "shart_positions_ratio" }) }) })] })] }), _jsx(ModalFooter, { children: _jsx(ButtonGroup, { children: _jsx(Button, { size: "sm", color: "primary", onClick: onSave, children: "Save" }) }) })] }));
};
export default memo(AddAssetBearing);
