var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var setRunningSpeedModels = function (data, setActiveInstallationPoint, installationPoint, setStatesInstallationPointStore, isRefreshFftReadings) {
    setStatesInstallationPointStore({
        isRefreshFftReadings: !isRefreshFftReadings,
    });
    setActiveInstallationPoint(__assign(__assign({}, installationPoint), { speed: +data.speed, speed_ratio: +data.speed_ratio }));
};
