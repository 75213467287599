import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
var useQuery = useUntypedQuery;
var faultFrequencyComponentQueryFn = function (_a) {
    var _b = _a.queryKey, _ = _b[0], data = _b[1];
    var coreLink = data.coreLink;
    return Api.get("".concat(coreLink, "/fault-frequency-component"));
};
export var useFaultFrequencyComponentQuery = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    return useQuery({
        queryKey: ['faultFrequencyComponent', { coreLink: getCoreUrl(selectedSystemType) }],
        queryFn: faultFrequencyComponentQueryFn,
        placeholderData: { data: [] },
        select: function (_a) {
            var data = _a.data;
            return data;
        },
    });
};
