import { createStore } from '../../../../../shared/store/createStore';
var useAssetBuilderStore = createStore(function (set) { return ({
    isAssetBuilderMode: false,
    componentId: null,
    actions: {
        setIsAssetBuilderMode: function (isAssetBuilderMode) { return set({ isAssetBuilderMode: isAssetBuilderMode }); },
        setComponentId: function (componentId) { return set({ componentId: componentId }); },
    },
}); }, 'AssetBuilder');
export var useAssetBuilderActions = function () {
    return useAssetBuilderStore(function (state) { return state.actions; });
};
export var selectIsAssetBuilderMode = function () {
    return useAssetBuilderStore(function (state) { return state.isAssetBuilderMode; });
};
export var selectComponentId = function () {
    return useAssetBuilderStore(function (state) { return state.componentId; });
};
