import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import Api from '../../../../../api/api';
var useQuery = useUntypedQuery;
export var AssignedPointsQueryKey = function (_a) {
    var equipmentId = _a.equipmentId;
    return [
        'assigned-points',
        { equipmentId: equipmentId },
    ];
};
export var AssignedPointsQueryFn = function (_a) {
    var equipmentId = _a.equipmentId;
    return Api.get("all-analytics/equipment/".concat(equipmentId, "/asset/points/assigned"));
};
export var useAssignedPointsQuery = function (_a) {
    var equipmentId = _a.equipmentId;
    return useQuery({
        queryKey: AssignedPointsQueryKey({ equipmentId: equipmentId }),
        queryFn: function () { return AssignedPointsQueryFn({ equipmentId: equipmentId }); },
        select: function (_a) {
            var data = _a.data;
            return { data: data };
        },
        placeholderData: { data: [] },
        enabled: !!equipmentId,
    });
};
