import { useMutation as useUntypedMutation, useQueryClient as useUntypedQueryClient, } from '@tanstack/react-query';
import { useContext } from 'react';
import { actionItemKey as actionItemKeyAmazon } from '../../../amazon/services/actionItems/useActionItemQuery';
import { preActionItemKey as preActionItemKeyAmazon } from '../../../amazon/services/preActionItems/usePreActionItemQuery';
import Api from '../../../api/api';
import { actionItemKey as actionItemKeyRemastered } from '../../../remastered/services/actionItems/useActionItemQuery';
import { preActionItemKey as preActionItemKeyRemastered } from '../../../remastered/services/preActionItems/usePreActionItemQuery';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
import { getHost } from '../../helper/customer';
var useMutation = useUntypedMutation;
var useQueryClient = useUntypedQueryClient;
var approveSeverityIncreaseFn = function (_a) {
    var payload = _a.payload, coreLink = _a.coreLink, storage = _a.storage;
    return Api.post("".concat(coreLink, "/action-item/approve-severity-increase"), payload, { host: getHost(storage) });
};
export var useApproveSeverityIncreaseMutation = function (_a) {
    var id = _a.id, isPreAI = _a.isPreAI, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (payload) {
            return approveSeverityIncreaseFn({
                coreLink: getCoreUrl(selectedSystemType),
                payload: payload,
                storage: storage,
            });
        },
        onSuccess: function () {
            queryClient.invalidateQueries({
                queryKey: isPreAI
                    ? selectedSystemType
                        ? preActionItemKeyRemastered(id)
                        : preActionItemKeyAmazon(id)
                    : selectedSystemType
                        ? actionItemKeyRemastered(id)
                        : actionItemKeyAmazon(id),
            });
        },
    });
};
