var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import { getIsViewMode } from '../../../../../../../../features';
import { useAssetTreeQuery } from '../../../../../../../../services/useAssetTreeQuery';
import assetBuilderStyles from '../../../../../../styles.module.scss';
import { BackToStep } from '../../../../../BackToStep';
import { BearingMonitoredPositions } from '../../../../../BearingMonitoredPositions';
import { BearingType } from '../../../../../BearingType';
import { ComponentOrientation } from '../../../../../ComponentOrientation';
import { ComponentType } from '../../../../../ComponentType';
import { NumberOfBlades } from '../../../../../NumberOfBlades';
import { RunningSpeed } from '../../../../../RunningSpeed';
import { FOURTH_STEP_TO_DEFAULT_VALUES, SECOND_STEP_TO_DEFAULT_VALUES, THIRD_STEP_TO_DEFAULT_VALUES, } from '../../../../constants';
import { Subtype } from '../../../Subtype';
import styles from '../DriverAMotor/styles.module.scss';
export var DriverATurbine = memo(function (_a) {
    var control = _a.control, equipmentId = _a.equipmentId;
    var assetTreeData = useAssetTreeQuery({ equipmentId: equipmentId }).data;
    var isViewMode = getIsViewMode(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data);
    var _b = useController({
        name: 'data',
        control: control,
    }).field, data = _b.value, onChange = _b.onChange;
    var step = data.step;
    return (_jsxs(_Fragment, { children: [step >= 2 && (_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsx("div", { className: assetBuilderStyles.sectionsBlock, children: _jsxs("div", { className: styles.position, children: [_jsx(ComponentType, { control: control, isDisabled: true }), _jsx(ComponentOrientation, { isDisabled: step !== 2, fieldName: 'data.componentOrientation' }), _jsx(BearingType, { isDisabled: step !== 2, fieldName: 'data.bearingType' }), _jsx(Subtype, { isDisabled: step !== 2 })] }) }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { return onChange(__assign(__assign(__assign({}, data), { step: 2 }), SECOND_STEP_TO_DEFAULT_VALUES)); }, isShow: step !== 2 })] })), step >= 3 && (_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsx("div", { className: assetBuilderStyles.sectionsBlock, children: _jsx(BearingMonitoredPositions, { control: control, isDisabled: step !== 3, bearingMonitoredPositionsFieldName: 'data.bearingMonitoredPositions', bearingMonitoredPositionsVariantFieldName: 'data.bearingMonitoredPositionsVariant', equipmentId: equipmentId, schemas: [[1, 2]] }) }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { return onChange(__assign(__assign(__assign({}, data), { step: 3 }), THIRD_STEP_TO_DEFAULT_VALUES)); }, isShow: step !== 3 })] })), step >= 4 && (_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsxs("div", { className: assetBuilderStyles.sectionsBlock, children: [_jsx(RunningSpeed, { isDisabled: step !== 4, equipmentId: equipmentId }), _jsx("div", { className: styles.position, children: _jsx(NumberOfBlades, { control: control, isDisabled: step !== 4, fieldName: 'data.numberOfBlades' }) })] }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { return onChange(__assign(__assign(__assign({}, data), { step: 4 }), FOURTH_STEP_TO_DEFAULT_VALUES)); }, isShow: step !== 4 })] }))] }));
});
DriverATurbine.displayName = 'DriverATurbine';
