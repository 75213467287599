import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import Api from '../../../../../api/api';
var useQuery = useUntypedQuery;
export var AssetTachometersQueryKey = function (_a) {
    var equipmentId = _a.equipmentId;
    return [
        'asset-tachometers',
        { equipmentId: equipmentId },
    ];
};
export var AssetTachometersQueryFn = function (_a) {
    var equipmentId = _a.equipmentId;
    return Api.get("all-analytics/equipment/".concat(equipmentId, "/asset/search-tachometers"));
};
export var useAssetTachometersQuery = function (_a) {
    var equipmentId = _a.equipmentId;
    return useQuery({
        queryKey: AssetTachometersQueryKey({ equipmentId: equipmentId }),
        queryFn: function () { return AssetTachometersQueryFn({ equipmentId: equipmentId }); },
        select: function (_a) {
            var list = _a.list;
            return { list: list };
        },
        placeholderData: { list: null },
        enabled: !!equipmentId,
    });
};
