var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'clsx';
import { memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { CALCULATION_MODE_RATIO_BELT_RATE, CALCULATION_MODE_SHEAVE_BELT_LENGTH, CALCULATION_MODE_TEETH_BELT_RPM, TRANSMISSION_SUBTYPE_GEAR, TRANSMISSION_SUBTYPE_LOVEJOY, TRANSMISSION_SUBTYPE_UNIVERSAL_JOINT, TRANSMISSION_TYPES, TRANSMISSION_TYPE_BELT, TRANSMISSION_TYPE_CHAIN_OR_TOOTHED_BELT, TRANSMISSION_TYPE_COUPLING, } from '../../../../constants';
import { getIsViewMode } from '../../../../features';
import { useAssetModuleQuery } from '../../../../services/useAssetModuleQuery';
import { useAssetTreeQuery } from '../../../../services/useAssetTreeQuery';
import assetBuilderStyles from '../../styles.module.scss';
import { BackToStep } from '../BackToStep';
import styles from './styles.module.scss';
export var Transmission = memo(function (_a) {
    var _b, _c, _d;
    var _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18;
    var isDisabled = _a.isDisabled, callback = _a.callback, equipmentId = _a.equipmentId;
    var _19 = useFormContext(), clearErrors = _19.clearErrors, control = _19.control;
    var _20 = useAssetModuleQuery(), data = _20.data, isLoading = _20.isLoading, isFetching = _20.isFetching;
    var assetTreeData = useAssetTreeQuery({ equipmentId: equipmentId }).data;
    var isViewMode = getIsViewMode(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data);
    var _21 = useController({
        name: 'data.transmission',
        control: control,
    }), _22 = _21.field, value = _22.value, onChange = _22.onChange, error = _21.fieldState.error;
    var type = value.type, transmissionType = value.transmissionType, calculationMode = value.calculationMode;
    var onChangeTransmission = function (type) {
        onChange({
            type: type,
            transmissionType: {
                type: undefined,
                numberOfComponents: undefined,
                numberOfTeeth: undefined,
            },
            calculationMode: {
                mode: undefined,
                sdi1: undefined,
                sdi2: undefined,
                teeth1: undefined,
                teeth2: undefined,
                beltLength: undefined,
                speedRatio: undefined,
                beltRpm: undefined,
            },
        });
    };
    var onChangeTransmissionTypeValue = function (field, val) {
        var _a;
        onChange(__assign(__assign({}, value), { transmissionType: __assign(__assign({}, transmissionType), (_a = {}, _a[field] = val, _a)) }));
    };
    var onChangeTransmissionModeValue = function (field, val) {
        var _a;
        onChange(__assign(__assign({}, value), { calculationMode: field === 'mode'
                ? {
                    mode: val,
                    sdi1: undefined,
                    sdi2: undefined,
                    teeth1: undefined,
                    teeth2: undefined,
                    beltLength: undefined,
                    speedRatio: undefined,
                    beltRpm: undefined,
                }
                : __assign(__assign({}, calculationMode), (_a = {}, _a[field] = val, _a)) }));
    };
    var couplingTypes = (_g = (_f = (_e = data === null || data === void 0 ? void 0 : data.data.find(function (el) { return el.name === 'Transmission'; })) === null || _e === void 0 ? void 0 : _e.assetComponents.find(function (item) { return item.name === 'Coupling'; })) === null || _f === void 0 ? void 0 : _f.assetComponentTypes) !== null && _g !== void 0 ? _g : [];
    var options = couplingTypes.map(function (el) { return ({
        value: el.name,
        label: el.name,
    }); });
    return (_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsxs("div", { className: assetBuilderStyles.sectionsBlock, children: [_jsx("div", { className: styles.typesPosition, children: _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Transmission type" }) }), _jsx("div", { className: cx(assetBuilderStyles.sectionButtons, (_b = {},
                                        _b[assetBuilderStyles.errorBorder] = !!((_h = error === null || error === void 0 ? void 0 : error.type) === null || _h === void 0 ? void 0 : _h.message),
                                        _b)), children: TRANSMISSION_TYPES.map(function (constType) {
                                        var _a;
                                        return (_jsx("button", { disabled: isDisabled, onClick: function () {
                                                clearErrors('data.transmission.type');
                                                onChangeTransmission(constType);
                                            }, className: cx(assetBuilderStyles.button, (_a = {},
                                                _a[assetBuilderStyles.active] = constType === type,
                                                _a)), children: constType }, constType));
                                    }) }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_j = error === null || error === void 0 ? void 0 : error.type) === null || _j === void 0 ? void 0 : _j.message })] }) }), type === TRANSMISSION_TYPE_COUPLING && (_jsxs("div", { className: styles.couplingPosition, children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Transmission type" }) }), _jsx(Select, { placeholder: "Select Transmission Type", classNamePrefix: "customSelectStyleDefault", isDisabled: isDisabled, isLoading: isLoading || isFetching, value: options.find(function (el) { return el.value === transmissionType.type; }), onChange: function (data) {
                                            clearErrors('data.transmission.transmissionType.type');
                                            onChangeTransmissionTypeValue('type', data.value);
                                        }, options: options }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_l = (_k = error === null || error === void 0 ? void 0 : error.transmissionType) === null || _k === void 0 ? void 0 : _k.type) === null || _l === void 0 ? void 0 : _l.message })] }), (transmissionType.type === TRANSMISSION_SUBTYPE_LOVEJOY ||
                                transmissionType.type === TRANSMISSION_SUBTYPE_UNIVERSAL_JOINT) && (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Number of components [CPL]" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_m = transmissionType.numberOfComponents) !== null && _m !== void 0 ? _m : '', onChange: function (e) {
                                            return onChangeTransmissionTypeValue('numberOfComponents', e.target.value ? Number(e.target.value) : undefined);
                                        }, min: 1 })] })), transmissionType.type === TRANSMISSION_SUBTYPE_GEAR && (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Number of teeth [CTM]" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_o = transmissionType.numberOfTeeth) !== null && _o !== void 0 ? _o : '', onChange: function (e) {
                                            return onChangeTransmissionTypeValue('numberOfTeeth', e.target.value ? Number(e.target.value) : undefined);
                                        }, min: 1 })] }))] })), type === TRANSMISSION_TYPE_BELT && (_jsxs("div", { className: styles.beltPosition, children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Calculation Mode" }) }), _jsx("div", { className: cx(assetBuilderStyles.sectionButtons, (_c = {},
                                            _c[assetBuilderStyles.errorBorder] = !!((_q = (_p = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _p === void 0 ? void 0 : _p.mode) === null || _q === void 0 ? void 0 : _q.message),
                                            _c)), children: [CALCULATION_MODE_SHEAVE_BELT_LENGTH, CALCULATION_MODE_RATIO_BELT_RATE].map(function (mode) {
                                            var _a;
                                            return (_jsx("button", { disabled: isDisabled, onClick: function () {
                                                    clearErrors('data.transmission.calculationMode');
                                                    onChangeTransmissionModeValue('mode', mode);
                                                }, className: cx(assetBuilderStyles.button, (_a = {},
                                                    _a[assetBuilderStyles.active] = calculationMode.mode === mode,
                                                    _a)), children: mode }, mode));
                                        }) }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_s = (_r = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _r === void 0 ? void 0 : _r.mode) === null || _s === void 0 ? void 0 : _s.message })] }), CALCULATION_MODE_SHEAVE_BELT_LENGTH === calculationMode.mode && (_jsxs(_Fragment, { children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Sheave Diameter 1" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_t = calculationMode.sdi1) !== null && _t !== void 0 ? _t : '', onChange: function (e) {
                                                    clearErrors('data.transmission.calculationMode.sdi1');
                                                    onChangeTransmissionModeValue('sdi1', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_v = (_u = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _u === void 0 ? void 0 : _u.sdi1) === null || _v === void 0 ? void 0 : _v.message })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Sheave Diameter 2" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_w = calculationMode.sdi2) !== null && _w !== void 0 ? _w : '', onChange: function (e) {
                                                    clearErrors('data.transmission.calculationMode.sdi2');
                                                    onChangeTransmissionModeValue('sdi2', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_y = (_x = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _x === void 0 ? void 0 : _x.sdi2) === null || _y === void 0 ? void 0 : _y.message })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Belt Length" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_z = calculationMode.beltLength) !== null && _z !== void 0 ? _z : '', onChange: function (e) {
                                                    return onChangeTransmissionModeValue('beltLength', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 })] })] })), CALCULATION_MODE_RATIO_BELT_RATE === calculationMode.mode && (_jsxs(_Fragment, { children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Speed Ratio" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_0 = calculationMode.speedRatio) !== null && _0 !== void 0 ? _0 : '', onChange: function (e) {
                                                    clearErrors('data.transmission.calculationMode.speedRatio');
                                                    onChangeTransmissionModeValue('speedRatio', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_2 = (_1 = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _1 === void 0 ? void 0 : _1.speedRatio) === null || _2 === void 0 ? void 0 : _2.message })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Belt RPM" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_3 = calculationMode.beltRpm) !== null && _3 !== void 0 ? _3 : '', onChange: function (e) {
                                                    return onChangeTransmissionModeValue('beltRpm', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 })] })] }))] })), type === TRANSMISSION_TYPE_CHAIN_OR_TOOTHED_BELT && (_jsxs("div", { className: styles.chainBeltPosition, children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Calculation Mode" }) }), _jsx("div", { className: cx(assetBuilderStyles.sectionButtons, (_d = {},
                                            _d[assetBuilderStyles.errorBorder] = !!((_5 = (_4 = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _4 === void 0 ? void 0 : _4.mode) === null || _5 === void 0 ? void 0 : _5.message),
                                            _d)), children: [CALCULATION_MODE_TEETH_BELT_RPM, CALCULATION_MODE_RATIO_BELT_RATE].map(function (mode) {
                                            var _a;
                                            return (_jsx("button", { disabled: isDisabled, onClick: function () {
                                                    clearErrors('data.transmission.calculationMode');
                                                    onChangeTransmissionModeValue('mode', mode);
                                                }, className: cx(assetBuilderStyles.button, (_a = {},
                                                    _a[assetBuilderStyles.active] = calculationMode.mode === mode,
                                                    _a)), children: mode }, mode));
                                        }) }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_7 = (_6 = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _6 === void 0 ? void 0 : _6.mode) === null || _7 === void 0 ? void 0 : _7.message })] }), CALCULATION_MODE_TEETH_BELT_RPM === calculationMode.mode && (_jsxs(_Fragment, { children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Teeth 1" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_8 = calculationMode.teeth1) !== null && _8 !== void 0 ? _8 : '', onChange: function (e) {
                                                    clearErrors('data.transmission.calculationMode.teeth1');
                                                    onChangeTransmissionModeValue('teeth1', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_10 = (_9 = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _9 === void 0 ? void 0 : _9.teeth1) === null || _10 === void 0 ? void 0 : _10.message })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Teeth 2" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_11 = calculationMode.teeth2) !== null && _11 !== void 0 ? _11 : '', onChange: function (e) {
                                                    clearErrors('data.transmission.calculationMode.teeth2');
                                                    onChangeTransmissionModeValue('teeth2', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_13 = (_12 = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _12 === void 0 ? void 0 : _12.teeth2) === null || _13 === void 0 ? void 0 : _13.message })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Belt RPM" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_14 = calculationMode.beltRpm) !== null && _14 !== void 0 ? _14 : '', onChange: function (e) {
                                                    return onChangeTransmissionModeValue('beltRpm', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 })] })] })), CALCULATION_MODE_RATIO_BELT_RATE === calculationMode.mode && (_jsxs(_Fragment, { children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Speed Ratio" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_15 = calculationMode.speedRatio) !== null && _15 !== void 0 ? _15 : '', onChange: function (e) {
                                                    clearErrors('data.transmission.calculationMode.speedRatio');
                                                    onChangeTransmissionModeValue('speedRatio', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_17 = (_16 = error === null || error === void 0 ? void 0 : error.calculationMode) === null || _16 === void 0 ? void 0 : _16.speedRatio) === null || _17 === void 0 ? void 0 : _17.message })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Belt RPM" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: (_18 = calculationMode.beltRpm) !== null && _18 !== void 0 ? _18 : '', onChange: function (e) {
                                                    return onChangeTransmissionModeValue('beltRpm', e.target.value ? Number(e.target.value) : undefined);
                                                }, min: 1 })] })] }))] }))] }), _jsx(BackToStep, { isViewMode: isViewMode, callback: callback, isShow: isDisabled })] }));
});
Transmission.displayName = 'Transmission';
