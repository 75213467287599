var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { CAUSE_CATEGORY_SEVERITY_MINOR } from '../../../constants';
import RichTextEditor from '../../../widgets/TextEditor/TextEditor';
import ApproveReassignRecommendations from '../../components/actionItem/selectedRecommendations/ApproveReassignRecommendations';
import { selectIsVisibleCancelAndCloseModal } from '../../store/AIPreAi/useActionItemModalsStore';
var CancelAndCloseModal = function (_a) {
    var onCancel = _a.onCancel, customerId = _a.customerId, cancelAndCloseMutate = _a.cancelAndCloseMutate, cancelAndCloseIsLoading = _a.cancelAndCloseIsLoading, _b = _a.storage, storage = _b === void 0 ? null : _b, recommendations = _a.recommendations, onSuccess = _a.onSuccess;
    var actionItemId = useParams().id;
    var isVisibleCancelAndCloseModal = selectIsVisibleCancelAndCloseModal();
    var _c = useState(''), description = _c[0], setDescription = _c[1];
    var _d = useState(''), mlComment = _d[0], setMlComment = _d[1];
    var _e = useState(recommendations.length === 0), isRecommendationsApproved = _e[0], setIsRecommendationsApproved = _e[1];
    var _f = useState({}), reassignedRecommendations = _f[0], setReassignedRecommendations = _f[1];
    var _g = useState(''), internalComment = _g[0], setInternalComment = _g[1];
    var onConfirm = function () {
        var _a;
        var strippedDescription = description.replace(/(<([^>]+)>)|\s|&nbsp;/gi, '');
        if (strippedDescription === '') {
            return toast.error('The reason for closing Action Item is required');
        }
        cancelAndCloseMutate({
            action_list_id: actionItemId,
            description: description,
            ml_comment: mlComment,
            isRecommendationsApproved: isRecommendationsApproved && Object.values(reassignedRecommendations).length === 0 ? 1 : 0,
            reassignedRecommendations: ((_a = reassignedRecommendations.faultRecommendations) === null || _a === void 0 ? void 0 : _a.filter(function (faultRecommendation) { return faultRecommendation; }).map(function (faultRecommendation) { return (__assign(__assign({}, faultRecommendation), { severity: CAUSE_CATEGORY_SEVERITY_MINOR })); })) || [],
            internalComment: internalComment,
        }, {
            onSuccess: function () {
                toast.success('Successfully closed', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                setDescription('');
                onCancel();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            },
        });
    };
    return (_jsxs(Modal, { size: "xl", className: "modal-response", toggle: onCancel, isOpen: isVisibleCancelAndCloseModal, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Cancel & Close Action Item?" }), _jsx(ModalBody, { children: _jsxs(Form, { children: [_jsxs(FormGroup, { children: [_jsx(Label, { for: "textarea", children: "The reason for closing Action Item:" }), _jsx(RichTextEditor, { storage: storage, customerId: customerId, onChange: function (content) { return setDescription(content); } })] }), _jsx(ApproveReassignRecommendations, { recommendations: recommendations, setReassignedRecommendations: setReassignedRecommendations, setIsRecommendationsApproved: setIsRecommendationsApproved, setInternalComment: setInternalComment, isRecommendationsApproved: isRecommendationsApproved }), _jsxs(FormGroup, { children: [_jsx(Label, { for: "ml_comment", children: "Comment for ML Team:" }), _jsx(Input, { className: "input-cancel", id: "ml_comment", bsSize: 'lg', label: "ml_comment", type: "textarea", onChange: function (ev) { return setMlComment(ev.currentTarget.value); } })] })] }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { disabled: cancelAndCloseIsLoading, size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Cancel" }), _jsx(Button, { disabled: cancelAndCloseIsLoading || !isRecommendationsApproved, size: "sm", color: "primary", onClick: onConfirm, children: "Confirm" })] }) })] }));
};
export default memo(CancelAndCloseModal);
