import { createStore } from '../../../store/createStore';
var useFFLabelSelectStore = createStore(function (set) { return ({
    ffLabel: null,
    actions: {
        setFFLabel: function (ffLabel) { return set({ ffLabel: ffLabel }); },
    },
}); });
export var useFFLabelSelectActions = function () {
    return useFFLabelSelectStore(function (state) { return state.actions; });
};
export var selectFFLabel = function () {
    return useFFLabelSelectStore(function (state) { return state.ffLabel; });
};
