// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position__FF6ak {
  display: flex;
  gap: 8px;
}

.inputGroupWrapper__y6CRd {
  display: flex;
  gap: 6px;
}

.inputGroup__zWP3V {
  display: flex;
  gap: 4px;
}

.inputStyle__tO_gp {
  max-width: 50px;
}

.bigInput__PHqlr {
  max-width: 80px;
}

.typeButtons__EOs_n {
  width: 340px;
}

.opacityZero__IClRL {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/NumberOfStages/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,QAAA;AACD;;AAEA;EACC,aAAA;EACA,QAAA;AACD;;AAEA;EACC,aAAA;EACA,QAAA;AACD;;AACA;EACC,eAAA;AAED;;AACA;EACC,eAAA;AAED;;AACA;EACC,YAAA;AAED;;AACA;EACC,UAAA;AAED","sourcesContent":[".position {\n\tdisplay: flex;\n\tgap: 8px;\n}\n\n.inputGroupWrapper {\n\tdisplay: flex;\n\tgap: 6px;\n}\n\n.inputGroup {\n\tdisplay: flex;\n\tgap: 4px;\n}\n.inputStyle {\n\tmax-width: 50px;\n}\n\n.bigInput {\n\tmax-width: 80px;\n}\n\n.typeButtons {\n\twidth: 340px;\n}\n\n.opacityZero {\n\topacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"position": `position__FF6ak`,
	"inputGroupWrapper": `inputGroupWrapper__y6CRd`,
	"inputGroup": `inputGroup__zWP3V`,
	"inputStyle": `inputStyle__tO_gp`,
	"bigInput": `bigInput__PHqlr`,
	"typeButtons": `typeButtons__EOs_n`,
	"opacityZero": `opacityZero__IClRL`
};
module.exports = ___CSS_LOADER_EXPORT___;
