import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import { InstallationPointsSelect } from './components/InstallationPointsSelect';
import styles from './styles.module.scss';
export var InstallationPointsSelects = memo(function (_a) {
    var control = _a.control, equipmentId = _a.equipmentId, isDisabled = _a.isDisabled, bearingMonitoredPositionsFieldName = _a.bearingMonitoredPositionsFieldName, timingGearFieldName = _a.timingGearFieldName, numberOfStagesField = _a.numberOfStagesField;
    var value = useController({
        name: bearingMonitoredPositionsFieldName,
        control: control,
    }).field.value;
    return (_jsx("div", { className: styles.wrapper, children: value.map(function (_a, index) {
            var id = _a.id;
            return (_jsxs("div", { className: styles.ip, children: [_jsxs("span", { className: styles.ipName, children: ["Installation Point ", id] }), _jsx(InstallationPointsSelect, { isDisabled: isDisabled, id: id, equipmentId: equipmentId, control: control, bearingMonitoredPositionsFieldName: bearingMonitoredPositionsFieldName, timingGearFieldName: timingGearFieldName, numberOfStagesField: numberOfStagesField, index: index })] }, id));
        }) }));
});
InstallationPointsSelects.displayName = 'InstallationPointsSelects';
