import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { useFaultFrequencyLabelQuery, } from '../../../../services/faultFrequency/useFaultFrequencyLabelQuery';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import { useFFLabelAndDescHandlers } from '../../hooks';
import { selectFFLabel } from '../../stores/useFFLabelSelectStore';
import { selectComponentType } from '../ComponentTypeSelect/stores/useComponentTypeSelectStore';
export var FFDescriptionSelect = function () {
    var componentType = selectComponentType();
    var ffLabel = selectFFLabel();
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var data = useFaultFrequencyLabelQuery({
        componentId: componentType === null || componentType === void 0 ? void 0 : componentType.id,
        equipmentById: installationPoint.equipment_id,
    }).data;
    var onChangeHandler = useFFLabelAndDescHandlers().onChangeHandler;
    return (_jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "FF Description:" }) }), _jsx(Select, { className: "select-custom-default", isSearchable: true, classNamePrefix: "customSelectStyleDefault", value: ffLabel ? { value: ffLabel, label: ffLabel.desc } : null, options: data === null || data === void 0 ? void 0 : data.map(function (el) {
                    return { value: el, label: el.desc };
                }), onChange: function (ev) { return onChangeHandler(ev.value); } })] }));
};
FFDescriptionSelect.displayName = 'FFDescriptionSelect';
