import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import Select from 'react-select';
import { CENTRIFUGE_TYPES } from '../../../../constants';
export var CentrifugeSelect = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, fieldName = _a.fieldName, clearError = _a.clearError;
    var options = CENTRIFUGE_TYPES.map(function (type) {
        return {
            label: type,
            value: type,
        };
    });
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, value = _b.value, onChange = _b.onChange;
    return (_jsx(Select, { placeholder: "Select Centrifuge", classNamePrefix: "customSelectStyleDefault", isDisabled: isDisabled, value: options.find(function (el) { return el.value === value; }), onChange: function (data) {
            clearError();
            onChange(data.value);
        }, options: options }));
});
CentrifugeSelect.displayName = 'CentrifugeSelect';
