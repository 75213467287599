import { get as _get, size as _size } from 'lodash';
import { isCfChart } from '../../../../../helper/chart';
import { calculateCurrentLevel } from './alertConditions';
export var getZones = function (chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure) {
    var _a, _b;
    if (isCfChart(chartIdentifier)) {
        return [];
    }
    if (_size(alertConditions) === 0 || !_get(alertConditions, 'conditions')) {
        return [];
    }
    var currentLevels = calculateCurrentLevel(chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure);
    var lowerZones = [];
    var upperZones = [];
    var cautionColor = '#eab700';
    var warningColor = '#ff0000';
    currentLevels.forEach(function (_a) {
        var caution = _a.caution, warning = _a.warning, cautionCondition = _a.cautionCondition, warningCondition = _a.warningCondition;
        if (caution && cautionCondition) {
            var value = Number(caution);
            if (cautionCondition === '<=' || cautionCondition === '<') {
                lowerZones.push({ value: value, color: cautionColor });
            }
            else if (cautionCondition === '>=' || cautionCondition === '>') {
                upperZones.push({ value: value, color: cautionColor });
            }
        }
        if (warning && warningCondition) {
            var value = Number(warning);
            if (warningCondition === '<=' || warningCondition === '<') {
                lowerZones.push({ value: value, color: warningColor });
            }
            else if (warningCondition === '>=' || warningCondition === '>') {
                upperZones.push({ value: value, color: warningColor });
            }
        }
    });
    lowerZones.sort(function (a, b) { var _a, _b; return ((_a = a.value) !== null && _a !== void 0 ? _a : 0) - ((_b = b.value) !== null && _b !== void 0 ? _b : 0); });
    upperZones.sort(function (a, b) { var _a, _b; return ((_a = a.value) !== null && _a !== void 0 ? _a : 0) - ((_b = b.value) !== null && _b !== void 0 ? _b : 0); });
    var zones = [];
    if (lowerZones.length) {
        zones.push.apply(zones, lowerZones);
    }
    if (upperZones.length) {
        if (lowerZones.length) {
            if (((_a = lowerZones[lowerZones.length - 1].value) !== null && _a !== void 0 ? _a : 0) < ((_b = upperZones[0].value) !== null && _b !== void 0 ? _b : 0)) {
                zones.push({ value: upperZones[0].value });
            }
        }
        else {
            zones.push({ value: upperZones[0].value });
        }
        for (var i = 0; i < upperZones.length - 1; i++) {
            zones.push({ value: upperZones[i + 1].value, color: upperZones[i].color });
        }
        zones.push({ color: upperZones[upperZones.length - 1].color });
    }
    else if (lowerZones.length) {
        zones.push({});
    }
    return zones;
};
