import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { ReactComponent as AssetComponentIcon } from '../../../../assets/svg/AssetComponentIcon.svg';
import { POINT_TYPE_ADAPTER, POINT_TYPE_SENSOR } from '../../../../constants';
import { TRANSMISSION_ICONS } from '../../../../remastered/components/charts/AssetBuilder/constants';
import { LeftEquipmentListStore } from '../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import DefaultTooltip from '../../DefaultTooltip';
import HighlighterWord from '../../shared/HighlighterWord';
import InstallationPointRow from './InstallationPointRow';
var ComponentRow = function (_a) {
    var installationPoints = _a.installationPoints, componentName = _a.componentName, equipmentName = _a.equipmentName, activeInstallationPointId = _a.activeInstallationPointId, overlayInstallationPoints = _a.overlayInstallationPoints, onOverlayCheck = _a.onOverlayCheck, equipment = _a.equipment, assetConfig = _a.assetConfig, isNotAssigned = _a.isNotAssigned;
    var _b = useState(true), isActive = _b[0], setIsActive = _b[1];
    var filter = LeftEquipmentListStore(function (state) { return ({
        filter: state.filter,
    }); }, shallow).filter;
    if ((assetConfig === null || assetConfig === void 0 ? void 0 : assetConfig.asset_module_name) === 'Foundation') {
        return _jsx(_Fragment, {});
    }
    if ((assetConfig === null || assetConfig === void 0 ? void 0 : assetConfig.asset_module_name) === 'Transmission') {
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: "equipment-transmission", "data-tooltip-id": "transmission_".concat(assetConfig === null || assetConfig === void 0 ? void 0 : assetConfig.id), children: TRANSMISSION_ICONS[assetConfig === null || assetConfig === void 0 ? void 0 : assetConfig.asset_component_name] }), _jsx(DefaultTooltip, { style: { zIndex: 1 }, openEvents: { mouseenter: true }, place: "right", id: "transmission_".concat(assetConfig === null || assetConfig === void 0 ? void 0 : assetConfig.id), children: assetConfig === null || assetConfig === void 0 ? void 0 : assetConfig.asset_component_name }), ' '] }));
    }
    return (_jsxs(React.Fragment, { children: [_jsx("div", { className: "equipment-item-sub-header", style: isNotAssigned ? { fontWeight: '400' } : {}, onClick: function () { return setIsActive(!isActive); }, children: _jsxs("div", { className: "equipment-code ".concat(assetConfig && 'asset-component'), children: [assetConfig && _jsx(AssetComponentIcon, { style: { width: '18px', height: '18px' } }), assetConfig && assetConfig.asset_module_name !== 'Gearbox' ? (_jsxs("div", { children: [_jsx(HighlighterWord, { searchWords: filter.search, block: false, textToHighlight: assetConfig === null || assetConfig === void 0 ? void 0 : assetConfig.asset_component_name }), _jsx("br", {}), _jsx(HighlighterWord, { searchWords: filter.search, block: false, textToHighlight: assetConfig === null || assetConfig === void 0 ? void 0 : assetConfig.asset_module_name, style: { fontWeight: '400' } })] })) : (_jsx(HighlighterWord, { searchWords: filter.search, block: false, textToHighlight: assetConfig ? assetConfig.asset_module_name : componentName }))] }) }), _jsx("div", { className: "equipment-select-wrap clearfix component-installation-points-container ".concat(isActive && 'active-component', " ").concat(assetConfig && 'asset-points'), children: installationPoints === null || installationPoints === void 0 ? void 0 : installationPoints.map(function (installationPoint) { return (_jsx("div", { children: installationPoint.point_type !== POINT_TYPE_SENSOR &&
                        installationPoint.point_type !== POINT_TYPE_ADAPTER ? (_jsx(_Fragment, {})) : (_jsx(InstallationPointRow, { installationPoint: installationPoint, activeInstallationPointId: activeInstallationPointId, equipment: equipment, onOverlayCheck: onOverlayCheck, overlayInstallationPoints: overlayInstallationPoints, assetConfig: assetConfig })) }, "".concat(equipmentName, "_").concat(componentName, "_").concat(installationPoint.id))); }) })] }));
};
export default memo(ComponentRow);
