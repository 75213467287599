var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import assetBuilderStyles from '../../styles.module.scss';
export var TimingGear = memo(function (_a) {
    var control = _a.control, fieldName = _a.fieldName, isDisabled = _a.isDisabled;
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, timingGearValue = _b.value, onChange = _b.onChange;
    var value = timingGearValue.value, teethIn = timingGearValue.teethIn, teethOut = timingGearValue.teethOut;
    var onChangeTimingGear = function (field, val) {
        var _a;
        onChange(field === 'value'
            ? {
                value: val,
                teethIn: undefined,
                teethOut: undefined,
            }
            : __assign(__assign({}, timingGearValue), (_a = {}, _a[field] = val, _a)));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: assetBuilderStyles.section, children: _jsxs("label", { htmlFor: "timingGear", className: assetBuilderStyles.switchWrapper, children: [_jsxs("div", { className: assetBuilderStyles.switch, children: [_jsx("input", { disabled: isDisabled, type: "checkbox", id: "timingGear", checked: value, onChange: function (e) { return onChangeTimingGear('value', e.target.checked); } }), _jsx("span", { className: assetBuilderStyles.slider })] }), "Timing Gear [TG]"] }) }), value && (_jsxs(_Fragment, { children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Teeth In" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: teethIn !== null && teethIn !== void 0 ? teethIn : '', onChange: function (e) {
                                    return onChangeTimingGear('teethIn', e.target.value ? Number(e.target.value) : undefined);
                                } })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Teeth Out" }) }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: teethOut !== null && teethOut !== void 0 ? teethOut : '', onChange: function (e) {
                                    return onChangeTimingGear('teethOut', e.target.value ? Number(e.target.value) : undefined);
                                } })] })] }))] }));
});
TimingGear.displayName = 'TimingGear';
