var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useActionItemModalsActions } from '../../../store/AIPreAi/useActionItemModalsStore';
import { useConfirmModalActions } from '../../../store/global/useConfirmModalStore';
import './index.scss';
var StatusSelect = function (_a) {
    var status = _a.status, options = _a.options, cancelSnoozeMutate = _a.cancelSnoozeMutate, inProgressMutate = _a.inProgressMutate, isReportExist = _a.isReportExist, isNoActionReport = _a.isNoActionReport, isViewMode = _a.isViewMode, onSuccessCancelSnooze = _a.onSuccessCancelSnooze, onBeforeChange = _a.onBeforeChange, onSuccessInProgress = _a.onSuccessInProgress;
    var actionItemId = useParams().id;
    var _b = useState(false), isDropdownOpen = _b[0], setIsDropdownOpen = _b[1];
    var color = status.color, name = status.name, buttons = status.buttons;
    var _c = useActionItemModalsActions(), setIsVisibleSnoozeModal = _c.setIsVisibleSnoozeModal, setIsVisibleAwaitingUpdateModal = _c.setIsVisibleAwaitingUpdateModal, setIsVisibleChangeToHardwareIssueModal = _c.setIsVisibleChangeToHardwareIssueModal, setIsVisibleHardwareAssistanceModal = _c.setIsVisibleHardwareAssistanceModal, setIsVisibleCancelHardwareAssistanceModal = _c.setIsVisibleCancelHardwareAssistanceModal, setIsVisibleCancelAndCloseModal = _c.setIsVisibleCancelAndCloseModal, setIsVisibleWaitingModal = _c.setIsVisibleWaitingModal, setIsVisibleQuestionModal = _c.setIsVisibleQuestionModal;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var methods = {
        cancel_and_close: function () {
            if (isReportExist || isNoActionReport) {
                return setConfirmData({
                    isVisible: true,
                    textConfirmationModal: 'Are you sure you want to close report? This will close Action Item without saving report data.',
                    onConfirm: function () {
                        setConfirmData({
                            isVisible: false,
                        });
                        setIsVisibleCancelAndCloseModal(true);
                    },
                });
            }
            return setIsVisibleCancelAndCloseModal(true);
        },
        snooze: function () { return setIsVisibleSnoozeModal(true); },
        cancel_snooze: function () {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure you want to update action item?',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    cancelSnoozeMutate({
                        action_item_id: actionItemId,
                    }, {
                        onSuccess: function () {
                            onSuccessCancelSnooze === null || onSuccessCancelSnooze === void 0 ? void 0 : onSuccessCancelSnooze();
                            toast.success('Successfully snoozed', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                        },
                    });
                },
            });
        },
        awaiting_update: function () { return setIsVisibleAwaitingUpdateModal(true); },
        change_to_hardware_issue: function () {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure you want to change the asset AI type to network AI? This AI will be processed by the Network team and will not be available for changes from Analytics.',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    setIsVisibleChangeToHardwareIssueModal(true);
                },
            });
        },
        get_hardware_assistance: function () { return setIsVisibleHardwareAssistanceModal(true); },
        waiting: function () { return setIsVisibleWaitingModal(true); },
        question: function () { return setIsVisibleQuestionModal(true); },
        cancel_hardware_assistance: function () { return setIsVisibleCancelHardwareAssistanceModal(true); },
        in_progress: function () {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: 'Are you sure you want to update AI to "In Progress" status?',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    if (!inProgressMutate) {
                        return;
                    }
                    inProgressMutate({
                        action_item_id: actionItemId,
                    }, {
                        onSuccess: function () {
                            onSuccessInProgress === null || onSuccessInProgress === void 0 ? void 0 : onSuccessInProgress();
                            toast.success('Successfully updated', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                        },
                    });
                },
            });
        },
    };
    var handleSelect = function (btnKeyName) { return __awaiter(void 0, void 0, void 0, function () {
        var ok;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (onBeforeChange === null || onBeforeChange === void 0 ? void 0 : onBeforeChange())];
                case 1:
                    ok = _a.sent();
                    if (!ok)
                        return [2 /*return*/];
                    methods[btnKeyName]();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "d-flex align-items-center", children: _jsxs(Dropdown, { className: "status-select-dropdown", size: "sm", isOpen: isDropdownOpen, toggle: function () { return setIsDropdownOpen(function (prev) { return !prev; }); }, children: [_jsx(DropdownToggle, { color: color, caret: !!buttons.length && !isViewMode, children: name }), buttons.length && !isViewMode ? (_jsx(DropdownMenu, { children: buttons.map(function (btnKeyName) { return (_jsx("div", { children: options[btnKeyName] ? (_jsxs(DropdownItem, { disabled: false, onClick: function () { return handleSelect(btnKeyName); }, children: [_jsx("div", { className: "label-status", children: options[btnKeyName].label }), _jsx("div", { className: "to-status", children: options[btnKeyName].to ? (_jsxs(_Fragment, { children: ["Changes the status to ", _jsx(FaArrowRightLong, {}), _jsx("b", { children: options[btnKeyName].to })] })) : (_jsx(_Fragment, {})) })] })) : (_jsx(_Fragment, {})) }, btnKeyName)); }) })) : (_jsx(_Fragment, {}))] }) }));
};
export default memo(StatusSelect);
