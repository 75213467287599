import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import Api from '../../../../../api/api';
var useQuery = useUntypedQuery;
export var AssetModuleQueryKey = function () { return ['asset-module']; };
export var AssetModuleQueryFn = function () {
    return Api.get("all-analytics/asset/module");
};
export var useAssetModuleQuery = function () {
    return useQuery({
        queryKey: AssetModuleQueryKey(),
        queryFn: function () { return AssetModuleQueryFn(); },
        select: function (_a) {
            var data = _a.data;
            return { data: data };
        },
        placeholderData: { data: [] },
    });
};
