// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__nN_sL {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.wrapper__nN_sL .ip__uAJFb {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border: 1px solid #d9d9d9;
}
.dark-layout .wrapper__nN_sL .ip__uAJFb {
  border: 1px solid #3e3f4f;
}
.wrapper__nN_sL .ip__uAJFb .ipName__ZEKix {
  font-weight: 500;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/InstallationPointsSelects/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AACD;AAAC;EACC,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,yBAAA;AAEF;AADE;EACC,yBAAA;AAGH;AADE;EACC,gBAAA;EACA,yBAAA;AAGH","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 16px;\n\t.ip {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tgap: 12px;\n\t\tpadding: 16px;\n\t\tborder: 1px solid #d9d9d9;\n\t\t:global(.dark-layout) & {\n\t\t\tborder: 1px solid #3e3f4f;\n\t\t}\n\t\t.ipName {\n\t\t\tfont-weight: 500;\n\t\t\ttext-transform: uppercase;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__nN_sL`,
	"ip": `ip__uAJFb`,
	"ipName": `ipName__ZEKix`
};
module.exports = ___CSS_LOADER_EXPORT___;
