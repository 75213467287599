import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { RiSortAsc } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { ProcessingIncreaseSeverityModal } from '@sharedModals/AIPreAi/ProcessingIncreaseSeverityModal';
import { PRE_ACTION_LIST } from '../../../../constants';
import { useTitle } from '../../../../customHooks';
import Loader from '../../../../shared/components/Loader';
import Header from '../../../../shared/components/preActionItem/Header';
import ActivityLog from '../../../../shared/components/preActionItem/activityLog';
import useDeactivationAnalystLog from '../../../../shared/hooks/AIPreAi/useDeactivationAnalystLog';
import useSetDocumentTitle from '../../../../shared/hooks/AIPreAi/useSetDocumentTitle';
import { useNotificationForCriticalPreAi } from '../../../../shared/hooks/preActionItem/useNotificationForCriticalPreAi';
import { useDeactivationPreAiAnalystLogMutation } from '../../../../shared/services/preActionItems/useDeactivationPreAiAnalystLogMutation';
import { selectIsEditSections, usePreActionItemViewActions, } from '../../../../shared/store/preActionItem/usePreActionItemViewStore';
import '../../../../shared/styles/AIPreAi/index.scss';
import FooterTools from '../../../../widgets/FooterTools/FooterTools';
import FooterToolsToggleBtn from '../../../../widgets/FooterTools/components/ToggleBtn/ToogleBtn';
import Content from '../../../components/preActionItem/view/Content';
import { useGetDefaultDeclineReasonsQuery, } from '../../../services/preActionItems/useDefaultDeclineReasonsQuery';
import { usePreActionItemQuery } from '../../../services/preActionItems/usePreActionItemQuery';
var PreActionItemViewPage = function () {
    var preActionItemId = useParams().id;
    var _a = useTitle('Pre-Action Item'), setDocumentTitle = _a[1];
    var _b = usePreActionItemQuery({ preActionItemId: preActionItemId }), isLoading = _b.isLoading, isFetching = _b.isFetching, preActionItemData = _b.data, refetchPreActionItem = _b.refetch;
    var _c = useGetDefaultDeclineReasonsQuery({
        preActionItemId: preActionItemId,
        componentName: (preActionItemData === null || preActionItemData === void 0 ? void 0 : preActionItemData.installationPointName) || '',
    }), isDefaultDeclineReasonsLoading = _c.isLoading, isDefaultDeclineReasonsFetching = _c.isFetching, defaultDeclineReasonsData = _c.data;
    var deactivationPreAiAnalystLogMutate = useDeactivationPreAiAnalystLogMutation({
        storage: null,
    }).mutate;
    useDeactivationAnalystLog(preActionItemId, deactivationPreAiAnalystLogMutate);
    useSetDocumentTitle(preActionItemData === null || preActionItemData === void 0 ? void 0 : preActionItemData.installationPointName, setDocumentTitle);
    var isEditSections = selectIsEditSections();
    var setIsEditSections = usePreActionItemViewActions().setIsEditSections;
    useNotificationForCriticalPreAi((preActionItemData === null || preActionItemData === void 0 ? void 0 : preActionItemData.urgency) === PRE_ACTION_LIST.URGENCY_CRITICAL &&
        (preActionItemData === null || preActionItemData === void 0 ? void 0 : preActionItemData.state) === PRE_ACTION_LIST.STATUS_NEW, true);
    return (_jsx("div", { className: "page-preActionItem", children: isLoading || isFetching || isDefaultDeclineReasonsLoading || isDefaultDeclineReasonsFetching ? (_jsx("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100% - 146px)',
            }, children: _jsx(Loader, { variant: "loader-lg" }) })) : preActionItemData ? (_jsxs("div", { children: [_jsx(Header, { preActionItemData: preActionItemData, storage: null, activeMlReport: null }), _jsxs("div", { className: "d-flex", children: [_jsx(Content, { defaultDeclineReasons: defaultDeclineReasonsData.defaultDeclineReasons }), _jsx(ActivityLog, { preActionItemData: preActionItemData, storage: null })] }), _jsx(FooterTools, { children: _jsx(FooterToolsToggleBtn, { isSelected: isEditSections, onClick: function () { return setIsEditSections(!isEditSections); }, children: _jsx(RiSortAsc, {}) }) }), _jsx(ProcessingIncreaseSeverityModal, { onSuccess: refetchPreActionItem })] })) : (_jsx(_Fragment, { children: "Something went wrong!" })) }));
};
export default memo(PreActionItemViewPage);
