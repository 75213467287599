// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.componentOrientationAndBearing__vuc9C {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-gap: 16px;
}

.positionThreeColumn__KsENC {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 16px;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/NotFirstAssetContent/components/DrivenContent/components/DrivenTypeVariant/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,kCAAA;EACA,cAAA;AACD;;AAEA;EACC,aAAA;EACA,mBAAA;EACA,kCAAA;EACA,cAAA;AACD","sourcesContent":[".componentOrientationAndBearing {\n\tdisplay: grid;\n\tgrid-template-columns: 2fr 3fr 1fr;\n\tgrid-gap: 16px;\n}\n\n.positionThreeColumn {\n\tdisplay: grid;\n\talign-items: center;\n\tgrid-template-columns: 2fr 2fr 2fr;\n\tgrid-gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"componentOrientationAndBearing": `componentOrientationAndBearing__vuc9C`,
	"positionThreeColumn": `positionThreeColumn__KsENC`
};
module.exports = ___CSS_LOADER_EXPORT___;
