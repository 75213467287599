import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { READING_TYPES } from '../../constants';
import { getUoms } from '../../shared/helper/chart';
import { useAnalystNotesCommentsByReadingTypeQuery, } from '../../shared/services/charts/useAnalystNotesCommnetsByReadingTypeQuery';
import { ChartsStore } from '../../shared/store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../shared/store/charts/chartsContent/InstallationPointStore';
import { selectMeasure, selectPersonalSettingMeasure } from '../../shared/store/global/useGlobalStore';
import './AlertConditionsSettingsLabel.scss';
export var AlertConditionsSettingsLabel = function (_a) {
    var alertConditions = _a.alertConditions, styles = _a.styles, _b = _a.isCircle, isCircle = _b === void 0 ? false : _b, chartIdentifier = _a.chartIdentifier;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var chartAlertLevelsVisibleToggle = ChartsStore(function (state) { return state.chartAlertLevelsVisibleToggle; });
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    var data = useAnalystNotesCommentsByReadingTypeQuery({
        installationPointIds: [installationPoint.id],
        readingTypeIds: [chartIdentifier],
    }).data;
    var shouldDisplay = useMemo(function () {
        return Object.keys(alertConditions.conditions || {}).some(function (key) {
            var _a = alertConditions.settings[key], cautionSettings = _a.caution, warningSettings = _a.warning;
            return ((cautionSettings === null || cautionSettings === void 0 ? void 0 : cautionSettings.is_ai) ||
                (cautionSettings === null || cautionSettings === void 0 ? void 0 : cautionSettings.is_pre_ai) ||
                (warningSettings === null || warningSettings === void 0 ? void 0 : warningSettings.is_ai) ||
                (warningSettings === null || warningSettings === void 0 ? void 0 : warningSettings.is_pre_ai));
        });
    }, [alertConditions]);
    if (chartAlertLevelsVisibleToggle || (!shouldDisplay && !(data !== null && data !== void 0 ? data : []).length)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Badge, { style: styles, id: "alert-conditions-settings-label-".concat(chartIdentifier), className: "alert-conditions-settings-label ".concat(isCircle ? 'label-circle' : ''), color: "warning", children: _jsx(FaRegLightbulb, { size: 15 }) }), _jsxs(UncontrolledTooltip, { className: "alert-conditions-settings-label-tooltip", placement: "bottom", target: "alert-conditions-settings-label-".concat(chartIdentifier), children: [_jsxs("div", { children: [_get(READING_TYPES, [chartIdentifier, 'title'], ''), ' ', _get(READING_TYPES, [chartIdentifier, 'axis'], '')] }), Object.keys(alertConditions.conditions || {}).map(function (key) {
                        var _a, _b;
                        var _c = alertConditions.settings[key], cautionSettings = _c.caution, warningSettings = _c.warning;
                        var _d = alertConditions.levels[key], cautionValue = _d.caution, warningValue = _d.warning;
                        var _e = (_b = (_a = alertConditions === null || alertConditions === void 0 ? void 0 : alertConditions.conditions) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : {}, cautionCondition = _e.caution, warningCondition = _e.warning;
                        return (_jsxs(Fragment, { children: [!!((cautionSettings === null || cautionSettings === void 0 ? void 0 : cautionSettings.is_ai) || (cautionSettings === null || cautionSettings === void 0 ? void 0 : cautionSettings.is_pre_ai)) && (_jsxs("div", { children: [cautionSettings.username, ": Caution ", cautionCondition, " ", cautionValue, ' ', getUoms(chartIdentifier, measure, personalSettingMeasure), ' ', cautionSettings.trigger_time && cautionSettings.trigger_time > 0
                                            ? "".concat(cautionSettings.trigger_time, " minutes")
                                            : ''] })), !!((warningSettings === null || warningSettings === void 0 ? void 0 : warningSettings.is_ai) || (warningSettings === null || warningSettings === void 0 ? void 0 : warningSettings.is_pre_ai)) && (_jsxs("div", { children: [warningSettings.username, ": Warning ", warningCondition, " ", warningValue, ' ', getUoms(chartIdentifier, measure, personalSettingMeasure), ' ', warningSettings.trigger_time && warningSettings.trigger_time > 0
                                            ? "".concat(warningSettings.trigger_time, " minutes")
                                            : ''] }))] }, key));
                    }), data.map(function (_a) {
                        var id = _a.id, user_name = _a.user_name;
                        return (_jsxs("div", { children: ["Has analyst notes by ", user_name] }, id));
                    })] })] }));
};
export default memo(AlertConditionsSettingsLabel);
