import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import assetBuilderStyles from '../../styles.module.scss';
export var MotorBars = memo(function (_a) {
    var control = _a.control, fieldName = _a.fieldName, isDisabled = _a.isDisabled;
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, motorBars = _b.value, onChangeMotorBars = _b.onChange;
    var subtype = useWatch({
        control: control,
        name: ['data.subtype'],
    })[0];
    if (subtype !== 6) {
        return null;
    }
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Motor Bars" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: motorBars !== null && motorBars !== void 0 ? motorBars : '', onChange: function (e) { return onChangeMotorBars(e.target.value ? Number(e.target.value) : undefined); }, min: 1 })] }));
});
MotorBars.displayName = 'MotorBars';
