var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import Select from 'react-select';
import { getIsViewMode } from '../../../../../../features';
import { useAssetTreeQuery } from '../../../../../../services/useAssetTreeQuery';
import assetBuilderStyles from '../../../../styles.module.scss';
import { BackToStep } from '../../../BackToStep';
import { Transmission } from '../../../Transmission';
import { FOURTH_STEP_TO_DEFAULT_VALUES, THIRD_STEP_TO_DEFAULT_VALUES } from '../../constants';
import { AdditionalInfo } from '../AdditionalInfo';
import { DrivenTypeVariant } from './components/DrivenTypeVariant';
import { Subtype } from './components/Subtype';
import styles from './styles.module.scss';
export var DrivenContent = function (_a) {
    var control = _a.control, step = _a.step, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    var assetTreeData = useAssetTreeQuery({ equipmentId: equipmentId }).data;
    var isViewMode = getIsViewMode(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data);
    var _b = useController({
        name: 'data',
        control: control,
    }).field, data = _b.value, onChangeData = _b.onChange;
    return (_jsxs("div", { children: [_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsx("div", { className: assetBuilderStyles.sectionsBlock, children: _jsx("div", { className: styles.driven, children: _jsx("div", { className: assetBuilderStyles.section, children: _jsx(Select, { placeholder: "Select Driven", classNamePrefix: "customSelectStyleDefault", isDisabled: true, value: { label: 'Driven', value: '' }, options: [] }) }) }) }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { }, isShow: false })] }), _jsx(Transmission, { equipmentId: equipmentId, callback: function () { return onChangeData(__assign(__assign(__assign({}, data), { step: 3 }), THIRD_STEP_TO_DEFAULT_VALUES)); }, isDisabled: step !== 3 }), step >= 4 && (_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsxs("div", { className: assetBuilderStyles.sectionsBlock, children: [_jsx("div", { className: styles.position, children: _jsx(Subtype, { isDisabled: step !== 4 }) }), _jsx(DrivenTypeVariant, { control: control, isDisabled: step !== 4, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber })] }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { return onChangeData(__assign(__assign(__assign({}, data), { step: 4 }), FOURTH_STEP_TO_DEFAULT_VALUES)); }, isShow: step !== 4 })] })), step >= 5 && (_jsxs("div", { className: assetBuilderStyles.groupBlock, children: [_jsx("div", { className: assetBuilderStyles.sectionsBlock, children: _jsx(AdditionalInfo, { control: control, isDisabled: step !== 5 }) }), _jsx(BackToStep, { isViewMode: isViewMode, callback: function () { return onChangeData(__assign(__assign(__assign({}, data), { step: 5 }), THIRD_STEP_TO_DEFAULT_VALUES)); }, isShow: step !== 5 })] }))] }));
};
DrivenContent.displayName = 'DrivenContent';
