// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__lw2SM {
  width: 100%;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.dark-layout .wrapper__lw2SM {
  border-color: #3e3f4f;
  background: #1b1f2c;
}
.wrapper__lw2SM .list__zHnnL {
  display: flex;
  flex-direction: column;
}
.wrapper__lw2SM .list__zHnnL > div:not(:last-child) {
  border-bottom: 1px solid #d9d9d9 !important;
}
.dark-layout .wrapper__lw2SM .list__zHnnL > div:not(:last-child) {
  border-bottom: 1px solid #3e3f4f !important;
}
.wrapper__lw2SM .loaderWrapper__ThHrz {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty__vclct {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/NotAssignedIP/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,yBAAA;EACA,gBAAA;AACD;AAAC;EACC,qBAAA;EACA,mBAAA;AAEF;AAAC;EACC,aAAA;EACA,sBAAA;AAEF;AADE;EACC,2CAAA;AAGH;AAFG;EACC,2CAAA;AAIJ;AAAC;EACC,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEF;;AAEA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AACD","sourcesContent":[".wrapper {\n\twidth: 100%;\n\tborder: 1px solid #d9d9d9;\n\tbackground: #fff;\n\t:global(.dark-layout) & {\n\t\tborder-color: #3e3f4f;\n\t\tbackground: #1b1f2c;\n\t}\n\t.list {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\t& > div:not(:last-child) {\n\t\t\tborder-bottom: 1px solid #d9d9d9 !important;\n\t\t\t:global(.dark-layout) & {\n\t\t\t\tborder-bottom: 1px solid #3e3f4f !important;\n\t\t\t}\n\t\t}\n\t}\n\t.loaderWrapper {\n\t\tpadding: 16px;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t}\n}\n\n.empty {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 20px;\n\tfont-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__lw2SM`,
	"list": `list__zHnnL`,
	"loaderWrapper": `loaderWrapper__ThHrz`,
	"empty": `empty__vclct`
};
module.exports = ___CSS_LOADER_EXPORT___;
