import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import NoActionReportBody from './components/NoActionReport/NoActionReport';
import ReportForms from './components/ReportForms/ReportForms';
import RegularReportBody from './components/regularReport/RegularReportBody';
import RegularReportHeader from './components/regularReport/RegularReportHeader';
import WwReportBody from './components/wwReport/WwReportBody';
import WwReportHeader from './components/wwReport/WwReportHeader';
import './index.scss';
var CompletionReport = function (_a) {
    var activeReport = _a.activeReport, isReportExist = _a.isReportExist, isNoActionReport = _a.isNoActionReport, isDeclinedReport = _a.isDeclinedReport, actionListNoActionsGroups = _a.actionListNoActionsGroups, actionListNoActionsTypes = _a.actionListNoActionsTypes, actionListReportsSeverity = _a.actionListReportsSeverity, customerId = _a.customerId, _b = _a.isShowButtons, isShowButtons = _b === void 0 ? false : _b, _c = _a.isOpenFromComments, isOpenFromComments = _c === void 0 ? false : _c, isShowCollapseBtn = _a.isShowCollapseBtn, isShowDeclineButton = _a.isShowDeclineButton, isSensorWasDetached = _a.isSensorWasDetached, isShowDetachBlockInReport = _a.isShowDetachBlockInReport, isSensorMountedInTheSameOrientation = _a.isSensorMountedInTheSameOrientation, declineReason = _a.declineReason, noActionsDescription = _a.noActionsDescription, translates = _a.translates, noActionReports = _a.noActionReports, wwNoActionReports = _a.wwNoActionReports, type = _a.type, declinedReports = _a.declinedReports, isDontCreateWo = _a.isDontCreateWo, verifiedTime = _a.verifiedTime, verifiedBy = _a.verifiedBy, noActionsGroupId = _a.noActionsGroupId, noActionsType = _a.noActionsType, noActionsGroupOtherText = _a.noActionsGroupOtherText, submitedBy = _a.submitedBy, timeSubmited = _a.timeSubmited, acceptAIReportMutate = _a.acceptAIReportMutate, closeAIReportMutate = _a.closeAIReportMutate, declineAIReportMutate = _a.declineAIReportMutate, _d = _a.storage, storage = _d === void 0 ? null : _d, _e = _a.isOpenReportBody, isOpenReportBody = _e === void 0 ? false : _e, onSuccess = _a.onSuccess, onBeforeComplete = _a.onBeforeComplete;
    var _f = useState(isOpenReportBody), isVisibleReportBody = _f[0], setIsVisibleReportBody = _f[1];
    if (!isReportExist && !isNoActionReport) {
        return _jsx(_Fragment, {});
    }
    var getReportHeader = function () {
        if (isReportExist) {
            return (_jsx("div", { children: _jsx(RegularReportHeader, { activeReport: activeReport, isVisibleReportBody: isVisibleReportBody, isDeclinedReport: isDeclinedReport, setIsVisibleReportBody: setIsVisibleReportBody, isShowCollapseBtn: isShowCollapseBtn, declinedReports: declinedReports }) }));
        }
        return (_jsx("div", { children: _jsx(WwReportHeader, { isNoActionReport: isNoActionReport, isDontCreateWo: isDontCreateWo, verifiedTime: verifiedTime, verifiedBy: verifiedBy, isDeclinedReport: isDeclinedReport, declineReason: declineReason, actionListNoActionsTypes: actionListNoActionsTypes, actionListNoActionsGroups: actionListNoActionsGroups, noActionsGroupId: noActionsGroupId, declinedReports: declinedReports, noActionsType: noActionsType, noActionsGroupOtherText: noActionsGroupOtherText, submitedBy: submitedBy, timeSubmited: timeSubmited, isOpenFromComments: isOpenFromComments, isVisibleReportBody: isVisibleReportBody, isShowCollapseBtn: isShowCollapseBtn, setIsVisibleReportBody: setIsVisibleReportBody }) }));
    };
    var getReportBody = function () {
        if (isReportExist) {
            return (_jsx("div", { children: activeReport.isWwReport ? (_jsx("div", { children: _jsx(WwReportBody, { activeReport: activeReport, isVisibleReportBody: isVisibleReportBody, actionListReportsSeverity: actionListReportsSeverity }) })) : (_jsx("div", { children: _jsx(RegularReportBody, { activeReport: activeReport, isSensorWasDetached: isSensorWasDetached, isShowDetachBlockInReport: isShowDetachBlockInReport, isSensorMountedInTheSameOrientation: isSensorMountedInTheSameOrientation, actionListReportsSeverity: actionListReportsSeverity, isVisibleReportBody: isVisibleReportBody, isOpenFromComments: isOpenFromComments }) })) }));
        }
        return (_jsx("div", { children: _jsx(NoActionReportBody, { isVisibleReportBody: isVisibleReportBody, isSensorWasDetached: isSensorWasDetached, isShowDetachBlockInReport: isShowDetachBlockInReport, isSensorMountedInTheSameOrientation: isSensorMountedInTheSameOrientation, noActionsDescription: noActionsDescription, translates: translates, noActionReports: noActionReports, wwNoActionReports: wwNoActionReports, isOpenFromComments: isOpenFromComments, type: type }) }));
    };
    return (_jsxs("div", { children: [_jsx("div", { children: getReportHeader() }), isVisibleReportBody ? (_jsxs("div", { children: [getReportBody(), isShowButtons ? (_jsx(ReportForms, { isNoActionReport: isNoActionReport, isShowDeclineButton: isShowDeclineButton, customerId: customerId, acceptAIReportMutate: acceptAIReportMutate, closeAIReportMutate: closeAIReportMutate, declineAIReportMutate: declineAIReportMutate, storage: storage, onSuccess: onSuccess, onBeforeComplete: onBeforeComplete })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(CompletionReport);
