import { useMutation } from '@tanstack/react-query';
import Api from '../../../api/api';
var addAssetBearingFn = function (payload) {
    return Api.put("all-analytics/asset/point/".concat(payload.installationPointId, "/bearing"), payload);
};
export var useAddAssetBearingMutation = function () {
    return useMutation({
        mutationFn: function (payload) { return addAssetBearingFn(payload); },
        onSuccess: function () { },
    });
};
