import { get as _get, uniq as _uniq } from 'lodash';
import { activeRequests, setActiveRequests } from '../../../../api/api';
import { makeValidLink } from '../../../helper/routeHelper';
export var abortOverlayRequests = function () {
    activeRequests.map(function (item) {
        if (item.url.indexOf('all-analytics/charts/get-rms-readings-batch/') !== -1 ||
            item.url.indexOf('amazon-analytic/charts/get-rms-readings-batch/') !== -1) {
            setActiveRequests(activeRequests.filter(function (activeRequest) { return activeRequest.url !== item.url; }));
            item.controller.abort();
            return true;
        }
    });
};
export var abortInstallationPointRequests = function () {
    activeRequests.map(function (item) {
        if (item.url.indexOf('all-analytics/charts/get-rms-readings/') !== -1 ||
            item.url.indexOf('all-analytics/charts/get-rms-readings-batch/') !== -1 ||
            item.url.indexOf('all-analytics/installation-point/') !== -1 ||
            item.url.indexOf('all-analytics/charts/get-fft-readings/') !== -1 ||
            item.url.indexOf('amazon-analytic/amazon/charts/get-rms-readings/') !== -1 ||
            item.url.indexOf('amazon-analytic/charts/get-rms-readings-batch/') !== -1 ||
            item.url.indexOf('amazon-analytic/installation-point/') !== -1 ||
            item.url.indexOf('amazon-analytic/charts/get-fft-readings/') !== -1) {
            setActiveRequests(activeRequests.filter(function (activeRequest) { return activeRequest.url !== item.url; }));
            item.controller.abort();
            return true;
        }
    });
};
export var onScroll = function (values, isLoading, lastReceivedEquipmentsCount, limit, callback) {
    var scrollTop = values.scrollTop, scrollHeight = values.scrollHeight, clientHeight = values.clientHeight;
    var pad = 100; // 100px of the bottom
    var scrollIndex = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (scrollIndex > 1 && !isLoading && lastReceivedEquipmentsCount >= limit) {
        callback();
    }
};
export var getOverlayPointsQueryString = function (overlayInstallationPoints, installationPoint, prevInstallationPoint, reset) {
    if (reset === void 0) { reset = false; }
    var params = new URLSearchParams(window.location.search), urlOverlayPointsIds = reset ? [] : params.getAll('overlay_points');
    var prevInstallationPointId = _get(prevInstallationPoint, 'id', 0);
    var overlayInstallationPointsParams = '';
    if (overlayInstallationPoints.length || urlOverlayPointsIds) {
        var overlayInstallationPointIds = overlayInstallationPoints.length
            ? overlayInstallationPoints.map(function (overlayInstallationPoint) { return overlayInstallationPoint.id; })
            : urlOverlayPointsIds, 
        //@ts-ignore
        activeInstallationPointIdIndex = overlayInstallationPointIds.indexOf(Number(installationPoint.id));
        if (activeInstallationPointIdIndex !== -1 &&
            Number(prevInstallationPointId) !== Number(installationPoint.id)) {
            overlayInstallationPointIds.splice(activeInstallationPointIdIndex, 1);
            //@ts-ignore
            overlayInstallationPointIds.push(Number(prevInstallationPointId));
        }
        _uniq(overlayInstallationPointIds).map(function (id) {
            overlayInstallationPointsParams += "&overlay_points=".concat(id);
        });
    }
    return overlayInstallationPointsParams;
};
export var updateUrl = function (prefix, installationPoint, overlayInstallationPoints, prevInstallationPoint, title) {
    if (!installationPoint) {
        return;
    }
    window.history.pushState(null, '', "".concat(makeValidLink('/' + prefix + '/dashboard'), "?installation_point=").concat(installationPoint.id).concat(getOverlayPointsQueryString(overlayInstallationPoints, installationPoint, prevInstallationPoint, overlayInstallationPoints.length === 0)));
    document.title = title || document.title;
};
