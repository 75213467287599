import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { ACTION_LIST } from '../../../../constants';
import { SensorId } from '../../../../widgets/sensor';
import { useConfirmModalActions } from '../../../store/global/useConfirmModalStore';
import DeclineReasonSelect from '../DeclineReasonSelect';
var DecliningForm = function (_a) {
    var declinePreActionItemMutate = _a.declinePreActionItemMutate, defaultDeclineReasons = _a.defaultDeclineReasons, onCancel = _a.onCancel, existingActionItems = _a.existingActionItems;
    var preActionItemId = useParams().id;
    var _b = useState(''), declineReason = _b[0], setDeclineReason = _b[1];
    var _c = useState(null), blockedPreActionItemType = _c[0], setBlockedPreActionItemType = _c[1];
    var _d = useState(null), blockingActionItemId = _d[0], setBlockingActionItemId = _d[1];
    var _e = useState(false), isShowTextInputForm = _e[0], setIsShowTextInputForm = _e[1];
    var _f = useState(false), isShowAdjustAlarmLevelsOptions = _f[0], setIsShowAdjustAlarmLevelsOptions = _f[1];
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var onDecline = function (text, blockingActionItemId, blockedPreActionItemType) {
        var callback = function () {
            declinePreActionItemMutate({
                id: +preActionItemId,
                decline_reason: text,
                blocking_action_item_id: blockingActionItemId,
                blocked_pre_action_item_type: blockedPreActionItemType,
            }, {
                onSuccess: function () {
                    toast.success('Successfully declined', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                },
            });
        };
        callback();
    };
    var onSubmit = function (isAdjusted, adjustedReason) {
        var text = isAdjusted ? 'Alarm levels adjusted: ' + adjustedReason : declineReason;
        setConfirmData({
            isVisible: true,
            textConfirmationModal: "Are you sure you want to decline pre-action item with reason: <b>".concat(text, "<b/>"),
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                onDecline(text, blockingActionItemId, blockedPreActionItemType);
            },
        });
    };
    var handleDefaultDeclineReason = function (text) {
        if (text === 'Alarm levels adjusted') {
            setIsShowTextInputForm(false);
            setIsShowAdjustAlarmLevelsOptions(true);
            setDeclineReason('Alarm levels set too tight or incorrectly');
            return;
        }
        setIsShowTextInputForm(false);
        setIsShowAdjustAlarmLevelsOptions(false);
        setConfirmData({
            isVisible: true,
            textConfirmationModal: "Are you sure you want to decline pre-action item with reason: <b>".concat(text, "<b/>"),
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                onDecline(text, blockingActionItemId, blockedPreActionItemType);
            },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "decline-form-title", children: ["Decline Pre-AI:", ' ', _jsx("span", { title: "Cancel decline", onClick: onCancel, children: _jsx(FaTimes, {}) })] }), existingActionItems.length ? (_jsxs("div", { className: "decline-suspend", children: ["Suspend preAI-creation for", _jsxs(Input, { type: "select", name: "select", className: "select-sm w-auto d-inline-block ms-2 me-2", onChange: function (ev) { return setBlockedPreActionItemType(ev.target.value); }, children: [_jsx("option", { value: "", children: "None" }), _jsx("option", { value: "all", children: "All" }), _jsx("option", { value: "temperature", children: "Temperature" }), _jsx("option", { value: "vibration", children: "Vibration" })] }), "readings until this AI", _jsxs(Input, { type: "select", name: "select", className: "select-sm w-auto d-inline-block ms-2 me-2", onChange: function (ev) { return setBlockingActionItemId(+ev.target.value); }, children: [_jsx("option", { value: "", children: "None" }), existingActionItems.map(function (existingActionItem) {
                                var _a, _b, _c, _d;
                                return (_jsxs("option", { value: existingActionItem.id, children: [((_a = existingActionItem.installationPoint) === null || _a === void 0 ? void 0 : _a.name) ||
                                            _get(ACTION_LIST.EQUIPMENT_TYPES_NAME_MAPPING, existingActionItem.equipment_type, '---'), "\u00A0", ((_b = existingActionItem.installationPoint) === null || _b === void 0 ? void 0 : _b.sensor_id) ? (_jsx(SensorId, { showDecId: false, sensor_id: ((_c = existingActionItem.installationPoint) === null || _c === void 0 ? void 0 : _c.sensor_id) || existingActionItem.sensor_id })) : (_jsxs(React.Fragment, { children: ["(", existingActionItem.serial ||
                                                    ((_d = existingActionItem.installationPoint) === null || _d === void 0 ? void 0 : _d.sensor_id) ||
                                                    existingActionItem.sensor_id ||
                                                    '---', ")"] }))] }, "decline_existing_action_items_".concat(existingActionItem.id)));
                            })] }), "has been closed."] })) : (_jsx(_Fragment, {})), _jsx(DeclineReasonSelect, { defaultDeclineReasons: defaultDeclineReasons, handleDefaultDeclineReason: handleDefaultDeclineReason }), isShowAdjustAlarmLevelsOptions ? (_jsx(Form, { className: "mt-3", children: _jsx(FormGroup, { children: _jsxs(Input, { type: "select", name: "alarm_levels_adjust_options", className: "w-auto d-inline-block", onChange: function (ev) {
                            setIsShowTextInputForm(false);
                            setDeclineReason(ev.target.value);
                            onSubmit(true, ev.target.value);
                        }, children: [_jsx("option", { value: "", disabled: true, selected: true, children: "Select reason" }), _jsx("option", { className: "fz-18", value: "Alarm levels set too tight or incorrectly", children: "Alarm levels set too tight or incorrectly" }), _jsx("option", { className: "fz-18", value: "Alarm levels not set", children: "Alarm levels not set" }), _jsx("option", { className: "fz-18", value: "Ambient Temperature Increase", children: "Ambient Temperature Increase" }), _jsx("option", { className: "fz-18", value: "Increase is valid but not enough to warrant an action item", children: "Increase is valid but not enough to warrant an action item" }), _jsx("option", { className: "fz-18", value: "Process change or variation", children: "Process change or variation" }), _jsx("option", { className: "fz-18", value: "New equipment", children: "New equipment" }), _jsx("option", { className: "fz-18", value: "Sensor intervals or settings changed.", children: "Sensor intervals or settings changed" })] }) }) })) : (_jsx(_Fragment, {})), isShowTextInputForm ? (_jsxs(Form, { className: "mt-3", children: [_jsxs(FormGroup, { children: [_jsx(Label, { children: "Decline reason:" }), _jsx(Input, { autoFocus: true, type: "textarea", name: "decline_reason", id: "decline_reason", value: declineReason, onChange: function (ev) { return setDeclineReason(ev.target.value); } })] }), _jsx("div", { className: "d-flex justify-content-end", children: _jsx("div", { className: "btn-submit", onClick: function () { return onSubmit(false); }, children: "Submit declining" }) })] })) : (_jsx(_Fragment, {}))] }));
};
export default memo(DecliningForm);
