// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-request-toggle__YpKgl {
  color: currentColor;
}
.customer-request-toggle__YpKgl[data-is-customer-requested=true] {
  color: #dc3545;
}
.customer-request-toggle__YpKgl:hover {
  color: #fff;
}
.customer-request-toggle__YpKgl:not([data-is-customer-requested=true]):focus {
  color: currentColor;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/actionItem/customerRequestToggle/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,WAAA;AAAJ;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".customer-request-toggle {\n  color: currentColor;\n\n  &[data-is-customer-requested='true'] {\n    color: #dc3545;\n  }\n\n  &:hover {\n    color: #fff;\n  }\n\n  &:not([data-is-customer-requested='true']):focus {\n    color: currentColor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customerRequestToggle": `customer-request-toggle__YpKgl`
};
module.exports = ___CSS_LOADER_EXPORT___;
