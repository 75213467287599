var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import { first as _first, size as _size, sortBy as _sortBy } from 'lodash';
import moment from 'moment';
import Api from '../../../api/api';
import { ACTION_LIST, CUSTOMER_USER_ID, SERVICE_SELF } from '../../../constants';
import Languages from '../../../shared/helper/languages';
import User from '../../../shared/helper/user';
import { selectMeasure } from '../../../shared/store/global/useGlobalStore';
import { BOOLEAN_INSTALLATION_POINT_TYPE } from '../../constants';
export var actionItemKey = function (actionItemId) { return ['actionItem', +actionItemId]; };
var params = new URLSearchParams(window.location.search);
var actionItemFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get("all-analytics/action-item/".concat(queryKey[1]), {
        query: {
            state_id: params.get('state_id'),
            search: params.get('search'),
        },
    });
};
export var INACTIVE_STATES = [ACTION_LIST.STATE_CLOSED, ACTION_LIST.STATE_ARCHIVE];
export var useActionItemQuery = function (_a) {
    var actionItemId = _a.actionItemId;
    var measure = selectMeasure();
    return useQuery({
        queryKey: actionItemKey(actionItemId),
        queryFn: actionItemFn,
        select: function (_a) {
            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            var data = _a.data, languages = _a.languages, action_list_no_actions_types = _a.action_list_no_actions_types, action_list_no_actions_groups = _a.action_list_no_actions_groups, action_list_reports_severity = _a.action_list_reports_severity, tags = _a.tags, photos = _a.photos;
            Languages.set(languages);
            var type = (_b = data === null || data === void 0 ? void 0 : data.type) !== null && _b !== void 0 ? _b : null, stateWithoutTroubleshooting = !!(data === null || data === void 0 ? void 0 : data.is_waites_new) &&
                (data === null || data === void 0 ? void 0 : data.state) !== ACTION_LIST.STATE_CLOSED &&
                +(data === null || data === void 0 ? void 0 : data.state) !== ACTION_LIST.STATE_ARCHIVE
                ? ACTION_LIST.STATE_WW_NEW
                : (_c = data === null || data === void 0 ? void 0 : data.state) !== null && _c !== void 0 ? _c : null, state = (data === null || data === void 0 ? void 0 : data.is_troubleshooting_hold)
                ? (data === null || data === void 0 ? void 0 : data.is_troubleshooting_waiting)
                    ? ACTION_LIST.STATE_TROUBLESHOOTING_HOLD_WAITING
                    : ACTION_LIST.STATE_TROUBLESHOOTING_HOLD
                : stateWithoutTroubleshooting, isClosedAi = INACTIVE_STATES.includes(state), isRegularAi = type === ACTION_LIST.AI_TYPE_REGULAR, isWwAi = type === ACTION_LIST.AI_TYPE_WW, sensorId = +((_d = data.installationPoint) === null || _d === void 0 ? void 0 : _d.sensor_id) || +data.sensor_id || null, equipmentType = (data === null || data === void 0 ? void 0 : data.equipment_type) || null, isDeactivatedEquipment = !!data.isDeactivatedEquipment, isTroubleshoot = +data.is_troubleshooting_hold === 1, isSelfService = ((_f = (_e = data.installationPoint) === null || _e === void 0 ? void 0 : _e.equipment) === null || _f === void 0 ? void 0 : _f.service) === SERVICE_SELF, existingActionItems = ((_h = (_g = data === null || data === void 0 ? void 0 : data.installationPoint) === null || _g === void 0 ? void 0 : _g.equipment) === null || _h === void 0 ? void 0 : _h.existingActionItems) || [], isHardwareAiExist = _size(existingActionItems.filter(function (existingActionItem) {
                return (+existingActionItem.state !== ACTION_LIST.STATE_CLOSED &&
                    +existingActionItem.state !== ACTION_LIST.STATE_ARCHIVE &&
                    existingActionItem.type === ACTION_LIST.AI_TYPE_WW &&
                    +existingActionItem.installation_point_id === +data.installation_point_id);
            })) > 0, inactiveReports = (data === null || data === void 0 ? void 0 : data.inactiveReports) || [], inactiveWwReports = ((data === null || data === void 0 ? void 0 : data.inactiveWwReports) || []).map(function (report) {
                return __assign(__assign({}, report), { isWwReport: true });
            }), report = data ? _first(data.reports) : null, wwReport = data
                ? _first(data.wwReports)
                    ? __assign(__assign({}, _first(data.wwReports)), { isWwReport: true }) : null
                : null, activeReport = type === ACTION_LIST.AI_TYPE_WW ? wwReport : report, declineReason = (data === null || data === void 0 ? void 0 : data.decline_reason) || null;
            return {
                title: data.title,
                type: type,
                state: state,
                declineReason: declineReason,
                existingActionItems: existingActionItems,
                isClosedAi: isClosedAi,
                stateWithoutTroubleshooting: stateWithoutTroubleshooting,
                isWwAi: isWwAi,
                sensorId: sensorId,
                sensorPartNumber: (data === null || data === void 0 ? void 0 : data.sensorPartNumber) || '---',
                oldSensorId: (data === null || data === void 0 ? void 0 : data.old_sensor_id) || null,
                oldSensorPartNumber: (data === null || data === void 0 ? void 0 : data.oldSensorPartNumber) || null,
                id: +data.id || null,
                equipmentType: equipmentType,
                equipmentInfo: (data === null || data === void 0 ? void 0 : data.equipment_info) || null,
                serial: +data.serial || null,
                urgency: +data.urgency,
                timeCreated: data.time_created || null,
                updatedAt: data.updated_at || null,
                updatedBy: (data === null || data === void 0 ? void 0 : data.updated_by) || null,
                createdBy: (data === null || data === void 0 ? void 0 : data.created_by) || null,
                detectedBy: (data === null || data === void 0 ? void 0 : data.detected_by) || null,
                remindDate: (data === null || data === void 0 ? void 0 : data.remind_date) || null,
                isDeactivatedEquipment: isDeactivatedEquipment,
                installationPoint: data.installationPoint || null,
                installationPointIdsOnEquipment: (data === null || data === void 0 ? void 0 : data.installationPointIdsOnEquipment) || [],
                installationPointName: ((_j = data.installationPoint) === null || _j === void 0 ? void 0 : _j.name) || null,
                installationPointId: ((_k = data.installationPoint) === null || _k === void 0 ? void 0 : _k.id) || null,
                installationPointSensorId: +((_l = data.installationPoint) === null || _l === void 0 ? void 0 : _l.sensor_id) || null,
                isSensor: equipmentType === ACTION_LIST.EQUIPMENT_TYPE_SENSOR ||
                    equipmentType === ACTION_LIST.EQUIPMENT_TYPE_MOTE,
                isSensorExist: !!sensorId,
                isInactive: +((_m = data.installationPoint) === null || _m === void 0 ? void 0 : _m.is_deleted) === 1 || isDeactivatedEquipment,
                isShowMissAi: isRegularAi,
                isShowCommentForMlTeam: type !== ACTION_LIST.AI_TYPE_WW,
                translates: _size(data.translates) === 0 ? {} : data.translates,
                status: {
                    name: (_o = ACTION_LIST.STATUS_NAMES[state]) !== null && _o !== void 0 ? _o : '',
                    color: (_p = ACTION_LIST.STATUS_COLORS[state]) !== null && _p !== void 0 ? _p : '',
                    buttons: (_q = ACTION_LIST.STATUS_BUTTONS[state]) !== null && _q !== void 0 ? _q : [],
                },
                comments: (data === null || data === void 0 ? void 0 : data.activeActionListComments) || [],
                isTroubleshoot: isTroubleshoot,
                isTroubleshootingWaiting: !!(data === null || data === void 0 ? void 0 : data.is_troubleshooting_waiting),
                isViewMode: isSelfService ||
                    isWwAi ||
                    isClosedAi ||
                    !User.can('manageAi') ||
                    ((_r = data.installationPoint) === null || _r === void 0 ? void 0 : _r.point_type) === BOOLEAN_INSTALLATION_POINT_TYPE,
                isBooleanTypeAi: ((_s = data.installationPoint) === null || _s === void 0 ? void 0 : _s.point_type) === BOOLEAN_INSTALLATION_POINT_TYPE,
                customer: (data === null || data === void 0 ? void 0 : data.customer) || null,
                userImage: (data === null || data === void 0 ? void 0 : data.customer.comment_image) && (data === null || data === void 0 ? void 0 : data.customer.comment_image.indexOf('http')) !== -1
                    ? data === null || data === void 0 ? void 0 : data.customer.comment_image
                    : "".concat(APP_SSO_URL, "/images/customers/").concat(data === null || data === void 0 ? void 0 : data.customer.comment_image),
                isReplaceEquipment: !!data.is_replace_equipment,
                customerId: (_t = data.customer_id) !== null && _t !== void 0 ? _t : undefined,
                isShowAwaitingUpdate: !isTroubleshoot &&
                    [
                        ACTION_LIST.STATE_WAITING,
                        ACTION_LIST.STATE_NEW,
                        ACTION_LIST.STATE_WW_NEW,
                        ACTION_LIST.STATE_NEEDS_RESPONSE,
                        ACTION_LIST.STATE_QUESTION_AMZ_CORPORATE,
                    ].includes(state),
                isSelfService: isSelfService,
                isShowChangeToHardwareIssue: data.is_waites_new && !isHardwareAiExist,
                isReportExist: !!_size(activeReport),
                isNoActionReport: !!data.is_no_actions,
                isWwMiss: +data.is_ww_miss === 1,
                wwMiss: (data === null || data === void 0 ? void 0 : data.wwMiss) || null,
                node: (data === null || data === void 0 ? void 0 : data.node) || null,
                isOlderThen3Months: !!(data === null || data === void 0 ? void 0 : data.is_older_then_3_months),
                actionListCharts: (data === null || data === void 0 ? void 0 : data.actionListCharts) || [],
                activeReport: activeReport,
                isDeclinedReport: !!declineReason &&
                    [
                        ACTION_LIST.STATE_AWAITING_UPDATE,
                        ACTION_LIST.STATE_WAITING,
                        ACTION_LIST.STATE_NEW,
                        ACTION_LIST.STATE_NEEDS_RESPONSE,
                        ACTION_LIST.STATE_SNOOZED,
                    ].includes(state),
                isShowNotificationBlock: !isWwAi &&
                    +data.confirmed_by_comment === 0 &&
                    +data.user_id === CUSTOMER_USER_ID &&
                    state === ACTION_LIST.STATE_WW_NEW,
                actionListNoActionsTypes: action_list_no_actions_types,
                actionListNoActionsGroups: action_list_no_actions_groups,
                actionListReportsSeverity: action_list_reports_severity,
                isSensorWasDetached: !!(data === null || data === void 0 ? void 0 : data.sensor_was_detached),
                isShowDetachBlockInReport: ((_u = _first(data === null || data === void 0 ? void 0 : data.replacementHistory)) === null || _u === void 0 ? void 0 : _u.type) === 'Detached sensor',
                isSensorMountedInTheSameOrientation: !!(data === null || data === void 0 ? void 0 : data.sensor_mounted_in_the_same_orientation),
                noActionsDescription: (data === null || data === void 0 ? void 0 : data.no_actions_description) || null,
                description: (data === null || data === void 0 ? void 0 : data.description) || null,
                noActionsType: (data === null || data === void 0 ? void 0 : data.no_actions_type) || null,
                noActionReports: (data === null || data === void 0 ? void 0 : data.noActionReports) || [],
                noActionsGroupId: (data === null || data === void 0 ? void 0 : data.no_actions_group_id) || null,
                noActionsGroupOtherText: (data === null || data === void 0 ? void 0 : data.no_actions_group_other_text) || null,
                wwNoActionReports: (data === null || data === void 0 ? void 0 : data.wwNoActionReports) || [],
                declinedReports: (data === null || data === void 0 ? void 0 : data.declinedReports) || [],
                isDontCreateWo: !!(data === null || data === void 0 ? void 0 : data.dont_create_wo),
                verifiedTime: (data === null || data === void 0 ? void 0 : data.verified_time) || null,
                verifiedBy: (data === null || data === void 0 ? void 0 : data.verified_by) || null,
                timeSubmited: (data === null || data === void 0 ? void 0 : data.time_submited) || null,
                submitedBy: (data === null || data === void 0 ? void 0 : data.submited_by) || null,
                actionListTags: (data === null || data === void 0 ? void 0 : data.actionListTags) || null,
                facility: (data === null || data === void 0 ? void 0 : data.facility) || null,
                facilityMeasure: ((_v = data === null || data === void 0 ? void 0 : data.facility) === null || _v === void 0 ? void 0 : _v.measurement_units) || measure,
                sensor: (data === null || data === void 0 ? void 0 : data.sensor) || null,
                locationInfo: (data === null || data === void 0 ? void 0 : data.location_info) || null,
                approximateDate: (data === null || data === void 0 ? void 0 : data.approximate_date) || null,
                sla: (data === null || data === void 0 ? void 0 : data.sla) || null,
                wwActionListReasonGroups: {},
                inactiveReportsList: _sortBy(inactiveWwReports.concat(inactiveReports), function (report) {
                    return moment(report.created_at);
                }).reverse(),
                analystLog: (_w = data === null || data === void 0 ? void 0 : data.analystLog) !== null && _w !== void 0 ? _w : [],
                tags: tags,
                photos: photos,
                storage: (data === null || data === void 0 ? void 0 : data.customer.storage) || null,
                severityIncrease: (data === null || data === void 0 ? void 0 : data.severityIncrease) || [],
                recommendations: (data === null || data === void 0 ? void 0 : data.recommendations) || [],
                isCustomerCreated: +data.user_id === CUSTOMER_USER_ID,
                isShowWaitesNew: isRegularAi && !isClosedAi && +data.user_id === CUSTOMER_USER_ID,
                isShowWaiting: isRegularAi,
                isRegularAi: isRegularAi,
                isCustomerRequested: data.is_customer_request || 0,
            };
        },
        enabled: !isNaN(+actionItemId) && +actionItemId !== 0,
    });
};
