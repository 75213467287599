import { useQuery } from '@tanstack/react-query';
import { selectHideHardwareAi, selectIsActive, selectOnlyHardwareAi, selectSelectedCustomers, selectSelectedFacility, selectSelectedLocation, } from '@sharedStore/actionItemsList/useActionItemsListStore';
import Api from '../../../api/api';
export var actionItemsListFilterKey = function (options) { return ['actionItemsListFilter', options]; };
var actionItemsListFilterFn = function (options, endpoint, host) {
    return Api.get(endpoint || 'api/analytic/action-item/list/filter', {
        query: {
            only_active: +options.isActive,
            hide_hardware_ai: +options.hideHardwareAi,
            only_hardware_ai: +options.onlyHardwareAi,
            selected_customer_ids: options.selectedCustomers,
            selected_location_id: options.selectedLocation,
            selected_facility: options.selectedFacility,
        },
        host: host || APP_API_V2_URL,
    });
};
export var useActionItemsListFilterQuery = function (_a) {
    var endpoint = _a.endpoint, host = _a.host;
    var queryOptions = {
        isActive: selectIsActive(),
        hideHardwareAi: selectHideHardwareAi(),
        onlyHardwareAi: selectOnlyHardwareAi(),
        selectedCustomers: selectSelectedCustomers(),
        selectedLocation: selectSelectedLocation(),
        selectedFacility: selectSelectedFacility(),
    };
    return useQuery({
        queryKey: actionItemsListFilterKey(queryOptions),
        queryFn: function () { return actionItemsListFilterFn(queryOptions, endpoint, host); },
        select: function (_a) {
            var data = _a.data;
            return {
                states: data.states_count,
                wwMissCount: data.ww_missed_ais_count,
                declinedReportsCount: data.with_declined_reports_count,
                withPreCommentsCount: data.with_pre_comments_count,
                severityIncreaseCount: data.severity_increase_count,
                troubleshootAiCount: data.troubleshoot_ais_count,
                troubleshootWaitingAiCount: data.troubleshoot_waiting_ais_count,
                waitesNewAiCount: data.waites_new_ais_count,
                troubleshootQuestionAiCount: data.troubleshoot_question_ais_count,
                noResponseCount: data.no_response_count,
                customerCreatedAisCount: data.customer_created_ais_count,
                hardwareActionItemsTypes: data.hardwareActionItemsTypes,
                autoAisCount: data.auto_ais_count,
                autoAisUaCount: data.auto_ais_ua_count,
                customerRequestedCount: data.customer_request_count,
            };
        },
        placeholderData: function (previousData) {
            return (previousData !== null && previousData !== void 0 ? previousData : {
                states: [],
                wwMissCount: 0,
                declinedReportsCount: 0,
                withPreCommentsCount: 0,
                severityIncreaseCount: 0,
                troubleshootAiCount: 0,
                troubleshootWaitingAiCount: 0,
                waitesNewAiCount: 0,
                troubleshootQuestionAiCount: 0,
                noResponseCount: 0,
                customerCreatedAisCount: 0,
                autoAisCount: 0,
                autoAisUaCount: 0,
                customerRequestedCount: 0,
                hardwareActionItemsTypes: [],
            });
        },
    });
};
