import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { InstallationPointStore } from '../../../../../../store/charts/chartsContent/InstallationPointStore';
import { ModalsStore } from '../../../../../../store/charts/chartsContent/ModalsStore';
import styles from './styles.module.scss';
var MachineInformation = function () {
    var isAnalystNotesExists = InstallationPointStore(function (state) { return ({
        isAnalystNotesExists: state.isAnalystNotesExists,
    }); }, shallow).isAnalystNotesExists;
    var setModalsStoreStates = ModalsStore(function (state) { return ({
        setModalsStoreStates: state.setStates,
    }); }, shallow).setModalsStoreStates;
    return (_jsxs(Button, { outline: true, color: "customChartTopBarSelect", className: "position-relative btn-chart-tools", size: "sm", onClick: function () { return setModalsStoreStates({ isVisibleMachineInfoModal: true }); }, children: ["Machine Information", isAnalystNotesExists && _jsx("span", { className: styles.machineInfoAlert })] }));
};
export default memo(MachineInformation);
