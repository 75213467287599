import { valibotResolver } from '@hookform/resolvers/valibot';
import { array, custom, minLength, nullable, number, object, optional, pipe } from 'valibot';
import { BAD_DATA_COMPONENT_TYPE_ID } from '../../../../../remastered/constants';
export var faultRecommendationsSchema = valibotResolver(object({
    faultRecommendations: pipe(array(optional(nullable(object({
        componentType: optional(nullable(number())),
        subType: optional(nullable(number())),
        fault: optional(nullable(number())),
        severity: optional(nullable(number())),
        recommendations: array(number()),
    })))), minLength(1, 'Recommendations cannot be empty'), custom(function (faultRecommendations) {
        if (!Array.isArray(faultRecommendations))
            return false;
        return faultRecommendations
            .filter(function (item) { return item; })
            .every(function (faultRecommendation) { var _a; return faultRecommendation && ((_a = faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.length) > 0; });
    }, 'Recommendations cannot be empty'), custom(function (faultRecommendations) {
        if (!Array.isArray(faultRecommendations))
            return false;
        var badDataIsset = faultRecommendations
            .filter(function (item) { return item; })
            .some(function (faultRecommendation) {
            return (faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.componentType) === BAD_DATA_COMPONENT_TYPE_ID;
        });
        return (badDataIsset ||
            faultRecommendations
                .filter(function (item) { return item; })
                .every(function (faultRecommendation) { return faultRecommendation === null || faultRecommendation === void 0 ? void 0 : faultRecommendation.severity; }));
    }, 'Severity cannot be empty')),
}));
