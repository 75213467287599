import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Input, Label, Row } from 'reactstrap';
import { getHexId } from '../../widgets/sensor';
var SensorsByEquipment = function (_a) {
    var selectedInstallationPointId = _a.selectedInstallationPointId, setSelectedInstallationPointId = _a.setSelectedInstallationPointId, equipment = _a.equipment, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.selectClassName, selectClassName = _d === void 0 ? '' : _d;
    return (_jsx(Row, { className: className, children: _jsxs(Col, { children: [_jsx(Label, { className: "ellipsis d-block fw-bold", children: "Sensor:" }), _jsx(Input, { value: selectedInstallationPointId, disabled: isDisabled, className: selectClassName, onChange: function (ev) { return setSelectedInstallationPointId(+ev.target.value); }, type: "select", children: equipment.installationPoints
                        .filter(function (_a) {
                        var sensor_id = _a.sensor_id;
                        return sensor_id;
                    })
                        .map(function (_a) {
                        var id = _a.id, name = _a.name, component = _a.component, point_name = _a.point_name, sensor_id = _a.sensor_id;
                        return (_jsxs("option", { value: id, children: [name || "".concat(component, " - ").concat(point_name), " (", getHexId(+sensor_id), ")"] }, id));
                    }) })] }) }));
};
export default SensorsByEquipment;
