import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { READING_TYPES } from '../../../../../../../constants';
import { getAvailableChartIdentifiersByReadingType } from '../../../../../../../modals/changeAlarmLevels/helpers';
import { useChartManagementActions } from '../../../../../../store/charts/chartsContent/useChartManagementStore';
var ManagementButton = function (_a) {
    var _b;
    var width = _a.width, isDisabled = _a.isDisabled, chartIdentifier = _a.chartIdentifier;
    var _c = useChartManagementActions(), setIsVisibleManagementModal = _c.setIsVisibleManagementModal, setChartIdentifier = _c.setChartIdentifier;
    var handleOpen = function () {
        setIsVisibleManagementModal(true);
        setChartIdentifier(getAvailableChartIdentifiersByReadingType(READING_TYPES, chartIdentifier, true));
    };
    if (!((_b = READING_TYPES[chartIdentifier]) === null || _b === void 0 ? void 0 : _b.axis)) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: "chart-btn", children: _jsx(Button, { className: "chart-action-btn ".concat(isDisabled ? 'chart-action-btn-disabled' : ''), color: "secondary", onClick: handleOpen, style: { width: width }, disabled: isDisabled, children: _jsx(FaRegEdit, {}) }) }));
};
export default memo(ManagementButton);
