import { get as _get } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import Endpoints from '../../../../../../api/endpoints/endpoints';
import { SYSTEM_TYPE_REMASTERED } from '../../../../../../constants';
import { SystemTypeContext } from '../../../../../../shared/context/SystemTypeContext';
export var useCustomerLevels = function (_a) {
    var installationPointId = _a.installationPointId, readingTypeId = _a.readingTypeId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState({
        caution: null,
        warning: null,
    }), customerLevels = _b[0], setCustomerLevels = _b[1];
    var _c = useState(false), isVisibleCustomerLevels = _c[0], setIsVisibleCustomerLevels = _c[1];
    useEffect(function () {
        if (selectedSystemType === SYSTEM_TYPE_REMASTERED && readingTypeId) {
            Endpoints.remastered.getCustomerAlertConditions(installationPointId, readingTypeId).then(function (response) {
                if (response === null || response === void 0 ? void 0 : response.data) {
                    setCustomerLevels(_get(response.data, [installationPointId, readingTypeId]));
                }
            });
        }
    }, [installationPointId, readingTypeId]);
    return {
        customerLevels: customerLevels,
        isVisibleCustomerLevels: isVisibleCustomerLevels,
        setIsVisibleCustomerLevels: setIsVisibleCustomerLevels,
    };
};
