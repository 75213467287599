import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { head as _head } from 'lodash';
import { useEffect } from 'react';
import Loader from '../../../../../../shared/components/Loader';
import { getIsViewMode } from '../../features';
import { useAssetTreeQuery } from '../../services/useAssetTreeQuery';
import { selectComponentId, useAssetBuilderActions } from '../../stores/useAssetBuilderStore';
import { FirstAssetContent } from './components/FirstAssetContent';
import { NotFirstAssetContent } from './components/NotFirstAssetContent';
import styles from './styles.module.scss';
export var SetupContent = function (_a) {
    var _b;
    var equipmentId = _a.equipmentId;
    var _c = useAssetTreeQuery({ equipmentId: equipmentId }), assetTreeData = _c.data, isLoading = _c.isLoading, isFetching = _c.isFetching;
    var componentId = selectComponentId();
    var setComponentId = useAssetBuilderActions().setComponentId;
    var isViewMode = getIsViewMode(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data);
    var componentData = isViewMode
        ? (_b = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _b === void 0 ? void 0 : _b.assetConfigs.find(function (_a) {
            var id = _a.id;
            return id === componentId;
        })
        : null;
    useEffect(function () {
        var _a, _b;
        if (!isViewMode) {
            return setComponentId(null);
        }
        setComponentId((_b = _head((_a = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _a === void 0 ? void 0 : _a.assetConfigs)) === null || _b === void 0 ? void 0 : _b.id);
    }, [assetTreeData]);
    return (_jsx("div", { className: styles.wrapper, children: isLoading || isFetching ? (_jsx("div", { className: styles.loader, children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsx(_Fragment, { children: (isViewMode && (componentData === null || componentData === void 0 ? void 0 : componentData.assetModuleId) === 1) || !(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data) ? (_jsx(FirstAssetContent, { equipmentId: equipmentId })) : (_jsx(NotFirstAssetContent, { equipmentId: equipmentId })) })) }));
};
SetupContent.displayName = 'SetupContent';
