import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, memo, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loader from '../../../../../../shared/components/Loader';
import { getIsViewMode } from '../../features';
import { useAssetTreeQuery } from '../../services/useAssetTreeQuery';
import { useUnassignedPointsQuery } from '../../services/useUnassignedPointsQuery';
import assetBuilderStyles from '../../styles.module.scss';
import { InstallationPointItem } from '../InstallationPointItem';
import styles from './styles.module.scss';
export var NotAssignedIP = memo(function (_a) {
    var _b;
    var equipmentId = _a.equipmentId;
    var _c = useUnassignedPointsQuery({ equipmentId: equipmentId }), data = _c.data, isLoading = _c.isLoading, isFetching = _c.isFetching;
    var _d = useAssetTreeQuery({ equipmentId: equipmentId }), assetTreeData = _d.data, assetTreeIsLoading = _d.isLoading, assetTreeIsFetching = _d.isFetching;
    var isViewMode = getIsViewMode(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data);
    useEffect(function () {
        if (isLoading || isFetching || assetTreeIsLoading || assetTreeIsFetching || isViewMode || (data === null || data === void 0 ? void 0 : data.data)) {
            return;
        }
        toast.error('Not assigned IP is empty', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
    }, [data === null || data === void 0 ? void 0 : data.data, isViewMode]);
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx("h2", { className: assetBuilderStyles.title, children: "Not assigned IP" }), isLoading || isFetching ? (_jsx("div", { className: styles.loaderWrapper, children: _jsx(Loader, { variant: "loader-sm" }) })) : (_jsx("div", { className: styles.list, children: !isLoading && !isFetching && !(data === null || data === void 0 ? void 0 : data.data) ? (_jsx("div", { className: styles.empty, children: "Not assigned IP is empty" })) : (_jsx(_Fragment, { children: ((_b = data === null || data === void 0 ? void 0 : data.data) !== null && _b !== void 0 ? _b : []).map(function (uninstallIp) { return (_jsx(Fragment, { children: _jsx(InstallationPointItem, { installationPoint: uninstallIp }) }, uninstallIp.id)); }) })) }))] }));
});
NotAssignedIP.displayName = 'NotAssignedIP';
