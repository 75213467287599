import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import assetBuilderStyles from '../../styles.module.scss';
export var HeadSprocketToothCount = memo(function (_a) {
    var control = _a.control, fieldName = _a.fieldName, isDisabled = _a.isDisabled;
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, pistonRate = _b.value, onChangeHeadSprocketToothCount = _b.onChange;
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Head sprocket tooth count" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: pistonRate !== null && pistonRate !== void 0 ? pistonRate : '', onChange: function (e) {
                    return onChangeHeadSprocketToothCount(e.target.value ? Number(e.target.value) : undefined);
                }, min: 1 })] }));
});
HeadSprocketToothCount.displayName = 'HeadSprocketToothCount';
