// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position__Z7AWu {
  display: grid;
  grid-template-columns: 4fr 4fr 1fr 4fr 4fr;
  grid-gap: 16px;
}

.positionTach__vqOMu {
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 4fr 4fr;
  grid-gap: 16px;
  height: 116px;
}

.bottom__Ykrt8 {
  margin-top: auto;
}

.center__nB_IG {
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/RunningSpeed/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,0CAAA;EACA,cAAA;AACD;;AACA;EACC,aAAA;EACA,0CAAA;EACA,cAAA;EACA,aAAA;AAED;;AAAA;EACC,gBAAA;AAGD;;AAAA;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;AAGD","sourcesContent":[".position {\n\tdisplay: grid;\n\tgrid-template-columns: 4fr 4fr 1fr 4fr 4fr;\n\tgrid-gap: 16px;\n}\n.positionTach {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 4fr 4fr 4fr 4fr;\n\tgrid-gap: 16px;\n\theight: 116px;\n}\n.bottom {\n\tmargin-top: auto;\n}\n\n.center {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"position": `position__Z7AWu`,
	"positionTach": `positionTach__vqOMu`,
	"bottom": `bottom__Ykrt8`,
	"center": `center__nB_IG`
};
module.exports = ___CSS_LOADER_EXPORT___;
