var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation as useMutationUntyped } from '@tanstack/react-query';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Api from '../../../api/api';
var useMutation = useMutationUntyped;
export var useToggleCustomerRequestMutation = function (_a, mutationOptions) {
    var actionItemId = _a.actionItemId, isCustomerCreated = _a.isCustomerCreated;
    if (mutationOptions === void 0) { mutationOptions = {}; }
    var mutation = useMutation(__assign(__assign({}, mutationOptions), { mutationFn: function (isCustomerRequest) {
            if (isCustomerCreated) {
                return Api.post('all-analytics/action-item/toggle-customer-request', {
                    is_customer_request: isCustomerRequest,
                    action_item_id: actionItemId,
                });
            }
            return Promise.reject({ success: false });
        }, onSuccess: function (data, isCustomerRequested, context) {
            var _a;
            toast.success("Customer's Request tag was ".concat(isCustomerRequested ? 'added' : 'deleted'), {
                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
            });
            (_a = mutationOptions.onSuccess) === null || _a === void 0 ? void 0 : _a.call(mutationOptions, data, isCustomerRequested, context);
        } }));
    return __assign(__assign({}, mutation), { deleteCustomerRequestTag: function () { return mutation.mutate(0); }, addCustomerRequestTag: function () { return mutation.mutate(1); } });
};
