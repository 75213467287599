var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { set as _set } from '#typed/lodash';
import { Badge, Button, Col, Input, Label, Row } from '#typed/reactstrap';
import { useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamation, FaInfo, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { selectMeasure } from '@sharedStore/global/useGlobalStore';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { METRIC_KEY, READING_TYPES, READING_TYPE_TACHOMETER, SYSTEM_TYPE_AMAZON, } from '../../../../../constants';
import { SystemTypeContext } from '../../../../../shared/context/SystemTypeContext';
import { isNumeric } from '../../../../../shared/helper/commonHelper';
import { ChartFeaturesStore } from '../../../../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../../../../shared/store/charts/chartsContent/InstallationPointStore';
import { useConfirmModalActions } from '../../../../../shared/store/global/useConfirmModalStore';
import { LossPacketsAlert } from '../../../../../widgets/common';
import './AlertLevelsTab.scss';
import AlertLevelBlock from './components/AlertLevelBlock';
import Chart from './components/Chart';
import { DEFAULT_ALERT_CONDITION } from './constants';
import { useCustomerLevels } from './hooks/useCustomerLevels';
import { useValidationErrors } from './hooks/useValidationErrors';
import { transformLevelsObjectToArray } from './lib';
export var AlertLevelsTab = function (_a) {
    var setSmallLoader = _a.setSmallLoader, selectedInstallationPointId = _a.installationPointId, selectedReadingTypeIds = _a.selectedReadingTypeIds, onSelectReadingType = _a.onSelectReadingType, chartIdentifiers = _a.chartIdentifiers, isAdapter = _a.isAdapter, onAlertLevelsChange = _a.onAlertLevelsChange;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var isAmazon = selectedSystemType === SYSTEM_TYPE_AMAZON;
    var _b = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
        setChartFeatures: state.setStates,
    }); }, shallow), changeAlertConditions = _b.changeAlertConditions, setChartFeatures = _b.setChartFeatures;
    var readingTypeId = changeAlertConditions.readingTypeId;
    var _c = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
        packetLossPercent: state.packetLossPercent,
    }); }, shallow), installationPoint = _c.installationPoint, overlayInstallationPoints = _c.overlayInstallationPoints, packetLossPercent = _c.packetLossPercent;
    var measure = selectMeasure();
    var _d = useState(false), forAllSensors = _d[0], setForAllSensors = _d[1];
    var _e = useState(false), forOverlaid = _e[0], setForOverlaid = _e[1];
    var _f = useState(false), areAlertConditionsFilled = _f[0], setAlertConditionsFilled = _f[1];
    var _g = useState([
        {
            caution: structuredClone(DEFAULT_ALERT_CONDITION),
            warning: structuredClone(DEFAULT_ALERT_CONDITION),
        },
    ]), alertLevels = _g[0], setAlertLevels = _g[1];
    var validationErrors = useValidationErrors({ alertLevels: alertLevels }).validationErrors;
    useEffect(function () {
        if (!areAlertConditionsFilled) {
            setAlertLevels(transformLevelsObjectToArray(changeAlertConditions, isAmazon));
            setAlertConditionsFilled(true);
        }
    }, [changeAlertConditions, areAlertConditionsFilled]);
    var hasAutoAi = alertLevels.some(function (_a) {
        var _b, _c;
        var caution = _a.caution, warning = _a.warning;
        return ((_b = caution === null || caution === void 0 ? void 0 : caution.settings) === null || _b === void 0 ? void 0 : _b.is_ai) || ((_c = warning === null || warning === void 0 ? void 0 : warning.settings) === null || _c === void 0 ? void 0 : _c.is_ai);
    });
    var triggerEachReading = alertLevels.some(function (_a) {
        var _b, _c;
        var caution = _a.caution, warning = _a.warning;
        return Number((_b = caution === null || caution === void 0 ? void 0 : caution.settings) === null || _b === void 0 ? void 0 : _b.trigger_time) > 0 || Number((_c = warning === null || warning === void 0 ? void 0 : warning.settings) === null || _c === void 0 ? void 0 : _c.trigger_time) > 0;
    });
    var _h = useState([]), comments = _h[0], setComments = _h[1];
    useEffect(function () {
        setSmallLoader(true);
        if (readingTypeId) {
            Endpoints[selectedSystemType]
                .getAlertConditionComments({
                query: {
                    installationPointIds: [installationPoint.id],
                    readingTypeIds: [readingTypeId],
                },
            })
                .then(function (response) {
                if (response === null || response === void 0 ? void 0 : response.data) {
                    setComments(response.data);
                }
            })
                .finally(function () {
                setSmallLoader(false);
            });
        }
    }, [installationPoint.id, readingTypeId]);
    var _j = useCustomerLevels({
        installationPointId: installationPoint.id,
        readingTypeId: readingTypeId,
    }), customerLevels = _j.customerLevels, isVisibleCustomerLevels = _j.isVisibleCustomerLevels, setIsVisibleCustomerLevels = _j.setIsVisibleCustomerLevels;
    var canAddMoreAlertLevels = alertLevels.length === 1;
    var _k = useState(false), shouldRefreshChart = _k[0], setShouldRefreshChart = _k[1];
    var addAlertLevel = function () {
        setAlertLevels(function (alertLevels) {
            var secondPair = {
                caution: structuredClone(DEFAULT_ALERT_CONDITION),
                warning: structuredClone(DEFAULT_ALERT_CONDITION),
            };
            if (alertLevels[0].caution.condition === DEFAULT_ALERT_CONDITION.condition) {
                secondPair.caution.condition = '<=';
            }
            if (alertLevels[0].warning.condition === DEFAULT_ALERT_CONDITION.condition) {
                secondPair.warning.condition = '<=';
            }
            return __spreadArray(__spreadArray([], alertLevels, true), [secondPair], false);
        });
    };
    var changeNewAlertCondition = function (index, level, path, value) {
        var changedAlertConditionsState = structuredClone(alertLevels);
        _set(changedAlertConditionsState, __spreadArray([index, level], path, true), value);
        setAlertLevels(changedAlertConditionsState);
    };
    var updateAlertConditions = function (shouldCloseModal) {
        if (shouldCloseModal === void 0) { shouldCloseModal = false; }
        if (readingTypeId) {
            Endpoints[selectedSystemType]
                .getAlertLevels(installationPoint.id, readingTypeId, METRIC_KEY[measure])
                .then(function (_a) {
                var alertConditions = _a.alertConditions;
                setAlertLevels(transformLevelsObjectToArray(__assign(__assign({ currentLevel: {} }, alertConditions), { 
                    // @ts-ignore
                    storedLevels: alertConditions.levels || {} }), isAmazon));
                setChartFeatures({
                    changeAlertConditions: __assign({ selectedReadingTypeIds: selectedReadingTypeIds, readingTypeId: readingTypeId, currentLevel: {}, storedLevels: {}, conditions: {}, settings: {}, fftSettings: {}, isRmsOd: {}, isVisibleModal: !shouldCloseModal, shouldUpdate: true }, alertConditions),
                });
            });
        }
    };
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var removeLevelsSecondPairOfLevels = function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure to delete alert conditions?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                setAlertLevels(function (alertConditions) {
                    return alertConditions.filter(function (_, i) { return i !== 1; });
                });
            },
        });
    };
    var deleteAlertCondition = function (index, level, alertCondition) {
        var alertConditionId = alertCondition === null || alertCondition === void 0 ? void 0 : alertCondition.conditionId;
        var removeLocally = function () {
            setAlertLevels(function (alertLevels) {
                return alertLevels.map(function (alertLevel, alertLevelIndex) {
                    var _a;
                    if (alertLevelIndex === index) {
                        return __assign(__assign({}, alertLevel), (_a = {}, _a[level] = structuredClone(DEFAULT_ALERT_CONDITION), _a));
                    }
                    return alertLevel;
                });
            });
        };
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure to delete alert condition?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                if (alertConditionId && readingTypeId) {
                    setSmallLoader(true);
                    Endpoints[selectedSystemType]
                        .deleteOnceAlertLevel({
                        alertConditionId: alertConditionId,
                        installationPointId: installationPoint.id,
                        readingTypeId: readingTypeId,
                    })
                        .then(function (_a) {
                        var success = _a.success;
                        if (success) {
                            toast.success('Alert condition successfully removed', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                            removeLocally();
                            updateAlertConditions();
                            onAlertLevelsChange === null || onAlertLevelsChange === void 0 ? void 0 : onAlertLevelsChange();
                        }
                    })
                        .finally(function () {
                        setSmallLoader(false);
                    });
                }
                else {
                    removeLocally();
                }
            },
        });
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        if (validationErrors.length)
            return;
        var action = event.nativeEvent.submitter.dataset.action;
        var shouldCloseModal = action === 'save&close';
        var submitData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var requests_2, currentOrder_1, isSuccess, _i, requests_1, request, success, _error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSmallLoader(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        requests_2 = [];
                        currentOrder_1 = 1;
                        if (readingTypeId) {
                            alertLevels.forEach(function (levels) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
                                var caution = levels.caution, warning = levels.warning;
                                var shouldInOneRequest = !(caution === null || caution === void 0 ? void 0 : caution.conditionId) && !(warning === null || warning === void 0 ? void 0 : warning.conditionId);
                                if (shouldInOneRequest && isNumeric(caution.level) && isNumeric(warning.level)) {
                                    var newLevels_1 = {
                                        caution: {
                                            alert_level: 'caution',
                                            reading_type_id: readingTypeId,
                                            condition: caution.condition,
                                            settings: {
                                                is_ai: (_b = (_a = caution.settings) === null || _a === void 0 ? void 0 : _a.is_ai) !== null && _b !== void 0 ? _b : 0,
                                                ai_urgency: (_d = (_c = caution.settings) === null || _c === void 0 ? void 0 : _c.ai_urgency) !== null && _d !== void 0 ? _d : 0,
                                                is_pre_ai: (_f = (_e = caution.settings) === null || _e === void 0 ? void 0 : _e.is_pre_ai) !== null && _f !== void 0 ? _f : 0,
                                                trigger_time: (_h = (_g = caution.settings) === null || _g === void 0 ? void 0 : _g.trigger_time) !== null && _h !== void 0 ? _h : null,
                                                ai_description: (_k = (_j = caution.settings) === null || _j === void 0 ? void 0 : _j.ai_description) !== null && _k !== void 0 ? _k : '',
                                                trigger_rms_od: (_m = (_l = caution.settings) === null || _l === void 0 ? void 0 : _l.trigger_rms_od) !== null && _m !== void 0 ? _m : null,
                                            },
                                            value: caution.level,
                                            chart_type: readingTypeId,
                                            order: currentOrder_1++,
                                        },
                                        warning: {
                                            alert_level: 'warning',
                                            reading_type_id: readingTypeId,
                                            condition: warning.condition,
                                            settings: {
                                                is_ai: (_p = (_o = warning.settings) === null || _o === void 0 ? void 0 : _o.is_ai) !== null && _p !== void 0 ? _p : 0,
                                                ai_urgency: (_r = (_q = warning.settings) === null || _q === void 0 ? void 0 : _q.ai_urgency) !== null && _r !== void 0 ? _r : 0,
                                                is_pre_ai: (_t = (_s = warning.settings) === null || _s === void 0 ? void 0 : _s.is_pre_ai) !== null && _t !== void 0 ? _t : 0,
                                                trigger_time: (_v = (_u = warning.settings) === null || _u === void 0 ? void 0 : _u.trigger_time) !== null && _v !== void 0 ? _v : null,
                                                ai_description: (_x = (_w = warning.settings) === null || _w === void 0 ? void 0 : _w.ai_description) !== null && _x !== void 0 ? _x : '',
                                                trigger_rms_od: (_z = (_y = warning.settings) === null || _y === void 0 ? void 0 : _y.trigger_rms_od) !== null && _z !== void 0 ? _z : null,
                                            },
                                            value: warning.level,
                                            chart_type: readingTypeId,
                                            order: currentOrder_1++,
                                        },
                                    };
                                    if (caution.isRequestFFT) {
                                        newLevels_1.caution.fftSettings = caution.fftSettings;
                                    }
                                    if (warning.isRequestFFT) {
                                        newLevels_1.warning.fftSettings = warning.fftSettings;
                                    }
                                    requests_2.push(function () {
                                        return Endpoints[selectedSystemType].createOnceAlertLevel({
                                            installationPointId: installationPoint.id,
                                            readingTypeId: readingTypeId,
                                            levels: newLevels_1,
                                            setForAllComponents: +forOverlaid,
                                            setForAllEquipment: +forAllSensors,
                                            overlayInstallationPoints: overlayInstallationPoints.map(function (_a) {
                                                var id = _a.id;
                                                return id;
                                            }),
                                            selected_facility_metric: METRIC_KEY[measure],
                                        }, { query: { duplicated: true } });
                                    });
                                }
                                else {
                                    if (isAmazon) {
                                        var willUpdatedLevels_1 = Object.entries(levels).reduce(function (acc, _a) {
                                            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                                            var levelKey = _a[0], level = _a[1];
                                            if (level.conditionId) {
                                                acc[levelKey] = {
                                                    alert_level: levelKey,
                                                    reading_type_id: readingTypeId,
                                                    condition: level.condition,
                                                    settings: {
                                                        is_ai: (_c = (_b = level.settings) === null || _b === void 0 ? void 0 : _b.is_ai) !== null && _c !== void 0 ? _c : 0,
                                                        ai_urgency: (_e = (_d = level.settings) === null || _d === void 0 ? void 0 : _d.ai_urgency) !== null && _e !== void 0 ? _e : 0,
                                                        is_pre_ai: (_g = (_f = level.settings) === null || _f === void 0 ? void 0 : _f.is_pre_ai) !== null && _g !== void 0 ? _g : 0,
                                                        trigger_time: (_j = (_h = level.settings) === null || _h === void 0 ? void 0 : _h.trigger_time) !== null && _j !== void 0 ? _j : null,
                                                        ai_description: (_l = (_k = level.settings) === null || _k === void 0 ? void 0 : _k.ai_description) !== null && _l !== void 0 ? _l : '',
                                                        trigger_rms_od: (_o = (_m = level.settings) === null || _m === void 0 ? void 0 : _m.trigger_rms_od) !== null && _o !== void 0 ? _o : null,
                                                    },
                                                    value: level.level,
                                                    chart_type: readingTypeId,
                                                    order: 0,
                                                    id: level.conditionId,
                                                };
                                            }
                                            return acc;
                                        }, {});
                                        requests_2.unshift(function () {
                                            var _a, _b;
                                            return Endpoints.amazon.updateOnceAlertLevel({
                                                installationPointId: installationPoint.id,
                                                readingTypeId: readingTypeId,
                                                levels: willUpdatedLevels_1,
                                                setForAllEquipment: +forAllSensors,
                                                setForAllComponents: +forOverlaid,
                                                overlayInstallationPoints: overlayInstallationPoints.map(function (_a) {
                                                    var id = _a.id;
                                                    return id;
                                                }),
                                                alertLevelId: Number(((_a = willUpdatedLevels_1.caution) === null || _a === void 0 ? void 0 : _a.id) || ((_b = willUpdatedLevels_1.warning) === null || _b === void 0 ? void 0 : _b.id)),
                                                selected_facility_metric: METRIC_KEY[measure],
                                            }, { query: { duplicated: true } });
                                        });
                                    }
                                    else {
                                        Object.entries(levels)
                                            .filter(function (_a) {
                                            var _ = _a[0], level = _a[1];
                                            var ok = level && isNumeric(level.level);
                                            if (!ok)
                                                currentOrder_1++;
                                            return ok;
                                        })
                                            .forEach(function (_a) {
                                            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                                            var alertLevelKey = _a[0], alertLevel = _a[1];
                                            var alertLevelPayload = {
                                                alert_level: alertLevelKey,
                                                reading_type_id: readingTypeId,
                                                condition: alertLevel.condition,
                                                settings: {
                                                    is_ai: (_c = (_b = alertLevel.settings) === null || _b === void 0 ? void 0 : _b.is_ai) !== null && _c !== void 0 ? _c : 0,
                                                    ai_urgency: (_e = (_d = alertLevel.settings) === null || _d === void 0 ? void 0 : _d.ai_urgency) !== null && _e !== void 0 ? _e : 0,
                                                    is_pre_ai: (_g = (_f = alertLevel.settings) === null || _f === void 0 ? void 0 : _f.is_pre_ai) !== null && _g !== void 0 ? _g : 0,
                                                    trigger_time: (_j = (_h = alertLevel.settings) === null || _h === void 0 ? void 0 : _h.trigger_time) !== null && _j !== void 0 ? _j : null,
                                                    ai_description: (_l = (_k = alertLevel.settings) === null || _k === void 0 ? void 0 : _k.ai_description) !== null && _l !== void 0 ? _l : '',
                                                    trigger_rms_od: (_o = (_m = alertLevel.settings) === null || _m === void 0 ? void 0 : _m.trigger_rms_od) !== null && _o !== void 0 ? _o : null,
                                                },
                                                value: alertLevel.level,
                                                chart_type: readingTypeId,
                                                order: currentOrder_1++,
                                            };
                                            var alertLevelId = alertLevel.conditionId;
                                            if (alertLevel.isRequestFFT) {
                                                alertLevelPayload.fftSettings = alertLevel.fftSettings;
                                            }
                                            if (alertLevelId) {
                                                requests_2.unshift(function () {
                                                    var _a;
                                                    return Endpoints[selectedSystemType].updateOnceAlertLevel({
                                                        installationPointId: installationPoint.id,
                                                        readingTypeId: readingTypeId,
                                                        levels: (_a = {},
                                                            _a[alertLevelKey] = __assign(__assign({}, alertLevelPayload), { id: alertLevelId }),
                                                            _a),
                                                        setForAllEquipment: +forAllSensors,
                                                        setForAllComponents: +forOverlaid,
                                                        overlayInstallationPoints: overlayInstallationPoints.map(function (_a) {
                                                            var id = _a.id;
                                                            return id;
                                                        }),
                                                        selected_facility_metric: METRIC_KEY[measure],
                                                    }, { query: { duplicated: true } });
                                                });
                                            }
                                            else {
                                                requests_2.push(function () {
                                                    var _a;
                                                    return Endpoints[selectedSystemType].createOnceAlertLevel({
                                                        installationPointId: installationPoint.id,
                                                        readingTypeId: readingTypeId,
                                                        levels: (_a = {},
                                                            _a[alertLevelKey] = alertLevelPayload,
                                                            _a),
                                                        setForAllEquipment: +forAllSensors,
                                                        setForAllComponents: +forOverlaid,
                                                        overlayInstallationPoints: overlayInstallationPoints.map(function (_a) {
                                                            var id = _a.id;
                                                            return id;
                                                        }),
                                                        selected_facility_metric: METRIC_KEY[measure],
                                                    }, { query: { duplicated: true } });
                                                });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                        isSuccess = true;
                        _i = 0, requests_1 = requests_2;
                        _a.label = 2;
                    case 2:
                        if (!(_i < requests_1.length)) return [3 /*break*/, 5];
                        request = requests_1[_i];
                        return [4 /*yield*/, request()];
                    case 3:
                        success = (_a.sent()).success;
                        if (!success) {
                            isSuccess = false;
                        }
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5:
                        if (isSuccess) {
                            if (selectedInstallationPointId === installationPoint.id) {
                                setShouldRefreshChart(true);
                                updateAlertConditions(shouldCloseModal);
                            }
                            toast.success('Successfully saved', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                        }
                        else {
                            toast.error('An error occurred during submission', {
                                icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                            });
                        }
                        return [3 /*break*/, 8];
                    case 6:
                        _error_1 = _a.sent();
                        toast.error('An error occurred during submission', {
                            icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                        });
                        return [3 /*break*/, 8];
                    case 7:
                        setSmallLoader(false);
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        var isAutoPreAi = alertLevels.some(function (_a) {
            var _b, _c;
            var caution = _a.caution, warning = _a.warning;
            return ((_b = caution === null || caution === void 0 ? void 0 : caution.settings) === null || _b === void 0 ? void 0 : _b.is_pre_ai) || ((_c = warning === null || warning === void 0 ? void 0 : warning.settings) === null || _c === void 0 ? void 0 : _c.is_pre_ai);
        });
        var showConfirmation = selectedReadingTypeIds.length > 1 || hasAutoAi || isAutoPreAi;
        if (showConfirmation) {
            var textConfirmation = '';
            if (comments.length > 0) {
                textConfirmation +=
                    'These alarm levels were set upon request, are you sure you want to confirm the action? </br>';
            }
            if (selectedReadingTypeIds.length > 1) {
                var axesLabel = selectedReadingTypeIds
                    .map(function (selectedReadingTypeId) { var _a; return (_a = READING_TYPES[selectedReadingTypeId]) === null || _a === void 0 ? void 0 : _a.axisShort; })
                    .join(', ');
                textConfirmation += "Are you sure you want to set the same levels for the ".concat(axesLabel, " axes? </br>");
            }
            if (hasAutoAi || isAutoPreAi) {
                textConfirmation += "This reading type has settings for creation ".concat(hasAutoAi ? 'Auto AI' : '', " ").concat(hasAutoAi && isAutoPreAi ? ' and ' : '', " ").concat(isAutoPreAi ? 'Auto Pre-AI' : '', ", are you sure you want to change the alarm levels?");
            }
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: textConfirmation,
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    submitData();
                },
            });
        }
        submitData();
    };
    return (_jsxs(_Fragment, { children: [(triggerEachReading || !!comments.length) && (_jsxs(Row, { className: "g-0 mb-2", children: [triggerEachReading && (_jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "danger", children: _jsx(FaInfo, {}) }), _jsx("span", { className: "fw-bold align-bottom ms-2", children: "This option will be trigger each reading for check alarm" })] })), !!comments.length && (_jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "warning", children: _jsx(FaExclamation, {}) }), _jsx("span", { className: "fw-bold align-bottom ms-2", children: "This equipment has an active alert notes for alert levels" })] }))] })), hasAutoAi && _jsx(LossPacketsAlert, { lossPacketsPercent: +packetLossPercent }), _jsxs(Row, { children: [_jsxs(Col, { md: 6, children: [_jsx(Chart, { customerLevels: customerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, chartIdentifiers: chartIdentifiers, selectedReadingTypeIds: selectedReadingTypeIds, setSelectedReadingTypeIds: onSelectReadingType, alertConditions: alertLevels, shouldRefresh: shouldRefreshChart, selectedInstallationPointId: selectedInstallationPointId }), readingTypeId !== READING_TYPE_TACHOMETER && !isAdapter && (_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forAllSensors, onChange: function () { return setForAllSensors(!forAllSensors); } }), _jsx("span", { className: "fw-bold", children: "Set Alarm for all components on equipment" })] }) }) })), readingTypeId !== READING_TYPE_TACHOMETER &&
                                Object.keys(overlayInstallationPoints).length !== 0 && (_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forOverlaid, onChange: function () { return setForOverlaid(!forOverlaid); } }), _jsx("span", { className: "fw-bold", children: "Set Alarm for all overlaid components" })] }) }) }))] }), _jsxs(Col, { md: 6, children: [!!validationErrors.length && (_jsx("ul", { className: "validation-errors", children: validationErrors.map(function (error, i) { return (_jsx("li", { children: error }, i)); }) })), _jsxs("form", { id: "alertLevelForm", onSubmit: handleSubmit, children: [_jsx("div", { className: "alert-levels", children: alertLevels.map(function (levels, index) {
                                            var _a, _b;
                                            var caution = levels.caution, warning = levels.warning;
                                            var noHaveConditionIds = !(caution === null || caution === void 0 ? void 0 : caution.conditionId) && !(warning === null || warning === void 0 ? void 0 : warning.conditionId);
                                            return (_jsxs("section", { className: "alert-levels__section alert-level-section", children: [_jsxs("div", { className: "alert-level-section__headlines block-grid", children: [_jsx("span", { children: "Alert type" }), _jsx("span", { children: "Value" }), _jsx("span", { children: "Action" })] }), _jsx(AlertLevelBlock, { levelIndex: index, alertCondition: caution, level: "caution", customerLevel: (_a = customerLevels === null || customerLevels === void 0 ? void 0 : customerLevels.caution) !== null && _a !== void 0 ? _a : null, setIsVisibleCustomerLevels: setIsVisibleCustomerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, onChange: function () {
                                                            var args = [];
                                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                                args[_i] = arguments[_i];
                                                            }
                                                            return changeNewAlertCondition.apply(void 0, __spreadArray([index, 'caution'], args, false));
                                                        }, onDeleteAlertCondition: function () {
                                                            var args = [];
                                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                                args[_i] = arguments[_i];
                                                            }
                                                            return deleteAlertCondition.apply(void 0, __spreadArray([index], args, false));
                                                        }, isAdapter: isAdapter }), _jsx(AlertLevelBlock, { levelIndex: index, alertCondition: warning, level: "warning", customerLevel: (_b = customerLevels === null || customerLevels === void 0 ? void 0 : customerLevels.warning) !== null && _b !== void 0 ? _b : null, setIsVisibleCustomerLevels: setIsVisibleCustomerLevels, isVisibleCustomerLevels: isVisibleCustomerLevels, onChange: function () {
                                                            var args = [];
                                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                                args[_i] = arguments[_i];
                                                            }
                                                            return changeNewAlertCondition.apply(void 0, __spreadArray([index, 'warning'], args, false));
                                                        }, onDeleteAlertCondition: function () {
                                                            var args = [];
                                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                                args[_i] = arguments[_i];
                                                            }
                                                            return deleteAlertCondition.apply(void 0, __spreadArray([index], args, false));
                                                        }, isAdapter: isAdapter }), index === 1 && noHaveConditionIds && (_jsx(Button, { className: "btn-delete-pair", color: "danger", onClick: removeLevelsSecondPairOfLevels, children: _jsx(FaTrashAlt, {}) }))] }, index));
                                        }) }), canAddMoreAlertLevels && (_jsx(Button, { outline: true, color: "primary", className: "mt-2 btn-add-second-pair", onClick: addAlertLevel, children: "Add second pair" }))] })] })] })] }));
};
