var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cloneDeep as _cloneDeep, get as _get, has as _has } from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { DEFAULT_READING_TYPES, DISTANCE_SENSOR_READING_TYPES, IMPACT_VUE_READING_TYPES, PIN_SENSOR_READING_TYPES, SENSOR, SPEED_TYPES, TACHOMETER_READING_TYPES, TEMPERATURE_SENSOR_READING_TYPES, } from '../../../../constants';
import { setColorForInstallationPoints } from '../../../helper/chart';
var initialState = {
    installationPoint: {},
    overlayInstallationPoints: [],
    isSupportHfdvue: false,
    nodePhotos: [],
    equipmentPhotos: [],
    sensorHistory: [],
    sensorPartNumber: '---',
    customerAlertLevels: [],
    equipmentInfo: [],
    startupMode: {},
    isOnDemand: false,
    installationPointAnalystLog: {},
    isAnalystNotesExists: false,
    isAutoAlertConditionsExists: false,
    isUniversalAdapter: false,
    isRefreshFftReadings: false,
    readingTypes: DEFAULT_READING_TYPES,
    aisOnBed: [],
    currentSpeed: 0,
    countFacilityHardwareAis: 0,
    countFacilityRegularAis: 0,
    packetLossPercent: 0,
    facilityNotes: [],
    mlSuggestedLevels: [],
    wosTrigger: null,
};
export var InstallationPointStore = create()(devtools(function (set) { return (__assign(__assign({}, initialState), { setCurrentSpeed: function (currentSpeed) { return set({ currentSpeed: currentSpeed }); }, setActiveInstallationPoint: function (installationPoint, overlayInstallationPoints, equipment, forceOverlay) {
        if (overlayInstallationPoints === void 0) { overlayInstallationPoints = []; }
        if (equipment === void 0) { equipment = undefined; }
        if (forceOverlay === void 0) { forceOverlay = false; }
        set(function (state) {
            if (!installationPoint) {
                return state;
            }
            var isImpactVueReading = installationPoint.isSupportHfdvue && +_get(installationPoint, 'sensor.is_hfdvue', 0) === 1;
            var readingTypes = isImpactVueReading
                ? __assign(__assign({}, DEFAULT_READING_TYPES), IMPACT_VUE_READING_TYPES) : DEFAULT_READING_TYPES;
            if (installationPoint.isUniversalAdapter) {
                readingTypes = installationPoint.customReadingTypes;
            }
            else if (installationPoint.isTachometer) {
                readingTypes = TACHOMETER_READING_TYPES;
            }
            else if (installationPoint.isTemperatureSensor) {
                readingTypes = TEMPERATURE_SENSOR_READING_TYPES;
            }
            else if (_get(installationPoint, _has(installationPoint, 'version_type') ? 'version_type' : 'sensor_version_type', null) === SENSOR.VERSION_TYPE_PIN) {
                readingTypes = PIN_SENSOR_READING_TYPES;
            }
            else if (_get(installationPoint, _has(installationPoint, 'version_type') ? 'version_type' : 'sensor_version_type', null) === SENSOR.VERSION_TYPE_DISTANCE) {
                readingTypes = DISTANCE_SENSOR_READING_TYPES;
            }
            if (equipment) {
                installationPoint = _cloneDeep(installationPoint);
                installationPoint.equipment = __assign(__assign({}, installationPoint.equipment), { installationPoints: equipment.installationPoints });
            }
            return {
                installationPoint: __assign(__assign({}, installationPoint), { equipment: setColorForInstallationPoints(((installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) || {})) }),
                isUniversalAdapter: installationPoint.isUniversalAdapter,
                isSupportHfdvue: installationPoint.isSupportHfdvue,
                isShowHeatsink: installationPoint.is_heatsink,
                readingTypes: readingTypes,
                overlayInstallationPoints: overlayInstallationPoints.length || forceOverlay
                    ? overlayInstallationPoints
                    : state.overlayInstallationPoints,
                currentSpeed: getCurrentSpeed(installationPoint),
            };
        }, false, 'InstallationPointStoreSetActiveInstallationPoint');
    }, setStates: function (states) {
        set(function (state) {
            return __assign({ installationPoint: __assign(__assign(__assign(__assign({}, state.installationPoint), _get(states, 'speed', {})), _get(states, 'idle_threshold', {})), { systemAlertConditions: _get(states, 'systemAlertConditions', []) }) }, states);
        }, false, 'InstallationPointStoreSetStates');
    }, setHeatsink: function (isHeatsink) {
        set(function (state) {
            return {
                installationPoint: __assign(__assign({}, state.installationPoint), { is_heatsink: +isHeatsink }),
            };
        }, false, 'InstallationPointStoreSetHeatsink');
    }, resetToDefault: function () {
        set(initialState);
    } })); }, { name: 'InstallationPointStore' }));
var getCurrentSpeed = function (installationPoint) {
    var _a;
    if ((_a = installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.isAsset) {
        return +_get(installationPoint, 'equipment.assets.0.speedSetting.base_running_speed', 0);
    }
    if (installationPoint.equipment && installationPoint.speed > 1) {
        return +installationPoint.equipment.equipment_speed_type_id !== SPEED_TYPES.RATIO
            ? installationPoint.speed
            : installationPoint.speed * (installationPoint.speed_ratio || 1);
    }
    if (installationPoint.equipment && installationPoint.equipment.current_speed > 1) {
        return +installationPoint.equipment.equipment_speed_type_id !== SPEED_TYPES.RATIO
            ? installationPoint.equipment.current_speed
            : installationPoint.equipment.current_speed * (installationPoint.speed_ratio || 1);
    }
    return 0;
};
