import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { has as _has, head as _head } from 'lodash';
import Endpoints from '../../../../api/endpoints/endpoints';
import DefaultTooltip from '../../../../shared/components/DefaultTooltip';
import CustomerCreatedAi from '../../../../shared/components/dashboard/cards/CustomerCreatedAi';
import { onToggleHandle } from '../../../../shared/features/dashboard';
import { get } from '../../../../storage/tableColumn';
import { selectSelectedCustomers } from '../../../store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../store/SelectedLocationsStore';
var CustomerCreatedAiCardWrapper = function (_a) {
    var refreshFlag = _a.refreshFlag, setActualLayout = _a.setActualLayout, isSelected = _a.isSelected;
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var tableName = 'CustomerCreatedAiRemastered';
    var visibleState = get(tableName);
    var tableColumns = [
        {
            name: 'customer_name',
            visible: _has(visibleState, 'customer_name') ? visibleState === null || visibleState === void 0 ? void 0 : visibleState.customer_name : true,
            title: 'Customer',
            sortable: false,
            component: function (_a) {
                var customer_name = _a.customer_name, is_customer_request = _a.is_customer_request, id = _a.id;
                return (_jsxs("span", { className: "customer-name", children: [customer_name, !!is_customer_request && (_jsxs(_Fragment, { children: [_jsx("span", { className: "sub-badge", "data-tooltip-id": "customerRequestTag".concat(id), children: "CR" }), _jsx(DefaultTooltip, { place: "right", openEvents: { mouseenter: true }, id: "customerRequestTag".concat(id), children: "Customer's Request tag" })] }))] }));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [isSelected && (_jsxs(_Fragment, { children: [_jsx("div", { className: "draggable-handle" }), _jsx("div", { className: "hide-handle", onClick: function () { return onToggleHandle('hide', 'CustomerCreatedAi', setActualLayout); } })] })), _jsx(CustomerCreatedAi, { deps: [selectedCustomers, selectedLocations, refreshFlag], title: "Action Items - Customer Created", tableName: tableName, tableColumns: tableColumns, endpoint: function (_a) {
                    var hideHardwareAi = _a.hideHardwareAi, onlyCustomerRequest = _a.onlyCustomerRequest, sort = _a.sort;
                    return Endpoints.remastered.getDashboardCustomerCreatedActionList({
                        query: {
                            selected_customer_ids: selectedCustomers,
                            selected_location_id: _head(selectedLocations),
                            hide_hardware_ai: +hideHardwareAi,
                            is_customer_request: +onlyCustomerRequest,
                            sort: sort,
                            onlyActive: 1,
                        },
                    });
                } })] }));
};
export default CustomerCreatedAiCardWrapper;
