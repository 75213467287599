import { useQuery as useUntypedQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SYSTEM_TYPE_AMAZON } from '../../../constants';
import { SystemTypeContext } from '../../context/SystemTypeContext';
var useQuery = useUntypedQuery;
export var severityIncreaseQueryKey = function (_a) {
    var actionItemId = _a.actionItemId;
    return [
        'severityIncrease',
        { actionItemId: actionItemId },
    ];
};
export var severityIncreaseQueryFn = function (_a) {
    var isAmazon = _a.isAmazon, actionItemId = _a.query.actionItemId;
    var coreLink = isAmazon ? 'amazon-analytic' : 'api/analytic';
    var host = isAmazon ? undefined : APP_API_V2_URL;
    return Api.get("".concat(coreLink, "/action-item/").concat(actionItemId, "/severity-increase"), {
        host: host,
    });
};
export var useSeverityIncreaseQuery = function (_a) {
    var actionItemId = _a.actionItemId;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var isAmazon = selectedSystemType === SYSTEM_TYPE_AMAZON;
    return useQuery({
        queryKey: severityIncreaseQueryKey({ actionItemId: actionItemId }),
        queryFn: function () { return severityIncreaseQueryFn({ isAmazon: isAmazon, query: { actionItemId: actionItemId } }); },
        select: function (_a) {
            var data = _a.data, severityIncrease = _a.severityIncrease;
            return Boolean(isAmazon ? severityIncrease : data === null || data === void 0 ? void 0 : data.severityIncrease);
        },
        placeholderData: { data: { severityIncrease: false } },
        enabled: !!actionItemId,
    });
};
