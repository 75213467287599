import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import DefaultLightBox from '../../../../../../shared/components/DefaultLightBox';
import { SensorId } from '../../../../../../widgets/sensor';
import styles from './styles.module.scss';
export var InstallationPointItem = memo(function (_a) {
    var installationPoint = _a.installationPoint;
    var _b = useState(-1), index = _b[0], setIndex = _b[1];
    var activePhotos = installationPoint.equipmentPhotos
        .filter(function (photo) { return photo.selected === 1; })
        .map(function (el) { return el.url; });
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("div", { className: styles.left, children: [_jsx("b", { children: installationPoint.name }), _jsx(SensorId, { showDecId: false, sensor_id: installationPoint.sensorId })] }), _jsxs("div", { className: styles.right, children: [_jsx("span", { children: installationPoint.pointType === 'adapter' ? 'Adapter' : installationPoint.sensorPartNumber }), !!activePhotos.length && (_jsx("img", { src: activePhotos[0], style: { maxHeight: '60px', maxWidth: '60px' }, alt: "IP photo", onClick: function () { return setIndex(0); } }))] }), _jsx(DefaultLightBox, { photos: activePhotos, index: index, setIndex: setIndex })] }));
});
InstallationPointItem.displayName = 'InstallationPointItem';
