var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get, values as _values } from 'lodash';
import React, { memo, useState } from 'react';
import { FaCheckCircle, FaSpinner, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '@sharedStore/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '@sharedStore/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import { IMPACT_VUE_FFT_TWF_CHART_LIST } from '../../../constants';
import { useSetAssetRunningSpeedMutation, } from '../../../remastered/services/setRunningSpeed/useSetAssetRunningSpeedMutation';
import { getNearestFFTTimestampForAllAxesByDate } from '../../../shared/helper/chart';
var SetAssetRunningSpeedModal = function () {
    var _a = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
        setRunningSpeed: state.setRunningSpeed,
    }); }, shallow), setStatesChartFeaturesStore = _a.setStatesChartFeaturesStore, setRunningSpeed = _a.setRunningSpeed, cpm = setRunningSpeed.cpm, chartIdentifier = setRunningSpeed.chartIdentifier;
    var _b = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        setStatesInstallationPointStore: state.setStates,
        isRefreshFftReadings: state.isRefreshFftReadings,
    }); }, shallow), installationPoint = _b.installationPoint, setStatesInstallationPointStore = _b.setStatesInstallationPointStore, isRefreshFftReadings = _b.isRefreshFftReadings, equipment = _get(installationPoint, 'equipment') || {}, asset = _get(equipment, 'assets.0') || {};
    var _c = FftChartsStore(function (state) { return ({
        fftData: state.data,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), fftData = _c.fftData, fftTimestampDates = _c.fftTimestampDates;
    var _d = useSetAssetRunningSpeedMutation(), setAssetRunningSpeedMutate = _d.mutate, isPending = _d.isPending;
    var currentDate = _get(fftTimestampDates, chartIdentifier);
    var currentAxisTimestamps = getNearestFFTTimestampForAllAxesByDate(fftData.fftTimestamps, currentDate);
    var readingIds = currentAxisTimestamps.map(function (currentAxisTimestamp) { return currentAxisTimestamp.readingId; });
    var _e = useState({
        assetId: _get(installationPoint, 'equipment.assets.0.id') || null,
        isHFDvue: +IMPACT_VUE_FFT_TWF_CHART_LIST.includes(chartIdentifier),
        cpm: cpm ? cpm.toFixed(2) : '',
        orders: 1,
        installationPointId: installationPoint.id,
        readingIds: readingIds,
    }), data = _e[0], setData = _e[1];
    var onSave = function (setForAll) {
        setAssetRunningSpeedMutate(__assign(__assign({}, data), { setForAll: +setForAll }), {
            onSuccess: function (resp) {
                if (resp.success) {
                    setStatesInstallationPointStore({
                        isRefreshFftReadings: !isRefreshFftReadings,
                    });
                    toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                    return onCancel();
                }
                if (_values(resp.errors).length > 0) {
                    return toast.error(_values(resp.errors).join(' '), {
                        icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
                    });
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            },
            onError: function () {
                toast.error('Something went wrong!', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
            },
        });
    };
    var onCancel = function () {
        setStatesChartFeaturesStore({
            setRunningSpeed: {
                visible: false,
                cpm: null,
                chartIdentifier: null,
            },
        });
    };
    return (_jsxs(Modal, { className: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["RUNNING SPEED SETTINGS", isPending && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsxs(ModalBody, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Asset:" }) }), _jsx("p", { children: asset.name })] }) }) }), _jsx("hr", {}), _jsxs(Row, { children: [_jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "CPM Value:" }) }), _jsx(Input, { id: "cpm_value", name: "cpm_value", placeholder: "CPM Value", value: data.cpm, onChange: function (ev) { return setData(__assign(__assign({}, data), { cpm: ev.target.value })); } })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Order:" }) }), _jsx(Input, { id: "value_to_tach", name: "value_to_tach", placeholder: "Ratio Value to Tach", value: data.orders || 1, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { orders: +ev.target.value }));
                                            } })] }) })] })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { className: "response-ignore", children: [_jsx(Button, { size: "sm", color: "primary", disabled: isPending, onClick: function () { return onSave(false); }, children: "Save Current Data IP only" }), _jsx(Button, { size: "sm", color: "primary", disabled: isPending, onClick: function () { return onSave(true); }, children: "Save Current Data All" })] }) })] }));
};
export default memo(SetAssetRunningSpeedModal);
