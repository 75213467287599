import { jsx as _jsx } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, get as _get, last as _last } from 'lodash';
import React from 'react';
import { FaCheck, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
export var afterRemove = function (installationPointBearings, id) {
    return _cloneDeep(installationPointBearings).filter(function (bearing) { return +bearing.bearing.id !== +id; });
};
var afterUpdate = function (installationPointBearings, id, count) {
    return _cloneDeep(installationPointBearings).map(function (bearing) {
        if (+bearing.bearing_id === +id) {
            bearing.plot_lines_count = +count;
        }
        return bearing;
    });
};
export var afterCreate = function (installationPointBearings, data, id, installationPoint) {
    var lastBearing = _last(installationPointBearings) || {}, newBearingId = _get(lastBearing, 'id', Number(installationPoint.id) * +id) + 1, selectedBearing = data.find(function (item) { return +item.id === +id; }), newBearing = {
        id: newBearingId,
        installation_point_id: installationPoint.id,
        bearing_id: id,
        plot_lines_count: 100,
        bearing: selectedBearing,
    };
    var updatedInstallationPointBearings = _cloneDeep(installationPointBearings);
    updatedInstallationPointBearings.push(newBearing);
    return updatedInstallationPointBearings;
};
export var updateStatesAfterAction = function (setStatesFftChartsStore, updatedInstallationPointBearings) {
    setStatesFftChartsStore({
        installationPointBearings: updatedInstallationPointBearings,
    });
};
export var onCancel = function (setStatesChartFeaturesStore) {
    setStatesChartFeaturesStore({
        isVisibleManageBearings: false,
    });
};
export var onRemove = function (id, bearingRemoveMutation, setSmallLoader, setStatesFftChartsStore, installationPointBearings, setConfirmData, needUpdate) {
    if (needUpdate === void 0) { needUpdate = true; }
    var callback = function () {
        bearingRemoveMutation({ bearingId: id }, {
            onMutate: function () {
                setSmallLoader(true);
            },
            onSuccess: function (resp) {
                if (needUpdate) {
                    updateStatesAfterAction(setStatesFftChartsStore, afterRemove(installationPointBearings, id));
                }
                toast.success(resp.message || 'Successfully removed', {
                    icon: _jsx(FaTrash, { style: { fill: '#198754' } }),
                });
            },
            onSettled: function () {
                setSmallLoader(false);
            },
        });
    };
    if (!setConfirmData) {
        return callback();
    }
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Delete bearing?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            callback();
        },
    });
};
export var onAdd = function (id, setConfirmData, bearingAddMutation, setSmallLoader, setStatesFftChartsStore, installationPointBearings, list, installationPoint, needUpdate) {
    if (needUpdate === void 0) { needUpdate = true; }
    setConfirmData({
        isVisible: true,
        textConfirmationModal: 'Add bearing?',
        onConfirm: function () {
            setConfirmData({
                isVisible: false,
            });
            bearingAddMutation({ bearingId: id }, {
                onMutate: function () {
                    setSmallLoader(true);
                },
                onSuccess: function (resp) {
                    if (needUpdate) {
                        updateStatesAfterAction(setStatesFftChartsStore, afterCreate(installationPointBearings, list, id, installationPoint));
                    }
                    toast.success(resp.message || 'Successfully added', {
                        icon: _jsx(FaCheck, { style: { fill: '#198754' } }),
                    });
                },
                onSettled: function () {
                    setSmallLoader(false);
                },
            });
        },
    });
};
export var onPlotLinesCountChange = function (id, count, bearingUpdateMutation, setSmallLoader, setStatesFftChartsStore, installationPointBearings, needUpdate) {
    if (needUpdate === void 0) { needUpdate = true; }
    bearingUpdateMutation({ bearing: { id: id, plot_lines_count: count } }, {
        onMutate: function () {
            setSmallLoader(true);
        },
        onSuccess: function (resp) {
            if (needUpdate) {
                updateStatesAfterAction(setStatesFftChartsStore, afterUpdate(installationPointBearings, id, count));
            }
            toast.success(resp.message || 'Successfully updated', {
                icon: _jsx(FaCheck, { style: { fill: '#198754' } }),
            });
        },
        onSettled: function () {
            setSmallLoader(false);
        },
    });
};
export var onCustomBearingButton = function (selectedSystemType, installationPoint, setStatesChartFeaturesStore, bearing) {
    if (bearing === void 0) { bearing = {}; }
    setStatesChartFeaturesStore({
        customBearingModal: {
            visible: true,
            bearing: bearing,
            extraData: selectedSystemType === 'remastered'
                ? { customer_id: installationPoint.customer_id }
                : { facility: installationPoint.facility },
        },
    });
};
