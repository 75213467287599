var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { each as _each, get as _get, merge as _merge, size as _size } from 'lodash';
import moment from 'moment';
import React, { memo, useMemo } from 'react';
import { FaRegClone, FaRegSquare, FaSquare, FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '@sharedStore/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '@sharedStore/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '@sharedStore/charts/chartsContent/FftChartsStore';
import { selectChartZoomDays } from '@sharedStore/charts/useChartSettingsStore';
import { CHART_DATA_GROUPS } from '../../../../../../constants';
import { clearAllTooltips } from '../../../../../helper/commonHelper';
import '../../../../../styles/charts/chartsWrapper/fftShapes.scss';
import { fetchFftData } from '../../../chartsWrapper/components/shared/chartToolsButtons/FftTwfCalendar';
var FftShapesButton = function () {
    var setStatesChartsStore = ChartsStore(function (state) { return ({
        setStatesChartsStore: state.setStates,
    }); }, shallow).setStatesChartsStore;
    var data = FftChartsStore(function (state) { return state.data; });
    // @ts-ignore
    var fftTimestamps = data.fftTimestamps;
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; });
    var customStyles = useMemo(function () { return ({
        control: function (provided) { return (__assign(__assign({}, provided), { minHeight: '30px', height: '30px', width: '58px', fontSize: '14px' })); },
    }); }, []);
    var chartZoomDays = selectChartZoomDays();
    var onToggle = function (type) {
        if (chartZoomDays > 62) {
            return toast.error('Zoom should be 2 months or less', {
                icon: _jsx(FaTimes, { style: { fill: '#198754' } }),
            });
        }
        setStatesChartsStore({
            isVisibleFftShapes: type !== null,
            fftShapesType: type === 'selectable' ? 'selectable' : 'regular',
        });
        clearAllTooltips();
        setStatesChartFeaturesStore({
            waterfallPlotModal: {
                visible: false,
                chartIdentifier: null,
                selectedUnits: 'Hz',
            },
        });
    };
    var FFT_SHAPE_TYPE_OPTIONS = [
        {
            value: null,
            label: (_jsx(_Fragment, { children: _jsx(FaRegClone, { id: "fft-shape-none", style: { marginTop: '-4px', width: '100%' } }) })),
        },
        {
            value: 'regular',
            label: (_jsx(_Fragment, { children: _jsx(FaSquare, { id: "fft-shape-regular", style: { marginTop: '-4px', width: '100%' } }) })),
        },
        {
            value: 'selectable',
            label: (_jsx(_Fragment, { children: _jsx(FaRegSquare, { id: "fft-shape-selectable", style: { marginTop: '-4px', width: '100%' } }) })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Select, { isDisabled: _size(fftTimestamps) === 0, className: "select-custom-default", styles: customStyles, id: "fft-shapes-button", isSearchable: false, classNamePrefix: "customChartTopBarSelect", options: FFT_SHAPE_TYPE_OPTIONS, onChange: function (ev) { return onToggle(ev.value); }, defaultValue: FFT_SHAPE_TYPE_OPTIONS[0] }), _jsx(UncontrolledTooltip, { trigger: "hover", target: "fft-shapes-button", children: "FFT Acquisition Times" })] }));
};
export default memo(FftShapesButton);
export var getFftShapesAnnotationRegular = function (fftTimestampsData, chart, chartZoomDays, installationPoint, setStatesFftChartsStore, data, readings, fftTimestampDates, selectedSystemType, interpolationDisabled, measure, setCurrentSpeed) {
    var annotations = [];
    if (!fftTimestampsData) {
        return;
    }
    if (!(Object.values(fftTimestampsData[1]) || []).length) {
        return;
    }
    Object.values(fftTimestampsData[1]).map(function (fftTimestamps, indexDate) {
        fftTimestamps.map(function (fftTimestamp, index) {
            var date = Object.keys(fftTimestampsData[1])[indexDate], x = moment.utc(fftTimestamp.fftShapeTimestamp).valueOf(), x2 = moment.utc(fftTimestamp.fftShapeTimestamp).add(3, 'hours').valueOf(), y = chart.yAxis[0].min + (chart.yAxis[0].max - chart.yAxis[0].min) / 20, y2 = chart.yAxis[0].min + (chart.yAxis[0].max - chart.yAxis[0].min) / 10;
            if (x >= moment().subtract(chartZoomDays, 'days').valueOf()) {
                annotations.push({
                    id: 'fft_labels_' + chart.userOptions.chartIdentifier + '_' + fftTimestamp.readingFFT_id,
                    type: 'fft_labels',
                    draggable: '',
                    animation: false,
                    fftOptions: {
                        1: {
                            fftTimestamp: fftTimestamp,
                            index: index,
                        },
                        2: {
                            fftTimestamp: _get(fftTimestampsData[2], [date, index], {}),
                            index: index,
                        },
                        3: {
                            fftTimestamp: _get(fftTimestampsData[3], [date, index], {}),
                            index: index,
                        },
                    },
                    labels: {
                        allowOverlap: true,
                        shape: 'rect',
                        text: ' ',
                        className: 'fft-labels',
                        backgroundColor: +fftTimestamp.is_on_demand === 1 ? '#147900' : '#dc3545',
                        borderColor: +fftTimestamp.is_on_demand === 1 ? '#147900' : '#dc3545',
                        points: [
                            { x: x, y: y, xAxis: 0, yAxis: 0 },
                            { x: x, y: y2, xAxis: 0, yAxis: 0 },
                            { x: x2, y: y2, xAxis: 0, yAxis: 0 },
                            { x: x2, y: y, xAxis: 0, yAxis: 0 },
                        ],
                    },
                    events: {
                        click: function () {
                            // @ts-ignore
                            document.getElementById('chart-107').scrollIntoView({
                                block: 'center',
                                behavior: 'smooth',
                            });
                            // @ts-ignore
                            var fftOptions = this.userOptions.fftOptions;
                            var newDates = {};
                            _each(fftOptions, function (option, group) {
                                var chartGroup = CHART_DATA_GROUPS[group];
                                chartGroup.map(function (chart) {
                                    // @ts-ignore
                                    newDates[chart] = _get(option, 'fftTimestamp.timestamp');
                                });
                            });
                            setStatesFftChartsStore({
                                fftTimestampDates: __assign(__assign({}, fftTimestampDates), newDates),
                                isChangedCalendar: true,
                            });
                            var readingFftIds = Object.values(fftOptions)
                                .map(function (fftOption) { return +fftOption.fftTimestamp.readingFFT_id; })
                                .filter(function (item) { return item; });
                            fetchFftData(readingFftIds, installationPoint, setStatesFftChartsStore, data, readings, selectedSystemType, measure, false, interpolationDisabled, setCurrentSpeed);
                        },
                    },
                    zIndex: 10,
                });
            }
        });
    });
    return annotations;
};
export var getFftShapesAnnotationSelectable = function (fftTimestampsData, chart, chartZoomDays, setWaterfallPlotModalReadings, readingTypes) {
    var annotations = [];
    var chartIdentifier = chart.userOptions.chartIdentifier;
    var axisId = _get(readingTypes, [chartIdentifier, 'axisId']);
    if (!fftTimestampsData || !axisId) {
        return;
    }
    if (!(Object.values(fftTimestampsData[axisId]) || []).length) {
        return;
    }
    Object.values(fftTimestampsData[axisId]).map(function (fftTimestamps) {
        fftTimestamps.map(function (fftTimestamp) {
            var x = moment.utc(fftTimestamp.fftShapeTimestamp).valueOf(), x2 = moment.utc(fftTimestamp.fftShapeTimestamp).add(3, 'hours').valueOf(), y = chart.yAxis[0].min + (chart.yAxis[0].max - chart.yAxis[0].min) / 20, y2 = chart.yAxis[0].min + (chart.yAxis[0].max - chart.yAxis[0].min) / 10;
            if (x >= moment().subtract(chartZoomDays, 'days').valueOf()) {
                annotations.push({
                    id: 'fft_labels_' + chart.userOptions.chartIdentifier + '_' + fftTimestamp.readingFFT_id,
                    type: 'fft_labels',
                    draggable: '',
                    animation: false,
                    fftTimestamp: fftTimestamp,
                    labels: [
                        {
                            allowOverlap: true,
                            shape: 'rect',
                            text: "<span class='fft-labels-shape " +
                                (+fftTimestamp.is_on_demand === 1 ? 'on-demand' : '') +
                                "'></span>",
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            className: 'fft-labels',
                            useHTML: true,
                            isSelected: false,
                            points: [
                                { x: x, y: y, xAxis: 0, yAxis: 0 },
                                { x: x, y: y2, xAxis: 0, yAxis: 0 },
                                { x: x2, y: y2, xAxis: 0, yAxis: 0 },
                                { x: x2, y: y, xAxis: 0, yAxis: 0 },
                            ],
                        },
                    ],
                    events: {
                        click: function () {
                            var _a, _b, _c, _d, _e, _f;
                            var readingFFT_id = (_b = (_a = this === null || this === void 0 ? void 0 : this.userOptions) === null || _a === void 0 ? void 0 : _a.fftTimestamp) === null || _b === void 0 ? void 0 : _b.readingFFT_id;
                            if (!readingFFT_id) {
                                return;
                            }
                            if (setWaterfallPlotModalReadings(readingFFT_id, chartIdentifier, fftTimestampsData[axisId])) {
                                this.update({
                                    labels: [
                                        _merge({}, this.labels[0].options, {
                                            text: "<span class='fft-labels-shape " +
                                                (+fftTimestamp.is_on_demand === 1 ? ' on-demand' : '') +
                                                "'>" +
                                                (((_d = (_c = this.labels[0]) === null || _c === void 0 ? void 0 : _c.options) === null || _d === void 0 ? void 0 : _d.isSelected) ? '' : "<span class='selected-shape'></span>") +
                                                '</span>',
                                            isSelected: !((_f = (_e = this.labels[0]) === null || _e === void 0 ? void 0 : _e.options) === null || _f === void 0 ? void 0 : _f.isSelected),
                                        }),
                                    ],
                                });
                            }
                        },
                    },
                    zIndex: 10,
                });
            }
        });
    });
    return annotations;
};
export var clearFftShapes = function (options) {
    return _size(options.annotations)
        ? options.annotations.filter(function (annotation) { return annotation.type !== 'fft_labels'; })
        : [];
};
