import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FaBezierCurve, FaTimes } from 'react-icons/fa';
import DefaultTooltip from '../../../../shared/components/DefaultTooltip';
import { selectIsAssetBuilderMode, useAssetBuilderActions, } from '../AssetBuilder/stores/useAssetBuilderStore';
import styles from './styles.module.scss';
export var AssetBuilderModeToggle = function () {
    var isAssetBuilderMode = selectIsAssetBuilderMode();
    var _a = useAssetBuilderActions(), setIsAssetBuilderMode = _a.setIsAssetBuilderMode, setComponentId = _a.setComponentId;
    return (_jsxs(_Fragment, { children: [_jsx("div", { "data-tooltip-id": "toggle-asset-builder-mode", className: styles.toggleAssetBuilderMode, onClick: function () {
                    setIsAssetBuilderMode(!isAssetBuilderMode);
                    setComponentId(null);
                }, children: isAssetBuilderMode ? _jsx(FaTimes, {}) : _jsx(FaBezierCurve, {}) }), isAssetBuilderMode ? (_jsx(_Fragment, {})) : (_jsx(DefaultTooltip, { style: { zIndex: 1000 }, openEvents: { mouseenter: true }, place: "left", id: "toggle-asset-builder-mode", children: "Asset Builder Mode" }))] }));
};
AssetBuilderModeToggle.displayName = 'AssetBuilderModeToggle';
