import { createStore } from '@sharedStore/createStore';
var useActionItemsListStore = createStore(function (set) { return ({
    isActive: true,
    hideHardwareAi: true,
    onlyHardwareAi: false,
    selectedStatuses: {},
    selectedAiType: '',
    selectedCustomers: [],
    selectedLocation: undefined,
    selectedFacility: undefined,
    installationPointId: undefined,
    increaseSeverity: false,
    sort: [
        {
            field: 'updated_at',
            direction: 'desc',
        },
    ],
    page: 1,
    pageSize: 20,
    watchingFilter: undefined,
    search: '',
    advancedSearch: false,
    urgency: undefined,
    readingType: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    actions: {
        setSearch: function (search) { return set({ search: search }); },
        setPage: function (page) { return set({ page: page }); },
        setPageSize: function (pageSize) { return set({ pageSize: pageSize }); },
        setSort: function (sort) { return set({ sort: sort }); },
        setIsActive: function (isActive) { return set({ isActive: isActive }); },
        setHideHardwareAi: function (hideHardwareAi) { return set({ hideHardwareAi: hideHardwareAi }); },
        setOnlyHardwareAi: function (onlyHardwareAi) { return set({ onlyHardwareAi: onlyHardwareAi }); },
        setSelectedStatuses: function (selectedStatuses) { return set({ selectedStatuses: selectedStatuses }); },
        setSelectedAiType: function (selectedAiType) { return set({ selectedAiType: selectedAiType }); },
        setSelectedCustomers: function (selectedCustomers) { return set({ selectedCustomers: selectedCustomers }); },
        setSelectedLocation: function (selectedLocation) { return set({ selectedLocation: selectedLocation }); },
        setSelectedFacility: function (selectedFacility) { return set({ selectedFacility: selectedFacility }); },
        setInstallationPointId: function (installationPointId) { return set({ installationPointId: installationPointId }); },
        setIncreaseSeverity: function (increaseSeverity) { return set({ increaseSeverity: increaseSeverity }); },
        setWatchingFilter: function (watchingFilter) { return set({ watchingFilter: watchingFilter }); },
        setAdvancedSearch: function (advancedSearch) { return set({ advancedSearch: advancedSearch }); },
        setUrgency: function (urgency) { return set({ urgency: urgency }); },
        setReadingType: function (readingType) { return set({ readingType: readingType }); },
        setDateFrom: function (dateFrom) { return set({ dateFrom: dateFrom }); },
        setDateTo: function (dateTo) { return set({ dateTo: dateTo }); },
    },
}); });
export var useActionItemsListDataTableActions = function () {
    return useActionItemsListStore(function (state) { return state.actions; });
};
export var selectSearchDataTable = function () {
    return useActionItemsListStore(function (state) { return state.search; });
};
export var selectPageDataTable = function () {
    return useActionItemsListStore(function (state) { return state.page; });
};
export var selectPageSizeDataTable = function () {
    return useActionItemsListStore(function (state) { return state.pageSize; });
};
export var selectSortDataTable = function () {
    return useActionItemsListStore(function (state) { return state.sort; });
};
export var selectIsActive = function () {
    return useActionItemsListStore(function (state) { return state.isActive; });
};
export var selectHideHardwareAi = function () {
    return useActionItemsListStore(function (state) { return state.hideHardwareAi; });
};
export var selectOnlyHardwareAi = function () {
    return useActionItemsListStore(function (state) { return state.onlyHardwareAi; });
};
export var selectSelectedStatuses = function () {
    return useActionItemsListStore(function (state) { return state.selectedStatuses; });
};
export var selectSelectedAiType = function () {
    return useActionItemsListStore(function (state) { return state.selectedAiType; });
};
export var selectSelectedCustomers = function () {
    return useActionItemsListStore(function (state) { return state.selectedCustomers; });
};
export var selectSelectedLocation = function () {
    return useActionItemsListStore(function (state) { return state.selectedLocation; });
};
export var selectSelectedFacility = function () {
    return useActionItemsListStore(function (state) { return state.selectedFacility; });
};
export var selectInstallationPointId = function () {
    return useActionItemsListStore(function (state) { return state.installationPointId; });
};
export var selectIncreaseSeverity = function () {
    return useActionItemsListStore(function (state) { return state.increaseSeverity; });
};
export var selectWatchingFilter = function () {
    return useActionItemsListStore(function (state) { return state.watchingFilter; });
};
export var selectAdvancedSearch = function () {
    return useActionItemsListStore(function (state) { return state.advancedSearch; });
};
export var selectUrgency = function () {
    return useActionItemsListStore(function (state) { return state.urgency; });
};
export var selectReadingType = function () {
    return useActionItemsListStore(function (state) { return state.readingType; });
};
export var selectDateFrom = function () {
    return useActionItemsListStore(function (state) { return state.dateFrom; });
};
export var selectDateTo = function () {
    return useActionItemsListStore(function (state) { return state.dateTo; });
};
