// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__K4vIE {
  height: 36px;
  display: grid !important;
  grid-template-columns: 3.5fr 3fr 2fr 2fr 3fr;
  grid-gap: 16px;
  margin-bottom: 12px;
}
.wrapper__K4vIE .selectBlock___ykM3 {
  display: flex;
  height: 100%;
  gap: 4px;
}
.wrapper__K4vIE .selectBlock___ykM3 > div {
  display: flex;
  align-items: center;
}
.wrapper__K4vIE .selectBlock___ykM3 input {
  width: 70px;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/InstallationPointsSelects/components/InstallationPointsSelect/Bearings/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,wBAAA;EACA,4CAAA;EACA,cAAA;EACA,mBAAA;AACD;AAAC;EACC,aAAA;EACA,YAAA;EACA,QAAA;AAEF;AADE;EACC,aAAA;EACA,mBAAA;AAGH;AADE;EACC,WAAA;AAGH","sourcesContent":[".wrapper {\n\theight: 36px;\n\tdisplay: grid!important;\n\tgrid-template-columns: 3.5fr 3fr 2fr 2fr 3fr;\n\tgrid-gap: 16px;\n\tmargin-bottom: 12px;\n\t.selectBlock {\n\t\tdisplay: flex;\n\t\theight: 100%;\n\t\tgap: 4px;\n\t\t& > div {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t}\n\t\t& input {\n\t\t\twidth: 70px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__K4vIE`,
	"selectBlock": `selectBlock___ykM3`
};
module.exports = ___CSS_LOADER_EXPORT___;
