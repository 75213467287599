import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { BearingMonitoredPositions } from '../../../../../../../BearingMonitoredPositions';
import { BearingType } from '../../../../../../../BearingType';
import { ComponentOrientation } from '../../../../../../../ComponentOrientation';
import { FixedHammerLugs } from '../../../../../../../FixedHammerLugs';
import { KeyedPlateAssembly } from '../../../../../../../KeyedPlateAssembly';
import { NumberOfRowsOfHammers } from '../../../../../../../NumberOfRowsOfHammers';
import { Pivoting } from '../../../../../../../Pivoting';
import { Rotor } from '../../../../../../../Rotor';
import { SolidPlateAssembly } from '../../../../../../../SolidPlateAssembly';
import styles from '../../styles.module.scss';
export var HummerMillContent = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.componentOrientationAndBearing, children: [_jsx(ComponentOrientation, { isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.componentOrientation' }), _jsx(BearingType, { isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.bearingType' })] }), _jsx(BearingMonitoredPositions, { control: control, isDisabled: isDisabled, bearingMonitoredPositionsFieldName: 'data.drivenTypeVariant.bearingMonitoredPositions', bearingMonitoredPositionsVariantFieldName: 'data.drivenTypeVariant.bearingMonitoredPositionsVariant', equipmentId: equipmentId, schemas: [[entryBoxNumber + 1, entryBoxNumber + 2]], hasGap: true }), _jsx("div", { className: styles.positionThreeColumn, children: _jsx(NumberOfRowsOfHammers, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.numberOfRowsOfHammers' }) }), _jsxs("div", { className: styles.positionThreeColumn, children: [_jsx(Rotor, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.rotor' }), _jsx(FixedHammerLugs, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.fixedHammer' }), _jsx(Pivoting, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.pivoting' }), _jsx(SolidPlateAssembly, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.solidPlateAssembly' }), _jsx(KeyedPlateAssembly, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.keyedPlateAssembly' })] })] }));
});
HummerMillContent.displayName = 'HummerMillContent';
