import { size as _size } from 'lodash';
import moment from 'moment';
import { ACTION_LIST } from '../../../../../../../constants';
export var getPlotLines = function (installationPointLastSeen, chartMarks, xAxisData, chart_zoom_days, hideChartMarks, customZoomExtremes) {
    var max = xAxisData.max || (customZoomExtremes ? customZoomExtremes.max : null) || null;
    var min = xAxisData.min || (customZoomExtremes ? customZoomExtremes.min : null) || null;
    var mediumTimestamp = xAxisData && max && min ? +((max - min) / 2 + min).toFixed() : null;
    if (hideChartMarks) {
        return [];
    }
    var plotLines = [];
    if (installationPointLastSeen) {
        var installationPointLastSeenLocal = +moment(moment.utc(installationPointLastSeen).toDate())
            .local()
            .format('X');
        plotLines.push(lastSeenPlotLine(installationPointLastSeenLocal * 1000));
    }
    if (_size(chartMarks) === 0) {
        return plotLines;
    }
    var plotLinesMapping = {
        11: badDataPlotLine,
        14: actionItemPlotLine,
        15: actionItemPlotLine,
        30: increasedSeverityPlotLine,
    };
    var y = 0;
    chartMarks.forEach(function (chartMark) {
        if (y > 4)
            y = 0;
        var lineCommentBlock = "<span class=\"line-comment-block\">".concat(chartMark.comment, "</span>"), plotLineFunction = plotLinesMapping[+chartMark.type] || defaultPlotLine, directionClass = getDirectionClass(chartMark.timestamp, mediumTimestamp);
        plotLines.push(plotLineFunction(chartMark, lineCommentBlock, directionClass, y));
        y++;
    });
    return plotLines;
};
var getDirectionClass = function (timestamp, mediumTimestamp) {
    if (!mediumTimestamp) {
        return 'right-direction';
    }
    return timestamp > mediumTimestamp ? 'left-direction' : 'right-direction';
};
var lastSeenPlotLine = function (installationPointLastSeen) {
    return {
        className: 'last-seen-line',
        color: 'grey',
        width: 2,
        dashStyle: 'Dot',
        value: installationPointLastSeen,
        zIndex: 2,
        label: {
            text: 'last seen',
            rotation: 90,
            y: 75,
            x: 2,
            style: {
                color: '#000',
            },
        },
    };
};
var defaultPlotLine = function (chartMark, lineCommentBlock, directionClass, y) {
    if (y === void 0) { y = 0; }
    var icon = "<i class='fa fa-info'></i>";
    if (chartMark.comment.toLowerCase().includes('settings')) {
        icon = "<span class='chart-mark-icon'>S</span>";
    }
    if (chartMark.comment.toLowerCase().includes('update')) {
        icon = "<span class='chart-mark-icon'>U</span>";
    }
    if (chartMark.comment.toLowerCase().includes('replace')) {
        icon = "<span class='chart-mark-icon'>R</span>";
    }
    return {
        width: 2,
        value: chartMark.timestamp,
        zIndex: 'auto',
        dashStyle: 'Dot',
        color: 'orange',
        label: {
            useHTML: true,
            text: "<div class=\"normal-wrapped-label line-label-block mirror-label mirror-label-info ".concat(directionClass, "\"><span class=\"default-line-label\">").concat(icon, "</span>").concat(lineCommentBlock, "</div>"),
            rotation: 0,
            y: y * 30,
        },
    };
};
var badDataPlotLine = function (chartMark, lineCommentBlock, directionClass, y) {
    if (y === void 0) { y = 0; }
    return {
        width: 2,
        value: chartMark.timestamp,
        zIndex: 2,
        dashStyle: 'Dot',
        color: 'red',
        label: {
            useHTML: true,
            text: "<div class=\"normal-wrapped-label line-label-block mirror-label bad-data-line ".concat(directionClass, "\"><span class=\"default-line-label\"><i class=\"fa fa-bug\"></i></span>").concat(lineCommentBlock, "</div>"),
            rotation: 0,
            y: y * 30,
        },
    };
};
var increasedSeverityPlotLine = function (chartMark, lineCommentBlock, directionClass, y) {
    if (y === void 0) { y = 0; }
    return {
        width: 2,
        value: chartMark.timestamp,
        zIndex: 2,
        dashStyle: 'Dot',
        color: 'black',
        label: {
            useHTML: true,
            text: "<div class=\"normal-wrapped-label line-label-block mirror-label increased-severity ".concat(directionClass, "\"><span class=\"default-line-label\">IS</span>").concat(lineCommentBlock, "</div>"),
            rotation: 0,
            y: y * 30,
        },
    };
};
var actionItemPlotLine = function (chartMark, lineCommentBlock, directionClass, y) {
    if (y === void 0) { y = 0; }
    return {
        width: 2,
        value: chartMark.timestamp,
        zIndex: 2,
        dashStyle: 'Dot',
        color: 'red',
        label: {
            useHTML: true,
            text: "<div class=\"normal-wrapped-label line-label-block mirror-label no-data-line ".concat(directionClass, " ").concat(chartMark.ai_type === ACTION_LIST.AI_TYPE_WW ? 'hardware' : 'regular', "\"><span class=\"default-line-label\"><i class=\"fa fa-lightbulb-o\"></i></span>").concat(lineCommentBlock, "</div>"),
            rotation: 0,
            y: y * 30,
        },
    };
};
