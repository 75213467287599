import { size as _size } from 'lodash';
import { isNumeric } from '../../../../../shared/helper/commonHelper';
import { DEFAULT_ALERT_CONDITION } from './constants';
var getDefaultAlertLevels = function () { return ({
    caution: structuredClone(DEFAULT_ALERT_CONDITION),
    warning: structuredClone(DEFAULT_ALERT_CONDITION),
}); };
export var transformLevelsObjectToArray = function (object, isAmazon) {
    if (_size(object.conditions)) {
        var pairs = Object.keys(object.conditions).map(function (key) {
            var levels = getDefaultAlertLevels();
            Object.keys(object.conditions[key]).forEach(function (level) {
                var _a, _b;
                var levelKey = level;
                levels[levelKey] = {
                    id: key,
                    conditionId: isAmazon ? +key : object.ids[key][level] || null,
                    condition: object.conditions[key][level],
                    fftSettings: object.fftSettings[key][level],
                    isRequestFFT: Number(Object.values(object.fftSettings[key][level]).every(Boolean)),
                    level: String((_a = object.storedLevels[key][level]) !== null && _a !== void 0 ? _a : ''),
                    settings: object.settings[key][level],
                    order: isAmazon ? null : (_b = object.orders[key][level]) !== null && _b !== void 0 ? _b : null,
                };
                var levelValue = object.currentLevel[levelKey];
                if (isNumeric(levels[levelKey].level)) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    levels[levelKey].level = Number(levels[levelKey].level).toFixed(2).toString();
                }
                if (object.currentLevel.id === key && isNumeric(levelValue)) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    levels[levelKey].level = Number(levelValue).toFixed(2).toString();
                }
            });
            return levels;
        });
        if (isAmazon) {
            return pairs;
        }
        // check if there are serial numbers
        if (pairs.every(function (_a) {
            var caution = _a.caution, warning = _a.warning;
            return caution.order || warning.order;
        })) {
            var firstPair_1 = getDefaultAlertLevels();
            var secondPair_1 = getDefaultAlertLevels();
            pairs.forEach(function (item) {
                Object.keys(item).forEach(function (key) {
                    var alertLevelKey = key;
                    var order = item[alertLevelKey].order || 0;
                    if (order) {
                        if (order < 3) {
                            firstPair_1[alertLevelKey] = item[alertLevelKey];
                        }
                        else {
                            secondPair_1[alertLevelKey] = item[alertLevelKey];
                        }
                    }
                });
            });
            return [firstPair_1, secondPair_1].filter(function (_a) {
                var caution = _a.caution, warning = _a.warning;
                return caution.id || warning.id;
            });
        }
        else {
            return pairs;
        }
    }
    var alertLevels = {
        caution: structuredClone(DEFAULT_ALERT_CONDITION),
        warning: structuredClone(DEFAULT_ALERT_CONDITION),
    };
    var _a = object.currentLevel, caution = _a.caution, warning = _a.warning;
    if (isNumeric(caution) && alertLevels.caution) {
        alertLevels.caution.level = Number(caution).toFixed(2);
    }
    if (isNumeric(caution) && alertLevels.warning) {
        alertLevels.warning.level = Number(warning).toFixed(2);
    }
    return [alertLevels];
};
export var transformLevelsArrayToObject = function (array) {
    return array.reduce(function (acc, alertLevel) {
        var _a;
        var caution = alertLevel.caution, warning = alertLevel.warning;
        var alertLevelId = (caution === null || caution === void 0 ? void 0 : caution.id) || (warning === null || warning === void 0 ? void 0 : warning.id) || Math.random().toString();
        if (!acc.conditions) {
            acc.conditions = {};
        }
        if (!((_a = acc.conditions) === null || _a === void 0 ? void 0 : _a[alertLevelId])) {
            acc.conditions[alertLevelId] = {};
            acc.levels[alertLevelId] = {};
        }
        if (caution) {
            acc.conditions[alertLevelId].caution = caution.condition;
            acc.levels[alertLevelId].caution = caution.level;
        }
        if (warning) {
            acc.conditions[alertLevelId].warning = warning.condition;
            acc.levels[alertLevelId].warning = warning.level;
        }
        return acc;
    }, {
        conditions: {},
        levels: {},
    });
};
