import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'clsx';
import { memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ASSET_BEARING_TYPES } from '../../../../constants';
import assetBuilderStyles from '../../styles.module.scss';
export var BearingType = memo(function (_a) {
    var _b;
    var isDisabled = _a.isDisabled, fieldName = _a.fieldName;
    var _c = useFormContext(), clearErrors = _c.clearErrors, control = _c.control;
    var _d = useController({
        name: fieldName,
        control: control,
    }), _e = _d.field, bearingType = _e.value, onChangeBearingType = _e.onChange, error = _d.fieldState.error;
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Bearing Type" }) }), _jsx("div", { className: cx(assetBuilderStyles.sectionButtons, (_b = {},
                    _b[assetBuilderStyles.errorBorder] = !!(error === null || error === void 0 ? void 0 : error.message),
                    _b)), children: ASSET_BEARING_TYPES.map(function (type) {
                    var _a;
                    return (_jsx("button", { disabled: isDisabled, onClick: function () {
                            clearErrors(fieldName);
                            onChangeBearingType(type);
                        }, className: cx(assetBuilderStyles.button, (_a = {},
                            _a[assetBuilderStyles.active] = bearingType === type,
                            _a)), children: type }, type));
                }) }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: error === null || error === void 0 ? void 0 : error.message })] }));
});
BearingType.displayName = 'BearingType';
