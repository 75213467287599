import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import assetBuilderStyles from '../../../../styles.module.scss';
import styles from './styles.module.scss';
export var AdditionalInfo = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled;
    var _b = useController({
        name: 'data.manufacturer',
        control: control,
    }).field, manufacturerValue = _b.value, onChangeManufacturer = _b.onChange;
    var _c = useController({
        name: 'data.model',
        control: control,
    }).field, modelValue = _c.value, onChangeModel = _c.onChange;
    var _d = useController({
        name: 'data.notes',
        control: control,
    }).field, notesValue = _d.value, onChangeNotes = _d.onChange;
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Manufacturer" }), _jsx("input", { disabled: isDisabled, type: "text", className: assetBuilderStyles.input, value: manufacturerValue !== null && manufacturerValue !== void 0 ? manufacturerValue : '', onChange: function (e) { return onChangeManufacturer(e.target.value); } })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Model" }), _jsx("input", { disabled: isDisabled, type: "text", className: assetBuilderStyles.input, value: modelValue !== null && modelValue !== void 0 ? modelValue : '', onChange: function (e) { return onChangeModel(e.target.value); } })] }), _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Notes" }), _jsx("input", { disabled: isDisabled, type: "text", className: assetBuilderStyles.input, value: notesValue !== null && notesValue !== void 0 ? notesValue : '', onChange: function (e) { return onChangeNotes(e.target.value); } })] })] }));
});
AdditionalInfo.displayName = 'AdditionalInfo';
