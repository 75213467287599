import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../../../api/api';
import { AssetTreeQueryKey } from './useAssetTreeQuery';
import { AssignedPointsQueryKey } from './useAssignedPointsQuery';
import { UnassignedPointsQueryKey } from './useUnassignedPointsQuery';
var assetCreateFn = function (equipmentId, body) {
    return Api.post("all-analytics/equipment/".concat(equipmentId, "/asset/config/batch-create"), body);
};
export var useAssetCreateMutation = function (_a) {
    var equipmentId = _a.equipmentId;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (body) { return assetCreateFn(equipmentId, body); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: AssetTreeQueryKey({ equipmentId: equipmentId }) });
            queryClient.invalidateQueries({ queryKey: UnassignedPointsQueryKey({ equipmentId: equipmentId }) });
            queryClient.invalidateQueries({ queryKey: AssignedPointsQueryKey({ equipmentId: equipmentId }) });
        },
    });
};
