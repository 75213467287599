var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get as _get } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useOnScreen } from '../../../../customHooks';
import { ChartFeaturesStore } from '../../../store/charts/chartsContent/ChartFeaturesStore';
import { FftImpactVueChartsStore } from '../../../store/charts/chartsContent/FftImpactVueChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
import { useAutoCorrelation } from '../fftTwf/useAutoCorrelation';
import { useCircleType } from '../fftTwf/useCircleType';
import { useFFDetection } from '../fftTwf/useFFDetection';
import { useFaultFrequencies } from '../fftTwf/useFaultFrequencies';
import { useCustomCursorPointsCount, useFftCursor, useHarmoniCursorByPoint } from '../fftTwf/useFftCursor';
import { useFftTwfSizeChartsEffect } from '../fftTwf/useFftTwfSizeCharts';
import { useLoader } from '../fftTwf/useLoader';
import { useLockedCursor } from '../fftTwf/useLockedCursor';
import { useMainSeriesLoaded } from '../fftTwf/useMainSeriesLoaded';
import { usePlotlines } from '../fftTwf/usePlotlines';
import { useResetCursorsOnAllCharts } from '../fftTwf/useResetCursorsOnAllCharts';
import { useResetCursorsOnChart } from '../fftTwf/useResetCursorsOnChart';
import { useSetOptions } from '../fftTwf/useSetOptions';
import { useTooltip } from '../fftTwf/useTooltip';
import { useUnits } from '../fftTwf/useUnits';
import { useAxisLabel } from '../shared/useAxisLabel';
import { useMessageOnChart } from '../shared/useMessageOnChart';
import { useResetChartSelection } from '../shared/useResetChartSelection';
import { useResetToolsToDefault } from '../shared/useResetToolsToDefault';
import { useSelectProblemChart } from '../shared/useSelectProblemChart';
export var useFftTwfHooks = function (state, dispatch, chartIdentifier, chartRef, chartContainerRef) {
    var _a;
    var _b = FftImpactVueChartsStore(function (state) { return ({
        loader: state.loader,
        data: state.data,
        readings: state.readings,
        fftTimestampDates: state.fftTimestampDates,
        isChangedCalendar: state.isChangedCalendar,
        fftSelectedDate: state.fftSelectedDate,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
    }); }, shallow), loader = _b.loader, data = _b.data, readings = _b.readings, fftTimestampDates = _b.fftTimestampDates, isChangedCalendar = _b.isChangedCalendar, fftSelectedDate = _b.fftSelectedDate, setStatesFftChartsStore = _b.setStatesFftChartsStore;
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; });
    var interpolationDisabled = ChartFeaturesStore(function (state) { return state.interpolationDisabled; });
    var isVisible = useOnScreen(chartContainerRef, [
        installationPoint.id,
        (_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.settings) === null || _a === void 0 ? void 0 : _a.high_pass,
        interpolationDisabled,
        fftSelectedDate,
        _get(data, 'sensor.is_hfdvue'),
    ]);
    useEffect(function () {
        chartRef.current.chart.userOptions.FFDetectionPoints = [];
    }, [state.FFDetectionPoints]);
    useMainSeriesLoaded(state, dispatch, chartIdentifier, loader, readings);
    useSetOptions(state, dispatch, chartIdentifier, chartRef, loader, __assign(__assign({}, data), { installationPoint: installationPoint }), fftTimestampDates, readings, isVisible);
    useFaultFrequencies(state, chartRef, chartIdentifier, loader);
    useAutoCorrelation(state, dispatch, chartIdentifier, loader, fftTimestampDates, isChangedCalendar, fftSelectedDate);
    useResetCursorsOnChart(state, chartRef);
    useResetCursorsOnAllCharts(state, chartIdentifier, loader, fftTimestampDates, isChangedCalendar, fftSelectedDate, chartRef);
    useResetChartSelection(dispatch);
    useUnits(state, chartRef, loader);
    useFftCursor(state, loader, chartRef);
    useCustomCursorPointsCount(loader, chartRef, state);
    useHarmoniCursorByPoint(loader, chartRef, chartIdentifier);
    useLockedCursor(state, chartRef);
    useLoader(state, setStatesFftChartsStore);
    useMessageOnChart(state, chartRef);
    usePlotlines(state, chartRef, loader);
    useResetToolsToDefault(state, chartRef);
    useSelectProblemChart(state, dispatch, chartIdentifier, chartRef);
    useFftTwfSizeChartsEffect(state, dispatch, loader);
    useCircleType(state, dispatch, chartIdentifier, readings, data);
    useAxisLabel(state, dispatch, chartIdentifier);
    useFFDetection(state, dispatch, chartRef);
    useTooltip(state, dispatch, chartIdentifier, data, readings);
};
