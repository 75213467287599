var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { ChartFeaturesStore } from '@sharedStore/charts/chartsContent/ChartFeaturesStore';
import { ChartsStore } from '@sharedStore/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '@sharedStore/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import { selectChartZoomDays } from '@sharedStore/charts/useChartSettingsStore';
import { selectMeasure, selectPersonalSettingMeasure } from '@sharedStore/global/useGlobalStore';
import { clearFftShapes, getFftShapesAnnotationRegular, getFftShapesAnnotationSelectable, } from '../../../components/charts/topBar/components/ChartToolsButtons/FftShapes';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
export var useAnnotations = function (state, dispatch, chartRef, isVisible) {
    var _a;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = FftChartsStore(function (state) { return ({
        data: state.data,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
        readings: state.readings,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), data = _b.data, setStatesFftChartsStore = _b.setStatesFftChartsStore, readings = _b.readings, fftTimestampDates = _b.fftTimestampDates, fftTimestamps = data.fftTimestamps;
    var _c = ChartsStore(function (state) { return ({
        isVisibleFftShapes: state.isVisibleFftShapes,
        fftShapesType: state.fftShapesType,
        customZoomExtremes: state.customZoomExtremes,
        setStates: state.setStates,
    }); }, shallow), isVisibleFftShapes = _c.isVisibleFftShapes, fftShapesType = _c.fftShapesType, customZoomExtremes = _c.customZoomExtremes, setStates = _c.setStates;
    var _d = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        readingTypes: state.readingTypes,
        setCurrentSpeed: state.setCurrentSpeed,
    }); }, shallow), installationPoint = _d.installationPoint, readingTypes = _d.readingTypes, setCurrentSpeed = _d.setCurrentSpeed;
    var _e = ChartFeaturesStore(function (state) { return ({
        interpolationDisabled: state.interpolationDisabled,
        setWaterfallPlotModalReadings: state.setWaterfallPlotModalReadings,
    }); }, shallow), interpolationDisabled = _e.interpolationDisabled, setWaterfallPlotModalReadings = _e.setWaterfallPlotModalReadings;
    var chartZoomDays = selectChartZoomDays();
    var mainSeriesLoaded = state.mainSeriesLoaded, seriesIsRendered = state.seriesIsRendered, options = state.options;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    useEffect(function () {
        var _a;
        if (!mainSeriesLoaded || !seriesIsRendered) {
            return;
        }
        if (chartZoomDays > 62) {
            setStates({ isVisibleFftShapes: false, fftShapesType: 'regular' });
        }
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { annotations: isVisibleFftShapes && !(chartZoomDays > 62)
                        ? fftShapesType === 'regular'
                            ? getFftShapesAnnotationRegular(fftTimestamps, chartRef.current.chart, chartZoomDays, installationPoint, setStatesFftChartsStore, data, readings, fftTimestampDates, selectedSystemType, interpolationDisabled, measure, ((_a = installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.isAsset) ? setCurrentSpeed : undefined)
                            : getFftShapesAnnotationSelectable(fftTimestamps, chartRef.current.chart, chartZoomDays, setWaterfallPlotModalReadings, readingTypes)
                        : clearFftShapes(options) }),
            },
        });
    }, [
        fftShapesType,
        isVisibleFftShapes,
        customZoomExtremes,
        seriesIsRendered,
        chartZoomDays,
        isVisible,
        (_a = options === null || options === void 0 ? void 0 : options.xAxis[0]) === null || _a === void 0 ? void 0 : _a.plotLines,
        measure,
        personalSettingMeasure,
    ]);
};
