var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get, map as _map, uniq as _uniq } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamation, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Badge, Button, ButtonGroup, Col, Input, Label, Row } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { READING_TYPE_TACHOMETER } from '../../../../../constants';
import { SystemTypeContext } from '../../../../../shared/context/SystemTypeContext';
import { ChartFeaturesStore } from '../../../../../shared/store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../../../../shared/store/charts/chartsContent/InstallationPointStore';
import CommentsBlock from './components/CommentsBlock';
var fetch = function (installationPointId, readingTypeIds, setComments, setSmallLoader, selectedSystemType) {
    setSmallLoader(true);
    Endpoints[selectedSystemType]
        .getAlertConditionCommentsBatch(installationPointId, {
        query: {
            readingTypeIds: readingTypeIds,
        },
    })
        .then(function (resp) {
        setComments(resp.data);
        setSmallLoader(false);
    });
};
export var AnalystNotesTab = function (_a) {
    var setSmallLoader = _a.setSmallLoader, selectedInstallationPointId = _a.installationPointId, selectedReadingTypeIds = _a.selectedReadingTypeIds;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var changeAlertConditions = ChartFeaturesStore(function (state) { return ({
        changeAlertConditions: state.changeAlertConditions,
    }); }, shallow).changeAlertConditions, readingTypeId = changeAlertConditions.readingTypeId;
    var _b = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
    }); }, shallow), installationPoint = _b.installationPoint, overlayInstallationPoints = _b.overlayInstallationPoints, equipment = _get(installationPoint, 'equipment') || {};
    var _c = useState([]), comments = _c[0], setComments = _c[1];
    var _d = useState(false), isVisibleFormBlock = _d[0], setIsVisibleFormBlock = _d[1];
    var _e = useState({
        user_name: '',
        comment: '',
        id: null,
    }), selectedComment = _e[0], setSelectedComment = _e[1];
    var _f = useState(false), forAllSensors = _f[0], setForAllSensors = _f[1];
    var _g = useState(false), forOverlaid = _g[0], setForOverlaid = _g[1];
    useEffect(function () {
        fetch(selectedInstallationPointId, selectedReadingTypeIds, setComments, setSmallLoader, selectedSystemType);
    }, [selectedInstallationPointId, selectedReadingTypeIds]);
    var getInstallationPointIds = function () {
        var installationPointIds = [selectedInstallationPointId];
        if (forAllSensors) {
            installationPointIds = __spreadArray(__spreadArray([], installationPointIds, true), _map(equipment.installationPoints, 'id'), true);
        }
        if (forOverlaid) {
            installationPointIds = __spreadArray(__spreadArray([], installationPointIds, true), _map(overlayInstallationPoints, 'id'), true);
        }
        return _uniq(installationPointIds);
    };
    var onSave = function () {
        Endpoints[selectedSystemType]
            .saveAlertConditionsComment({
            installationPointIds: getInstallationPointIds(),
            readingTypes: selectedReadingTypeIds,
            comment: selectedComment.comment,
            comment_id: selectedComment.id,
        })
            .then(function (resp) {
            if (resp.success) {
                setIsVisibleFormBlock(false);
                setSelectedComment({ user_name: '', comment: '', id: null });
                fetch(installationPoint.id, selectedReadingTypeIds, setComments, setSmallLoader, selectedSystemType);
                return toast.success(resp.message || 'Successfully saved', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
            }
            return toast.error(resp.message || 'Server error', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        });
    };
    return (_jsxs(_Fragment, { children: [comments.length > 0 && (_jsx(Row, { className: "g-1", children: _jsxs(Col, { children: [_jsx(Badge, { className: "badge-circle", color: "warning", children: _jsx(FaExclamation, {}) }), _jsxs("span", { className: "fw-bold align-bottom ms-2", children: [' ', "This equipment has an active analyst notes for alert levels"] })] }) })), _jsx(Row, { className: "g-1 mt-2", children: _jsx(Col, { children: _jsx(CommentsBlock, { isVisibleFormBlock: isVisibleFormBlock, setIsVisibleFormBlock: setIsVisibleFormBlock, selectedComment: selectedComment, customerId: installationPoint.customer_id, setSelectedComment: setSelectedComment, callback: function () {
                            return fetch(installationPoint.id, selectedReadingTypeIds, setComments, setSmallLoader, selectedSystemType);
                        }, comments: comments }) }) }), isVisibleFormBlock && (_jsxs("div", { className: "mt-2", children: [_jsx("div", { className: "float-start", children: _jsxs(_Fragment, { children: [readingTypeId !== READING_TYPE_TACHOMETER && (_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forAllSensors, onChange: function () { return setForAllSensors(!forAllSensors); } }), _jsx("span", { className: "fw-bold", children: "Set Analyst Note for all Alerts on all components on equipment" })] }) }) })), readingTypeId !== READING_TYPE_TACHOMETER &&
                                    Object.keys(overlayInstallationPoints).length !== 0 && (_jsx(Row, { className: "g-1 ms-4 mt-2", children: _jsx(Col, { md: 12, children: _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", checked: forOverlaid, onChange: function () { return setForOverlaid(!forOverlaid); } }), _jsx("span", { className: "fw-bold", children: "Set Analyst Note for all overlaid components" })] }) }) }))] }) }), _jsxs(ButtonGroup, { className: "float-end", children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: function () { return setIsVisibleFormBlock(false); }, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onSave, children: "Save" })] })] }))] }));
};
