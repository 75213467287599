import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import RichTextEditor from '../../../../widgets/TextEditor/TextEditor';
import useToastErrorAlert from '../../../hooks/useToastErrorAlert';
import { selectIsVisibleAwaitingUpdateModal, useActionItemModalsActions, } from '../../../store/AIPreAi/useActionItemModalsStore';
import { awaitingUpdateSchema } from './schemas';
var AwaitingUpdateModal = function (_a) {
    var awaitingUpdateMutate = _a.awaitingUpdateMutate, awaitingUpdateIsLoading = _a.awaitingUpdateIsLoading, storage = _a.storage, onSuccess = _a.onSuccess;
    var actionItemId = useParams().id;
    var commentRef = useRef('');
    var isVisibleAwaitingUpdateModal = selectIsVisibleAwaitingUpdateModal();
    var setIsVisibleAwaitingUpdateModal = useActionItemModalsActions().setIsVisibleAwaitingUpdateModal;
    var _b = useForm({
        defaultValues: {
            comment: '',
        },
        resolver: awaitingUpdateSchema,
    }), setValue = _b.setValue, errors = _b.formState.errors, handleSubmit = _b.handleSubmit;
    useToastErrorAlert(errors);
    var onConfirm = handleSubmit(function () {
        awaitingUpdateMutate({
            action_item_id: actionItemId,
            comment: commentRef.current === '<p></p>' || commentRef.current === '<p><br></p>' ? '' : commentRef.current,
        }, {
            onSuccess: function () {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                toast.success('Successfully updated', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                setIsVisibleAwaitingUpdateModal(false);
            },
        });
    });
    return (_jsxs(Modal, { size: "lg", className: "modal-response", toggle: function () { return setIsVisibleAwaitingUpdateModal(false); }, isOpen: isVisibleAwaitingUpdateModal, children: [_jsx(ModalHeader, { toggle: function () { return setIsVisibleAwaitingUpdateModal(false); }, children: "Awaiting Update" }), _jsxs(ModalBody, { children: [_jsxs("p", { className: "d-flex justify-content-between align-items-end", children: ["Comment:", _jsx("i", { className: "fz-14", children: "(Optional)" })] }), _jsx(RichTextEditor, { storage: storage, content: commentRef.current, onChange: function (content) { return (commentRef.current = content); } })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: function () { return setIsVisibleAwaitingUpdateModal(false); }, children: "Cancel" }), _jsx(Button, { size: "sm", disabled: awaitingUpdateIsLoading, color: "primary", onClick: function () {
                                setValue('comment', commentRef.current);
                                onConfirm();
                            }, children: "Confirm" })] }) })] }));
};
export default memo(AwaitingUpdateModal);
