var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaEdit } from 'react-icons/fa';
import { Card, CardBody, CardHeader, Input, Label } from 'reactstrap';
import { get } from '../../../../storage/tableColumn';
import { config as baseConfig } from '../../../config/dashboard/preActionItems/dataTableConfig';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { fetch, onChangeStatus, onScroll } from '../../../features/dashboard/preActionItems';
import { onViewPreActionItem } from '../../../features/shared';
import { reducer } from '../../../helper/reducer';
import LinkWithPrefix from '../../LinkWithPrefix';
import Loader from '../../Loader';
import DataTable from '../../shared/Table/Table';
var initialState = function (tableName, tableColumns, onViewPreActionItem) {
    var visibleState = get(tableName);
    var config = baseConfig(visibleState, onViewPreActionItem);
    return {
        onlyNew: true,
        limit: 10,
        offset: 0,
        scrollLoader: true,
        fetchingInProcess: false,
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray([
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                }
            ], tableColumns, true), config.columns, true) }),
    };
};
var PreActionItems = function (_a) {
    var _b = _a.deps, deps = _b === void 0 ? [] : _b, tableName = _a.tableName, _c = _a.tableColumns, tableColumns = _c === void 0 ? [] : _c, endpoint = _a.endpoint;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _d = useReducer(reducer, initialState(tableName, tableColumns, function (id) { return onViewPreActionItem(id, selectedSystemType); })), state = _d[0], dispatch = _d[1];
    var config = state.config, onlyNew = state.onlyNew, limit = state.limit, offset = state.offset, scrollLoader = state.scrollLoader;
    var _e = useState(false), autoAisAdapter = _e[0], setAutoAisAdapter = _e[1];
    useEffect(function () {
        if (offset) {
            dispatch({
                type: 'setState',
                state: {
                    offset: 0,
                },
            });
        }
        fetch(state, dispatch, function () { return endpoint(onlyNew, autoAisAdapter, limit, 0); });
    }, __spreadArray([onlyNew, autoAisAdapter], deps, true));
    useEffect(function () {
        if (config.loader) {
            return;
        }
        fetch(state, dispatch, function () { return endpoint(onlyNew, autoAisAdapter, limit, offset); }, true);
    }, [offset]);
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsxs(CardHeader, { className: "d-flex justify-content-between", style: { padding: 10 }, children: [_jsx(LinkWithPrefix, { to: "/pre-action-list", children: _jsx("div", { className: "float-start", children: "Pre-Action Items" }) }), _jsxs("div", { className: "float-end", children: [_jsxs(Label, { check: true, className: "me-5", children: [_jsx(Input, { type: "checkbox", title: "".concat(onlyNew ? 'Show New/Show Decline/Show Approved' : 'Show only New'), defaultChecked: onlyNew, onChange: function () { return onChangeStatus(state, dispatch); } }), _jsx("span", { className: "text-warning", children: "New" })] }), _jsxs(Label, { check: true, children: [_jsx(Input, { type: "checkbox", defaultChecked: false, onChange: function () { return setAutoAisAdapter(!autoAisAdapter); } }), _jsx("span", { children: "Universal adapter" })] })] })] }), _jsx(CardBody, { style: { padding: 0 }, children: _jsxs(Scrollbars, { onUpdate: function (values) {
                        return onScroll(state, dispatch, values);
                    }, style: { height: '100%' }, children: [_jsx(DataTable, { tableName: tableName, dispatchTable: dispatch, config: config }), !config.loader && scrollLoader ? (_jsx("div", { className: "d-flex justify-content-center py-3", children: _jsx(Loader, { variant: "loader-md" }) })) : (_jsx(_Fragment, {}))] }) })] }));
};
export default PreActionItems;
