var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { difference as _difference } from '#typed/lodash';
import { Button, ButtonGroup } from '#typed/reactstrap';
import { memo } from 'react';
import { AXIS_NAMES, AXIS_Z, READING_TYPES } from '../../../constants';
var AxisType = function (_a) {
    var selectedReadingTypeIds = _a.selectedReadingTypeIds, setSelectedReadingTypeIds = _a.setSelectedReadingTypeIds, chartIdentifiers = _a.chartIdentifiers, isSupportZAxis = _a.isSupportZAxis, _b = _a.showAll, showAll = _b === void 0 ? true : _b, _c = _a.className, className = _c === void 0 ? '' : _c;
    var selectAll = function () {
        if (selectedReadingTypeIds.length < 3) {
            setSelectedReadingTypeIds(chartIdentifiers);
        }
    };
    var handleToggleAxis = function (chartIdentifier, value) {
        if (value) {
            setSelectedReadingTypeIds(__spreadArray(__spreadArray([], selectedReadingTypeIds, true), [chartIdentifier], false));
        }
        else {
            if (selectedReadingTypeIds.length === 1 && selectedReadingTypeIds.includes(chartIdentifier))
                return;
            setSelectedReadingTypeIds(_difference(selectedReadingTypeIds, [chartIdentifier]));
        }
    };
    var selectedAll = selectedReadingTypeIds.length === 3;
    return (_jsxs("div", { className: className, children: [_jsx("label", { className: "fw-bold d-block align-middle", children: "Axis Type:" }), _jsxs(ButtonGroup, { children: [showAll && (_jsx(Button, { color: "primary", outline: true, onClick: selectAll, active: selectedAll, children: "All" })), chartIdentifiers.map(function (id) {
                        var _a;
                        var axisShort = (_a = READING_TYPES[id]) === null || _a === void 0 ? void 0 : _a.axisShort;
                        if (!isSupportZAxis && axisShort === AXIS_NAMES[AXIS_Z]) {
                            return null;
                        }
                        var checked = selectedReadingTypeIds.includes(+id);
                        return (_jsx(Button, { className: "axis-btn", color: "primary", outline: true, onClick: function () { return handleToggleAxis(id, !checked); }, active: checked, children: axisShort }));
                    })] })] }));
};
export default memo(AxisType);
