import { jsx as _jsx } from "react/jsx-runtime";
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useApproveSeverityIncreaseMutation } from '../../services/actionItems/useApproveSeverityIncreasedMutation';
import { useDeclineSeverityIncreaseMutation } from '../../services/actionItems/useDeclineSeverityIncreasedMutation';
export var useDeleteSeverityIncrease = function (_a) {
    var actionItemId = _a.actionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b, _c = _a.isPreAI, isPreAI = _c === void 0 ? false : _c;
    var _d = useApproveSeverityIncreaseMutation({
        id: String(actionItemId),
        isPreAI: isPreAI,
        storage: storage,
    }), approveSeverityIncreaseMutate = _d.mutate, approveSeverityIncreaseIsLoading = _d.isPending;
    var _e = useDeclineSeverityIncreaseMutation({
        id: String(actionItemId),
        isPreAI: isPreAI,
        storage: storage,
    }), declineSeverityIncreaseMutate = _e.mutate, declineSeverityIncreaseIsLoading = _e.isPending;
    var deleteSeverityIncrease = function (_a) {
        var action = _a.action, comment = _a.comment, onSuccess = _a.onSuccess;
        var mutate = action === 'approve' ? approveSeverityIncreaseMutate : declineSeverityIncreaseMutate;
        return mutate(isPreAI ? { pre_action_list_id: actionItemId, comment: comment } : { action_list_id: actionItemId, comment: comment }, {
            onSuccess: function () {
                toast.success('Severity Increase successfully removed', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            },
        });
    };
    return {
        deleteSeverityIncrease: deleteSeverityIncrease,
        isPending: approveSeverityIncreaseIsLoading || declineSeverityIncreaseIsLoading,
    };
};
