import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Table } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import { useShaftInfoQuery } from '../../services/shaft/useShaftInfoQuery';
var ShaftInfo = function () {
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint, equipment = _get(installationPoint, 'equipment') || {}, asset = _get(equipment, 'assets.0') || {};
    var _a = useShaftInfoQuery(asset.id), data = _a.data, isFetching = _a.isFetching, isLoading = _a.isLoading;
    return (_jsxs("div", { children: [isFetching || (isLoading && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })), data && data.length > 0 ? (_jsxs(Table, { striped: true, className: "position-relative responsive_table table-sm", children: [_jsx("thead", { className: "noselect table-head", children: _jsxs("tr", { className: "text-nowrap  text-center card-row_title ", children: [_jsx("th", { children: "Name" }), _jsx("th", { className: "text-center", children: "Shaft" }), _jsx("th", { className: "text-center", children: "Ratio" })] }) }), _jsx("tbody", { children: data.map(function (shaft, index) { return (_jsx(React.Fragment, { children: shaft.shafts.map(function (shaftItem, index) { return (_jsxs("tr", { children: [_jsx("td", { className: "card-row_title text-center", children: _jsx("b", { children: index === 0 ? shaft.name : '' }) }), _jsx("td", { className: "card-row_title text-center", children: shaftItem.shaft }), _jsx("td", { className: "card-row_title text-center", children: shaftItem.ratio })] }, "shaft-".concat(index, "-").concat(shaft.name, "-").concat(index))); }) }, "shaft-".concat(index, "-").concat(shaft.name))); }) })] })) : (_jsx("p", { children: "No shaft info" }))] }));
};
export default memo(ShaftInfo);
