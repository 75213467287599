import { literal, number, object, optional, variant } from 'valibot';
import { CALCULATION_MODE_RATIO_BELT_RATE, CALCULATION_MODE_SHEAVE_BELT_LENGTH } from '../../constants';
export var calculationModeForBeltRules = variant('mode', [
    object({
        mode: literal(CALCULATION_MODE_SHEAVE_BELT_LENGTH),
        sdi1: number('SD 1 is required'),
        sdi2: number('SD 2 is required'),
        beltLength: optional(number()),
    }),
    object({
        mode: literal(CALCULATION_MODE_RATIO_BELT_RATE),
        speedRatio: number('Speed Ratio is required'),
        beltRpm: optional(number()),
    }),
], 'Calculation mode is required');
