import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'clsx';
import { memo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import assetBuilderStyles from '../../styles.module.scss';
export var SCRInfo = memo(function (_a) {
    var control = _a.control, fieldName = _a.fieldName, isDisabled = _a.isDisabled;
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, value = _b.value, onChangePivoting = _b.onChange;
    var _c = useWatch({
        control: control,
        name: ['data.subtype', 'data.speed.variantSpeedType'],
    }), subtype = _c[0], variantSpeedType = _c[1];
    if (subtype !== 9 || variantSpeedType === 'base') {
        return null;
    }
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "SCR Info" }), _jsx("div", { className: assetBuilderStyles.sectionButtons, children: ['3 pulses', '6 pulses'].map(function (type) {
                    var _a;
                    return (_jsx("button", { disabled: isDisabled, onClick: function () { return onChangePivoting(type); }, className: cx(assetBuilderStyles.button, (_a = {},
                            _a[assetBuilderStyles.active] = value === type,
                            _a)), children: type }, type ? 1 : 0));
                }) })] }));
});
SCRInfo.displayName = 'SCRInfo';
