import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { useFaultFrequencyComponentQuery, } from '../../../../services/faultFrequency/useFaultFrequencyComponentQuery';
import { useFFLabelSelectActions } from '../../stores/useFFLabelSelectStore';
import { selectComponentType, useComponentTypeSelectActions } from './stores/useComponentTypeSelectStore';
export var ComponentTypeSelect = function () {
    var data = useFaultFrequencyComponentQuery().data;
    var setComponentType = useComponentTypeSelectActions().setComponentType;
    var setFFLabel = useFFLabelSelectActions().setFFLabel;
    var ffComponent = selectComponentType();
    return (_jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Component Type:" }) }), _jsx(Select, { className: "select-custom-default", isSearchable: true, classNamePrefix: "customSelectStyleDefault", value: ffComponent ? { value: ffComponent, label: ffComponent.name } : null, options: data === null || data === void 0 ? void 0 : data.map(function (componentType) {
                    return { value: componentType, label: componentType.name };
                }), onChange: function (ev) {
                    setFFLabel(null);
                    setComponentType(ev.value);
                } })] }));
};
ComponentTypeSelect.displayName = 'ComponentTypeSelect';
