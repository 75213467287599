import { has as _has, map as _map, max as _max, min as _min } from 'lodash';
import { getSoftMax, getSoftMin } from '../../../../../helper/chart';
import { calculateCurrentLevel } from '../rms/alertConditions';
export var calculateMaxYAxisValue = function (alertConditions, chartIdentifier, readings, readingTypes, measure, personalSettingMeasure) {
    var maxY = getSoftMax(chartIdentifier, measure, personalSettingMeasure);
    var currentLevels = calculateCurrentLevel(chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure);
    var maxSpot = Math.max.apply(Math, currentLevels
        .map(function (_a) {
        var caution = _a.caution, warning = _a.warning;
        return [caution, warning];
    })
        .flat()
        .map(Number));
    var maxReadingValue = _max(_map(readings, '1'));
    if (readings && readings.length && maxReadingValue < maxSpot * 1.1) {
        return maxSpot * 1.3;
    }
    if (readings && readings.length && Math.max(maxReadingValue, maxSpot) * 1.5 < maxY) {
        maxY = Math.max(maxReadingValue, maxSpot) * 1.5;
    }
    return maxY;
};
export var calculateMinYAxisValue = function (alertConditions, chartIdentifier, readingTypes, measure, personalSettingMeasure) {
    var minY = getSoftMin(chartIdentifier, measure, personalSettingMeasure);
    var currentLevels = calculateCurrentLevel(chartIdentifier, alertConditions, readingTypes, measure, personalSettingMeasure);
    var minSpot = Math.min.apply(Math, currentLevels
        .map(function (_a) {
        var caution = _a.caution, warning = _a.warning;
        return [caution, warning];
    })
        .flat()
        .map(Number));
    return minSpot < minY ? minSpot : minY;
};
export var calculateYExtremes = function (series, plotLines) {
    var maxY = 0;
    var minY = 0;
    var visiblePlotLines = plotLines
        ? plotLines.filter(function (plotLine) { return !plotLine.className.includes('hide'); })
        : [];
    var visibleSeries = series
        ? series.filter(function (seriesItem) { return !_has(seriesItem, 'visible') || seriesItem.visible; })
        : [];
    visibleSeries.map(function (visibleSeriesItem) {
        var maxSeries = _max(_map(visibleSeriesItem.data, '1'));
        var minSeries = _min(_map(visibleSeriesItem.data, '1'));
        if (maxSeries > maxY) {
            maxY = maxSeries * 1.3;
        }
        if (minSeries < minY) {
            minY = minSeries - minSeries * 1.3;
        }
    });
    visiblePlotLines.map(function (visiblePlotLine) {
        if (visiblePlotLine.value > maxY) {
            maxY = visiblePlotLine.value * 1.3;
        }
        if (visiblePlotLine.value < minY) {
            minY = visiblePlotLine.value - visiblePlotLine.value * 1.3;
        }
    });
    return [maxY, minY];
};
