var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { shallow } from 'zustand/shallow';
import { InstallationPointStore } from '@sharedStore/charts/chartsContent/InstallationPointStore';
import { SPEED_TYPES } from '../../../../constants';
import { FftTooltipFormatter } from '../../../components/charts/chartsWrapper/components/shared/tooltip';
import { isTwfAccelerationChart } from '../../../helper/chart';
export var useTooltip = function (state, dispatch, chartIdentifier, readings, data) {
    var _a;
    var options = state.options;
    var rpmFrom = data.rpmFrom, rpm = data.rpm;
    var _b = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        currentSpeed: state.currentSpeed,
        readingTypes: state.readingTypes,
    }); }, shallow), installationPoint = _b.installationPoint, currentSpeed = _b.currentSpeed, readingTypes = _b.readingTypes;
    var readingsYTwfAccelerationChart = [];
    if (isTwfAccelerationChart(chartIdentifier)) {
        readingsYTwfAccelerationChart = (_get(readings, chartIdentifier) || []).map(function (el) { return el[1]; });
    }
    useEffect(function () {
        dispatch({
            type: 'setState',
            state: {
                options: __assign(__assign({}, options), { tooltip: __assign(__assign({}, options.tooltip), { formatter: function () {
                            var _a, _b, _c;
                            // @ts-ignore
                            var points = this.points.filter(function (point) {
                                return point.series.options.id;
                            });
                            return ReactDOMServer.renderToStaticMarkup((_jsx(FftTooltipFormatter, { readingsYTwfAccelerationChart: readingsYTwfAccelerationChart, isSensorSpeedType: !!(installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.speed), equipmentSpeedTypeId: (_b = (_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.equipment_speed_type_id) !== null && _b !== void 0 ? _b : SPEED_TYPES.FIXED, isAsset: !!((_c = installationPoint.equipment) === null || _c === void 0 ? void 0 : _c.isAsset), currentSpeed: currentSpeed, readingTypes: readingTypes, rpmFrom: _get(rpmFrom, chartIdentifier), rpm: _get(rpm, chartIdentifier), points: points, 
                                // @ts-ignore
                                xValue: this.x })));
                        } }) }),
            },
        });
    }, [(_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.equipment_speed_type_id, installationPoint.speed, rpm, rpmFrom]);
};
