var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { FaCheckCircle } from 'react-icons/fa';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import { ACTION_LIST } from '../../../../../constants';
import { selectActivityLogSize, selectEditComment, selectIsGatheringData, selectIsInternalComment, useActionItemViewActions, } from '../../../../store/AIPreAi/useActionItemViewStore';
import '../../../../styles/AIPreAi/activityLog.scss';
import UrgencySelect from '../../../AIPreAi/UrgencySelect';
import ActivityLogToggleSize from '../../../AIPreAi/activityLogHeader/components/ActivityLogToggleSize';
import GatheringDataToggle from '../../../AIPreAi/activityLogHeader/components/GatheringDataToggle';
import SaveAsDefaultSize from '../../../AIPreAi/activityLogHeader/components/SaveAsDefaultSize';
import DefaultTooltip from '../../../DefaultTooltip';
var Header = function (_a) {
    var id = _a.id, isReplaceEquipment = _a.isReplaceEquipment, isViewMode = _a.isViewMode, internalCommentSaveMutate = _a.internalCommentSaveMutate, internalCommentSaveIsLoading = _a.internalCommentSaveIsLoading, internalCommentUpdateMutate = _a.internalCommentUpdateMutate, internalCommentUpdateIsLoading = _a.internalCommentUpdateIsLoading, commentSaveMutate = _a.commentSaveMutate, commentSaveIsLoading = _a.commentSaveIsLoading, commentGatheringDataMutate = _a.commentGatheringDataMutate, commentGatheringDataLoading = _a.commentGatheringDataLoading, commentUpdateMutate = _a.commentUpdateMutate, updatePreCommentMutate = _a.updatePreCommentMutate, commentUpdateIsLoading = _a.commentUpdateIsLoading, updatePreCommentIsLoading = _a.updatePreCommentIsLoading, control = _a.control, handleSubmit = _a.handleSubmit, comments = _a.comments, onSuccessSaveComment = _a.onSuccessSaveComment, onBeforeComment = _a.onBeforeComment;
    var activityLogSize = selectActivityLogSize();
    var editComment = selectEditComment();
    var isGatheringData = selectIsGatheringData();
    var isInternalComment = selectIsInternalComment();
    var setEditComment = useActionItemViewActions().setEditComment;
    var _b = useWatch({
        control: control,
        name: ['comment', 'urgency'],
    }), comment = _b[0], urgency = _b[1];
    var field = useController({
        control: control,
        name: 'comment',
    }).field;
    var setIsGatheringData = useActionItemViewActions().setIsGatheringData;
    var commentCount = useMemo(function () { return comments.filter(function (comment) { return comment.type === ACTION_LIST.COMMENT_TYPE_REGULAR; }).length; }, [comments]);
    var onSubmit = handleSubmit(function () { return __awaiter(void 0, void 0, void 0, function () {
        var ok;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (onBeforeComment === null || onBeforeComment === void 0 ? void 0 : onBeforeComment())];
                case 1:
                    ok = _a.sent();
                    if (!ok)
                        return [2 /*return*/];
                    if (isGatheringData) {
                        return [2 /*return*/, commentGatheringDataMutate({
                                action_list_id: id,
                                comment: comment,
                            }, {
                                onSuccess: function () {
                                    toast.success('The comment has been successfully created', {
                                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                    });
                                    field.onChange('');
                                    setIsGatheringData(false);
                                },
                            })];
                    }
                    if (editComment) {
                        if ((editComment === null || editComment === void 0 ? void 0 : editComment.type) === ACTION_LIST.COMMENT_TYPE_INTERNAL) {
                            return [2 /*return*/, internalCommentUpdateMutate({
                                    id: editComment === null || editComment === void 0 ? void 0 : editComment.id,
                                    action_list_id: id,
                                    comment: comment,
                                }, {
                                    onSuccess: function () {
                                        toast.success('The internal comment has been successfully updated', {
                                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                        });
                                        field.onChange('');
                                        setEditComment(null);
                                    },
                                })];
                        }
                        if ((editComment === null || editComment === void 0 ? void 0 : editComment.is_pre_comment) === 1) {
                            return [2 /*return*/, updatePreCommentMutate({
                                    id: editComment === null || editComment === void 0 ? void 0 : editComment.id,
                                    action_list_id: id,
                                    comment: comment,
                                }, {
                                    onSuccess: function () {
                                        toast.success('The pre-comment has been successfully updated', {
                                            icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                        });
                                        field.onChange('');
                                        setEditComment(null);
                                    },
                                })];
                        }
                        return [2 /*return*/, commentUpdateMutate({
                                id: editComment === null || editComment === void 0 ? void 0 : editComment.id,
                                action_list_id: id,
                                comment: comment,
                            }, {
                                onSuccess: function () {
                                    toast.success('The comment has been successfully updated', {
                                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                    });
                                    field.onChange('');
                                    setEditComment(null);
                                },
                            })];
                    }
                    if (isInternalComment) {
                        return [2 /*return*/, internalCommentSaveMutate({
                                action_list_id: id,
                                comment: comment,
                            }, {
                                onSuccess: function () {
                                    toast.success('The internal comment has been successfully created', {
                                        icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                                    });
                                    field.onChange('');
                                },
                            })];
                    }
                    commentSaveMutate({
                        action_list_id: id,
                        comment: comment,
                        urgency: urgency,
                        is_replace_equipment: +isReplaceEquipment,
                    }, {
                        onSuccess: function () {
                            toast.success('The comment has been successfully saved', {
                                icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                            });
                            onSuccessSaveComment === null || onSuccessSaveComment === void 0 ? void 0 : onSuccessSaveComment();
                            field.onChange('');
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); });
    return (_jsxs("div", { className: "activity-log-header", children: [_jsx("div", { className: "d-flex align-items-center", children: !activityLogSize ? (_jsxs("div", { className: "d-flex align-items-center flex-wrap justify-content-center gap-2", children: [_jsx(ActivityLogToggleSize, {}), _jsx(SaveAsDefaultSize, { localStorageKey: "aiDefaultActivityLogSize" })] })) : (_jsxs("div", { className: "d-flex align-items-center gap-2", children: [_jsx(ActivityLogToggleSize, {}), _jsx(SaveAsDefaultSize, { localStorageKey: "aiDefaultActivityLogSize" }), _jsx("div", { className: "activity-log-header-title", children: "Activity Log" }), commentCount ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "activity-log-header-comments-count", "data-tooltip-id": "comments_count", children: commentCount || _jsx(_Fragment, {}) }), _jsx(DefaultTooltip, { openEvents: { mouseenter: true }, place: "left", id: "comments_count", children: "Comments count" })] })) : (_jsx(_Fragment, {}))] })) }), activityLogSize ? (_jsx("div", { className: "d-flex align-items-center", children: isViewMode ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [editComment || isInternalComment ? _jsx(_Fragment, {}) : _jsx(GatheringDataToggle, {}), editComment || isGatheringData || isInternalComment ? (_jsx(_Fragment, {})) : (_jsx(UrgencySelect, { control: control })), _jsx("div", { className: "activity-log-header-send", id: "send", onClick: function () {
                                return commentSaveIsLoading ||
                                    commentUpdateIsLoading ||
                                    commentGatheringDataLoading ||
                                    updatePreCommentIsLoading ||
                                    internalCommentSaveIsLoading ||
                                    internalCommentUpdateIsLoading
                                    ? {}
                                    : onSubmit();
                            }, children: _jsx(RiSendPlane2Fill, {}) }), _jsx(UncontrolledTooltip, { placement: "left", target: "send", children: editComment ? 'Save changes' : isInternalComment ? 'Save internal comment' : 'Save comment' })] })) })) : (_jsx(_Fragment, {}))] }));
};
export default memo(Header);
