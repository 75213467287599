// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-speed-setting-info .form-control:disabled {
  opacity: 1 !important;
}`, "",{"version":3,"sources":["webpack://./../src/modals/runningSpeed/equipmentSpeed/assetSpeed/scss/assetSpeed.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ","sourcesContent":[".asset-speed-setting-info {\n  & .form-control:disabled {\n    opacity: 1 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
