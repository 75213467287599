import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
export var dataTableConfig = {
    columns: [
        {
            name: 'component_name',
            title: 'Component name',
            sortable: false,
        },
        {
            name: 'description',
            title: 'Description',
            sortable: false,
        },
        {
            name: 'name',
            title: 'Labels',
            sortable: false,
        },
        {
            name: 'on_shaft',
            title: 'On Shaft',
            sortable: false,
        },
        {
            name: 'elements',
            title: 'Elements',
            sortable: false,
            component: function (_a) {
                var elements = _a.elements;
                return (elements && elements > 0 ? elements : '');
            },
        },
        {
            name: 'frequency',
            title: 'Order',
            sortable: false,
            component: function (row) {
                if (row.frequency_type === 'Orders') {
                    return row.frequency;
                }
                return '---';
            },
        },
        {
            name: 'fixed_frequency',
            title: 'Fixed Frequency',
            sortable: false,
            component: function (row) {
                return row.frequency_type !== 'Orders' ? "".concat(row.frequency, " ").concat(row.frequency_type) : '---';
            },
        },
        {
            name: 'plot_lines_count',
            title: 'Plot Lines',
            sortable: false,
        },
        {
            name: 'action',
            title: '',
            sortable: false,
            component: function (row, callbacks, filtersData) {
                if (filtersData.readonly || row.createdType === 'auto') {
                    return _jsx(_Fragment, {});
                }
                return (_jsxs("div", { className: "d-flex justify-content-center", children: [_jsxs("div", { id: "customer_created_fault_frequency_".concat(row.id), children: [_jsx(ButtonGroup, { className: "me-2", children: _jsxs(Button, { color: "success", size: "sm", onClick: function () { return callbacks.onEdit(row); }, children: [_jsx(FaEdit, {}), " Edit"] }) }), _jsx(ButtonGroup, { children: _jsxs(Button, { color: "danger", size: "sm", onClick: function () { return callbacks.onRemove(row.id); }, children: [_jsx(FaTimes, {}), " Remove"] }) })] }), row.source !== 'analytic' ? (_jsx(UncontrolledTooltip, { target: "customer_created_fault_frequency_".concat(row.id), children: "Customer created fault/forcing frequency" })) : (_jsx(_Fragment, {}))] }));
            },
        },
    ],
    data: [],
    loader: false,
    hideTotalLabel: true,
    pagination: {
        disabled: true,
    },
    search: '',
    disabledSearch: true,
};
