// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.machine-info-alert__GhMi8 {
  position: absolute;
  background-color: #d9534f;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/charts/topBar/components/ChartToolsButtons/MachineInformation/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;AACF","sourcesContent":[".machine-info-alert {\n  position: absolute;\n  background-color: #d9534f;\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  top: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"machineInfoAlert": `machine-info-alert__GhMi8`
};
module.exports = ___CSS_LOADER_EXPORT___;
