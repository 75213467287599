import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { VARIATION_TYPE_FAN_BLOWER } from '../../../../../../../../../../constants';
import { VariationType } from '../../../VariationType';
import { AxialFlowPropellerFanContent } from './AxialFlowPropellerFanContent';
import { CentrifugalBlowerContent } from './CentrifugalBlowerContent';
import { LobedBlowerContent } from './LobedBlowerContent';
import { ScrewBlowerContent } from './ScrewBlowerContent';
import { SquirrelCageContent } from './SquirrelCageContent';
var Component = function (type, control, isDisabled, equipmentId, entryBoxNumber) {
    switch (type) {
        case VARIATION_TYPE_FAN_BLOWER.AXIAL_FLOW_PROPELLER_FAN:
            return (_jsx(AxialFlowPropellerFanContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_FAN_BLOWER.SQUIRREL_CAGE:
            return (_jsx(SquirrelCageContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_FAN_BLOWER.CENTRIFUGAL_BLOWER:
            return (_jsx(CentrifugalBlowerContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_FAN_BLOWER.LOBED_BLOWER:
            return (_jsx(LobedBlowerContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_FAN_BLOWER.SCREW_BLOWER:
            return (_jsx(ScrewBlowerContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
    }
};
export var FanBlowerContent = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    var variation = useWatch({
        control: control,
        name: 'data.drivenTypeVariant.variation',
    });
    var type = variation.type;
    return (_jsxs(_Fragment, { children: [_jsx(VariationType, { isDisabled: isDisabled, types: Object.values(VARIATION_TYPE_FAN_BLOWER) }), !!type && Component(type, control, isDisabled, equipmentId, entryBoxNumber)] }));
});
FanBlowerContent.displayName = 'FanBlowerContent';
