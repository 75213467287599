// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labels-chart-actions {
  display: flex;
  position: absolute;
  width: auto;
  top: 2px;
  right: 40px;
  z-index: 1;
}
.labels-chart-actions .default-chart-label {
  display: flex;
  align-items: center;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  background: #f1f1f1;
  border: 1px solid #efefef;
  margin-left: 5px;
  cursor: default;
}
.dark-layout .labels-chart-actions .default-chart-label {
  background: #1e222d;
  border: 1px solid #3e3f4f;
}
.labels-chart-actions .label-active[class] {
  background: #0a66b7;
  color: #f1f1f1;
}
.labels-chart-actions .label-warning[class] {
  background: #d9d601;
  color: #212529;
}
.labels-chart-actions .label-danger[class] {
  background-color: #dc3545;
  color: #f1f1f1;
}`, "",{"version":3,"sources":["webpack://./../src/shared/styles/charts/chartsWrapper/LabelsChartActions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;AACJ;AACI;EACE,mBAAA;EACA,yBAAA;AACN;AAGE;EACE,mBAAA;EACA,cAAA;AADJ;AAIE;EACE,mBAAA;EACA,cAAA;AAFJ;AAKE;EACE,yBAAA;EACA,cAAA;AAHJ","sourcesContent":[".labels-chart-actions {\n  display: flex;\n  position: absolute;\n  width: auto;\n  top: 2px;\n  right: 40px;\n  z-index: 1;\n\n  & .default-chart-label {\n    display: flex;\n    align-items: center;\n    font-size: 11px;\n    padding: 2px 4px;\n    border-radius: 4px;\n    background: #f1f1f1;\n    border: 1px solid #efefef;\n    margin-left: 5px;\n    cursor: default;\n\n    .dark-layout & {\n      background: #1e222d;\n      border: 1px solid #3e3f4f;\n    }\n  }\n\n  & .label-active[class] {\n    background: #0a66b7;\n    color: #f1f1f1;\n  }\n\n  & .label-warning[class] {\n    background: #d9d601;\n    color: #212529;\n  }\n\n  & .label-danger[class] {\n    background-color: #dc3545;\n    color: #f1f1f1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
