import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
export var InListStore = create()(devtools(function (set) { return ({
    inList: {
        detachedList: {},
        badList: {},
        watchList: {},
        alertsList: {},
        preActionList: {},
        actionList: {},
        nodesAiList: {},
        nodesAllAiList: {},
        nodesAllPreAiList: {},
        wwActionList: {},
        wwPreActionList: {},
        severityIncrease: {},
    },
    setInList: function (inList) {
        set({ inList: inList }, false, 'InListStoreSetInList');
    },
}); }, { name: 'InListStore' }));
