import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import { DRIVEN_SUBTYPE_AGITATOR_MIXER, DRIVEN_SUBTYPE_CENTRIFUGE, DRIVEN_SUBTYPE_COMPRESSOR, DRIVEN_SUBTYPE_CONVEYOR_BELT_DRAG, DRIVEN_SUBTYPE_CONVEYOR_SCREW, DRIVEN_SUBTYPE_FAN_AND_BLOWER, DRIVEN_SUBTYPE_GENERATOR, DRIVEN_SUBTYPE_HAMMER_MILL, DRIVEN_SUBTYPE_PUMP, DRIVEN_SUBTYPE_SIMPLE_SHAFT, } from '../../../../../../../../constants';
import { AgitatorMixerContent } from './components/AgitatorMixerContent';
import { CentrifugeContent } from './components/CentrifugeContent';
import { CompressorContent } from './components/CompressorContent';
import { ConveyorBeltDragContent } from './components/ConveyorBeltDragContent';
import { ConveyorScrewContent } from './components/ConveyorScrewContent';
import { FanBlowerContent } from './components/FanBlowerContent';
import { GeneratorContent } from './components/GeneratorContent';
import { HummerMillContent } from './components/HummerMillContent';
import { PumpContent } from './components/PumpContent';
import { SimpleShaftContent } from './components/SimpleShaftContent';
var Component = function (subtype, control, isDisabled, equipmentId, entryBoxNumber) {
    switch (subtype) {
        case DRIVEN_SUBTYPE_SIMPLE_SHAFT:
            return (_jsx(SimpleShaftContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_PUMP:
            return (_jsx(PumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_FAN_AND_BLOWER:
            return (_jsx(FanBlowerContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_COMPRESSOR:
            return (_jsx(CompressorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_GENERATOR:
            return (_jsx(GeneratorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_CENTRIFUGE:
            return (_jsx(CentrifugeContent, { isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_HAMMER_MILL:
            return (_jsx(HummerMillContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_CONVEYOR_BELT_DRAG:
            return (_jsx(ConveyorBeltDragContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_CONVEYOR_SCREW:
            return (_jsx(ConveyorScrewContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case DRIVEN_SUBTYPE_AGITATOR_MIXER:
            return (_jsx(AgitatorMixerContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
    }
};
export var DrivenTypeVariant = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    var drivenTypeVariant = useController({
        name: 'data.drivenTypeVariant',
        control: control,
    }).field.value;
    var subtype = drivenTypeVariant.subtype;
    if (!subtype) {
        return null;
    }
    return Component(subtype, control, isDisabled, equipmentId, entryBoxNumber);
});
DrivenTypeVariant.displayName = 'DrivenTypeVariant';
