import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Highcharts from 'highcharts';
import { get as _get, orderBy as _orderBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { ChartsStore } from '@sharedStore/charts/chartsContent/ChartsStore';
import { selectMeasure, selectPersonalSettingMeasure } from '@sharedStore/global/useGlobalStore';
import { CHART_GROUPS, READING_TYPES, RPM_FROM, SPEED_TYPES } from '../../../../../../constants';
import { getHexId } from '../../../../../../widgets/sensor';
import { getMaxAbsNumber, getNearestPointIndex, getUoms, getValueForNormalizeReadings, isFftChart, isFftTwfChart, isRmsChart, isTwfAccelerationChart, isTwfChart, setHoverPointsAndCrosshair, } from '../../../../../helper/chart';
import { makeValidLink } from '../../../../../helper/routeHelper';
import '../../../../../styles/charts/chartsWrapper/tooltip.scss';
import { FrequencyConverter } from '../../features/fftTwf/frequencyConverter';
export var syncOverlays = function (proceed) {
    var isDarkMode = document.getElementsByTagName('body')[0].className === 'dark-layout';
    // eslint-disable-next-line prefer-rest-params
    var args = arguments, points = args[1], point = points[0];
    if (point) {
        var chart_1 = point.series.chart;
        if (chart_1) {
            Highcharts.each(chart_1.series, function (series) {
                if (series.visible === false ||
                    !series.data.length ||
                    ('enableMouseTracking' in series.userOptions && !series.userOptions.enableMouseTracking)) {
                    return;
                }
                var current = series.points[getNearestPointIndex(series, point.x)];
                if (isRmsChart(series.userOptions.chartType)) {
                    setHoverPointsAndCrosshair(true, chart_1, current, series, isDarkMode);
                }
                if (isFftTwfChart(series.userOptions.chartType) && !point.series.chart.userOptions.isCircleTwfType) {
                    setHoverPointsAndCrosshair(true, chart_1, current, series, isDarkMode);
                }
                if (point.series.userOptions.installationPointId === series.userOptions.installationPointId) {
                    return;
                }
                if (points.indexOf(current) === -1) {
                    points.push(current);
                }
            });
        }
    }
    // @ts-ignore
    proceed.apply(this, [].slice.call(args, 1));
};
export var prepareToMoveFftTwf = function (point) {
    Highcharts.charts.forEach(function (chartItem) {
        if (!chartItem ||
            !isFftTwfChart(chartItem.userOptions.chartIdentifier) ||
            chartItem.userOptions.chartIdentifier !== point.series.userOptions.chartType) {
            return;
        }
        if (chartItem.customCrosshair) {
            chartItem.customCrosshair.element.remove();
        }
        chartItem.userOptions.lastFftTwfHoverPointX = point.x;
    });
};
export var syncRms = function (point) {
    Highcharts.charts.forEach(function (chartItem) {
        if (!chartItem || !isRmsChart(chartItem.userOptions.chartIdentifier)) {
            return;
        }
        var arrPoints = [];
        chartItem.series.forEach(function (seriesItem) {
            if (!seriesItem.data.length) {
                return;
            }
            if (chartItem.customCrosshair) {
                chartItem.customCrosshair.element.remove();
            }
            if (point.series.userOptions.installationPointId !== seriesItem.userOptions.installationPointId) {
                return;
            }
            var nearestPoint = getNearestPointIndex(seriesItem, point.x);
            arrPoints.push(seriesItem.points[nearestPoint]);
        });
        if (!arrPoints.length) {
            return;
        }
        chartItem.userOptions.lastRmsHoverPointsX = arrPoints.map(function (el) {
            return {
                x: el.x,
                name: el.series.name,
            };
        });
        chartItem.tooltip.refresh(arrPoints);
    });
};
var getRmsTooltipPosition = function (chart, labelWidth, labelHeight, lockTooltipPosition) {
    if (lockTooltipPosition === 'top-left') {
        return {
            x: chart ? 80 : 1200,
            y: 0,
        };
    }
    if (lockTooltipPosition === 'bottom-right') {
        return {
            x: chart ? chart.chartWidth - (labelWidth + 25) : 1200,
            y: chart ? chart.chartHeight - (labelHeight + 30) : 0,
        };
    }
    if (lockTooltipPosition === 'bottom-left') {
        return {
            x: chart ? 80 : 1200,
            y: chart ? chart.chartHeight - (labelHeight + 30) : 0,
        };
    }
    // top-right
    return {
        x: chart ? chart.chartWidth - (labelWidth + 25) : 1200,
        y: 0,
    };
};
export var positioner = function (labelWidth, labelHeight, point, chart) {
    if (chart.userOptions.isLockTooltip) {
        return getRmsTooltipPosition(chart, labelWidth, labelHeight, chart.userOptions.lockTooltipPosition);
    }
    return {
        x: point.plotX - 100 < labelWidth ? point.plotX + 100 : point.plotX - labelWidth + 40,
        y: chart.chartHeight - (labelHeight + 30),
    };
};
export var fftPositioner = function (labelWidth, chart) {
    return {
        x: chart ? chart.chartWidth - (labelWidth + 25) : 1200,
        y: 10,
    };
};
var formatSymbolTooltip = function (point) {
    return _get({
        circle: '●',
        diamond: '♦',
        square: '■',
        triangle: '▲',
        'triangle-down': '▼',
    }, point.point.series.symbol, '●');
};
var TooltipPointRow = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var point = _a.point, allPoints = _a.allPoints, visible = _a.visible, prefix = _a.prefix, readingTypes = _a.readingTypes;
    if (!visible) {
        return _jsx(React.Fragment, {});
    }
    var installationPointId = _get(point, 'series.userOptions.installationPointId');
    var chartIdentifier = ((_d = (_c = (_b = point.series) === null || _b === void 0 ? void 0 : _b.chart) === null || _c === void 0 ? void 0 : _c.userOptions) === null || _d === void 0 ? void 0 : _d.chartIdentifier) || ((_f = (_e = point.series) === null || _e === void 0 ? void 0 : _e.userOptions) === null || _f === void 0 ? void 0 : _f.chartIdentifier);
    if (!chartIdentifier) {
        return _jsx(_Fragment, {});
    }
    var isAccVel = [CHART_GROUPS.ACC, CHART_GROUPS.VEL].includes((_h = (_g = READING_TYPES[chartIdentifier]) === null || _g === void 0 ? void 0 : _g.groupId) !== null && _h !== void 0 ? _h : 0);
    var extraType = useMemo(function () { return (isAccVel ? READING_TYPES[chartIdentifier].extraType : ''); }, [chartIdentifier]);
    return (_jsxs(React.Fragment, { children: [installationPointId ? (_jsxs("a", { target: "_blank", rel: "noreferrer", href: makeValidLink("/".concat(prefix, "/dashboard?installation_point=").concat(installationPointId)), className: "cursor-pointer", style: { color: 'inherit' }, children: [_jsxs("span", { style: { color: point.color }, children: [_get(point, 'series.userOptions.sensorId') ? (getHexId(_get(point, 'series.userOptions.sensorId')) + ' /') : (_jsx(_Fragment, {})), ' ', formatSymbolTooltip(point), " ", point.series.name] }), ":\u00A0", _jsx("b", { children: Highcharts.numberFormat(getValueForNormalizeReadings(allPoints, point), _get(point, 'series.userOptions.precision') ||
                            ((_j = readingTypes[chartIdentifier]) === null || _j === void 0 ? void 0 : _j.precision) ||
                            2, '.', ' ') }), "\u00A0", _get(point, 'series.userOptions.units', ''), "\u00A0", extraType] })) : (_jsxs("span", { className: "cursor-pointer", children: [_jsxs("span", { style: { color: point.color }, children: [_get(point, 'series.userOptions.sensorId') ? (getHexId(_get(point, 'series.userOptions.sensorId')) + ' /') : (_jsx(_Fragment, {})), ' ', formatSymbolTooltip(point), " ", point.series.name] }), ":\u00A0", _jsx("b", { children: Highcharts.numberFormat(getValueForNormalizeReadings(allPoints, point), _get(point, 'series.userOptions.precision') ||
                            ((_k = READING_TYPES[chartIdentifier]) === null || _k === void 0 ? void 0 : _k.precision) ||
                            2, '.', ' ') }), "\u00A0", _get(point, 'series.userOptions.units', ''), "\u00A0", extraType] })), _jsx("br", {})] }));
};
var TooltipFftPointRow = function (_a) {
    var _b, _c, _d, _e, _f;
    var point = _a.point, userOptions = _a.userOptions, readingsYTwfAccelerationChart = _a.readingsYTwfAccelerationChart, readingTypes = _a.readingTypes, _g = _a.showRms, showRms = _g === void 0 ? true : _g;
    if (_get(point, 'series.options.seriesType') === 'envelope') {
        return _jsx(React.Fragment, {});
    }
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    return (_jsxs(React.Fragment, { children: [_jsxs("span", { children: [_jsxs("span", { className: "cursor-pointer", style: { color: point.series.color }, children: [point.series.name, ":\u00A0"] }), _jsx("b", { children: Highcharts.numberFormat(point.y, (_b = readingTypes[userOptions.chartIdentifier]) === null || _b === void 0 ? void 0 : _b.precision, '.', ' ') }), "\u00A0", getUoms(userOptions.chartIdentifier, measure, personalSettingMeasure)] }), showRms ? (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsxs("span", { children: ["RMS:\u00A0", _jsx("b", { children: Highcharts.numberFormat(userOptions.overall || ((_d = (_c = point === null || point === void 0 ? void 0 : point.series) === null || _c === void 0 ? void 0 : _c.userOptions) === null || _d === void 0 ? void 0 : _d.overall), readingTypes[userOptions.chartIdentifier].precision, '.', ' ') }), "\u00A0", getUoms(userOptions.chartIdentifier, measure, personalSettingMeasure)] })] })) : (_jsx(_Fragment, {})), _jsx("br", {}), isTwfChart(userOptions.chartIdentifier) ? (_jsxs(_Fragment, { children: [_jsxs("span", { children: ["PK-PK:\u00A0", _jsx("b", { children: Highcharts.numberFormat(userOptions.pkpk, (_e = readingTypes[userOptions.chartIdentifier]) === null || _e === void 0 ? void 0 : _e.precision, '.', ' ') }), "\u00A0", getUoms(userOptions.chartIdentifier, measure, personalSettingMeasure)] }), _jsx("br", {})] })) : (''), isTwfAccelerationChart(userOptions.chartIdentifier) && readingsYTwfAccelerationChart ? (_jsxs("span", { children: ["CF:\u00A0", _jsx("b", { children: Highcharts.numberFormat(getMaxAbsNumber(readingsYTwfAccelerationChart || []) / userOptions.overall, (_f = readingTypes[userOptions.chartIdentifier]) === null || _f === void 0 ? void 0 : _f.precision, '.', ' ') }), "\u00A0", getUoms(userOptions.chartIdentifier, measure, personalSettingMeasure)] })) : (_jsx(_Fragment, {}))] }));
};
export var RmsTooltipFormatter = function (_a) {
    var _b, _c, _d;
    var points = _a.points, allPoints = _a.allPoints, xValue = _a.xValue, timezone = _a.timezone, _e = _a.isVisibleLocker, isVisibleLocker = _e === void 0 ? true : _e, _f = _a.prefix, prefix = _f === void 0 ? '' : _f, _g = _a.readingTypes, readingTypes = _g === void 0 ? READING_TYPES : _g;
    var utcTime = ((_c = (_b = moment.tz.zone(timezone)) === null || _b === void 0 ? void 0 : _b.utcOffset(xValue)) !== null && _c !== void 0 ? _c : 0) * 60 * 1000 + xValue;
    points = points.filter(function (point) { return point.y !== null; });
    var sortedPoints = points.length > 1
        ? _orderBy(points, ['point.series.userOptions.isRpmData', 'y'], ['asc', 'desc'])
        : points;
    var shouldBeSliced = sortedPoints.length > 10 && !_get(points[0], 'series.chart.userOptions.isVisibleFullscreenChart', false);
    return (_jsxs("div", { className: "custom_tooltip", children: [sortedPoints.map(function (point, index) { return (_jsx(TooltipPointRow, { point: point, prefix: prefix, allPoints: allPoints, visible: !(shouldBeSliced && index >= 10), readingTypes: readingTypes }, "tooltip_row_".concat(index))); }), shouldBeSliced && (_jsxs("span", { className: "cursor-pointer view-all-overlays-btn", children: ["View all ", _jsx("br", {})] })), _jsxs("span", { children: [(_d = moment.tz.zone(timezone)) === null || _d === void 0 ? void 0 : _d.abbr(xValue / 1000), ":", ' ', Highcharts.dateFormat('%m/%d/%y\xa0\xa0\xa0', xValue), _jsx("b", { children: Highcharts.dateFormat('%I:%M %p', xValue) })] }), _jsx("br", {}), _jsxs("span", { children: ["UTC: ", Highcharts.dateFormat('%m/%d/%y\xa0\xa0\xa0', utcTime), _jsx("b", { children: Highcharts.dateFormat('%I:%M %p', utcTime) })] }), isVisibleLocker ? (_jsx(Button, { size: "sm", className: "custom_tooltip_locker", children: _jsx("i", { className: "fa fa-arrows-alt" }) })) : (_jsx(_Fragment, {}))] }));
};
export var FftTooltipType = function (_a) {
    var userOptions = _a.userOptions, xValue = _a.xValue, _b = _a.rpm, rpm = _b === void 0 ? undefined : _b, _c = _a.rpmFrom, rpmFrom = _c === void 0 ? undefined : _c, currentSpeed = _a.currentSpeed, equipmentSpeedTypeId = _a.equipmentSpeedTypeId, isAsset = _a.isAsset, isSensorSpeedType = _a.isSensorSpeedType;
    var frequency = FrequencyConverter.fromHz(xValue, currentSpeed).format(), cpm = FrequencyConverter.fromHz(xValue, currentSpeed).toCpm().format(), currentSpeedHz = FrequencyConverter.fromCpm(rpm || currentSpeed, rpm || currentSpeed).toHz().value, orders = currentSpeed > 0 ? FrequencyConverter.fromHz(xValue, currentSpeed).toOrders().format() : '';
    if (isTwfChart(userOptions.chartIdentifier)) {
        return (_jsxs("div", { children: [rpm || currentSpeed > 0 || rpmFrom === RPM_FROM.TACHOMETER ? (_jsxs(_Fragment, { children: [_jsxs("span", { children: [getRpmLabel(isAsset, isSensorSpeedType, equipmentSpeedTypeId, rpmFrom), " RPM:", ' ', _jsxs("b", { children: [Highcharts.numberFormat(rpm || currentSpeed, 2), " (", Highcharts.numberFormat(currentSpeedHz, 2), " Hz)"] }), "\u00A0"] }), _jsx("span", { className: "line" })] })) : (_jsx(_Fragment, {})), _jsxs("span", { children: ["Time: ", _jsx("b", { children: xValue }), " sec"] }), _jsx("br", {})] }));
    }
    return (_jsxs("div", { children: [rpm || currentSpeed > 0 || rpmFrom === RPM_FROM.TACHOMETER ? (_jsxs("span", { children: [getRpmLabel(isAsset, isSensorSpeedType, equipmentSpeedTypeId, rpmFrom), " RPM:", ' ', _jsxs("b", { children: [Highcharts.numberFormat(rpm || currentSpeed, 2), " (", Highcharts.numberFormat(currentSpeedHz, 2), ' ', "Hz)"] }), "\u00A0"] })) : (_jsx(_Fragment, {})), userOptions.lor && userOptions.fmax ? (_jsxs("span", { children: ["(", _jsx("b", { children: "FMax: " }), userOptions.fmax, "/", _jsx("b", { children: "LOR: " }), parseInt(userOptions.lor), ")"] })) : (_jsx(_Fragment, {})), _jsx("span", { className: "line" }), _jsxs("span", { children: ["Frequency: ", _jsx("span", { dangerouslySetInnerHTML: { __html: frequency + ' ' + cpm + ' ' + orders } })] }), _jsx("br", {})] }));
};
export var FftTooltip3dTrendFormatter = function (_a) {
    var chart = _a.chart, precision = _a.precision, units = _a.units;
    return (_jsxs("div", { className: "chart-3d-trend-tooltip", children: [_jsx("span", { children: chart.hoverPoint.category }), _jsx("br", {}), _jsxs("span", { children: [_jsx("b", { children: Highcharts.numberFormat(chart.hoverPoint.y, precision) }), " ", units] })] }));
};
export var FftTooltipFormatter = function (_a) {
    var _b, _c;
    var points = _a.points, xValue = _a.xValue, rpm = _a.rpm, rpmFrom = _a.rpmFrom, readingsYTwfAccelerationChart = _a.readingsYTwfAccelerationChart, readingTypes = _a.readingTypes, currentSpeed = _a.currentSpeed, isSensorSpeedType = _a.isSensorSpeedType, equipmentSpeedTypeId = _a.equipmentSpeedTypeId, isAsset = _a.isAsset, _d = _a.showPeaks, showPeaks = _d === void 0 ? true : _d, _e = _a.showRms, showRms = _e === void 0 ? true : _e, children = _a.children;
    var chartCursorType = ChartsStore(function (state) { return ({
        chartCursorType: state.chartCursorType,
    }); }, shallow).chartCursorType;
    var chart = {};
    if (points.length) {
        chart = points[0].series.chart;
        currentSpeed = chart.userOptions.currentSpeed || currentSpeed;
    }
    var currentSpeedHz = FrequencyConverter.fromCpm(currentSpeed, currentSpeed).toHz().value;
    return (_jsxs("div", { className: "custom_tooltip", children: [children, _jsx(FftTooltipNormalCursor, { showRms: showRms, isSensorSpeedType: isSensorSpeedType, equipmentSpeedTypeId: equipmentSpeedTypeId, isAsset: isAsset, points: points, currentSpeedHz: currentSpeedHz, currentSpeed: currentSpeed, xValue: xValue, rpm: rpm, rpmFrom: rpmFrom, readingsYTwfAccelerationChart: readingsYTwfAccelerationChart, readingTypes: readingTypes }), chartCursorType === 'harmonic' && isFftChart((_b = chart === null || chart === void 0 ? void 0 : chart.userOptions) === null || _b === void 0 ? void 0 : _b.chartIdentifier) ? (_jsx(FftTooltipHarmonicCursor, { currentSpeed: currentSpeed, chart: chart, readingTypes: readingTypes })) : (_jsx(_Fragment, {})), showPeaks && chartCursorType === 'normal' && isFftChart((_c = chart === null || chart === void 0 ? void 0 : chart.userOptions) === null || _c === void 0 ? void 0 : _c.chartIdentifier) ? (_jsx(FftTooltipPeaks, { currentSpeed: currentSpeed, chart: chart, readingTypes: readingTypes })) : (_jsx(_Fragment, {}))] }));
};
var FftTooltipNormalCursor = function (_a) {
    var points = _a.points, xValue = _a.xValue, isSensorSpeedType = _a.isSensorSpeedType, equipmentSpeedTypeId = _a.equipmentSpeedTypeId, isAsset = _a.isAsset, rpm = _a.rpm, rpmFrom = _a.rpmFrom, currentSpeed = _a.currentSpeed, currentSpeedHz = _a.currentSpeedHz, readingsYTwfAccelerationChart = _a.readingsYTwfAccelerationChart, readingTypes = _a.readingTypes, _b = _a.showRms, showRms = _b === void 0 ? true : _b;
    var userOptions = {}, chart = {}, isSidebandByRmp;
    if (points.length) {
        userOptions = points[0].series.chart.userOptions;
        chart = points[0].series.chart;
        currentSpeed = chart.userOptions.currentSpeed || currentSpeed;
        isSidebandByRmp = chart.altIsPressed && isTwfChart(userOptions.chartIdentifier) && currentSpeedHz > 0;
    }
    var showSidebandInfo = userOptions.chartCursorType === 'sideband' &&
        userOptions.sideBandPoint &&
        chart &&
        chart.isSidebandPoint(), dfrqValue = showSidebandInfo
        ? Math.abs(isSidebandByRmp
            ? 1 / currentSpeedHz
            : userOptions.isLockedFftSidebandLineCursor
                ? userOptions.sideBandSecondHoverPoint.x - userOptions.sideBandPoint.x
                : xValue - userOptions.sideBandPoint.x)
        : 0, dfrqValueTwf = showSidebandInfo && isTwfChart(userOptions.chartIdentifier)
        ? Math.abs(1 /
            (isSidebandByRmp
                ? 1 / currentSpeedHz
                : userOptions.isLockedFftSidebandLineCursor
                    ? userOptions.sideBandSecondHoverPoint.x - userOptions.sideBandPoint.x
                    : xValue - userOptions.sideBandPoint.x))
        : 0, cpm = FrequencyConverter.fromHz(isTwfChart(userOptions.chartIdentifier) ? dfrqValueTwf : dfrqValue, currentSpeed)
        .toCpm()
        .format(), orders = currentSpeed > 0
        ? FrequencyConverter.fromHz(isTwfChart(userOptions.chartIdentifier) ? dfrqValueTwf : dfrqValue, currentSpeed)
            .toOrders()
            .format()
        : '';
    return (_jsxs(_Fragment, { children: [_jsx(FftTooltipType, { userOptions: userOptions, xValue: xValue, rpm: rpm, rpmFrom: rpmFrom, currentSpeed: currentSpeed, equipmentSpeedTypeId: equipmentSpeedTypeId, isAsset: isAsset, isSensorSpeedType: isSensorSpeedType }), points.map(function (point, index) { return (_jsx(TooltipFftPointRow, { showRms: showRms, readingsYTwfAccelerationChart: readingsYTwfAccelerationChart, point: point, userOptions: userOptions, readingTypes: readingTypes }, "tooltip_fft_row_".concat(index))); }), showSidebandInfo && (_jsxs(React.Fragment, { children: [_jsx("br", {}), _jsxs("span", { children: ["Dfrq:", _jsxs("b", { children: [" ", dfrqValue.toFixed(2), " "] }), isTwfChart(userOptions.chartIdentifier) ? 'sec' : 'Hz', isTwfChart(userOptions.chartIdentifier) ? (_jsxs("span", { children: [_jsxs("b", { children: [" ", dfrqValueTwf.toFixed(2)] }), " Hz"] })) : (_jsx(_Fragment, {})), _jsx("span", { dangerouslySetInnerHTML: { __html: ' ' + cpm + ' ' + orders } })] })] }))] }));
};
var FftTooltipHarmonicCursor = function (_a) {
    var _b;
    var chart = _a.chart, currentSpeed = _a.currentSpeed, readingTypes = _a.readingTypes;
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "line" }), _jsxs("div", { className: "mb-2 d-flex justify-content-between", children: [_jsx("span", { children: "Harmonics" }), _jsx("span", { className: "tooltip-harmonic-toggle-btn", children: chart.userOptions.hiddenHarmonicsInTooltip ? '+' : '-' })] }), !chart.userOptions.hiddenHarmonicsInTooltip ? (_jsx(_Fragment, { children: _jsx("div", { className: "tooltip-harmonics-container", children: (((_b = chart === null || chart === void 0 ? void 0 : chart.userOptions) === null || _b === void 0 ? void 0 : _b.storedHarmonicPoints) || []).map(function (harmonicPoint, index) {
                        var _a;
                        return (_jsxs("div", { children: [index + 1, ".\u00A0", _jsxs("b", { children: [isTwfChart(chart.userOptions.chartIdentifier)
                                            ? harmonicPoint.x
                                            : Highcharts.numberFormat(FrequencyConverter.fromHz(harmonicPoint.x, currentSpeed).toType(chart.userOptions.units).value, 2, '.', ' '), "\u00A0", isTwfChart(chart.userOptions.chartIdentifier) ? 'sec' : chart.userOptions.units, "\u00A0", Highcharts.numberFormat(harmonicPoint.y, (_a = readingTypes[chart.userOptions.chartIdentifier]) === null || _a === void 0 ? void 0 : _a.precision, '.', ' '), "\u00A0", getUoms(chart.userOptions.chartIdentifier, measure, personalSettingMeasure)] })] }, "next_harmonic_".concat(index)));
                    }) }) })) : (_jsx(_Fragment, {}))] }));
};
var FftTooltipPeaks = function (_a) {
    var _b;
    var chart = _a.chart, currentSpeed = _a.currentSpeed, readingTypes = _a.readingTypes;
    var peaks = _orderBy(((_b = chart === null || chart === void 0 ? void 0 : chart.userOptions) === null || _b === void 0 ? void 0 : _b.peaks) || [], [1], 'desc').slice(0, 10);
    var measure = selectMeasure();
    var personalSettingMeasure = selectPersonalSettingMeasure();
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "line" }), _jsxs("div", { className: "mb-2 d-flex justify-content-between", children: [_jsx("span", { children: "Peaks" }), _jsx("span", { className: "tooltip-peaks-toggle-btn", children: chart.userOptions.visiblePeaksInTooltip ? '-' : '+' })] }), chart.userOptions.visiblePeaksInTooltip ? (_jsx(_Fragment, { children: _jsx("div", { className: "tooltip-harmonics-container", children: peaks.map(function (peak, index) {
                        var _a;
                        return (_jsxs("div", { children: [index + 1, ".\u00A0", _jsxs("b", { children: [Highcharts.numberFormat(peak[1], (_a = readingTypes[chart.userOptions.chartIdentifier]) === null || _a === void 0 ? void 0 : _a.precision, '.', ' '), "\u00A0", getUoms(chart.userOptions.chartIdentifier, measure, personalSettingMeasure), "\u00A0", _jsx("span", { dangerouslySetInnerHTML: {
                                                __html: FrequencyConverter.fromHz(peak[0], currentSpeed).toHz().format(2) +
                                                    ' ' +
                                                    FrequencyConverter.fromHz(peak[0], currentSpeed).toCpm().format(2) +
                                                    ' ' +
                                                    (currentSpeed > 0
                                                        ? FrequencyConverter.fromHz(peak[0], currentSpeed).toOrders().format(2)
                                                        : ''),
                                            } })] })] }, "next_harmonic_".concat(index)));
                    }) }) })) : (_jsx(_Fragment, {}))] }));
};
var getRpmLabel = function (isAsset, isSensorSpeedType, equipmentSpeedTypeId, rpmFrom) {
    if (rpmFrom === RPM_FROM.READING || rpmFrom === RPM_FROM.TACHOMETER || isAsset) {
        return rpmFrom;
    }
    if (isSensorSpeedType || equipmentSpeedTypeId === SPEED_TYPES.RATIO) {
        return 'Sensor';
    }
    if (equipmentSpeedTypeId === SPEED_TYPES.FIXED) {
        return 'Equipment';
    }
    if (equipmentSpeedTypeId === SPEED_TYPES.VARIABLE) {
        return 'Detected Variable';
    }
    return '';
};
