import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { memo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { selectIsVisibleSnoozeModal } from '../../store/AIPreAi/useActionItemModalsStore';
var SnoozeModal = function (_a) {
    var onCancel = _a.onCancel, snoozeMutate = _a.snoozeMutate, snoozeIsLoading = _a.snoozeIsLoading, onSuccess = _a.onSuccess;
    var actionItemId = useParams().id;
    var _b = useState(''), description = _b[0], setDescription = _b[1];
    var _c = useState(''), date = _c[0], setDate = _c[1];
    var isVisibleSnoozeModal = selectIsVisibleSnoozeModal();
    var onConfirm = function () {
        snoozeMutate({
            action_list_id: +actionItemId,
            description: description,
            count_days: moment(date).diff(moment(), 'days') + 1,
        }, {
            onSuccess: function () {
                toast.success('Successfully snoozed', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                onCancel();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            },
        });
    };
    return (_jsxs(Modal, { size: "sm", className: "modal-response", toggle: onCancel, isOpen: isVisibleSnoozeModal, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Remind later?" }), _jsx(ModalBody, { children: _jsxs(Form, { children: [_jsxs(FormGroup, { children: [_jsx(Label, { for: "textarea", children: "Remind me on this date:" }), _jsx(DatePicker, { id: "datepicker", className: "form-control snooze-datepicker", minDate: moment().add(1, 'days').toDate(), selected: date, onChange: function (date) { return setDate(date); } })] }), _jsxs(FormGroup, { children: [_jsx(Label, { for: "textarea", children: "Description:" }), _jsx(Input, { id: "textarea", label: "description", type: "textarea", required: true, onChange: function (ev) { return setDescription(ev.currentTarget.value); } })] })] }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", disabled: snoozeIsLoading, onClick: onConfirm, children: "Confirm" })] }) })] }));
};
export default memo(SnoozeModal);
