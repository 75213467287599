import { array, literal, maxLength, minLength, number, object, optional, pipe, union } from 'valibot';
export var bearingMonitoredPositionsRules = function (points) {
    var max = points.length >= 5 ? 5 : points.length;
    return pipe(array(object({
        id: union(points.map(function (pointId) { return literal(pointId); }), 'Id incorrect'),
        installationPointId: number('Installation point id is number type'),
        bearings: optional(array(object({
            type: union([literal('onShaft'), literal('ratio')], 'type incorrect'),
            bearingId: pipe(number('Bearing Id should be number')),
            value: number('Value is number type'),
            plotlines: number('Plotlines is number type'),
        }))),
    })), minLength(1, 'Bearing monitored position is required'), maxLength(max, "Maximum ".concat(max, " points")));
};
