var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { selectAdvancedSearch, selectDateFrom, selectDateTo, selectHideHardwareAi, selectIncreaseSeverity, selectInstallationPointId, selectIsActive, selectOnlyHardwareAi, selectPageDataTable, selectPageSizeDataTable, selectReadingType, selectSearchDataTable, selectSelectedAiType, selectSelectedCustomers, selectSelectedFacility, selectSelectedLocation, selectSelectedStatuses, selectSortDataTable, selectUrgency, selectWatchingFilter, } from '@sharedStore/actionItemsList/useActionItemsListStore';
import Api from '../../../api/api';
export var actionItemsListKey = function (options) { return ['actionItemsList', options]; };
var actionItemsListFn = function (options, endpoint, host) {
    var _a;
    return Api.get(endpoint || 'api/analytic/action-item/list', {
        query: {
            only_active: +options.isActive,
            hide_hardware_ai: +options.hideHardwareAi,
            only_hardware_ai: +options.onlyHardwareAi,
            selected_statuses: options.selectedStatuses,
            selected_ai_type: options.selectedAiType,
            selected_customer_ids: options.selectedCustomers,
            selected_location_id: options.selectedLocation,
            selected_facility: options.selectedFacility,
            installation_point_id: options.installationPointId,
            increase_severity: +options.increaseSeverity,
            pagination: {
                page: options.page,
                pageSize: options.pageSize,
            },
            sort: options.sort,
            watching_filter: options.watchingFilter,
            search: options.search,
            advanced_search: +options.advancedSearch,
            urgency: options.urgency,
            reading_type: options.readingType,
            date_from: options.dateFrom ? moment(options.dateFrom).format('YYYY-MM-DD 00:00:00') : null,
            date_to: options.dateTo ? moment(options.dateTo).format('YYYY-MM-DD 00:00:00') : null,
            is_customer_request: Number(!!((_a = options.selectedStatuses) === null || _a === void 0 ? void 0 : _a.customer_requested) || 0),
        },
        host: host || APP_API_V2_URL,
    });
};
export var useActionItemsListQuery = function (_a) {
    var options = _a.options, endpoint = _a.endpoint, host = _a.host;
    var queryOptions = {
        isActive: selectIsActive(),
        hideHardwareAi: selectHideHardwareAi(),
        onlyHardwareAi: selectOnlyHardwareAi(),
        selectedStatuses: selectSelectedStatuses(),
        selectedAiType: selectSelectedAiType(),
        selectedCustomers: selectSelectedCustomers(),
        selectedFacility: selectSelectedFacility(),
        selectedLocation: selectSelectedLocation(),
        installationPointId: selectInstallationPointId(),
        increaseSeverity: selectIncreaseSeverity(),
        page: selectPageDataTable(),
        pageSize: selectPageSizeDataTable(),
        sort: selectSortDataTable(),
        watchingFilter: selectWatchingFilter(),
        search: selectSearchDataTable(),
        advancedSearch: selectAdvancedSearch(),
        urgency: selectUrgency(),
        readingType: selectReadingType(),
        dateFrom: selectDateFrom(),
        dateTo: selectDateTo(),
    };
    return useQuery({
        queryKey: actionItemsListKey(queryOptions),
        queryFn: function () { return actionItemsListFn(queryOptions, endpoint, host); },
        select: function (_a) {
            var data = _a.data, meta = _a.meta;
            return {
                config: __assign(__assign({}, options.config), { data: data, pagination: __assign(__assign({}, options.config.pagination), { total: meta.total, page: queryOptions.page, pageSize: queryOptions.pageSize }), search: queryOptions.search, sort: queryOptions.sort }),
            };
        },
        placeholderData: function (previousData) {
            return (previousData !== null && previousData !== void 0 ? previousData : {
                data: [],
                total: 0,
            });
        },
    });
};
