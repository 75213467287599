var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'clsx';
import { Fragment, memo } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { DRIVEN_SUBTYPE_SIMPLE_SHAFT } from '../../../../constants';
import styles from './styles.module.scss';
export var ConfigurationSchemas = memo(function (_a) {
    var _b;
    var _c;
    var isDisabled = _a.isDisabled, bearingMonitoredPositionsFieldName = _a.bearingMonitoredPositionsFieldName, bearingMonitoredPositionsVariantFieldName = _a.bearingMonitoredPositionsVariantFieldName, schemas = _a.schemas, hasGap = _a.hasGap;
    var _d = useFormContext(), clearErrors = _d.clearErrors, control = _d.control;
    var _e = useController({
        name: bearingMonitoredPositionsFieldName,
        control: control,
    }).field, value = _e.value, onChange = _e.onChange;
    var _f = useController({
        name: bearingMonitoredPositionsVariantFieldName,
        control: control,
    }).field, bearingMonitoredPositionsVariant = _f.value, onChangeBearingMonitoredPositionsVariant = _f.onChange;
    var isChecked = function (id) { return value.some(function (el) { return el.id === id; }); };
    var onChangeCheckbox = function (id) {
        clearErrors(bearingMonitoredPositionsFieldName);
        if (isChecked(id)) {
            onChange(value.filter(function (el) { return el.id !== id; }));
        }
        else {
            if (value.length >= 4) {
                return;
            }
            onChange(__spreadArray(__spreadArray([], value, true), [{ id: id }], false));
        }
    };
    var onChangeRadio = function (val) {
        clearErrors(bearingMonitoredPositionsVariantFieldName);
        onChangeBearingMonitoredPositionsVariant(val);
        onChange([]);
    };
    var _g = useWatch({
        control: control,
        name: ['isGearbox', 'data'],
    }), isGearbox = _g[0], data = _g[1];
    var isSimpleShaft = ((_c = data === null || data === void 0 ? void 0 : data.drivenTypeVariant) === null || _c === void 0 ? void 0 : _c.subtype) === DRIVEN_SUBTYPE_SIMPLE_SHAFT;
    return (_jsx("div", { className: cx(styles.grouped, (_b = {}, _b[styles.ml] = isSimpleShaft, _b)), children: schemas.map(function (schema, index) {
            var _a, _b;
            return (_jsxs("div", { className: cx(styles.row, (_a = {},
                    _a[styles.gap] = hasGap,
                    _a)), children: [schemas.length > 1 && (_jsx("input", { type: "radio", className: styles.radio, disabled: isDisabled, checked: bearingMonitoredPositionsVariant === index + 1, onChange: function () { return onChangeRadio(index + 1); } })), _jsx("div", { className: styles.wrapper, children: _jsx("div", { className: cx(styles.block, (_b = {},
                                _b[styles.gridTwoColumn] = (isGearbox && index === 0) || (data.isDriven && index === 1),
                                _b[styles.gridThreeColumn] = isGearbox && index === 1,
                                _b[styles.left] = data.isDriven && index === 0,
                                _b)), children: schema.map(function (el, i) { return (_jsxs(Fragment, { children: [isGearbox && index === 1 && i === 1 && _jsx("div", {}), _jsxs("label", { htmlFor: "ip-".concat(index + 1).concat(el), children: [el, _jsx("input", { id: "ip-".concat(index + 1).concat(el), disabled: isDisabled || (schemas.length > 1 && bearingMonitoredPositionsVariant !== index + 1), checked: schemas.length > 1
                                                    ? isChecked(el) && bearingMonitoredPositionsVariant === index + 1
                                                    : isChecked(el), type: "checkbox", onChange: function () { return onChangeCheckbox(el); } })] })] }, "ip-".concat(index + 1).concat(el))); }) }) })] }, "ip-wrapper-".concat(index + 1)));
        }) }));
});
ConfigurationSchemas.displayName = 'ConfigurationSchemas';
