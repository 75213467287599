var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQueryClient as useQueryClientUntyped } from '@tanstack/react-query';
import { memo, useRef } from 'react';
import { RiSortAsc } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { ProcessingIncreaseSeverityModal, } from '@sharedModals/AIPreAi/ProcessingIncreaseSeverityModal';
import { useTitle } from '../../../../customHooks';
import Loader from '../../../../shared/components/Loader';
import ActivityLog from '../../../../shared/components/actionItem/activityLog';
import useDeactivationAnalystLog from '../../../../shared/hooks/AIPreAi/useDeactivationAnalystLog';
import ReportEditorEntityEditModal from '../../../../shared/modals/reportEditor/EntityEditModal';
import { useDeactivationAiAnalystLogMutation } from '../../../../shared/services/actionItems/useDeactivationAiAnalystLogMutation';
import { useSeverityIncreaseQuery } from '../../../../shared/services/actionItems/useSeverityIncreaseQuery';
import { selectReportEditorEntityEditState, useActionItemModalsActions, } from '../../../../shared/store/AIPreAi/useActionItemModalsStore';
import { selectIsEditSections, useActionItemViewActions, } from '../../../../shared/store/actionItem/useActionItemViewStore';
import '../../../../shared/styles/AIPreAi/index.scss';
import FooterTools from '../../../../widgets/FooterTools/FooterTools';
import FooterToolsToggleBtn from '../../../../widgets/FooterTools/components/ToggleBtn/ToogleBtn';
import Content from '../../../components/actionItem/view/Content';
import Header from '../../../components/actionItem/view/Header';
import { actionItemKey, useActionItemQuery, } from '../../../services/actionItems/useActionItemQuery';
import { useToggleCustomerRequestMutation } from '../../../services/actionItems/useCustomerRequestMutation';
var useQueryClient = useQueryClientUntyped;
var ActionItemViewPage = function () {
    var queryClient = useQueryClient();
    var actionItemId = useParams().id;
    useTitle('Action Item');
    var _a = useActionItemQuery({ actionItemId: actionItemId }), actionItemData = _a.data, isLoading = _a.isLoading, isFetching = _a.isFetching, isError = _a.isError, refetchActionItem = _a.refetch;
    var _b = actionItemData || {}, _c = _b.storage, storage = _c === void 0 ? null : _c, _d = _b.isCustomerCreated, isCustomerCreated = _d === void 0 ? false : _d, _e = _b.isCustomerRequested, isCustomerRequested = _e === void 0 ? false : _e;
    var deactivationAiAnalystLogMutate = useDeactivationAiAnalystLogMutation({
        storage: storage !== null && storage !== void 0 ? storage : null,
    }).mutate;
    var reportEditorEntityEditState = selectReportEditorEntityEditState();
    var setReportEditorEntityEditState = useActionItemModalsActions().setReportEditorEntityEditState;
    useDeactivationAnalystLog(actionItemId, deactivationAiAnalystLogMutate);
    var isEditSections = selectIsEditSections();
    var setIsEditSections = useActionItemViewActions().setIsEditSections;
    var toggleCustomerRequest = useToggleCustomerRequestMutation({
        actionItemId: actionItemId,
        isCustomerCreated: isCustomerCreated,
    }, {
        onSuccess: function () {
            if (actionItemId) {
                queryClient.invalidateQueries({ queryKey: actionItemKey(actionItemId) });
            }
        },
    }).mutate;
    var deleteCustomerRequestTag = function () {
        if (isCustomerCreated && isCustomerRequested) {
            toggleCustomerRequest(0);
        }
    };
    var refetchSeverityIncrease = useSeverityIncreaseQuery({
        actionItemId: actionItemId,
    }).refetch;
    var processingIncreaseSeverityModalRef = useRef({});
    var deleteSeverityIncrease = function (beforeAction) { return __awaiter(void 0, void 0, void 0, function () {
        var hasSeverityIncrease, callback;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refetchSeverityIncrease()];
                case 1:
                    hasSeverityIncrease = (_a.sent()).data;
                    callback = processingIncreaseSeverityModalRef.current.deleteSeverityIncrease;
                    if (hasSeverityIncrease && callback) {
                        return [2 /*return*/, callback({
                                isVisibleModal: true,
                                itemId: actionItemId,
                                storage: storage,
                                beforeAction: beforeAction,
                                isPreAI: false,
                            })];
                    }
                    return [2 /*return*/, Promise.resolve(true)];
            }
        });
    }); };
    return (_jsxs("div", { className: "page-actionItem", children: [(isLoading || isFetching) && (_jsx("div", { className: "loader", children: _jsx(Loader, { variant: "loader-lg" }) })), actionItemData && (_jsxs(_Fragment, { children: [_jsx(Header, { onBeforeStatusChange: function () { return deleteSeverityIncrease('status change'); }, onSuccessStatusChange: deleteCustomerRequestTag }), _jsxs("div", { className: "d-flex", children: [_jsx(Content, { onSuccessCompleteReport: deleteCustomerRequestTag, onBeforeCompleteReport: function () { return deleteSeverityIncrease('report completion'); } }), _jsx(ActivityLog, { actionItemData: actionItemData, storage: storage, onSuccessDeleteSeverityIncrease: function () { return refetchActionItem(); }, onSuccessSaveComment: deleteCustomerRequestTag, onBeforeComment: function () { return deleteSeverityIncrease('posting a comment'); } }), _jsx(ReportEditorEntityEditModal, { reportEditorEntityEditState: reportEditorEntityEditState, onCancel: function () {
                                    return setReportEditorEntityEditState(__assign(__assign({}, reportEditorEntityEditState), { visible: false }));
                                } })] }), _jsx(FooterTools, { children: _jsx(FooterToolsToggleBtn, { isSelected: isEditSections, onClick: function () { return setIsEditSections(!isEditSections); }, children: _jsx(RiSortAsc, {}) }) }), _jsx(ProcessingIncreaseSeverityModal, { ref: processingIncreaseSeverityModalRef, onSuccess: function () {
                            refetchActionItem();
                            refetchSeverityIncrease();
                        } })] })), isError && _jsx("p", { children: "Something went wrong!" })] }));
};
export default memo(ActionItemViewPage);
