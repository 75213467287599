var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, get as _get, size as _size } from 'lodash';
import React, { memo, useCallback, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../api/endpoints/endpoints';
import { SYSTEM_TYPES_DATA } from '../../../constants';
import { usePrevious } from '../../../customHooks';
import ChartsWrapper from '../../../shared/components/charts/chartsWrapper/ChartsWrapper';
import ToggleFooterBar from '../../../shared/components/charts/components/ToggleFooterBar';
import FooterBar from '../../../shared/components/charts/footerBar/FooterBar';
import Filter from '../../../shared/components/charts/leftEquipmentList/Filter';
import LeftEquipmentList from '../../../shared/components/charts/leftEquipmentList/LeftEquipmentList';
import RightBar from '../../../shared/components/charts/rightBar/RightBar';
import TopBar from '../../../shared/components/charts/topBar/TopBar';
import { isSeverityIncreasedPreAi } from '../../../shared/components/charts/topBar/components/Labels/features';
import HighlighterWord from '../../../shared/components/shared/HighlighterWord';
import { SystemTypeContext } from '../../../shared/context/SystemTypeContext';
import { abortOverlayRequests, updateUrl } from '../../../shared/features/charts/leftEquipmentList';
import { scrollToChart } from '../../../shared/features/charts/shared';
import { useDeleteInstallationPointAnalystLog } from '../../../shared/hooks/charts/shared/useDeleteInstallationPointAnalystLog';
import { useSetInstallationPointStore } from '../../../shared/hooks/charts/shared/useSetInstallationPointStore';
import FormAnalystNoteModal from '../../../shared/modals/analystNotes/FormAnalystNoteModal';
import ModalsWrapper from '../../../shared/modals/charts/ModalsWrapper';
import { useAnalystNotesCreateMutation } from '../../../shared/services/notes/analystNotes/useAnalystNotesCreateMutation';
import { useAnalystNotesDeleteAttachmentMutation } from '../../../shared/services/notes/analystNotes/useAnalystNotesDeleteAttachmentMutation';
import { useAnalystNotesUpdateMutation } from '../../../shared/services/notes/analystNotes/useAnalystNotesUpdateMutation';
import { selectNote } from '../../../shared/store/analystNotes/useAnalystNotesFormModalStore';
import { ChartsStore } from '../../../shared/store/charts/chartsContent/ChartsStore';
import { InstallationPointStore } from '../../../shared/store/charts/chartsContent/InstallationPointStore';
import { InListStore } from '../../../shared/store/charts/inList/InListStore';
import { LeftEquipmentListStore } from '../../../shared/store/charts/leftEquipmentList/LeftEquipmentListStore';
import { selectMeasure, useGlobalActions } from '../../../shared/store/global/useGlobalStore';
import SelectsGroup from '../../components/analystNotes/SelectsGroup';
import InstallationInfoBlock from '../../components/charts/topbar/InstallationInfoBlock';
import { config as systemConfig } from '../../config/analystNotes/dataTableConfig';
import { ANALYST_NOTES_TAGS } from '../../constants';
var ChartsPage = function (_a) {
    var _b = _a.selectedFacility, selectedFacility = _b === void 0 ? null : _b, _c = _a.selectedZone, selectedZone = _c === void 0 ? null : _c, _d = _a.selectedArea, selectedArea = _d === void 0 ? null : _d, layoutDispatch = _a.layoutDispatch;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var prefix = SYSTEM_TYPES_DATA[selectedSystemType].prefix;
    var scrollTo = ChartsStore(function (state) { return ({
        scrollTo: state.scrollTo,
    }); }, shallow).scrollTo;
    var _e = InListStore(function (state) { return ({
        inList: state.inList,
        setInList: state.setInList,
    }); }, shallow), inList = _e.inList, setInList = _e.setInList;
    var _f = InstallationPointStore(function (state) { return ({
        setStatesInstallationPointStore: state.setStates,
        installationPoint: state.installationPoint,
        setActiveInstallationPoint: state.setActiveInstallationPoint,
        overlayInstallationPoints: state.overlayInstallationPoints,
    }); }, shallow), setStatesInstallationPointStore = _f.setStatesInstallationPointStore, installationPoint = _f.installationPoint, setActiveInstallationPoint = _f.setActiveInstallationPoint, overlayInstallationPoints = _f.overlayInstallationPoints;
    var _g = LeftEquipmentListStore(function (state) { return ({
        filter: state.filter,
        setFilter: state.setFilter,
        resetFilters: state.resetFilters,
        toggleExpand: state.toggleExpand,
        setLoader: state.setLoader,
        offset: state.offset,
        fetchEquipments: state.fetchEquipments,
    }); }, shallow), filter = _g.filter, setFilter = _g.setFilter, resetFilters = _g.resetFilters, toggleExpand = _g.toggleExpand, setLoader = _g.setLoader, offset = _g.offset, fetchEquipments = _g.fetchEquipments;
    var prevInstallationPoint = usePrevious(installationPoint);
    useSetInstallationPointStore(function (id) { return Endpoints.amazon.getInstallationPointInfo(id); });
    useEffect(function () {
        if (!installationPoint.id) {
            return;
        }
        scrollToChart(scrollTo);
    }, [installationPoint.id]);
    useDeleteInstallationPointAnalystLog(prefix, function (id) {
        return Endpoints.amazon.deleteInstallationPointAnalystLog(id, {}, { keepalive: true });
    });
    var id = isSeverityIncreasedPreAi(installationPoint, inList)
        ? _get(inList, ['preActionList', installationPoint.id, 'id'])
        : _get(inList, ['actionList', installationPoint.id, 'id']);
    var setMeasure = useGlobalActions().setMeasure;
    var measure = selectMeasure();
    var fetchEquipmentsCallback = function (resetOffset) {
        var params = new URLSearchParams(window.location.search);
        var overlayPointsIds = params.getAll('overlay_points');
        fetchEquipments(function () {
            return Endpoints.amazon.getEquipmentsList({
                query: __assign(__assign({ selected_facility: selectedFacility, selected_zone: selectedZone, selected_area: selectedArea }, filter), { offset: resetOffset ? 0 : offset }),
            });
        }, function (equipments) {
            var _a;
            if (equipments.length === 0) {
                return;
            }
            var overlayInstallationPoints = [];
            equipments.map(function (equipment) {
                equipment.installationPoints.map(function (installationPoint) {
                    if (overlayPointsIds.includes(installationPoint.id.toString())) {
                        overlayInstallationPoints.push(installationPoint);
                    }
                });
            });
            var activeInstallationPoint = _get(equipments, '0.installationPoints.0');
            var measurement = (_a = activeInstallationPoint === null || activeInstallationPoint === void 0 ? void 0 : activeInstallationPoint.facilityTable) === null || _a === void 0 ? void 0 : _a.measurement_units;
            if (measurement && measurement !== measure) {
                toast.info(_jsxs("div", { children: ["Your measurement settings are different from the facility", _jsx(Button, { color: "primary", size: "sm", style: {
                                marginTop: '8px',
                                fontSize: '14px',
                                letterSpacing: '0.5px',
                            }, onClick: function () { return setMeasure(measurement); }, children: "Apply facility measurement" })] }), { style: { cursor: 'default' }, autoClose: 3500, position: 'bottom-left' });
            }
            setFilter('installation_point', _get(activeInstallationPoint, 'id', filter.installation_point));
            setActiveInstallationPoint(activeInstallationPoint, overlayInstallationPoints, _get(equipments, '0'), true);
            updateUrl(prefix, activeInstallationPoint, overlayInstallationPoints, prevInstallationPoint, "Charts Dashboard - ".concat(activeInstallationPoint.name));
        }, setInList, inList, resetOffset);
    };
    var onOverlayCheck = useCallback(function (installationPoint) {
        if (!installationPoint) {
            setStatesInstallationPointStore({ overlayInstallationPoints: [] });
            return;
        }
        var alreadyCheckedByOverlay = _size(overlayInstallationPoints.filter(function (overlayInstallationPoint) { return +overlayInstallationPoint.id === +installationPoint.id; })) > 0;
        var changedOverlayInstallationPoints = __spreadArray([], overlayInstallationPoints, true);
        if (alreadyCheckedByOverlay) {
            changedOverlayInstallationPoints = changedOverlayInstallationPoints.filter(function (changedOverlayInstallationPoint) { return +changedOverlayInstallationPoint.id !== +installationPoint.id; });
        }
        else {
            var updatedInstallationPoint = _cloneDeep(installationPoint);
            updatedInstallationPoint.id = +updatedInstallationPoint.id;
            abortOverlayRequests();
            changedOverlayInstallationPoints.push(updatedInstallationPoint);
        }
        setStatesInstallationPointStore({ overlayInstallationPoints: changedOverlayInstallationPoints });
    }, [overlayInstallationPoints]);
    var equipmentTitle = useCallback(function (equipment) {
        return _get(equipment, 'facility', '');
    }, []);
    var equipmentName = useCallback(function (equipment) {
        return (_jsx("div", { className: "d-block equipment-code", children: _jsx(HighlighterWord, { searchWords: filter.search, block: false, textToHighlight: equipment.equipment_desc }) }));
    }, [filter]);
    var facilityName = _get(installationPoint, 'facility') ? _get(installationPoint, 'facility') + ' - ' : '';
    var equipment_name = _get(installationPoint, 'equipment_name')
        ? _get(installationPoint, 'equipment_name') + ' - '
        : '';
    var component = _get(installationPoint, 'component') ? _get(installationPoint, 'component') + ' - ' : '';
    var alias = _get(installationPoint, 'alias') ? _get(installationPoint, 'alias') + ' - ' : '';
    var point_name = _get(installationPoint, 'point_name', '');
    var title = facilityName + equipment_name + component + alias + point_name;
    var country = _get(installationPoint, 'facilityTable.country', '');
    var facilityMeasure = _get(installationPoint, 'facilityTable.measurement_units', measure);
    var _h = useAnalystNotesDeleteAttachmentMutation(), deleteAttachmentMutate = _h.mutate, deleteAttachmentIsLoading = _h.isPending;
    var options = {
        installationPointId: installationPoint.id,
        config: systemConfig(layoutDispatch, true),
        additionOptions: {
            selected_facility_id: selectedFacility,
        },
    };
    var note = selectNote();
    var _j = useAnalystNotesUpdateMutation(), updateMutate = _j.mutate, updateIsLoading = _j.isPending;
    var _k = useAnalystNotesCreateMutation(), createMutate = _k.mutate, createIsLoading = _k.isPending;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "left-bar", children: [_jsx(Filter, { setFilter: setFilter, filter: filter, resetFilters: resetFilters, toggleExpand: toggleExpand, onOverlayCheck: onOverlayCheck, onFilterChange: function (name) {
                            setFilter(name, +!filter[name]);
                            setLoader(true);
                        } }), _jsx(LeftEquipmentList, { prevInstallationPoint: prevInstallationPoint, fetchEquipmentsCallback: fetchEquipmentsCallback, fetchEquipmentsCallbackBySearch: function () { return fetchEquipmentsCallback(true); }, deps: [selectedFacility, selectedZone, selectedArea], onOverlayCheck: onOverlayCheck, equipmentTitle: equipmentTitle, equipmentName: equipmentName, setFilter: setFilter })] }), _jsx("div", { className: "charts-page-container", children: _jsxs("div", { className: "charts-wrapper-content", children: [_jsx(TopBar, { title: title, country: country, facilityMeasure: facilityMeasure, children: _jsx(InstallationInfoBlock, { setFilter: setFilter, layoutDispatch: layoutDispatch }) }), _jsx(ChartsWrapper, {}), _jsx(ToggleFooterBar, {}), _jsx(FooterBar, {}), _jsx(RightBar, {})] }) }), _jsx(ModalsWrapper, { title: title, options: options, additionalNoteParam: function (note) {
                    return {
                        zone: { name: note.zone },
                        area: { name: note.area },
                    };
                } }), _jsx(FormAnalystNoteModal, { tagsList: ANALYST_NOTES_TAGS, deleteAttachmentMutate: deleteAttachmentMutate, deleteAttachmentIsLoading: deleteAttachmentIsLoading, saveMutate: note.id ? updateMutate : createMutate, saveIsLoading: note.id ? updateIsLoading : createIsLoading, children: _jsx(SelectsGroup, {}) })] }));
};
export default memo(ChartsPage);
