import { valibotResolver } from '@hookform/resolvers/valibot';
import { custom, minLength, object, pipe, string } from 'valibot';
var reasonValidation = pipe(string('Reason should be string'), minLength(1, 'Reason cannot be empty'), custom(function (reportProcessingReason) {
    return reportProcessingReason !== '<p><br></p>' && reportProcessingReason !== '<p></p>';
}, 'Reason cannot be empty'), custom(function (reportProcessingReason) {
    return typeof reportProcessingReason === 'string' && /^[^\u0400-\u04FF]+$/.test(reportProcessingReason);
}, 'You cannot use Cyrillic characters in reason'));
export var actionItemReportFormsSchemaAccept = valibotResolver(object({
    reportProcessingReason: reasonValidation,
}));
export var actionItemReportFormsSchemaClosed = valibotResolver(object({
    reportProcessingReason: reasonValidation,
}));
export var actionItemReportFormsSchemaDecline = valibotResolver(object({
    reportProcessingReason: reasonValidation,
}));
