import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import assetBuilderStyles from '../../styles.module.scss';
export var CoolingFanBlades = memo(function (_a) {
    var control = _a.control, fieldName = _a.fieldName, isDisabled = _a.isDisabled;
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, coolingFanBlades = _b.value, onChangeCoolingFanBlades = _b.onChange;
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Cooling Fan Blades" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: coolingFanBlades !== null && coolingFanBlades !== void 0 ? coolingFanBlades : '', onChange: function (e) { return onChangeCoolingFanBlades(e.target.value ? Number(e.target.value) : undefined); }, min: 1 })] }));
});
CoolingFanBlades.displayName = 'CoolingFanBlades';
