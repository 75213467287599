import { literal, number, object, optional, variant } from 'valibot';
import { CALCULATION_MODE_RATIO_BELT_RATE, CALCULATION_MODE_TEETH_BELT_RPM } from '../../constants';
export var calculationModeForChainRules = variant('mode', [
    object({
        mode: literal(CALCULATION_MODE_TEETH_BELT_RPM),
        teeth1: number('Teeth1 is required'),
        teeth2: number('Teeth2 is required'),
        beltRpm: optional(number()),
    }),
    object({
        mode: literal(CALCULATION_MODE_RATIO_BELT_RATE),
        speedRatio: number('Speed Ratio is required'),
        beltRpm: optional(number()),
    }),
], 'Calculation mode is required');
