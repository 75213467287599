import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep as _cloneDeep, each as _each, get as _get, set as _set } from 'lodash';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { ProcessingIncreaseSeverityModal } from '@sharedModals/AIPreAi/ProcessingIncreaseSeverityModal';
import Endpoints from '../../../api/endpoints/endpoints';
import { ACTION_LIST } from '../../../constants';
import InList from '../../../modals/inList/InList';
import ReplaceBattery from '../../../modals/replaceBattery/ReplaceBattery';
import ReplaceSensor from '../../../modals/replaceSensor/ReplaceSensor';
import { isMote } from '../../../widgets/sensor';
import { getSvgSource } from '../../components/charts/chartsWrapper/components/shared/ChartSelect';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { handleDisableSelectCharts } from '../../features/charts/rightBar';
import { getChartsData } from '../../features/mlStatistic/saveCharts';
import User from '../../helper/user';
import { useMlStatisticSaveChartsDataMutation, } from '../../services/mlStatistic/useMlStatisticSaveChartsDataMutation';
import { ChartsStore } from '../../store/charts/chartsContent/ChartsStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { FftImpactVueChartsStore } from '../../store/charts/chartsContent/FftImpactVueChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import { ModalsStore } from '../../store/charts/chartsContent/ModalsStore';
import { InListStore } from '../../store/charts/inList/InListStore';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
import EquipmentPhotosModal from '../EquipmentPhotosModal';
import ExistingAiPreAiOnBedModal from '../ExistingAiPreAiOnBed';
import ManagementModal from '../Management';
import ReportMl from '../ReportMl';
import MachineInfoModal from '../machineInfo/MachineInfo';
import RequestOnDemand from '../requestOnDemand/RequestOnDemand';
import TwfDetectionsReport from '../twfDetectionsReport/TwfDetectionsReport';
import HardwareDataModal from './HardwareDataModal';
import PeaksModal from './PeaksModal';
var statuses = {
    badList: 'Bad Data',
    watchList: 'Watch List',
};
var ModalsWrapper = function (_a) {
    var title = _a.title, options = _a.options, additionalNoteParam = _a.additionalNoteParam;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState(false), showLoader = _b[0], setShowLoader = _b[1];
    var mlStatisticSaveChartsDataMutate = useMlStatisticSaveChartsDataMutation().mutate;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var installationPoint = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
    }); }, shallow).installationPoint;
    var _c = ModalsStore(function (state) { return state; }, shallow), isHardwareReadingsModalVisible = _c.isHardwareReadingsModalVisible, isVisibleMachineInfoModal = _c.isVisibleMachineInfoModal, isVisibleTwfDetectionsReport = _c.isVisibleTwfDetectionsReport, isVisibleRequestOnDemandModal = _c.isVisibleRequestOnDemandModal, isVisibleInListModal = _c.isVisibleInListModal, inListModalType = _c.inListModalType, isVisibleReplaceBatteryModal = _c.isVisibleReplaceBatteryModal, isVisiblePreAiReplaceBatteryModal = _c.isVisiblePreAiReplaceBatteryModal, equipmentTypeForReplace = _c.equipmentTypeForReplace, isVisibleReplaceSensorModal = _c.isVisibleReplaceSensorModal, isVisiblePreAiReplaceSensorModal = _c.isVisiblePreAiReplaceSensorModal, isVisibleImageModal = _c.isVisibleImageModal, peaksModal = _c.peaksModal, setStates = _c.setStates;
    var _d = InListStore(function (state) { return ({
        setInList: state.setInList,
        inList: state.inList,
    }); }, shallow), setInList = _d.setInList, inList = _d.inList;
    var _e = ChartsStore(function (state) { return ({
        selectedChartsAvailable: state.selectedChartsAvailable,
        chartsSelectedPainting: state.chartsSelectedPainting,
        chartsSelectedRanges: state.chartsSelectedRanges,
        setStatesChartsStore: state.setStates,
    }); }, shallow), selectedChartsAvailable = _e.selectedChartsAvailable, chartsSelectedPainting = _e.chartsSelectedPainting, chartsSelectedRanges = _e.chartsSelectedRanges, setStatesChartsStore = _e.setStatesChartsStore;
    var _f = FftChartsStore(function (state) { return ({
        data: state.data,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), data = _f.data, fftTimestampDates = _f.fftTimestampDates;
    var lastTimestamps = FftImpactVueChartsStore(function (state) { return ({
        lastTimestamps: state.lastTimestamps,
    }); }, shallow).lastTimestamps;
    var sensor = _get(installationPoint, 'sensor') || {}, node = _get(installationPoint, 'sensor.node') || {}, storage = _get(installationPoint, 'equipment.locationTable.facility.customer.storage') || {};
    var afterReplaceBattery = function (actionItemId, isPreAi) {
        var updatedInList = _cloneDeep(inList);
        if (isMote(_get(sensor, 'version_type'), _get(sensor, 'node.lot_data'))) {
            _set(updatedInList, ['wwActionList', installationPoint.id], {
                id: actionItemId,
                installation_point_id: installationPoint.id,
                state: ACTION_LIST.STATE_NEW,
                urgency: ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD,
            });
        }
        else {
            _set(updatedInList, [isPreAi ? 'nodesAllPreAiList' : 'nodesAllAiList', node.serial], {
                id: actionItemId,
                serial: node.serial,
                state: ACTION_LIST.STATE_NEW,
                urgency: ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD,
            });
        }
        setInList(updatedInList);
    };
    var afterReplaceSensor = function (actionItemId, isPreAi) {
        var updatedInList = _cloneDeep(inList);
        _set(updatedInList, [isPreAi ? 'wwPreActionList' : 'wwActionList', installationPoint.id], {
            id: actionItemId,
            state: ACTION_LIST.STATE_NEW,
            urgency: ACTION_LIST.ACTION_ITEM_URGENCY_STANDARD,
        });
        setInList(updatedInList);
    };
    useEffect(function () {
        if (!isVisibleInListModal) {
            handleDisableSelectCharts(selectedChartsAvailable, chartsSelectedPainting, setStatesChartsStore, setStates);
        }
    }, [isVisibleInListModal]);
    var onConfirmInList = useCallback(function (description, selectedTags) {
        setShowLoader(true);
        var charts = [], tags = [];
        var svgSources = getSvgSource(selectedChartsAvailable);
        _each(svgSources, function (svg, readingType) {
            charts.push({
                svg: svg,
                ranges: _get(chartsSelectedRanges, readingType) || [],
            });
        });
        _each(selectedTags, function (readingTypes, label) {
            tags.push({ label: label, readingTypes: readingTypes });
        });
        Endpoints[selectedSystemType]
            .addToInList({
            installationPointId: +installationPoint.id,
            type: inListModalType,
            description: description,
            tags: tags,
            charts: charts,
        })
            .then(function (resp) {
            callBack(resp, 'create');
        });
        if (!getChartsData(chartsSelectedRanges, lastTimestamps, data.readingIds, fftTimestampDates).length) {
            return;
        }
        mlStatisticSaveChartsDataMutate({
            sensor_id: installationPoint.sensor_id,
            installation_point_id: installationPoint.id,
            type: 'bad_data',
            charts: getChartsData(chartsSelectedRanges, lastTimestamps, data.readingIds, fftTimestampDates),
        });
    }, [
        selectedChartsAvailable,
        chartsSelectedRanges,
        selectedSystemType,
        inListModalType,
        +installationPoint.id,
        installationPoint.sensor_id,
        lastTimestamps,
        data.readingIds,
        fftTimestampDates,
    ]);
    var deleteFromList = useCallback(function () {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: "Are you sure you want to remove this sensor from ".concat(_get(statuses, inListModalType), "?"),
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                setShowLoader(true);
                Endpoints[selectedSystemType]
                    .deleteItem({
                    installationPointId: +installationPoint.id,
                    type: inListModalType,
                })
                    .then(function (resp) {
                    callBack(resp, 'delete');
                });
            },
        });
    }, [+installationPoint.id, inListModalType]);
    var callBack = function (resp, action) {
        if (resp.success) {
            toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
            setStates({ isVisibleInListModal: false });
            var updatedInList = _cloneDeep(inList);
            if (action === 'delete') {
                delete updatedInList[inListModalType][+installationPoint.id];
            }
            else {
                _set(updatedInList, [inListModalType, +installationPoint.id], {
                    installation_point_id: +installationPoint.id,
                    user_name: User.get().full_name,
                });
            }
            setInList(updatedInList);
        }
        else {
            toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
        }
        setShowLoader(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ExistingAiPreAiOnBedModal, {}), _jsx(ManagementModal, {}), _jsx(MachineInfoModal, { visible: isVisibleMachineInfoModal, onCancel: useCallback(function () { return setStates({ isVisibleMachineInfoModal: false }); }, []), title: title, options: options, additionalNoteParam: additionalNoteParam }), _jsx(ReportMl, { handleDisableSelectCharts: useCallback(function () {
                    return handleDisableSelectCharts(selectedChartsAvailable, chartsSelectedPainting, setStatesChartsStore, setStates);
                }, [selectedChartsAvailable]) }), _jsx(TwfDetectionsReport, { visible: isVisibleTwfDetectionsReport, handleDisableSelectCharts: useCallback(function () {
                    return handleDisableSelectCharts(selectedChartsAvailable, chartsSelectedPainting, setStatesChartsStore, setStates);
                }, [selectedChartsAvailable]), onCancel: useCallback(function () { return setStates({ isVisibleTwfDetectionsReport: false }); }, []) }), _jsx(RequestOnDemand, { visible: isVisibleRequestOnDemandModal, onCancel: useCallback(function () { return setStates({ isVisibleRequestOnDemandModal: false }); }, []) }), _jsx(InList, { visible: isVisibleInListModal, type: inListModalType, inList: inList, showLoader: showLoader, deleteFromList: deleteFromList, onConfirmInList: onConfirmInList, installationPointId: +installationPoint.id, onCancel: useCallback(function () { return setStates({ isVisibleInListModal: false }); }, []) }), _jsx(ReplaceBattery, { visible: isVisibleReplaceBatteryModal || isVisiblePreAiReplaceBatteryModal, type: equipmentTypeForReplace, inList: inList, sensor: sensor, storage: storage, node: node, isPreAi: isVisiblePreAiReplaceBatteryModal, callback: function (actionItemId) { return afterReplaceBattery(actionItemId, isVisiblePreAiReplaceBatteryModal); }, installationPointId: +installationPoint.id, onCancel: useCallback(function () { return setStates({ isVisibleReplaceBatteryModal: false, isVisiblePreAiReplaceBatteryModal: false }); }, []) }), _jsx(ReplaceSensor, { visible: isVisibleReplaceSensorModal || isVisiblePreAiReplaceSensorModal, sensor: sensor, sensorPartNumber: installationPoint.sensorPartNumber || '---', storage: storage, isPreAi: isVisiblePreAiReplaceSensorModal, callback: function (actionItemId) { return afterReplaceSensor(actionItemId, isVisiblePreAiReplaceSensorModal); }, installationPointId: +installationPoint.id, onCancel: useCallback(function () { return setStates({ isVisibleReplaceSensorModal: false, isVisiblePreAiReplaceSensorModal: false }); }, []) }), _jsx(EquipmentPhotosModal, { visible: isVisibleImageModal, onCancel: useCallback(function () { return setStates({ isVisibleImageModal: false }); }, []) }), _jsx(PeaksModal, { visible: peaksModal.visible, onCancel: useCallback(function () {
                    return setStates({
                        peaksModal: {
                            visible: false,
                            chartIdentifier: 0,
                        },
                    });
                }, []) }), _jsx(HardwareDataModal, { visible: isHardwareReadingsModalVisible, onCancel: useCallback(function () { return setStates({ isHardwareReadingsModalVisible: false }); }, []) }), _jsx(ProcessingIncreaseSeverityModal, {})] }));
};
export default memo(ModalsWrapper);
