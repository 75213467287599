import { literal, notValue, number, object, pipe, variant } from 'valibot';
export var speedRules = variant('variantSpeedType', [
    object({
        variantSpeedType: literal('base'),
    }),
    object({
        variantSpeedType: literal('vfd'),
        speedMin: pipe(number('Speed min is required'), notValue(0, 'The number must more than 0')),
        speedMax: pipe(number('Speed max is required'), notValue(0, 'The number must more than 0')),
    }),
    object({
        variantSpeedType: literal('tach'),
        tachMultiplier: pipe(number('Tach multiplier is required'), notValue(0, 'The number must more than 0')),
        tach: number('Tachometer is required'),
    }),
]);
