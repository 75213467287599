var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import assetBuilderStyles from '../../../../../../styles.module.scss';
import { drivenTypeVariantVariationDefaultValues } from '../../constants';
import styles from '../DrivenTypeVariant/styles.module.scss';
export var VariationType = memo(function (_a) {
    var _b;
    var isDisabled = _a.isDisabled, types = _a.types;
    var _c = useFormContext(), clearErrors = _c.clearErrors, control = _c.control;
    var _d = useController({
        name: 'data.drivenTypeVariant.variation',
        control: control,
    }), _e = _d.field, variation = _e.value, onChange = _e.onChange, error = _d.fieldState.error;
    var onChangeVariationType = function (type) {
        onChange(__assign(__assign({}, drivenTypeVariantVariationDefaultValues), { type: type }));
    };
    var options = types.map(function (type) { return ({
        label: type,
        value: type,
    }); });
    return (_jsx("div", { className: styles.positionThreeColumn, children: _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Subtype" }) }), _jsx(Select, { placeholder: "Select Subtype", classNamePrefix: "customSelectStyleDefault", isDisabled: isDisabled, value: options.find(function (el) { return el.value === variation.type; }), onChange: function (data) {
                        clearErrors('data.drivenTypeVariant.variation');
                        onChangeVariationType(data.value);
                    }, options: options }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (_b = error === null || error === void 0 ? void 0 : error.type) === null || _b === void 0 ? void 0 : _b.message })] }) }));
});
VariationType.displayName = 'VariationType';
