var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { boolean, literal, number, object, optional, string, variant } from 'valibot';
import { NUMBER_OF_STAGES_TYPE_SHAFT_RPM, NUMBER_OF_STAGES_TYPE_SPEED_RATIO } from '../constants';
import { bearingMonitoredPositionsRules } from './shared/bearingMonitoredPositionsRules';
import { bearingMonitoredPositionsVariantRules } from './shared/bearingMonitoredPositionsVariantRules';
import { bearingTypeRules } from './shared/bearingTypeRules';
import { componentOrientationRules } from './shared/componentOrientationRules';
import { manufacturerRules } from './shared/manufacturerRules';
import { modelRules } from './shared/modelRules';
import { numberOfStagesRules } from './shared/numberOfStagesRules';
import { numberOfStagesTypeRules } from './shared/numberOfStagesTypeRules';
import { transmissionRules } from './shared/transmissionRules';
var gearboxSecondStepSchema = {
    transmission: transmissionRules,
};
var gearboxThirdStepSchema = {
    componentOrientation: componentOrientationRules,
    bearingType: bearingTypeRules,
};
var gearboxFourthStepSchema = {
    numberOfStagesType: numberOfStagesTypeRules,
    numberOfStages: numberOfStagesRules([NUMBER_OF_STAGES_TYPE_SHAFT_RPM, NUMBER_OF_STAGES_TYPE_SPEED_RATIO]),
};
var gearboxFifthStepSchema = function (entryBoxNumber) { return ({
    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
    bearingMonitoredPositions: bearingMonitoredPositionsRules([
        entryBoxNumber + 3,
        entryBoxNumber + 1,
        entryBoxNumber + 4,
        entryBoxNumber + 2,
        entryBoxNumber + 5,
    ]),
}); };
var gearboxSixthStepSchema = {
    oilPump: optional(boolean()),
    gearTeeth: optional(number()),
    numberOfOilVanes: optional(number()),
};
var gearboxSeventhStepSchema = {
    manufacturer: manufacturerRules,
    model: modelRules,
    notes: optional(string()),
};
export var gearboxSchema = function (entryBoxNumber) {
    return object({
        isGearbox: literal(true),
        data: variant('step', [
            object({
                step: literal(1),
            }),
            object(__assign({ step: literal(2) }, gearboxSecondStepSchema)),
            object(__assign(__assign({ step: literal(3) }, gearboxSecondStepSchema), gearboxThirdStepSchema)),
            object(__assign(__assign(__assign({ step: literal(4) }, gearboxSecondStepSchema), gearboxThirdStepSchema), gearboxFourthStepSchema)),
            object(__assign(__assign(__assign(__assign({ step: literal(5) }, gearboxSecondStepSchema), gearboxThirdStepSchema), gearboxFourthStepSchema), gearboxFifthStepSchema(entryBoxNumber))),
            object(__assign(__assign(__assign(__assign(__assign({ step: literal(6) }, gearboxSecondStepSchema), gearboxThirdStepSchema), gearboxFourthStepSchema), gearboxFifthStepSchema(entryBoxNumber)), gearboxSixthStepSchema)),
            object(__assign(__assign(__assign(__assign(__assign(__assign({ step: literal(7) }, gearboxSecondStepSchema), gearboxThirdStepSchema), gearboxFourthStepSchema), gearboxFifthStepSchema(entryBoxNumber)), gearboxSixthStepSchema), gearboxSeventhStepSchema)),
        ], 'Step is required'),
    });
};
