// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__M31ZJ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
}
.wrapper__M31ZJ .left__cJyE7 b {
  font-weight: 500;
}
.wrapper__M31ZJ .right__StaHa {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}
.wrapper__M31ZJ .right__StaHa span {
  padding: 4px 8px;
  border-radius: 4px;
  background: #eaeaea;
  height: 30px;
}
.dark-layout .wrapper__M31ZJ .right__StaHa span {
  background: #3e3f4f;
}
.wrapper__M31ZJ .right__StaHa img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  cursor: zoom-in;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/InstallationPointItem/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;AACD;AACE;EACC,gBAAA;AACH;AAEC;EACC,aAAA;EACA,mBAAA;EACA,SAAA;EAgBA,eAAA;AAfF;AAAE;EACC,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAEH;AADG;EACC,mBAAA;AAGJ;AAAE;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAEH","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding: 16px;\n\twidth: 100%;\n\t.left {\n\t\tb {\n\t\t\tfont-weight: 500;\n\t\t}\n\t}\n\t.right {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tgap: 12px;\n\t\tspan {\n\t\t\tpadding: 4px 8px;\n\t\t\tborder-radius: 4px;\n\t\t\tbackground: #eaeaea;\n\t\t\theight: 30px;\n\t\t\t:global(.dark-layout) & {\n\t\t\t\tbackground: #3e3f4f;\n\t\t\t}\n\t\t}\n\t\timg {\n\t\t\twidth: 60px;\n\t\t\theight: 60px;\n\t\t\tborder-radius: 4px;\n\t\t\tcursor: zoom-in;\n\t\t}\n\t\tfont-size: 14px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__M31ZJ`,
	"left": `left__cJyE7`,
	"right": `right__StaHa`
};
module.exports = ___CSS_LOADER_EXPORT___;
