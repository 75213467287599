import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { Badge } from 'reactstrap';
import { useProcessingIncreaseSeverityModalActions } from '@sharedStore/useProcessingIncreaseSeverityModalStore';
var IncreasedSeverityBadge = function (_a) {
    var id = _a.id, _b = _a.isPreAI, isPreAI = _b === void 0 ? false : _b, _c = _a.storage, storage = _c === void 0 ? null : _c;
    var _d = useState(false), isHidden = _d[0], setIsHidden = _d[1];
    var setModalState = useProcessingIncreaseSeverityModalActions().setModalState;
    var deleteIncreaseSeverity = function () {
        setModalState({
            isVisibleModal: true,
            itemId: id,
            isPreAI: isPreAI,
            storage: storage,
            onSuccess: function () { return setIsHidden(true); },
        });
    };
    if (isHidden) {
        return null;
    }
    return (_jsx(Badge, { className: "cursor-pointer", onClick: deleteIncreaseSeverity, children: "Increased Severity" }));
};
export default memo(IncreasedSeverityBadge);
