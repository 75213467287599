import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, ButtonGroup, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import ReportEditorForm from '../../components/actionItem/FormDescription/ReportEditorForm';
import { faultRecommendationsSchema } from '../../components/actionItem/FormDescription/schemas/faultRecommendations';
import { groupRecommendations } from '../../features/actionItems';
import useToastErrorAlert from '../../hooks/useToastErrorAlert';
import { useReportEditorOptionsQuery, } from '../../services/reportEditor/useReportEditorOptionsQuery';
import { selectRecommendationsReassignModalState, useActionItemModalsActions, } from '../../store/AIPreAi/useActionItemModalsStore';
var RecommendationsReassignModal = function () {
    var recommendationsReassignModalState = selectRecommendationsReassignModalState();
    var setRecommendationsReassignModalState = useActionItemModalsActions().setRecommendationsReassignModalState;
    var _a = useReportEditorOptionsQuery(), reportEditorData = _a.data, isPending = _a.isPending;
    var _b = useForm({
        defaultValues: {
            faultRecommendations: [],
        },
        resolver: faultRecommendationsSchema,
    }), setValue = _b.setValue, reset = _b.reset, unregister = _b.unregister, control = _b.control, errors = _b.formState.errors, handleSubmit = _b.handleSubmit;
    useToastErrorAlert(errors);
    var faultRecommendations = useWatch({
        control: control,
        name: 'faultRecommendations',
    });
    var recommendationsGroupedByComponentType = groupRecommendations(recommendationsReassignModalState.recommendations);
    useEffect(function () {
        var recommendationsGroupedByComponentType = groupRecommendations(recommendationsReassignModalState.recommendations);
        var faultRecommendationsInitialState = [];
        Object.values(recommendationsGroupedByComponentType).forEach(function (components) {
            Object.values(components).forEach(function (recommendations) {
                var _a, _b, _c, _d;
                faultRecommendationsInitialState.push({
                    componentType: (_a = recommendations[0]) === null || _a === void 0 ? void 0 : _a.component_type_id,
                    subType: (_b = recommendations[0]) === null || _b === void 0 ? void 0 : _b.sub_type_id,
                    fault: (_c = recommendations[0]) === null || _c === void 0 ? void 0 : _c.fault_id,
                    severity: (_d = recommendations[0]) === null || _d === void 0 ? void 0 : _d.severity,
                    recommendations: recommendations.map(function (recommendation) { return recommendation.recommendation_id; }),
                });
            });
        });
        reset({ faultRecommendations: faultRecommendationsInitialState });
    }, [recommendationsReassignModalState.visible]);
    var onCancel = function () {
        setRecommendationsReassignModalState({
            visible: false,
            onSave: function () { },
            recommendations: [],
        });
    };
    var onSave = handleSubmit(function () {
        recommendationsReassignModalState.onSave({ faultRecommendations: faultRecommendations });
        onCancel();
    });
    return (_jsxs(Modal, { size: "xxl", className: "modal-response", toggle: onCancel, isOpen: recommendationsReassignModalState.visible, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Recommendations Reassign" }), _jsxs(ModalBody, { children: [_jsx(Row, { children: _jsxs(Col, { children: [_jsx(Label, { className: "fw-bold", children: "Primary Fault Reason: " }), Object.values(recommendationsGroupedByComponentType).map(function (recommendationsGrouped, parentIndex) { return (_jsx(React.Fragment, { children: Object.values(recommendationsGrouped).map(function (recommendations, index) {
                                        var _a, _b, _c, _d, _e;
                                        return (_jsx(ReportEditorForm, { setValue: setValue, unregister: unregister, index: index, countFaultRecommendations: 0, firstSelectedComponentTypeId: ((_a = recommendations[0]) === null || _a === void 0 ? void 0 : _a.component_type_id) || undefined, selectedReportEditorData: {
                                                componentType: (_b = recommendations[0]) === null || _b === void 0 ? void 0 : _b.component_type_id,
                                                subType: (_c = recommendations[0]) === null || _c === void 0 ? void 0 : _c.sub_type_id,
                                                fault: (_d = recommendations[0]) === null || _d === void 0 ? void 0 : _d.fault_id,
                                                recommendations: recommendations.map(function (actionItemRecommendation) { return actionItemRecommendation.recommendation_id; }),
                                                severity: (_e = recommendations[0]) === null || _e === void 0 ? void 0 : _e.severity,
                                            }, isPending: true, reportEditorData: reportEditorData }, "report-editor-existing-items-".concat(parentIndex, "-").concat(index)));
                                    }) }, "report-editor-existing-items-".concat(parentIndex))); })] }) }), _jsx(Row, { children: _jsxs(Col, { children: [_jsx(Label, { className: "fw-bold", children: "Reassign To: " }), _jsx(FormGroup, { children: faultRecommendations.map(function (_a, index) {
                                        var _b;
                                        var componentType = _a.componentType, subType = _a.subType, fault = _a.fault, recommendations = _a.recommendations, severity = _a.severity;
                                        return (_jsx(ReportEditorForm, { setValue: setValue, unregister: unregister, index: index, firstSelectedComponentTypeId: (_b = recommendationsReassignModalState.recommendations[0]) === null || _b === void 0 ? void 0 : _b.component_type_id, disableResetByFirstComponent: true, countFaultRecommendations: faultRecommendations.length, selectedReportEditorData: { componentType: componentType, subType: subType, fault: fault, recommendations: recommendations, severity: severity }, isPending: isPending, isReassign: true, reportEditorData: reportEditorData }, "report-editor-items-".concat(index)));
                                    }) })] }) })] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "success", onClick: onSave, children: "Save" })] }) })] }));
};
export default memo(RecommendationsReassignModal);
