import { useMutation } from '@tanstack/react-query';
import Api from '../../../api/api';
var setAssetRunningSpeedFn = function (payload) {
    return Api.post('all-analytics/asset/change-speed-reading', payload);
};
export var useSetAssetRunningSpeedMutation = function () {
    return useMutation({
        mutationFn: function (payload) { return setAssetRunningSpeedFn(payload); },
        onSuccess: function () { },
    });
};
