import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import RichTextEditor from '../../../widgets/TextEditor/TextEditor';
import { selectIsVisibleCancelHardwareAssistanceModal } from '../../store/AIPreAi/useActionItemModalsStore';
var CancelHardwareAssistanceModal = function (_a) {
    var onCancel = _a.onCancel, customerId = _a.customerId, cancelHardwareAssistanceMutate = _a.cancelHardwareAssistanceMutate, cancelHardwareAssistanceIsLoading = _a.cancelHardwareAssistanceIsLoading, _b = _a.storage, storage = _b === void 0 ? null : _b, onSuccess = _a.onSuccess;
    var actionItemId = useParams().id;
    var _c = useState(''), comment = _c[0], setComment = _c[1];
    var isVisibleCancelHardwareAssistanceModal = selectIsVisibleCancelHardwareAssistanceModal();
    var onConfirm = function () {
        if (comment.trim() === '<p></p>' || comment.trim() === '') {
            return toast.error('Comment is required');
        }
        cancelHardwareAssistanceMutate({
            action_item_id: +actionItemId,
            comment_internal: comment,
        }, {
            onSuccess: function () {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                toast.success('Successfully updated', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                setComment('');
                onCancel();
            },
        });
    };
    return (_jsxs(Modal, { size: "lg", className: "modal-response", toggle: onCancel, isOpen: isVisibleCancelHardwareAssistanceModal, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Network assistance canceling" }), _jsx(ModalBody, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "comment", className: "small", children: "Please leave your comment (internal)" }), _jsx(RichTextEditor, { storage: storage, customerId: customerId, content: comment, onChange: function (content) { return setComment(content); } })] }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Close" }), _jsx(Button, { size: "sm", color: "primary", disabled: cancelHardwareAssistanceIsLoading, onClick: onConfirm, children: "Save" })] }) })] }));
};
export default memo(CancelHardwareAssistanceModal);
