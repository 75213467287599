import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { BearingMonitoredPositions } from '../../../../../../../BearingMonitoredPositions';
import { BearingType } from '../../../../../../../BearingType';
import { ComponentOrientation } from '../../../../../../../ComponentOrientation';
import { PistonRate } from '../../../../../../../PistonRate';
import styles from '../../styles.module.scss';
export var SwashPlateAxialPistonPumpContent = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.componentOrientationAndBearing, children: [_jsx(ComponentOrientation, { isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.variation.componentOrientation' }), _jsx(BearingType, { isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.variation.bearingType' })] }), _jsx(BearingMonitoredPositions, { control: control, isDisabled: isDisabled, bearingMonitoredPositionsFieldName: 'data.drivenTypeVariant.variation.bearingMonitoredPositions', bearingMonitoredPositionsVariantFieldName: 'data.drivenTypeVariant.variation.bearingMonitoredPositionsVariant', equipmentId: equipmentId, schemas: [[entryBoxNumber + 1, entryBoxNumber + 2]], hasGap: true, timingGearFieldName: 'data.drivenTypeVariant.variation.timingGear' }), _jsx("div", { className: styles.positionThreeColumn, children: _jsx(PistonRate, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.variation.pistonRate' }) })] }));
});
SwashPlateAxialPistonPumpContent.displayName = 'SwashPlateAxialPistonPumpContent';
