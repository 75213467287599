import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import Select from 'react-select';
export var NumberOfPolesSelect = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, fieldName = _a.fieldName;
    var options = [2, 4, 6, 8, 10, 12, 16].map(function (type) {
        return {
            label: type,
            value: type,
        };
    });
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, value = _b.value, onChange = _b.onChange;
    return (_jsx(Select, { placeholder: "Select Number Of Poles", classNamePrefix: "customSelectStyleDefault", isDisabled: isDisabled, value: options.find(function (el) { return el.value === value; }), onChange: function (data) {
            onChange(data.value);
        }, options: options }));
});
NumberOfPolesSelect.displayName = 'NumberOfPolesSelect';
