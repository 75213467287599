var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import AddAssetBearing from '../../../modals/assetBearing/AddAssetBearing';
import { config as baseConfig } from '../../config/bearing';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { afterRemove, onAdd, onCustomBearingButton, onPlotLinesCountChange, onRemove, updateStatesAfterAction, } from '../../features/bearings';
import { useBearingAttachMutation } from '../../services/bearings/useBearingAttachMutation';
import { useBearingDeleteMutation } from '../../services/bearings/useBearingDeleteMutation';
import { useBearingDetachMutation } from '../../services/bearings/useBearingDetachMutation';
import { useBearingLogsQuery } from '../../services/bearings/useBearingLogsQuery';
import { useBearingMfrsQuery } from '../../services/bearings/useBearingMfrsQuery';
import { useBearingUpdatePlotLinesMutation } from '../../services/bearings/useBearingUpdatePlotLinesMutation';
import { useBearingsQuery } from '../../services/bearings/useBearingsQuery';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
import DataTable from '../shared/Table/Table';
var BearingsTable = function (_a) {
    var _b;
    var setSmallLoader = _a.setSmallLoader, selectedInstallationPointId = _a.selectedInstallationPointId, readonly = _a.readonly;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }, shallow);
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; });
    var _c = FftChartsStore(function (state) { return ({
        installationPointBearings: state.installationPointBearings,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
    }); }, shallow), installationPointBearings = _c.installationPointBearings, setStatesFftChartsStore = _c.setStatesFftChartsStore;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var _d = useState(''), search = _d[0], setSearch = _d[1];
    var _e = useState({
        page: 1,
        pageSize: 10,
        total: 0,
    }), pagination = _e[0], setPagination = _e[1];
    var _f = useState(''), mfr = _f[0], setMfr = _f[1];
    var _g = useState(false), selfBearings = _g[0], setSelfBearings = _g[1];
    var _h = useState({
        visible: false,
        id: 0,
    }), addAssetBearingModal = _h[0], setAddAssetBearingModal = _h[1];
    var config = baseConfig.config;
    var bearingAddMutation = useBearingAttachMutation(selectedInstallationPointId || installationPoint.id).mutate;
    var bearingRemoveMutation = useBearingDetachMutation(selectedInstallationPointId || installationPoint.id).mutate;
    var bearingUpdateMutation = useBearingUpdatePlotLinesMutation(selectedInstallationPointId || installationPoint.id).mutate;
    var bearingDeleteMutation = useBearingDeleteMutation().mutate;
    var _j = useBearingsQuery({
        installationPointId: selectedInstallationPointId || installationPoint.id,
        search: search,
        pagination: pagination,
        selfBearings: selfBearings,
        mfr: mfr,
    }), data = _j.data, isLoading = _j.isLoading, isFetching = _j.isFetching;
    var logsData = useBearingLogsQuery(selectedInstallationPointId || installationPoint.id).data;
    var mfrsData = useBearingMfrsQuery(selectedInstallationPointId || installationPoint.id).data;
    var list = (data === null || data === void 0 ? void 0 : data.list) || [];
    var selected = (data === null || data === void 0 ? void 0 : data.selected) || [];
    var onDelete = function (id) {
        setConfirmData({
            isVisible: true,
            textConfirmationModal: 'Are you sure you want remove bearing?',
            onConfirm: function () {
                setConfirmData({
                    isVisible: false,
                });
                bearingDeleteMutation(id, {
                    onSuccess: function (resp) {
                        updateStatesAfterAction(setStatesFftChartsStore, afterRemove(installationPointBearings, id));
                        toast.success(resp.message || 'Successfully removed', {
                            icon: _jsx(FaTrash, { style: { fill: '#198754' } }),
                        });
                        setSmallLoader(false);
                    },
                });
            },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataTable, { config: __assign(__assign({}, config), { data: list, loader: isLoading || isFetching, search: search, pagination: __assign(__assign({}, pagination), { total: (data === null || data === void 0 ? void 0 : data.total) || 0 }), filtersData: {
                        selectedCount: selected.length,
                        logsData: logsData,
                        mfrsData: (mfrsData === null || mfrsData === void 0 ? void 0 : mfrsData.list) || [],
                        readonly: readonly,
                    } }), callbacks: {
                    setMfr: setMfr,
                    onDelete: onDelete,
                    onEdit: function (bearing) {
                        return onCustomBearingButton(selectedSystemType, installationPoint, setStatesChartFeaturesStore, bearing);
                    },
                    onSelfBearings: function () { return setSelfBearings(!selfBearings); },
                    onAdd: function (id) {
                        var _a;
                        return ((_a = installationPoint.equipment) === null || _a === void 0 ? void 0 : _a.isAsset)
                            ? setAddAssetBearingModal({ visible: true, id: id })
                            : onAdd(id, setConfirmData, bearingAddMutation, setSmallLoader, setStatesFftChartsStore, installationPointBearings, list, installationPoint, !selectedInstallationPointId || selectedInstallationPointId === installationPoint.id);
                    },
                    onRemove: function (id) {
                        return onRemove(id, bearingRemoveMutation, setSmallLoader, setStatesFftChartsStore, installationPointBearings, setConfirmData, !selectedInstallationPointId || selectedInstallationPointId === installationPoint.id);
                    },
                    onPlotLinesCountChange: function (id, count) {
                        return onPlotLinesCountChange(id, count, bearingUpdateMutation, setSmallLoader, setStatesFftChartsStore, installationPointBearings, !selectedInstallationPointId || selectedInstallationPointId === installationPoint.id);
                    },
                }, onPageChange: function (page) { return setPagination(__assign(__assign({}, pagination), { page: page })); }, onPageSizeChange: function (pageSize) { return setPagination(__assign(__assign({}, pagination), { pageSize: pageSize })); }, onSearch: setSearch }), ((_b = installationPoint.equipment) === null || _b === void 0 ? void 0 : _b.isAsset) && addAssetBearingModal.visible ? (_jsx(AddAssetBearing, { selectedInstallationPointId: selectedInstallationPointId, list: list, id: addAssetBearingModal.id, visible: addAssetBearingModal.visible, onCancel: function () {
                    return setAddAssetBearingModal({
                        visible: false,
                        id: 0,
                    });
                } })) : (_jsx(_Fragment, {}))] }));
};
export default memo(BearingsTable);
