import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { VARIATION_TYPE_COMPRESSOR } from '../../../../../../../../../../constants';
import { VariationType } from '../../../VariationType';
import { AxialFlowCompressorContent } from './AxialFlowCompressorContent';
import { CentrifugalCompressorContent } from './CentrifugalCompressorContent';
import { LobedCompressorContent } from './LobedCompressorContent';
import { ReciprocatingCompressContent } from './ReciprocatingCompressContent';
import { ScrewCompressorContent } from './ScrewCompressorContent';
import { ScrollCompressorContent } from './ScrollCompressorContent';
import { SlidingVaneCompressorContent } from './SlidingVaneCompressorContent';
import { SwashPlateAxialPistonCompressorContent } from './SwashPlateCompressorContent';
var Component = function (type, control, isDisabled, equipmentId, entryBoxNumber) {
    switch (type) {
        case VARIATION_TYPE_COMPRESSOR.CENTRIFUGAL_COMPRESSOR:
            return (_jsx(CentrifugalCompressorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_COMPRESSOR.SCREW_COMPRESSOR:
            return (_jsx(ScrewCompressorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_COMPRESSOR.LOBED_COMPRESSOR:
            return (_jsx(LobedCompressorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_COMPRESSOR.AXIAL_FLOW_COMPRESSOR:
            return (_jsx(AxialFlowCompressorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_COMPRESSOR.SLIDING_VANE_ROTARY_VANE_COMPRESSOR:
            return (_jsx(SlidingVaneCompressorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_COMPRESSOR.SWASH_PLATE_AXIAL_PISTON_COMPRESSOR:
            return (_jsx(SwashPlateAxialPistonCompressorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_COMPRESSOR.SCROLL_COMPRESSOR:
            return (_jsx(ScrollCompressorContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_COMPRESSOR.RECIPROCATING_COMPRESSOR:
            return (_jsx(ReciprocatingCompressContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
    }
};
export var CompressorContent = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    var variation = useWatch({
        control: control,
        name: 'data.drivenTypeVariant.variation',
    });
    var type = variation.type;
    return (_jsxs(_Fragment, { children: [_jsx(VariationType, { isDisabled: isDisabled, types: Object.values(VARIATION_TYPE_COMPRESSOR) }), !!type && Component(type, control, isDisabled, equipmentId, entryBoxNumber)] }));
});
CompressorContent.displayName = 'CompressorContent';
