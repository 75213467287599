import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import assetBuilderStyles from '../../styles.module.scss';
import { ConfigurationSchemas } from '../ConfigurationSchemas';
import { InstallationPointsSelects } from '../InstallationPointsSelects';
export var BearingMonitoredPositions = memo(function (_a) {
    var isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, bearingMonitoredPositionsVariantFieldName = _a.bearingMonitoredPositionsVariantFieldName, bearingMonitoredPositionsFieldName = _a.bearingMonitoredPositionsFieldName, schemas = _a.schemas, hasGap = _a.hasGap, timingGearFieldName = _a.timingGearFieldName, numberOfStagesField = _a.numberOfStagesField;
    var control = useFormContext().control;
    var error = useController({
        name: bearingMonitoredPositionsFieldName,
        control: control,
    }).fieldState.error;
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsxs("h3", { className: assetBuilderStyles.sectionTitle, children: [_jsx("span", { className: assetBuilderStyles.required, children: "Bearing monitored position" }), _jsx("div", { className: assetBuilderStyles.subtext, children: "Select the Installation Points where you want to install the sensors" })] }), _jsx(ConfigurationSchemas, { isDisabled: isDisabled, bearingMonitoredPositionsFieldName: bearingMonitoredPositionsFieldName, bearingMonitoredPositionsVariantFieldName: bearingMonitoredPositionsVariantFieldName, schemas: schemas, hasGap: hasGap }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: error === null || error === void 0 ? void 0 : error.message }), _jsx(InstallationPointsSelects, { isDisabled: isDisabled, control: control, equipmentId: equipmentId, bearingMonitoredPositionsFieldName: bearingMonitoredPositionsFieldName, timingGearFieldName: timingGearFieldName, numberOfStagesField: numberOfStagesField })] }));
});
BearingMonitoredPositions.displayName = 'BearingMonitoredPositions';
