var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import React, { memo, useContext, useState } from 'react';
import { FaPlusCircle, FaSpinner } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../api/endpoints/endpoints';
import { dataTableConfig } from '../../../remastered/components/assetFaultFrequencies/dataTableConfig';
import { assetFaultFrequencyQueryKey, useAssetFaultFrequenciesQuery, } from '../../../remastered/services/useAssetFaultFrequenciesQuery';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { onEdit, onRemove, onSetFaultFrequency } from '../../features/faultFrequency';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
import DataTable from '../shared/Table/Table';
var FaultFrequenciesTable = function (_a) {
    var installationPoint = _a.installationPoint, _b = _a.readonly, readonly = _b === void 0 ? false : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    var _c = FftChartsStore(function (state) { return ({
        setStatesFftChartsStore: state.setStatesFftChartsStore,
        faultFrequencies: state.faultFrequencies,
    }); }, shallow), setStatesFftChartsStore = _c.setStatesFftChartsStore, faultFrequencies = _c.faultFrequencies;
    var _d = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
        setFaultFrequency: state.setFaultFrequency,
    }); }, shallow), setStatesChartFeaturesStore = _d.setStatesChartFeaturesStore, setFaultFrequency = _d.setFaultFrequency;
    var _e = useState(false), loader = _e[0], setLoader = _e[1];
    var setConfirmData = useConfirmModalActions().setConfirmData;
    var _f = useAssetFaultFrequenciesQuery({ equipmentId: installationPoint.equipment_id }).data, data = _f === void 0 ? [] : _f;
    if (!data.length) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { children: [loader && _jsx(FaSpinner, { className: "ms-2 spinner-anim" }), !readonly && (_jsxs(Button, { outline: true, className: "float-end mb-2", color: "primary", size: "sm", onClick: function () {
                    return onSetFaultFrequency(setStatesChartFeaturesStore, setFaultFrequency.openedByChartIdentifier);
                }, children: [_jsx(FaPlusCircle, {}), " Add"] })), _jsx(DataTable, { config: __assign(__assign({}, dataTableConfig), { data: data, filtersData: __assign(__assign({}, dataTableConfig.filtersData), { readonly: readonly }) }), callbacks: {
                    onRemove: function (id) {
                        return onRemove(id, faultFrequencies, setLoader, setConfirmData, setStatesFftChartsStore, Endpoints[selectedSystemType].deleteFaultFrequency, function () {
                            return queryClient.invalidateQueries({
                                queryKey: assetFaultFrequencyQueryKey({ equipmentId: installationPoint.equipment_id }),
                            });
                        });
                    },
                    onEdit: function (faultFrequency) {
                        return onEdit(faultFrequency, setFaultFrequency.openedByChartIdentifier, setStatesChartFeaturesStore);
                    },
                }, onSearch: function () { } })] }));
};
export default memo(FaultFrequenciesTable);
