import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { VARIATION_TYPE_PUMPS } from '../../../../../../../../../../constants';
import { VariationType } from '../../../VariationType';
import { AxialFlowPropellerPumpContent } from './AxialFlowPropellerPumpContent';
import { CentrifugePumpContent } from './CentrifugePumpContent';
import { GearPumpContent } from './GearPumpContent';
import { LobedPumpContent } from './LobedPumpContent';
import { ReciprocatingPumpContent } from './ReciprocatingPumpContent';
import { ScrewPumpContent } from './ScrewPumpContent';
import { ScrollPumpContent } from './ScrollPumpContent';
import { SlidingVanePumpContent } from './SlidingVanePumpContent';
import { SwashPlateAxialPistonPumpContent } from './SwashPlateAxialPistonPumpContent';
var Component = function (type, control, isDisabled, equipmentId, entryBoxNumber) {
    switch (type) {
        case VARIATION_TYPE_PUMPS.CENTRIFUGAL_PUMP:
            return (_jsx(CentrifugePumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_PUMPS.SCREW_PUMP:
            return (_jsx(ScrewPumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_PUMPS.AXIAL_FLOW_PROPELLER_PUMP:
            return (_jsx(AxialFlowPropellerPumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_PUMPS.RECIPROCATING_PUMP:
            return (_jsx(ReciprocatingPumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_PUMPS.SWASH_PLATE_AXIAL_PISTON_PUMP:
            return (_jsx(SwashPlateAxialPistonPumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_PUMPS.LOBED_PUMP:
            return (_jsx(LobedPumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_PUMPS.SLIDING_VANE_PUMP:
            return (_jsx(SlidingVanePumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_PUMPS.GEAR_PUMP:
            return (_jsx(GearPumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
        case VARIATION_TYPE_PUMPS.SCROLL_PUMP:
            return (_jsx(ScrollPumpContent, { control: control, isDisabled: isDisabled, equipmentId: equipmentId, entryBoxNumber: entryBoxNumber }));
    }
};
export var PumpContent = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    var variation = useWatch({
        control: control,
        name: 'data.drivenTypeVariant.variation',
    });
    var type = variation.type;
    return (_jsxs(_Fragment, { children: [_jsx(VariationType, { isDisabled: isDisabled, types: Object.values(VARIATION_TYPE_PUMPS) }), !!type && Component(type, control, isDisabled, equipmentId, entryBoxNumber)] }));
});
PumpContent.displayName = 'PumpContent';
