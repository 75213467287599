var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ASSET_BEARING_TYPE, ASSET_ORIENTATION_HORIZONTAL } from '../../../../../constants';
import { drivenTypeVariantDefaultValues } from '../components/DrivenContent/constants';
export var SEVENTH_STEP_TO_DEFAULT_VALUES = {
    manufacturer: undefined,
    model: undefined,
    notes: undefined,
};
export var SIXTH_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, SEVENTH_STEP_TO_DEFAULT_VALUES), { oilPump: false, gearTeeth: undefined, numberOfOilVanes: undefined });
export var FIFTH_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, SIXTH_STEP_TO_DEFAULT_VALUES), { bearingMonitoredPositionsVariant: 1, bearingMonitoredPositions: [] });
export var FOURTH_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, FIFTH_STEP_TO_DEFAULT_VALUES), { numberOfStagesType: undefined, numberOfStages: {
        stages: undefined,
        stage1: undefined,
        stage2: undefined,
        stage3: undefined,
        stage4: undefined,
        stage5: undefined,
        stage6: undefined,
        stage7: undefined,
        stage8: undefined,
    } });
export var THIRD_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, FOURTH_STEP_TO_DEFAULT_VALUES), { componentOrientation: ASSET_ORIENTATION_HORIZONTAL, bearingType: ASSET_BEARING_TYPE.ROLLING_ELEMENT, drivenTypeVariant: drivenTypeVariantDefaultValues, foundationType: undefined });
export var SECOND_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, THIRD_STEP_TO_DEFAULT_VALUES), { transmission: {
        type: undefined,
        transmissionType: {
            type: undefined,
            numberOfComponents: undefined,
            numberOfTeeth: undefined,
        },
        calculationMode: {
            mode: undefined,
            sdi1: undefined,
            sdi2: undefined,
            teeth1: undefined,
            teeth2: undefined,
            beltLength: undefined,
            speedRatio: undefined,
            beltRpm: undefined,
        },
    } });
export var FIRST_STEP_TO_DEFAULT_VALUES = __assign(__assign({}, SECOND_STEP_TO_DEFAULT_VALUES), { isDriven: false });
