var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { boolean, literal, number, object, optional, string, variant } from 'valibot';
import { DRIVEN_SUBTYPE_AGITATOR_MIXER, DRIVEN_SUBTYPE_CENTRIFUGE, DRIVEN_SUBTYPE_COMPRESSOR, DRIVEN_SUBTYPE_CONVEYOR_BELT_DRAG, DRIVEN_SUBTYPE_CONVEYOR_SCREW, DRIVEN_SUBTYPE_FAN_AND_BLOWER, DRIVEN_SUBTYPE_GENERATOR, DRIVEN_SUBTYPE_HAMMER_MILL, DRIVEN_SUBTYPE_PUMP, DRIVEN_SUBTYPE_SIMPLE_SHAFT, NUMBER_OF_STAGES_TYPE_BLADES, NUMBER_OF_STAGES_TYPE_VANES, VARIATION_TYPE_COMPRESSOR, VARIATION_TYPE_FAN_BLOWER, VARIATION_TYPE_PUMPS, } from '../constants';
import { foundationSchema } from './foundationSchema';
import { balanceableRules } from './shared/balanceableRules';
import { bearingMonitoredPositionsRules } from './shared/bearingMonitoredPositionsRules';
import { bearingMonitoredPositionsVariantRules } from './shared/bearingMonitoredPositionsVariantRules';
import { bearingTypeRules } from './shared/bearingTypeRules';
import { componentOrientationRules } from './shared/componentOrientationRules';
import { idlerShaftSpeedRules } from './shared/idlerShaftSpeedRules';
import { manufacturerRules } from './shared/manufacturerRules';
import { modelRules } from './shared/modelRules';
import { numberOfBladesRules } from './shared/numberOfBladesRules';
import { numberOfElementsRules } from './shared/numberOfElementsRules';
import { numberOfGearTeethRules } from './shared/numberOfGearTeethRules';
import { numberOfLobesRules } from './shared/numberOfLobesRules';
import { numberOfPolesRules } from './shared/numberOfPolesRules';
import { numberOfStagesRules } from './shared/numberOfStagesRules';
import { numberOfStagesTypeRules } from './shared/numberOfStagesTypeRules';
import { numberOfThreadsRules } from './shared/numberOfThreadsRules';
import { numberOfVanesRules } from './shared/numberOfVanesRules';
import { pistonRateRules } from './shared/pistonRateRules';
import { rotorRules } from './shared/rotorRules';
import { timingGearRules } from './shared/timingGearRules';
import { transmissionRules } from './shared/transmissionRules';
var drivenThirdStepSchema = {
    isDriven: literal(true),
    transmission: transmissionRules,
};
var drivenFourthStepSchema = function (entryBoxNumber) { return ({
    drivenTypeVariant: variant('subtype', [
        object({
            subtype: literal(DRIVEN_SUBTYPE_SIMPLE_SHAFT),
            componentOrientation: componentOrientationRules,
            bearingType: bearingTypeRules,
            bearingMonitoredPositions: bearingMonitoredPositionsRules([
                entryBoxNumber + 1,
                entryBoxNumber + 2,
                entryBoxNumber + 3,
                entryBoxNumber + 4,
            ]),
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_PUMP),
            variation: variant('type', [
                object({
                    type: literal(VARIATION_TYPE_PUMPS.CENTRIFUGAL_PUMP),
                    bearingType: bearingTypeRules,
                    numberOfStagesType: numberOfStagesTypeRules,
                    numberOfStages: numberOfStagesRules([NUMBER_OF_STAGES_TYPE_VANES]),
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    componentOrientation: componentOrientationRules,
                    rotor: rotorRules,
                    balanceable: balanceableRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_PUMPS.SCREW_PUMP),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    numberOfThreads: numberOfThreadsRules,
                    idlerShaftSpeed: idlerShaftSpeedRules,
                    timingGear: timingGearRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_PUMPS.AXIAL_FLOW_PROPELLER_PUMP),
                    bearingType: bearingTypeRules,
                    numberOfStagesType: numberOfStagesTypeRules,
                    numberOfStages: numberOfStagesRules([NUMBER_OF_STAGES_TYPE_BLADES]),
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    componentOrientation: componentOrientationRules,
                    rotor: rotorRules,
                    balanceable: balanceableRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_PUMPS.RECIPROCATING_PUMP),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    pistonRate: pistonRateRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_PUMPS.SWASH_PLATE_AXIAL_PISTON_PUMP),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    pistonRate: pistonRateRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_PUMPS.LOBED_PUMP),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    numberOfLobes: numberOfLobesRules,
                    timingGear: timingGearRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_PUMPS.SLIDING_VANE_PUMP),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    numberOfVanes: numberOfVanesRules,
                    rotor: rotorRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_PUMPS.GEAR_PUMP),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    numberOfGearTeeth: numberOfGearTeethRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_PUMPS.SCROLL_PUMP),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                }),
            ], 'Variant is required'),
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_FAN_AND_BLOWER),
            variation: variant('type', [
                object({
                    type: literal(VARIATION_TYPE_FAN_BLOWER.AXIAL_FLOW_PROPELLER_FAN),
                    bearingType: bearingTypeRules,
                    numberOfStagesType: numberOfStagesTypeRules,
                    numberOfStages: numberOfStagesRules([NUMBER_OF_STAGES_TYPE_BLADES]),
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    componentOrientation: componentOrientationRules,
                    rotor: rotorRules,
                    balanceable: balanceableRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_FAN_BLOWER.SQUIRREL_CAGE),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    rotor: rotorRules,
                    numberOfBlades: numberOfBladesRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_FAN_BLOWER.CENTRIFUGAL_BLOWER),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    numberOfStagesType: numberOfStagesTypeRules,
                    numberOfStages: numberOfStagesRules([NUMBER_OF_STAGES_TYPE_BLADES]),
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                }),
                object({
                    type: literal(VARIATION_TYPE_FAN_BLOWER.LOBED_BLOWER),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    timingGear: timingGearRules,
                    numberOfLobes: numberOfLobesRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_FAN_BLOWER.SCREW_BLOWER),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    idlerShaftSpeed: idlerShaftSpeedRules,
                    timingGear: timingGearRules,
                }),
            ], 'Variant is required'),
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_COMPRESSOR),
            variation: variant('type', [
                object({
                    type: literal(VARIATION_TYPE_COMPRESSOR.CENTRIFUGAL_COMPRESSOR),
                    bearingType: bearingTypeRules,
                    numberOfStagesType: numberOfStagesTypeRules,
                    numberOfStages: numberOfStagesRules([NUMBER_OF_STAGES_TYPE_VANES]),
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    componentOrientation: componentOrientationRules,
                    rotor: rotorRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_COMPRESSOR.SCREW_COMPRESSOR),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    idlerShaftSpeed: idlerShaftSpeedRules,
                    timingGear: timingGearRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_COMPRESSOR.LOBED_COMPRESSOR),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    numberOfLobes: numberOfLobesRules,
                    timingGear: timingGearRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_COMPRESSOR.AXIAL_FLOW_COMPRESSOR),
                    bearingType: bearingTypeRules,
                    numberOfStagesType: numberOfStagesTypeRules,
                    numberOfStages: numberOfStagesRules([NUMBER_OF_STAGES_TYPE_BLADES]),
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    componentOrientation: componentOrientationRules,
                    rotor: rotorRules,
                    balanceable: balanceableRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_COMPRESSOR.SLIDING_VANE_ROTARY_VANE_COMPRESSOR),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    rotor: rotorRules,
                    numberOfVanes: numberOfVanesRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_COMPRESSOR.SWASH_PLATE_AXIAL_PISTON_COMPRESSOR),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    pistonRate: pistonRateRules,
                }),
                object({
                    type: literal(VARIATION_TYPE_COMPRESSOR.SCROLL_COMPRESSOR),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                }),
                object({
                    type: literal('Multistage compressor or compressor with internal gear'),
                }),
                object({
                    type: literal(VARIATION_TYPE_COMPRESSOR.RECIPROCATING_COMPRESSOR),
                    componentOrientation: componentOrientationRules,
                    bearingType: bearingTypeRules,
                    bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
                    bearingMonitoredPositions: bearingMonitoredPositionsRules([
                        entryBoxNumber + 1,
                        entryBoxNumber + 2,
                        entryBoxNumber + 3,
                        entryBoxNumber + 4,
                    ]),
                    pistonRate: pistonRateRules,
                }),
            ], 'Variant is required'),
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_GENERATOR),
            bearingType: bearingTypeRules,
            bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
            bearingMonitoredPositions: bearingMonitoredPositionsRules([
                entryBoxNumber + 1,
                entryBoxNumber + 2,
                entryBoxNumber + 3,
                entryBoxNumber + 4,
            ]),
            numberOfPoles: numberOfPolesRules,
            componentOrientation: componentOrientationRules,
            rotor: rotorRules,
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_CENTRIFUGE),
            type: string('Subtype is required'),
            bearingType: bearingTypeRules,
            bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
            bearingMonitoredPositions: bearingMonitoredPositionsRules([
                entryBoxNumber + 1,
                entryBoxNumber + 2,
                entryBoxNumber + 3,
                entryBoxNumber + 4,
            ]),
            componentOrientation: componentOrientationRules,
            rotor: rotorRules,
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_HAMMER_MILL),
            componentOrientation: componentOrientationRules,
            bearingType: bearingTypeRules,
            bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
            bearingMonitoredPositions: bearingMonitoredPositionsRules([
                entryBoxNumber + 1,
                entryBoxNumber + 2,
                entryBoxNumber + 3,
                entryBoxNumber + 4,
            ]),
            rotor: rotorRules,
            numberOfRowsOfHammers: optional(number()),
            fixedHammer: optional(boolean()),
            pivoting: optional(boolean()),
            solidPlateAssembly: optional(boolean()),
            keyedPlateAssembly: optional(boolean()),
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_CONVEYOR_BELT_DRAG),
            componentOrientation: componentOrientationRules,
            bearingType: bearingTypeRules,
            bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
            bearingMonitoredPositions: bearingMonitoredPositionsRules([
                entryBoxNumber + 1,
                entryBoxNumber + 2,
                entryBoxNumber + 3,
                entryBoxNumber + 4,
            ]),
            headSprocketToothCount: optional(number()),
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_CONVEYOR_SCREW),
            componentOrientation: componentOrientationRules,
            bearingType: bearingTypeRules,
            bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
            bearingMonitoredPositions: bearingMonitoredPositionsRules([
                entryBoxNumber + 1,
                entryBoxNumber + 2,
                entryBoxNumber + 3,
                entryBoxNumber + 4,
            ]),
        }),
        object({
            subtype: literal(DRIVEN_SUBTYPE_AGITATOR_MIXER),
            componentOrientation: componentOrientationRules,
            bearingType: bearingTypeRules,
            numberOfElements: numberOfElementsRules,
            bearingMonitoredPositionsVariant: bearingMonitoredPositionsVariantRules,
            bearingMonitoredPositions: bearingMonitoredPositionsRules([
                entryBoxNumber + 1,
                entryBoxNumber + 2,
                entryBoxNumber + 3,
                entryBoxNumber + 4,
            ]),
            rotor: rotorRules,
        }),
    ], 'Subtype is required'),
}); };
var drivenFifthStepSchema = {
    manufacturer: manufacturerRules,
    model: modelRules,
    notes: optional(string()),
};
export var drivenSchema = function (entryBoxNumber) {
    return object({
        isGearbox: literal(false),
        data: variant('step', [
            object({
                step: literal(1),
            }),
            object({
                step: literal(2),
            }),
            variant('isDriven', [
                object(__assign({ step: literal(3) }, drivenThirdStepSchema)),
                foundationSchema,
            ]),
            object(__assign(__assign({ step: literal(4) }, drivenThirdStepSchema), drivenFourthStepSchema(entryBoxNumber))),
            object(__assign(__assign({ step: literal(5) }, drivenThirdStepSchema), drivenFourthStepSchema(entryBoxNumber))),
            object(__assign(__assign(__assign({ step: literal(6) }, drivenThirdStepSchema), drivenFourthStepSchema(entryBoxNumber)), drivenFifthStepSchema)),
        ], 'Step is required'),
    });
};
