import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EquipmentTree } from './components/EqupmentTree';
import { NotAssignedIP } from './components/NotAssignedIP';
import { SetupContent } from './components/SetupContent';
import styles from './styles.module.scss';
export var AssetBuilder = function (_a) {
    var equipmentId = _a.equipmentId;
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("aside", { className: styles.aside, children: [_jsx(EquipmentTree, { equipmentId: equipmentId }), _jsx(NotAssignedIP, { equipmentId: equipmentId })] }), _jsx(SetupContent, { equipmentId: equipmentId })] }));
};
AssetBuilder.displayName = 'AssetBuilder';
