import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Badge } from 'reactstrap';
import { ACTION_LIST } from '../../../constants';
var StatusLabelRemastered = function (_a) {
    var status = _a.status, isTroubleshootingHold = _a.isTroubleshootingHold, isTroubleshootingWaiting = _a.isTroubleshootingWaiting, _b = _a.decline, decline = _b === void 0 ? '' : _b, _c = _a.commentsCount, commentsCount = _c === void 0 ? 0 : _c, isWaitesNew = _a.isWaitesNew;
    if (isTroubleshootingHold) {
        return (_jsxs(Badge, { className: "position-relative", color: isTroubleshootingWaiting
                ? ACTION_LIST.STATUS_COLORS[ACTION_LIST.STATE_WAITING]
                : ACTION_LIST.IS_TROUBLESHOOTING_HOLD_COLOR, children: [isTroubleshootingWaiting ? (_jsxs("span", { children: ["Troubleshooting Hold - ", _jsx("br", {}), "Waiting"] })) : (ACTION_LIST.IS_TROUBLESHOOTING_HOLD_NAME), ' ', commentsCount > 0 && _jsx("span", { className: "sub-badge", children: commentsCount }), decline &&
                    +status !== ACTION_LIST.STATE_VERIFYING &&
                    +status !== ACTION_LIST.STATE_CLOSED &&
                    +status !== ACTION_LIST.STATE_ARCHIVE && _jsx("span", { className: "sub-badge sub-badge-right", children: "D" })] }));
    }
    if (isWaitesNew && +status !== ACTION_LIST.STATE_CLOSED && +status !== ACTION_LIST.STATE_ARCHIVE) {
        return (_jsxs(Badge, { className: "position-relative", color: "danger", children: ["WAITES NEW ", commentsCount > 0 && _jsx("span", { className: "sub-badge", children: commentsCount }), decline &&
                    +status !== ACTION_LIST.STATE_VERIFYING &&
                    +status !== ACTION_LIST.STATE_CLOSED &&
                    +status !== ACTION_LIST.STATE_ARCHIVE && _jsx("span", { className: "sub-badge sub-badge-right", children: "D" })] }));
    }
    return (_jsxs(Badge, { className: "position-relative", color: ACTION_LIST.STATUS_COLORS[status], children: [ACTION_LIST.STATUS_NAMES[status], ' ', commentsCount > 0 && _jsx("span", { className: "sub-badge", children: commentsCount }), decline &&
                +status !== ACTION_LIST.STATE_VERIFYING &&
                +status !== ACTION_LIST.STATE_CLOSED &&
                +status !== ACTION_LIST.STATE_ARCHIVE && _jsx("span", { className: "sub-badge sub-badge-right", children: "D" })] }));
};
export default memo(StatusLabelRemastered);
