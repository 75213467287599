var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'clsx';
import { memo, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { NUMBER_OF_STAGES_TYPE_BLADES, NUMBER_OF_STAGES_TYPE_GEAR_TEETH, NUMBER_OF_STAGES_TYPE_SHAFT_RPM, NUMBER_OF_STAGES_TYPE_SPEED_RATIO, NUMBER_OF_STAGES_TYPE_VANES, } from '../../../../constants';
import assetBuilderStyles from '../../styles.module.scss';
import styles from './styles.module.scss';
export var NumberOfStages = memo(function (_a) {
    var control = _a.control, isDisabled = _a.isDisabled, numberOfStagesField = _a.numberOfStagesField, numberOfStagesTypeField = _a.numberOfStagesTypeField, numberOfStagesTypeFixed = _a.numberOfStagesTypeFixed;
    var _b = useController({
        name: numberOfStagesField,
        control: control,
    }).field, numberOfStages = _b.value, onChangeNumberOfStages = _b.onChange;
    var _c = useController({
        name: numberOfStagesTypeField,
        control: control,
    }).field, numberOfStagesType = _c.value, onChangeNumberOfStagesType = _c.onChange;
    var onChange = function (field, value, resetStages) {
        var _a;
        onChangeNumberOfStages(field === 'stages'
            ? {
                stages: value,
                type: numberOfStagesType,
            }
            : __assign(__assign(__assign({}, numberOfStages), resetStages), (_a = {}, _a[field] = value, _a)));
    };
    var countStageItems = !!numberOfStages.stages && !!numberOfStagesType
        ? ([
            NUMBER_OF_STAGES_TYPE_VANES,
            NUMBER_OF_STAGES_TYPE_BLADES,
            NUMBER_OF_STAGES_TYPE_SPEED_RATIO,
            NUMBER_OF_STAGES_TYPE_SHAFT_RPM,
        ].includes(numberOfStagesType)
            ? [[1], [2], [3], [4]]
            : [
                [1, 2],
                [3, 4],
                [5, 6],
                [7, 8],
            ]).slice(0, numberOfStages.stages)
        : [];
    var getNamesData = function () {
        switch (numberOfStagesType) {
            case NUMBER_OF_STAGES_TYPE_GEAR_TEETH:
                return {
                    title: 'Number of Stages',
                    placeholder: 'T',
                    itemName: 'Stage ',
                };
            case NUMBER_OF_STAGES_TYPE_VANES:
                return {
                    title: 'Number of Stages',
                    placeholder: 'PV',
                    itemName: 'Stage ',
                };
            case NUMBER_OF_STAGES_TYPE_BLADES:
                return {
                    title: 'Number of Stages',
                    placeholder: 'PB',
                    itemName: 'Stage ',
                };
            case NUMBER_OF_STAGES_TYPE_SPEED_RATIO:
                return {
                    title: 'Number of Stages',
                    placeholder: '',
                    itemName: 'Ratio ',
                };
            case NUMBER_OF_STAGES_TYPE_SHAFT_RPM:
                return {
                    title: 'Number of Stages',
                    placeholder: '',
                    itemName: 'Shaft ',
                };
            default:
                return {
                    title: 'Number of Stages',
                    placeholder: '',
                    itemName: 'Stage ',
                };
        }
    };
    useEffect(function () {
        if (!numberOfStagesTypeFixed) {
            return;
        }
        onChangeNumberOfStagesType(numberOfStagesTypeFixed);
    }, [numberOfStagesTypeFixed]);
    return (_jsxs("div", { className: styles.position, children: [_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: getNamesData().title }) }), _jsx("div", { className: assetBuilderStyles.sectionButtons, children: [1, 2, 3, 4].map(function (stages) {
                            var _a;
                            return (_jsx("button", { disabled: isDisabled, onClick: function () { return onChange('stages', stages); }, className: cx(assetBuilderStyles.button, (_a = {},
                                    _a[assetBuilderStyles.active] = numberOfStages.stages === stages,
                                    _a)), children: stages }, stages));
                        }) })] }), ![NUMBER_OF_STAGES_TYPE_VANES, NUMBER_OF_STAGES_TYPE_BLADES].includes(numberOfStagesType) && (_jsxs("div", { className: cx(assetBuilderStyles.section, styles.typeButtons), children: [_jsx("h3", { className: cx(assetBuilderStyles.sectionTitle, styles.opacityZero), children: "Type" }), _jsx("div", { className: assetBuilderStyles.sectionButtons, children: [
                            NUMBER_OF_STAGES_TYPE_GEAR_TEETH,
                            NUMBER_OF_STAGES_TYPE_SPEED_RATIO,
                            NUMBER_OF_STAGES_TYPE_SHAFT_RPM,
                        ].map(function (type) {
                            var _a;
                            return (_jsx("button", { disabled: isDisabled, onClick: function () {
                                    onChangeNumberOfStagesType(type);
                                    onChange('type', type, {
                                        stage1: undefined,
                                        stage2: undefined,
                                        stage3: undefined,
                                        stage4: undefined,
                                        stage5: undefined,
                                        stage6: undefined,
                                        stage7: undefined,
                                        stage8: undefined,
                                    });
                                }, className: cx(assetBuilderStyles.button, (_a = {},
                                    _a[assetBuilderStyles.active] = numberOfStagesType === type,
                                    _a)), children: type }, type));
                        }) })] })), _jsx("div", { className: styles.inputGroupWrapper, children: countStageItems.map(function (items, index) { return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsxs("span", { className: assetBuilderStyles.required, children: [getNamesData().itemName, index + (numberOfStagesType === NUMBER_OF_STAGES_TYPE_SHAFT_RPM ? 2 : 1)] }) }), _jsx("div", { className: styles.inputGroup, children: items.map(function (item, i) {
                                var _a;
                                var _b;
                                return (_jsx("div", { children: _jsx("input", { disabled: isDisabled, type: "number", className: cx(assetBuilderStyles.input, styles.inputStyle, (_a = {},
                                            _a[styles.bigInput] = items.length !== 2,
                                            _a)), placeholder: getNamesData().placeholder +
                                            (getNamesData().placeholder ? index * items.length + i + 1 : ''), value: (_b = numberOfStages['stage' + item]) !== null && _b !== void 0 ? _b : '', onChange: function (e) {
                                            return onChange("stage".concat(item), e.target.value ? Number(e.target.value) : undefined);
                                        }, min: 1 }) }, item));
                            }) })] })); }) })] }));
});
NumberOfStages.displayName = 'NumberOfStages';
