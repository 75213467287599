// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firstBlockPosition__MO04T {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-gap: 16px;
}

.secondBlockPosition__u7b54 {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-gap: 16px;
}

.oilPosition__rQNdN {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-gap: 16px;
  align-items: center;
}

.componentTypeGroup___ib36 {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  grid-gap: 16px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./../src/remastered/components/charts/AssetBuilder/components/SetupContent/components/NotFirstAssetContent/components/GearboxContent/styles.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,kCAAA;EACA,cAAA;AACD;;AAEA;EACC,aAAA;EACA,kCAAA;EACA,cAAA;AACD;;AAGA;EACC,aAAA;EACA,kCAAA;EACA,cAAA;EACA,mBAAA;AAAD;;AAIA;EACC,aAAA;EACA,kCAAA;EACA,cAAA;EACA,mBAAA;AADD","sourcesContent":[".firstBlockPosition {\n\tdisplay: grid;\n\tgrid-template-columns: 2fr 3fr 1fr;\n\tgrid-gap: 16px;\n}\n\n.secondBlockPosition {\n\tdisplay: grid;\n\tgrid-template-columns: 2fr 3fr 1fr;\n\tgrid-gap: 16px;\n}\n\n\n.oilPosition {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 2fr 2fr;\n\tgrid-gap: 16px;\n\talign-items: center;\n}\n\n\n.componentTypeGroup {\n\tdisplay: grid;\n\tgrid-template-columns: 2fr 3fr 3fr;\n\tgrid-gap: 16px;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstBlockPosition": `firstBlockPosition__MO04T`,
	"secondBlockPosition": `secondBlockPosition__u7b54`,
	"oilPosition": `oilPosition__rQNdN`,
	"componentTypeGroup": `componentTypeGroup___ib36`
};
module.exports = ___CSS_LOADER_EXPORT___;
