import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import assetBuilderStyles from '../../../../../../../../styles.module.scss';
import { BearingMonitoredPositions } from '../../../../../../../BearingMonitoredPositions';
import { BearingType } from '../../../../../../../BearingType';
import { CentrifugeSelect } from '../../../../../../../CentrifugeSelect';
import { ComponentOrientation } from '../../../../../../../ComponentOrientation';
import { Rotor } from '../../../../../../../Rotor';
import styles from '../../styles.module.scss';
export var CentrifugeContent = memo(function (_a) {
    var isDisabled = _a.isDisabled, equipmentId = _a.equipmentId, entryBoxNumber = _a.entryBoxNumber;
    var _b = useFormContext(), clearErrors = _b.clearErrors, control = _b.control;
    var error = useController({
        name: 'data.drivenTypeVariant.type',
        control: control,
    }).fieldState.error;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.positionThreeColumn, children: _jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: _jsx("span", { className: assetBuilderStyles.required, children: "Subtype" }) }), _jsx(CentrifugeSelect, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.type', clearError: function () { return clearErrors('data.drivenTypeVariant.type'); } }), _jsx("div", { className: assetBuilderStyles.sectionErrors, children: (error === null || error === void 0 ? void 0 : error.message) ? 'Subtype is required' : '' })] }) }), _jsxs("div", { className: styles.componentOrientationAndBearing, children: [_jsx(ComponentOrientation, { isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.componentOrientation' }), _jsx(BearingType, { isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.bearingType' })] }), _jsx(BearingMonitoredPositions, { control: control, isDisabled: isDisabled, bearingMonitoredPositionsFieldName: 'data.drivenTypeVariant.bearingMonitoredPositions', bearingMonitoredPositionsVariantFieldName: 'data.drivenTypeVariant.bearingMonitoredPositionsVariant', equipmentId: equipmentId, schemas: [[entryBoxNumber + 1, entryBoxNumber + 2]], hasGap: true }), _jsx("div", { className: styles.positionThreeColumn, children: _jsx(Rotor, { control: control, isDisabled: isDisabled, fieldName: 'data.drivenTypeVariant.rotor' }) })] }));
});
CentrifugeContent.displayName = 'CentrifugeContent';
