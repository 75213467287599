import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { FiEdit } from 'react-icons/fi';
import assetBuilderStyles from '../../styles.module.scss';
export var BackToStep = memo(function (_a) {
    var isShow = _a.isShow, callback = _a.callback, isViewMode = _a.isViewMode;
    if (isViewMode) {
        return;
    }
    return (_jsx("div", { className: assetBuilderStyles.sectionsEditBlock, onClick: callback, children: isShow && _jsx(FiEdit, {}) }));
});
BackToStep.displayName = 'BackToStep';
