var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import Highcharts from 'highcharts';
import { cloneDeep as _cloneDeep, get as _get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaSpinner, FaTimes } from 'react-icons/fa';
import { HiCursorClick } from 'react-icons/hi';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Alert, Button, ButtonGroup, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import Endpoints from '../../../api/endpoints/endpoints';
import { FREQUENCY_TYPES, READING_TYPES } from '../../../constants';
import { usePrevious } from '../../../customHooks';
import { assetFaultFrequencyQueryKey } from '../../../remastered/services/useAssetFaultFrequenciesQuery';
import DefaultTooltip from '../../components/DefaultTooltip';
import { FrequencyConverter } from '../../components/charts/chartsWrapper/features/fftTwf/frequencyConverter';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { useFaultFrequencyComponentQuery } from '../../services/faultFrequency/useFaultFrequencyComponentQuery';
import { useFaultFrequencyLabelQuery } from '../../services/faultFrequency/useFaultFrequencyLabelQuery';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { FftChartsStore } from '../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
import { useConfirmModalActions } from '../../store/global/useConfirmModalStore';
import { ComponentTypeSelect } from './components/ComponentTypeSelect';
import { useComponentTypeSelectActions } from './components/ComponentTypeSelect/stores/useComponentTypeSelectStore';
import { FFDescriptionSelect } from './components/FFDescriptionSelect';
import { FFLabelSelect } from './components/FFLabelSelect';
import { selectFFLabel, useFFLabelSelectActions } from './stores/useFFLabelSelectStore';
var SetFaultFrequencyModal = function () {
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var queryClient = useQueryClient();
    var ffLabel = selectFFLabel();
    var setFFLabel = useFFLabelSelectActions().setFFLabel;
    var setComponentType = useComponentTypeSelectActions().setComponentType;
    var FFComponentData = useFaultFrequencyComponentQuery().data;
    var _a = ChartFeaturesStore(function (state) { return ({
        setStatesChartFeaturesStore: state.setStates,
        setFaultFrequency: state.setFaultFrequency,
    }); }, shallow), setStatesChartFeaturesStore = _a.setStatesChartFeaturesStore, setFaultFrequency = _a.setFaultFrequency;
    var _b = InstallationPointStore(function (state) { return ({
        installationPoint: state.installationPoint,
        currentSpeedState: state.currentSpeed,
    }); }, shallow), installationPoint = _b.installationPoint, currentSpeedState = _b.currentSpeedState, equipment = _get(installationPoint, 'equipment') || {};
    var _c = useState(currentSpeedState), currentSpeed = _c[0], setCurrentSpeed = _c[1];
    var _d = FftChartsStore(function (state) { return ({
        faultFrequencies: state.faultFrequencies,
        setStatesFftChartsStore: state.setStatesFftChartsStore,
    }); }, shallow), faultFrequencies = _d.faultFrequencies, setStatesFftChartsStore = _d.setStatesFftChartsStore;
    var _e = useState({
        equipmentId: equipment.id,
        frequency: setFaultFrequency.faultFrequency.frequency,
        id: setFaultFrequency.faultFrequency.id || null,
        plotLinesCount: setFaultFrequency.faultFrequency.plotLinesCount || 1,
        installationPointId: setFaultFrequency.installationPointId || installationPoint.id,
        frequencyType: setFaultFrequency.faultFrequency.frequencyType || 'Orders',
        type: 'single',
    }), data = _e[0], setData = _e[1];
    var FFLabelData = useFaultFrequencyLabelQuery({
        equipmentById: installationPoint.equipment_id,
    }).data;
    var setConfirmData = useConfirmModalActions().setConfirmData;
    useEffect(function () {
        if (!data.id) {
            return;
        }
        var FFLabel = FFLabelData === null || FFLabelData === void 0 ? void 0 : FFLabelData.find(function (_a) {
            var desc = _a.desc, label = _a.label;
            return desc === setFaultFrequency.faultFrequency.name || label === setFaultFrequency.faultFrequency.name;
        });
        if (!FFLabel) {
            return;
        }
        setFFLabel(FFLabel);
        var FFComponent = FFComponentData === null || FFComponentData === void 0 ? void 0 : FFComponentData.find(function (_a) {
            var id = _a.id;
            return id === FFLabel.componentId;
        });
        if (!FFComponent) {
            return;
        }
        setComponentType(FFComponent);
    }, [setFaultFrequency.faultFrequency.name, data.id, FFLabelData, FFComponentData]);
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    var prevFrequencyType = usePrevious(data.frequencyType);
    useEffect(function () {
        if (!prevFrequencyType || !data.frequencyType) {
            return;
        }
        setData(__assign(__assign({}, data), { frequency: parseFloat(FrequencyConverter.fromType(prevFrequencyType, data.frequency, currentSpeed).toType(data.frequencyType).value).toFixed(2) }));
    }, [data.frequencyType, currentSpeed]);
    var onCancel = function () {
        setFFLabel(null);
        setComponentType(null);
        setStatesChartFeaturesStore({
            setFaultFrequency: {
                visible: false,
                openedByChartIdentifier: setFaultFrequency.openedByChartIdentifier,
                faultFrequency: {},
            },
        });
    };
    var onCreate = function () {
        Endpoints[selectedSystemType].addFaultFrequency(__assign(__assign({}, data), { labelId: ffLabel === null || ffLabel === void 0 ? void 0 : ffLabel.id })).then(function (resp) {
            if (resp) {
                setLoading(false);
                if (resp.success) {
                    var updatedFaultFrequencies = _cloneDeep(faultFrequencies);
                    updatedFaultFrequencies.push(resp.result);
                    setStatesFftChartsStore({
                        faultFrequencies: updatedFaultFrequencies,
                    });
                    onCancel();
                    queryClient.invalidateQueries({
                        queryKey: assetFaultFrequencyQueryKey({ equipmentId: equipment.id }),
                    });
                    return toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
        });
    };
    var onUpdate = function () {
        Endpoints[selectedSystemType]
            .updateFaultFrequency(data.id, __assign(__assign({}, data), { labelId: ffLabel === null || ffLabel === void 0 ? void 0 : ffLabel.id }))
            .then(function (resp) {
            if (resp) {
                setLoading(false);
                if (resp.success) {
                    var updatedFaultFrequencies_1 = _cloneDeep(faultFrequencies);
                    updatedFaultFrequencies_1.forEach(function (faultFrequency, index) {
                        if (faultFrequency.id === data.id) {
                            updatedFaultFrequencies_1[index] = resp.result;
                        }
                    });
                    setStatesFftChartsStore({
                        faultFrequencies: updatedFaultFrequencies_1,
                    });
                    queryClient.invalidateQueries({
                        queryKey: assetFaultFrequencyQueryKey({ equipmentId: equipment.id }),
                    });
                    onCancel();
                    return toast.success(resp.message, { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                }
                return toast.error(resp.message, { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
        });
    };
    var onSave = function () {
        var _a, _b, _c, _d;
        var handler = function () {
            setLoading(true);
            if (!ffLabel) {
                setLoading(false);
                return toast.error('Field Label is required', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
            if (!data.installationPointId) {
                setLoading(false);
                return toast.error('Field Point name is required', { icon: _jsx(FaTimes, { style: { fill: '#198754' } }) });
            }
            if (data.id) {
                return onUpdate();
            }
            return onCreate();
        };
        if ((((_a = ffLabel === null || ffLabel === void 0 ? void 0 : ffLabel.equipmentOption) === null || _a === void 0 ? void 0 : _a.isExtendable) && ((_b = ffLabel === null || ffLabel === void 0 ? void 0 : ffLabel.equipmentOption) === null || _b === void 0 ? void 0 : _b.isPresent)) ||
            !!((_c = ffLabel === null || ffLabel === void 0 ? void 0 : ffLabel.equipmentOption) === null || _c === void 0 ? void 0 : _c.count)) {
            return setConfirmData({
                isVisible: true,
                textConfirmationModal: ((_d = ffLabel === null || ffLabel === void 0 ? void 0 : ffLabel.equipmentOption) === null || _d === void 0 ? void 0 : _d.count)
                    ? 'Fault Frequency with this description has already been specified, are you sure you want to save another one?'
                    : 'The settings for this Fault Frequency already exist, please check if the correct settings have been specified.',
                onConfirm: function () {
                    setConfirmData({
                        isVisible: false,
                    });
                    handler();
                },
            });
        }
        return handler();
    };
    var setHoverPointForFrequency = function () {
        var hoverPoint = null;
        if (setFaultFrequency.openedByChartIdentifier) {
            Highcharts.charts.forEach(function (chart) {
                var _a, _b, _c, _d, _e;
                if (((_a = chart === null || chart === void 0 ? void 0 : chart.userOptions) === null || _a === void 0 ? void 0 : _a.chartIdentifier) === setFaultFrequency.openedByChartIdentifier) {
                    hoverPoint = (_b = chart === null || chart === void 0 ? void 0 : chart.hoverPoint) === null || _b === void 0 ? void 0 : _b.x.toFixed((_e = (_d = READING_TYPES[(_c = chart === null || chart === void 0 ? void 0 : chart.userOptions) === null || _c === void 0 ? void 0 : _c.chartIdentifier]) === null || _d === void 0 ? void 0 : _d.precision) !== null && _e !== void 0 ? _e : 2);
                }
            });
        }
        if (!hoverPoint) {
            return;
        }
        setData(__assign(__assign({}, data), { frequency: parseFloat(FrequencyConverter.fromType(FREQUENCY_TYPES.HZ, hoverPoint, (equipment === null || equipment === void 0 ? void 0 : equipment.isAsset) ? currentSpeedState : currentSpeed).toType(data.frequencyType).value).toFixed(2) }));
    };
    var _g = useState([]), installPointsList = _g[0], setInstallPointsList = _g[1];
    var _h = useState(null), installPointsListLoading = _h[0], setInstallPointsListLoading = _h[1];
    useEffect(function () {
        if (installPointsListLoading !== null) {
            return;
        }
        setInstallPointsListLoading(true);
        Endpoints[selectedSystemType]
            .getInstallationPointsByEquipment(installationPoint.equipment_id)
            .then(function (data) {
            var _a;
            return setInstallPointsList((_a = data === null || data === void 0 ? void 0 : data.list) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                var name = _a.name, id = _a.id, speed = _a.speed;
                return { label: name, value: id, speed: speed };
            }));
        })
            .finally(function () { return setInstallPointsListLoading(false); });
    }, [installPointsListLoading]);
    useEffect(function () {
        var _a;
        if (!installPointsList.length) {
            return;
        }
        setCurrentSpeed(
        // @ts-ignore
        ((_a = installPointsList.find(function (el) { return el.value === (data === null || data === void 0 ? void 0 : data.installationPointId); })) === null || _a === void 0 ? void 0 : _a.speed) ||
            (equipment === null || equipment === void 0 ? void 0 : equipment.current_speed));
    }, [installPointsList]);
    return (_jsxs(Modal, { clleassName: "modal-response", size: "xl", toggle: onCancel, isOpen: true, children: [_jsxs(ModalHeader, { toggle: onCancel, children: ["Set fault/forcing frequency", loading && _jsx(FaSpinner, { className: "ms-2 spinner-anim" })] }), _jsxs(ModalBody, { className: "bearing-modal-content", children: [_jsxs(Row, { children: [!equipment.isAsset ? (_jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Point name:" }) }), _jsx(Select, { className: "select-custom-default", isSearchable: false, classNamePrefix: "customSelectStyleDefault", value: data.installationPointId
                                                ? installPointsList.find(function (el) { return el.value === (data === null || data === void 0 ? void 0 : data.installationPointId); })
                                                : null, options: installPointsList, isLoading: installPointsListLoading, onChange: function (ev) {
                                                var _a;
                                                setData(__assign(__assign({}, data), { installationPointId: ev.value }));
                                                setCurrentSpeed(
                                                // @ts-ignore
                                                ((_a = installPointsList.find(function (el) { return el.value === ev.value; })) === null || _a === void 0 ? void 0 : _a.speed) ||
                                                    (equipment === null || equipment === void 0 ? void 0 : equipment.current_speed));
                                            } })] }) })) : (_jsx(_Fragment, {})), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Equipment:" }) }), _jsx("p", { children: equipment.name })] }) }), _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Sensor RPM:" }) }), _jsx("p", { children: _get(equipment.assets, '0.speedSetting.base_running_speed') || currentSpeed })] }) })] }), _jsx("hr", {}), _jsxs(Row, { children: [_jsx(Col, { md: 2, children: _jsx(ComponentTypeSelect, {}) }), _jsx(Col, { md: 2, children: _jsx(FFDescriptionSelect, {}) }), _jsx(Col, { md: 2, children: _jsx(FFLabelSelect, {}) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Frequency:" }) }), _jsxs(InputGroup, { children: [_jsx(Input, { id: "set_fault_frequency_frequency", name: "set_fault_frequency_frequency", type: "number", value: data.frequency, onChange: function (ev) {
                                                        return setData(__assign(__assign({}, data), { frequency: ev.target.value }));
                                                    } }), _jsxs("div", { className: "input-group-append", children: [_jsx(Button, { color: "primary", onClick: setHoverPointForFrequency, "data-tooltip-id": "cursor", children: _jsx(HiCursorClick, {}) }), _jsx(DefaultTooltip, { id: "cursor", children: "Set value by cursor point" })] })] })] }) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Units:" }) }), _jsxs(Input, { id: "set_fault_frequency_frequency_type", name: "set_fault_frequency_frequency_type", type: "select", value: data.frequencyType, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { frequencyType: ev.target.value }));
                                            }, children: [_jsx("option", { value: "Hz", children: "Hz" }), _jsx("option", { value: "CPM", children: "CPM" }), _jsx("option", { value: "Orders", children: "Orders" })] })] }) }), _jsx(Col, { md: 2, children: _jsxs(FormGroup, { children: [_jsx(Label, { children: _jsx("b", { children: "Plot Lines:" }) }), _jsx(Input, { id: "set_fault_frequency_plot_lines", name: "set_fault_frequency_plot_lines", type: "number", value: data.plotLinesCount, onChange: function (ev) {
                                                return setData(__assign(__assign({}, data), { plotLinesCount: parseInt(ev.target.value) }));
                                            } })] }) })] }), setFaultFrequency.faultFrequency.name === null && (_jsx(Row, { children: _jsx(Col, { md: 12, children: _jsx(Alert, { color: "warning", children: "The Fault Frequency is not specified according to the standards, please select the correct component type and description for this FF, or delete it if it is not relevant" }) }) }))] }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", outline: true, color: "secondary", onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", onClick: onSave, disabled: loading, children: "Save" })] }) })] }));
};
export default SetFaultFrequencyModal;
