var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Endpoints from '../../../../api/endpoints/endpoints';
import { METRIC_KEY, SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import UserSourceBadge from '../../../../shared/components/UserSourceBadge';
import DataTable from '../../../../shared/components/shared/Table/Table';
import { SystemTypeContext } from '../../../../shared/context/SystemTypeContext';
import { selectMeasure } from '../../../../shared/store/global/useGlobalStore';
import AlertLogContent from '../../../../widgets/alertLogContent';
import { DateTimeLabel } from '../../../../widgets/common';
var initialConfig = function (selectedSystemType) {
    return {
        columns: [
            {
                name: 'label',
                title: '',
                sortable: false,
                component: function (row) {
                    var permissions = _get(row, 'user.permissions') || [];
                    return (_jsx("span", { className: "mt-3", children: _jsx(UserSourceBadge, { permissions: permissions }) }));
                },
            },
            {
                name: selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'customer_name' : 'facility',
                title: selectedSystemType === SYSTEM_TYPE_REMASTERED ? 'Customer' : 'Facility',
                sortable: false,
            },
            {
                name: 'sensor_hex',
                title: 'Sensor',
                sortable: false,
            },
            {
                name: 'reading_type',
                title: 'Reading Type',
                sortable: false,
            },
            {
                name: 'old_data',
                title: 'Old data',
                sortable: false,
                component: function (row) {
                    return (_jsx(AlertLogContent, { readingTypeId: row.val2, cautionValue: +_get(row.old_data, 'levels.caution'), warningValue: +_get(row.old_data, 'levels.warning') }));
                },
            },
            {
                name: 'new_data',
                title: 'New data',
                sortable: false,
                component: function (row) {
                    return (_jsx(AlertLogContent, { readingTypeId: row.val2, isNewData: true, cautionValue: +_get(row.new_data, 'levels.caution'), warningValue: +_get(row.new_data, 'levels.warning') }));
                },
            },
            {
                name: 'date_create',
                title: 'Time',
                sortable: false,
                component: function (row) {
                    return _jsx(DateTimeLabel, { id: "default-alert-log-".concat(row.id), dateTime: row.date_create });
                },
            },
            {
                name: 'user_name',
                title: 'User',
                sortable: false,
            },
        ],
        sort: [
            {
                field: 'date_create',
                direction: 'desc',
            },
        ],
        pagination: {
            disabled: true,
        },
        search: '',
        disabledSearch: true,
        hideTotalLabel: true,
        data: [],
        loader: false,
    };
};
var fetch = function (config, setConfig, setSmallLoader, selectedInstallationPointId, selectedReadingTypeIds, selectedSystemType, measure) {
    setSmallLoader(true);
    Endpoints[selectedSystemType]
        .getAlertLogBySensor({
        query: {
            installationPointId: selectedInstallationPointId,
            readingTypes: selectedReadingTypeIds,
            selected_facility_metric: METRIC_KEY[measure],
        },
    })
        .then(function (resp) {
        setSmallLoader(false);
        if (resp) {
            setConfig(__assign(__assign({}, config), { data: resp.data }));
        }
    });
};
export var AlertLogTab = function (_a) {
    var setSmallLoader = _a.setSmallLoader, installationPointId = _a.installationPointId, selectedReadingTypeIds = _a.selectedReadingTypeIds;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _b = useState(initialConfig(selectedSystemType)), config = _b[0], setConfig = _b[1];
    var measure = selectMeasure();
    useEffect(function () {
        fetch(config, setConfig, setSmallLoader, installationPointId, selectedReadingTypeIds, selectedSystemType, measure);
    }, [selectedReadingTypeIds, installationPointId]);
    return (_jsx(Row, { className: "g-1 mt-2", children: _jsx(Col, { children: _jsx(DataTable, { config: config }) }) }));
};
