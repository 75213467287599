import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import RichTextEditor from '../../../../widgets/TextEditor/TextEditor';
import useToastErrorAlert from '../../../hooks/useToastErrorAlert';
import { selectIsVisibleChangeToHardwareIssueModal } from '../../../store/AIPreAi/useActionItemModalsStore';
import { changeToHardwareIssueSchema } from './schemas';
var ChangeToHardwareIssueModal = function (_a) {
    var onCancel = _a.onCancel, customerId = _a.customerId, changeToHardwareIssueMutate = _a.changeToHardwareIssueMutate, changeToHardwareIssueIsLoading = _a.changeToHardwareIssueIsLoading, _b = _a.storage, storage = _b === void 0 ? null : _b, onSuccess = _a.onSuccess;
    var actionItemId = useParams().id;
    var commentRef = useRef('');
    var isVisibleChangeToHardwareIssueModal = selectIsVisibleChangeToHardwareIssueModal();
    var _c = useForm({
        defaultValues: {
            comment: '',
        },
        resolver: changeToHardwareIssueSchema,
    }), setValue = _c.setValue, errors = _c.formState.errors, handleSubmit = _c.handleSubmit;
    useToastErrorAlert(errors);
    var onConfirm = handleSubmit(function () {
        changeToHardwareIssueMutate({
            action_list_id: actionItemId,
            comment: commentRef.current,
        }, {
            onSuccess: function () {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                toast.success('Successfully changed to network issue', {
                    icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }),
                });
                commentRef.current = '';
                onCancel();
            },
        });
    });
    return (_jsxs(Modal, { size: "lg", className: "modal-response", toggle: onCancel, isOpen: isVisibleChangeToHardwareIssueModal, children: [_jsx(ModalHeader, { toggle: onCancel, children: "Change to Network Issues" }), _jsx(ModalBody, { children: _jsx(Form, { children: _jsxs(FormGroup, { children: [_jsx(Label, { for: "textarea", children: "The reason (optional):" }), _jsx(RichTextEditor, { customerId: customerId, storage: storage, content: commentRef.current, onChange: function (content) { return (commentRef.current = content); } })] }) }) }), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, { size: "sm", color: "secondary", outline: true, onClick: onCancel, children: "Cancel" }), _jsx(Button, { size: "sm", color: "primary", disabled: changeToHardwareIssueIsLoading, onClick: function () {
                                setValue('comment', commentRef.current);
                                onConfirm();
                            }, children: "Confirm" })] }) })] }));
};
export default memo(ChangeToHardwareIssueModal);
