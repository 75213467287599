var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'clsx';
import { cloneDeep as _cloneDeep, head as _head } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Api from '../../../../../../../../../../../api/api';
import { useAssetTreeQuery } from '../../../../../../../services/useAssetTreeQuery';
import assetBuilderStyles from '../../../../../styles.module.scss';
import styles from './styles.module.scss';
export var Bearings = memo(function (_a) {
    var _b, _c, _d;
    var id = _a.id, isDisabled = _a.isDisabled, control = _a.control, activePoint = _a.activePoint, bearingMonitoredPositionsFieldName = _a.bearingMonitoredPositionsFieldName, timingGearFieldName = _a.timingGearFieldName, numberOfStagesField = _a.numberOfStagesField, equipmentId = _a.equipmentId, errors = _a.errors;
    var assetTreeData = useAssetTreeQuery({ equipmentId: equipmentId }).data;
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState(_cloneDeep(errors)), localErrors = _f[0], setLocalErrors = _f[1];
    useEffect(function () {
        setLocalErrors(_cloneDeep(errors));
    }, [errors]);
    var loadOptions = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsLoading(true);
            return [2 /*return*/, Api.get('all-analytics/bearing', {
                    query: {
                        search: inputValue,
                        self_bearings: 0,
                        mfr: '',
                        installation_point_id: activePoint.id,
                        pagination: {
                            page: 1,
                            pageSize: 50,
                            total: 0,
                        },
                    },
                })
                    .then(function (data) {
                    return data.list.map(function (_a) {
                        var name = _a.name, id = _a.id, mfr = _a.mfr, balls_rollers = _a.balls_rollers;
                        return {
                            label: name,
                            value: id,
                            mfr: mfr,
                            ballsRollers: balls_rollers,
                        };
                    });
                })
                    .finally(function () { return setIsLoading(false); })];
        });
    }); };
    var _g = useController({
        name: bearingMonitoredPositionsFieldName,
        control: control,
    }).field, value = _g.value, onChange = _g.onChange;
    var _h = useWatch({
        control: control,
        name: [timingGearFieldName !== null && timingGearFieldName !== void 0 ? timingGearFieldName : '', numberOfStagesField !== null && numberOfStagesField !== void 0 ? numberOfStagesField : ''],
    }), timingGear = _h[0], numberOfStages = _h[1];
    var countShaft = ((_c = (_b = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _b === void 0 ? void 0 : _b.lastShaft) !== null && _c !== void 0 ? _c : 0) +
        1 +
        ((timingGear === null || timingGear === void 0 ? void 0 : timingGear.value) ? 1 : 0) +
        ((_d = numberOfStages === null || numberOfStages === void 0 ? void 0 : numberOfStages.stages) !== null && _d !== void 0 ? _d : 0);
    var item = value.find(function (el) { return el.installationPointId === activePoint.id; });
    var onChangeBearingData = function (data, index) {
        // @ts-ignore
        if (data['type']) {
            setLocalErrors(localErrors.map(function (el) { return (__assign(__assign({}, el), { type: undefined })); }));
        }
        // @ts-ignore
        if (data['value']) {
            setLocalErrors(localErrors.map(function (el) { return (__assign(__assign({}, el), { value: undefined })); }));
        }
        // @ts-ignore
        if (data['plotlines']) {
            setLocalErrors(localErrors.map(function (el) { return (__assign(__assign({}, el), { plotlines: undefined })); }));
        }
        // @ts-ignore
        if (data['bearingId']) {
            setLocalErrors(localErrors.map(function (el) { return (__assign(__assign({}, el), { bearingId: undefined })); }));
        }
        if (isDisabled) {
            return;
        }
        onChange(value.map(function (el) {
            if (id === el.id) {
                return __assign(__assign({}, el), { bearings: el.bearings.map(function (bearing, i) {
                        if (i === index) {
                            return __assign(__assign({}, bearing), data);
                        }
                        return bearing;
                    }) });
            }
            return el;
        }));
    };
    var optionsBearingShaft = function () {
        var _a, _b;
        var arr = [];
        for (var i = ((_b = (_a = _head(assetTreeData === null || assetTreeData === void 0 ? void 0 : assetTreeData.data)) === null || _a === void 0 ? void 0 : _a.lastShaft) !== null && _b !== void 0 ? _b : 0) + 1; i <= countShaft; i++) {
            arr.push({ label: String(i), value: i });
        }
        return arr;
    };
    return (_jsx(_Fragment, { children: item.bearings.map(function (bearing, index) {
            var _a, _b, _c, _d, _e, _f;
            var _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("div", { className: styles.selectBlock, children: [_jsx("button", { disabled: isDisabled, onClick: function () { return onChangeBearingData({ type: 'ratio', value: null }, index); }, className: cx(assetBuilderStyles.button, (_a = {},
                                    _a[assetBuilderStyles.active] = bearing.type === 'ratio',
                                    _a[assetBuilderStyles.errorBorder] = (_h = (_g = localErrors[index]) === null || _g === void 0 ? void 0 : _g.type) === null || _h === void 0 ? void 0 : _h.message,
                                    _a)), children: "Ratio" }), _jsx("button", { disabled: isDisabled, onClick: function () { return onChangeBearingData({ type: 'onShaft', value: null }, index); }, className: cx(assetBuilderStyles.button, (_b = {},
                                    _b[assetBuilderStyles.active] = bearing.type === 'onShaft',
                                    _b[assetBuilderStyles.errorBorder] = (_k = (_j = localErrors[index]) === null || _j === void 0 ? void 0 : _j.type) === null || _k === void 0 ? void 0 : _k.message,
                                    _b)), children: "On Shaft" }), bearing.type && (_jsx(_Fragment, { children: bearing.type === 'ratio' ? (_jsx("input", { disabled: isDisabled, onChange: function (e) {
                                        return onChangeBearingData({ value: e.target.value ? Number(e.target.value) : undefined }, index);
                                    }, className: cx(assetBuilderStyles.input, (_c = {},
                                        _c[assetBuilderStyles.errorBorder] = (_m = (_l = localErrors[index]) === null || _l === void 0 ? void 0 : _l.value) === null || _m === void 0 ? void 0 : _m.message,
                                        _c)), type: "number", min: 1 })) : (_jsx(Select, { isDisabled: isDisabled, placeholder: "Select Shaft Number", classNamePrefix: "customSelectStyleDefault", className: cx((_d = {},
                                        _d[assetBuilderStyles.errorBorder] = (_p = (_o = localErrors[index]) === null || _o === void 0 ? void 0 : _o.value) === null || _p === void 0 ? void 0 : _p.message,
                                        _d)), value: (_q = optionsBearingShaft().find(function (el) { return el.value === bearing.value; })) !== null && _q !== void 0 ? _q : {
                                        label: '',
                                        value: '',
                                    }, onChange: function (el) {
                                        onChangeBearingData({ value: Number(el.value) }, index);
                                    }, options: optionsBearingShaft() })) }))] }), _jsx("div", { children: _jsx(AsyncSelect, { isDisabled: isDisabled, placeholder: "Bearing Id", classNamePrefix: "customSelectStyleDefault", isLoading: isLoading, className: cx((_e = {},
                                _e[assetBuilderStyles.errorBorder] = (_s = (_r = localErrors[index]) === null || _r === void 0 ? void 0 : _r.bearingId) === null || _s === void 0 ? void 0 : _s.message,
                                _e)), defaultOptions: true, loadOptions: loadOptions, onChange: function (el) {
                                onChangeBearingData({ bearingId: el === null || el === void 0 ? void 0 : el.value, mfr: String(el === null || el === void 0 ? void 0 : el.mfr), ballsRollers: String(el === null || el === void 0 ? void 0 : el.ballsRollers) }, index);
                            } }) }), _jsx("div", { children: (_t = bearing.mfr) !== null && _t !== void 0 ? _t : '---' }), _jsx("div", { children: (_u = bearing.ballsRollers) !== null && _u !== void 0 ? _u : '---' }), _jsx("div", { children: _jsx("input", { disabled: isDisabled, onChange: function (e) {
                                return onChangeBearingData({ plotlines: e.target.value ? Number(e.target.value) : undefined }, index);
                            }, className: cx(assetBuilderStyles.input, (_f = {},
                                _f[assetBuilderStyles.errorBorder] = (_w = (_v = localErrors[index]) === null || _v === void 0 ? void 0 : _v.plotlines) === null || _w === void 0 ? void 0 : _w.message,
                                _f)), type: "number", value: bearing.plotlines, min: 1 }) })] }, index));
        }) }));
});
Bearings.displayName = 'Bearings';
