import { useFaultFrequencyComponentQuery } from '../../../services/faultFrequency/useFaultFrequencyComponentQuery';
import { useComponentTypeSelectActions } from '../components/ComponentTypeSelect/stores/useComponentTypeSelectStore';
import { useFFLabelSelectActions } from '../stores/useFFLabelSelectStore';
export var useFFLabelAndDescHandlers = function () {
    var setFFLabel = useFFLabelSelectActions().setFFLabel;
    var setComponentType = useComponentTypeSelectActions().setComponentType;
    var FFComponentData = useFaultFrequencyComponentQuery().data;
    var onChangeHandler = function (FFLabel) {
        setFFLabel(FFLabel);
        var FFComponent = FFComponentData === null || FFComponentData === void 0 ? void 0 : FFComponentData.find(function (_a) {
            var id = _a.id;
            return id === FFLabel.componentId;
        });
        if (!FFComponent) {
            return;
        }
        setComponentType(FFComponent);
    };
    return { onChangeHandler: onChangeHandler };
};
