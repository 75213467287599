var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FaEdit } from 'react-icons/fa';
import { Card, CardBody, CardHeader, Input, Label } from 'reactstrap';
import { SYSTEM_TYPE_AMAZON, SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { get } from '../../../../storage/tableColumn';
import { config as baseConfig } from '../../../config/dashboard/customerCreatedAi/dataTableConfig';
import { SystemTypeContext } from '../../../context/SystemTypeContext';
import { fetch } from '../../../features/dashboard/customerCreatedAi';
import { reducer } from '../../../helper/reducer';
import DefaultTooltip from '../../DefaultTooltip';
import LinkWithPrefix from '../../LinkWithPrefix';
import DataTable from '../../shared/Table/Table';
var initialState = function (tableName, tableColumns, selectedSystemType) {
    var visibleState = get(tableName);
    var config = baseConfig(visibleState, selectedSystemType);
    return {
        config: __assign(__assign({}, config), { columns: __spreadArray(__spreadArray([
                {
                    name: '',
                    component: function (_a) {
                        var id = _a.id;
                        return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flag-customer-created", "data-tooltip-id": "customerCreated".concat(id) }), _jsx(DefaultTooltip, { place: "right", openEvents: { mouseenter: true }, id: "customerCreated".concat(id), children: "Customer's Created" })] }));
                    },
                },
                {
                    name: 'table_menu',
                    visible: true,
                    component: function () {
                        return _jsx(FaEdit, {});
                    },
                }
            ], tableColumns, true), config.columns, true) }),
    };
};
var CustomerCreatedAi = function (_a) {
    var _b = _a.deps, deps = _b === void 0 ? [] : _b, tableName = _a.tableName, tableColumns = _a.tableColumns, endpoint = _a.endpoint, title = _a.title;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var _c = useReducer(reducer, initialState(tableName, tableColumns, selectedSystemType)), state = _c[0], dispatch = _c[1];
    var config = state.config;
    var sort = config.sort;
    var _d = useState(true), hideHardwareAi = _d[0], setHideHardwareAi = _d[1];
    var _e = useState(true), onlyWaitesNew = _e[0], setOnlyWaitesNew = _e[1];
    var _f = useState(true), onlyCustomerRequest = _f[0], setOnlyCustomerRequest = _f[1];
    var isRemastered = selectedSystemType === SYSTEM_TYPE_REMASTERED;
    var isAmazon = selectedSystemType === SYSTEM_TYPE_AMAZON;
    useEffect(function () {
        fetch(state, dispatch, function () { return endpoint({ hideHardwareAi: hideHardwareAi, onlyCustomerRequest: onlyCustomerRequest, sort: sort, onlyWaitesNew: onlyWaitesNew }); });
    }, __spreadArray([hideHardwareAi, onlyCustomerRequest, onlyWaitesNew], deps, true));
    return (_jsxs(Card, { className: "dashboard-widget", children: [_jsxs(CardHeader, { className: "d-flex justify-content-between", style: { padding: 10 }, children: [_jsx(LinkWithPrefix, { to: "/action-list?state=customer_created", children: _jsx("div", { className: "float-start", children: title }) }), _jsxs("div", { className: "d-flex float-end", children: [isAmazon && (_jsxs(Label, { check: true, className: "ms-4 me-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setOnlyWaitesNew(!onlyWaitesNew); } }), _jsx("span", { children: "WAITES NEW" })] })), isRemastered && (_jsxs(Label, { check: true, className: "ms-4 me-2", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setOnlyCustomerRequest(!onlyCustomerRequest); } }), _jsx("span", { children: "Customer's Request" })] })), _jsxs(Label, { check: true, className: "ms-4", children: [_jsx(Input, { type: "checkbox", defaultChecked: true, onChange: function () { return setHideHardwareAi(!hideHardwareAi); } }), _jsx("span", { children: "Hide network Ais" })] })] })] }), _jsx(CardBody, { style: { padding: 0 }, children: _jsx(Scrollbars, { style: { height: '100%' }, children: _jsx(DataTable, { tableName: tableName, dispatchTable: dispatch, config: config }) }) })] }));
};
export default CustomerCreatedAi;
