import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useController } from 'react-hook-form';
import assetBuilderStyles from '../../styles.module.scss';
export var IdlerShaftSpeed = memo(function (_a) {
    var control = _a.control, fieldName = _a.fieldName, isDisabled = _a.isDisabled;
    var _b = useController({
        name: fieldName,
        control: control,
    }).field, value = _b.value, onChangeIdlerShaftSpeed = _b.onChange;
    return (_jsxs("div", { className: assetBuilderStyles.section, children: [_jsx("h3", { className: assetBuilderStyles.sectionTitle, children: "Idler shaft speed [IDL]" }), _jsx("input", { disabled: isDisabled, type: "number", className: assetBuilderStyles.input, value: value !== null && value !== void 0 ? value : '', onChange: function (e) { return onChangeIdlerShaftSpeed(e.target.value ? Number(e.target.value) : undefined); }, min: 1 })] }));
});
IdlerShaftSpeed.displayName = 'IdlerShaftSpeed';
